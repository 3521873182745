@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";

.readersTable {
  width: 100%;

  thead {
    display: none;

    th {
      padding: 5px 20px 5px 0;
      border-bottom: 1px solid map-get($typography-colors, sub-text-50);
    }
  }

  th,
  td {
    text-align: left;
  }

  th:first-of-type,
  td:first-of-type {
    width: auto;
  }

  tbody {
    tr {
      &:not(:first-of-type) td {
        border-top: none;
      }

      td {
        padding: 10px 10px 20px 0;
        text-align: left;
      }
    }

    @media screen and (max-width: 598px) {
      tr {
        &:not(:first-of-type) td {
          border-top: hidden !important;
        }
      }
    }
  }

  // Custom class for the `Link`
  .jumboBook {
    min-width: 180px;
    font-size: 1.3em;
  }

  &__spaced {
    &--wide {
      tbody tr td {
        font-size: 17px;
      }
    }

    &--narrow {
      tbody tr td {
        font-size: 15px;
      }
    }
  }
}

@media screen and (min-width: 599px) {
  .readersTable.tabletBreakpoint {
    thead {
      display: table-header-group;
    }

    tbody {
      tr {
        &:not(:first-of-type) td {
          border-top: hidden;
          border-bottom: 1px solid map-get($typography-colors, sub-text-50);
        }

        td {
          border-top: 1px solid map-get($typography-colors, sub-text-50);
          border-bottom: 1px solid map-get($typography-colors, sub-text-50);
        }
      }
    }
  }
}

@media #{$smMedia} {
  .readersTable {
    thead {
      display: table-header-group;
    }

    tbody {
      tr {
        &:not(:first-of-type) td {
          border-top: hidden;
          border-bottom: 1px solid map-get($typography-colors, sub-text-50);
        }

        td {
          border-top: 1px solid map-get($typography-colors, sub-text-50);
          border-bottom: 1px solid map-get($typography-colors, sub-text-50);
        }
      }
    }
  }
}
/* IE browser styles goes here */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .readersTable .jumboBook {
    max-width: 180px;
    -ms-word-wrap: break-word;
  }
}
