@import '../../styles/constants/colors.scss';

.label {
  display: block;
  margin: 15px 0 5px;
  font-weight: 600;
}

.card {

  border: 1px solid map-get($primary-colors, primary);
  padding: 15px;
  border-radius: 4px;
  background-color: $contrast-light-blue4;
}

.field {
  position: relative;
  display: table;
  border-collapse: separate;
  margin-bottom: 10px;
}

.input {
  border-radius: 4px 0 0 4px;
  border: 1px solid $color-light-grey;
  box-shadow: inset 0 1px 1px $contrast-light-shadow;
  display: table-cell;
  float: left;
  height: 40px;
  line-height: 34px;
  margin-bottom: 0;
  padding: 0 12px;
  position: relative;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
  z-index: 2;

  &:focus {
    border-color: $color-borders-v2;
    outline: none;
    box-shadow: inset 0 1px 1px $contrast-light-shadow,
    0 0 8px $contrast-light-blue3;
  }

  &:disabled {
    color: $color-text-grey-disabled;
    background: $color-grey-disabled;
  }
}

.generate {
  display: table-cell;
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: bottom;
}

.button {
  -moz-user-select: none;
  -ms-touch-action: manipulation;
  -ms-user-select: none;
  -webkit-user-select: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 0 4px 4px 0;
  border: 1px solid $color-light-border;
  color: map-get($primary-colors, primary);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 1.42857143;
  margin-bottom: 0;
  margin-left: -1px;
  padding: 6px 12px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  z-index: 2;
}

.caption {
  margin-bottom: 15px;
  margin-left: 1px;
}

.disabled {
  border: none;
  padding: 0;
  background: map-get($background-and-border-colors, background);
}
