@import '../../../../styles/constants/colors';
@import '../../../../styles/constants/breakpoints';

.mainSection {
  background-color: map-get($secondary-colors, teacher);
  text-align: center;
  position: relative;
  padding: 25px 0;
  color: $color-white;

  @media #{$mdMedia} {
    padding-bottom: 75px;
  }

  .title {
    h2 {
      margin: 22px 0 0;
    }

    h2, p {
      font-size: 25px;
    }

    p {
      opacity: 0.8;
    }

    @media #{$smMedia} {
      h2, p {
        font-size: 35px;
      }

      p {
        margin-bottom: 50px;
      }
    }
  }
}

.listItem {
  padding: 32px 45px 48px;

  &:last-of-type {
    padding-bottom: 0;
  }

  @media #{$mdMedia} {
    padding-left: 0;
    padding-right: 0;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $contrast-dark-accent-03;

    @media #{$mdMedia} {
      border-bottom: none
    }
  }

  p {
    font-weight: bold;
    font-size: 25px;
    margin: 22px 0 11px;

    @media #{$smMedia} {
      font-size: 35px;
    }
  }

  p + p {
    font-size: 21px;
    opacity: 0.8;
    margin: 0;
    max-width: 100%;

    @media #{$mdMedia} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &.buttonCol {
    align-items: center;

    // This is for the buttons
    a {
      font-size: 16px;
      border-radius: 5px;
      padding: 2px 10px;
      margin-top: 15px;

      &:first-of-type {
        margin-top: 22px;
      }
    }
  }
}

.image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 100%;

  @media #{$mdMedia} {
    width: 250px;
    height: 250px;
  }
}
