.searchContainer {
  margin: 20px;

  .fieldsList {
    list-style-type: disc;

    li {
      display: list-item;
      margin-left: 20px;
    }
  }

  .searchForm {
    display: flex;
    margin-top: 20px;
    gap: 20px;
  }

  .searchCriteriaContainer {
    flex-grow: 10;
  }

  .searchButtonsContainer {
    flex-grow: 2;

    div {
      display: flex;
      gap: 10px;
    }

    button {
      height: 48px;
    }
  }

  .cardRow {
    display: flex;
    width: 100%;
    min-height: 48px;
    margin-bottom: 10px;
    gap: 10px;

    span {
      width: 20%;
      margin-top: 10px;
    }

    select {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      height: 40px;
      border-width: 1px;
      border-style: solid;
      border-color: #ccd3da;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 7%);
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 7%);
      line-height: 38px;
    }

    div {
      flex-grow: 1;
      margin-left: 0;
    }

    &:first-of-type {
      margin-top: 10px;
    }
  }

  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .resultsContainer {
    margin-top: 20px;
  }
}
