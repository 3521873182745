.defaultGroupFormat {
  width: fit-content;
}

.groupLabel {
  font-size: 20px;
  margin: 14px 0;
}

.HORIZONTAL_GROUP {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
