.warningIcon {
  display: block;
  width: 50px;
  height: 50px;
  margin: 20px auto;
}

.errorMsg {
  text-align: center;
}

.detailWrapper {
  display: table;
  margin-bottom: 20px;

  div {
    display: table-row;

    label {
      display: table-cell;
    }

    span {
      padding-left: 10px;
    }
  }
}

.tableWrapper {
  max-width: 450px;
  margin-bottom: 20px;
}
