.panelHeading {
  max-width: 100%;

  h2 {
    margin: 1rem 0 0;
  }

  p {
    padding-top: 0.625rem;
  }
}
