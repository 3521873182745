@import "../../styles/constants/colors";

.badge {
  font-size: 0.875em;
  font-weight: 600;
  border: none;
  border-radius: 13px;
  margin-right: 0;
  padding: 3px 13px;
  color: $color-white;
  height: 26px;
  display: inline-block;
  line-height: 22px;
  padding: 0;

  &.button {
    display: inline-flex;
  }

  // LHS or full part depending if there is a button
  .value {
    padding: 3px 13px;
    line-height: 20px;
  }

  .hasOnClick {
    border-top-right-radius: 0;
    border-right-width: 1px;
    border-right-style: solid;
  }

  // RHS is a button that fires an action
  .button {
    padding: 6px;
    border-radius: 0 13px 13px 0;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  svg {
    fill: $color-white;
  }

  &.noCustomStyles {
    background-color: map-get($legacy-color-names, tooltip);
    height: auto;
      .value {
        padding: 3px 10px;
        line-height: 20px;
      }
  }
}
