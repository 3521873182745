@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.container {
  border: 1px solid map-get($background-and-border-colors, border);
  border-radius: 5px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.logo {
  width: 30px;
  height: 30px;
}

.title {
  margin-left: 7px;
  font-size: 21px;

  @media print {
    font-size: 14px;
  }
}

.booksRead {
  margin-left: 4px;
  color: map-get($hubColors, dark-gray);
  font-size: 16px;
  font-weight: 200;

  @media print {
    font-size: 14px;
    color: $color-black;
  }
}

.content {
  display: flex;
  flex-direction: column;
  @media #{$smMedia} {
    padding: 15px;
    flex-direction: row;
  }
}

.block {
  flex: 1;
  margin: 0px 15px;
  padding: 15px 0;
  @media #{$smMedia} {
    margin: 0;
    padding: 0;
  }
}

.clickedPercentageBlock {
  composes: block;
  border-bottom: 1px solid map-get($background-and-border-colors, border);
  @media #{$smMedia} {
    border-right: 1px solid map-get($background-and-border-colors, border);
    border-bottom: 0;
  }
}

.scorePercentageBlock {
  composes: block;
}

.blockTitle {
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: bold;
  text-align: center;
}

.percentageText {
  font-size: 25px;
  font-weight: bold;
  text-align: center;

  @media #{$smMedia} {
    font-size: 35px;
  }

  @media print {
    font-size: 25px;
  }
}
