@import '../../styles/constants/colors.scss';

.link {
  color: var(--accent-color);

  &:hover,
  &:focus {
    color: var(--accent-color-darker);
  }

  &:not(.underlined) {
    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &:focus {
    outline: 5px auto var(--accent-color);
    outline-offset: -2px;
  }
}

body[data-theme="orb-theme"] {
  .link {
    color: map-get($hubColors, product_finder_tab_unselected_text);
  }
}

/* increase specifity of selector to ensure color not overriden */
.linkDisabled.linkDisabled {
  display: inline-block;
  color: map-get($legacy-color-names, background-dark);
  pointer-events: none;
}
