@import "../../styles/constants/breakpoints.scss";

.tabWrapper {
  position: relative;
  margin-top: 37px;

  .printLinkStudents {
    position: absolute;
    top: 10px;
    right: 0;

    @media print {
      display: none;
    }

    @media #{$phoneMedia} {
      top: -20px;
    }
  }
}

.reportCard {
  margin-bottom: 37px;

  @media print {
    margin-top: 37px;
    font-size: 14px;
    page-break-inside: avoid;

    > *:last-child {
      flex-direction: column;

      :global(.sm4),
      :global(.sm8) {
        max-width: 100%;
        flex-basis: 100%;
      }

      :global(.sm4) {
        margin-bottom: 1em;

        :global(.row) {
          flex-wrap: nowrap;

          > * {
            max-width: none;
            flex-basis: auto;
            flex-grow: 1;

            &:first-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
