@import "../../styles/constants/colors";

.GradedReadersBanner {
  box-sizing: border-box;
  padding: 0.3rem;
  padding-left: 1rem;
  margin-bottom: 180px;
  width: 100vw;
  height: 88px;
  background: map-get($secondary-colors, class_average);
  margin: 1em 0 1em -1rem !important;

    td {
        text-align: left;
        width: 50vw;
    }
};

  .total {
    font-size: 1.8em;
  }

  .totalLabel {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }
