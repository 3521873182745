@import '../../../styles/constants/breakpoints';
@import '../../../styles/constants/colors';

.simpleHeader {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100vw;
  height: 61px;
  border-bottom: 1px solid $color-border-header;
  background: $color-white;

  nav {
    flex-grow: 1;

    > ul {
      display: flex;

      // Same height as .siteHeader:
      height: 61px;
      flex-direction: row;
      justify-content: center;

      @media #{$smMedia} {
        justify-content: space-between;
      }
    }
  }

  .oxfordLogo {
    svg {
      width: 105px;
      height: 30px;
      flex-shrink: 1;
      margin-top: 15px;
      fill: color(primary-dark);
    }

    .hubLogo {
      svg {
        width: 180px;
        height: 76px;
        margin: -7px 0;
      }
    }
  }
}
