@import "../../styles/constants/colors";

.redeemCodeBox {
  padding: 1rem;

  #validationIconFor-enterRedeemCode {
    padding: 28px 0 8px 10px;
  }
}

.mainContent {
  background: linear-gradient(180deg, map-get($primary-colors, primary) 0%, $contrast-light-blue 41%, $contrast-light-blue-gradient 100%);
}

.redeemCodePage {
  min-height: 100vh;
  justify-content: center;
  padding-top: calc(2rem);
  padding-bottom: 2rem;
  margin: auto;

  .checkRedeemCode {
    padding: 1px 10px 10px 10px;

    input {
      height: 45px;
      font-size: 20px;
      text-align: center;
    }

    .content {
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;

      h1 {
        font-size: 36px;
      }

      p {
        color: map-get($hubColors, dark-gray);
        font-size: 20px;
        word-wrap: break-word;
      }

      button {
        width: 180px;
        height: 50px;
        font-size: 21px;
      }
    }

    .linkItem {
      margin: 1rem 0;
    }
  }

  .linkItems {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px 5px;

    .content {
      padding-bottom: 10px;
      text-align: center;
    }

    a {
      position: relative;
      display: inline-block;
      width: 100%;
      font-size: 18px;
      text-decoration: none;

      &::after {
        position: absolute;
        top: 3px;
        width: 20px;
        height: 100%;
        content: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' fill='%234776ae' viewBox='0 0 24 24'><title>icon-external</title><path d='M20,5.17a.74.74,0,0,0-.21-.57.78.78,0,0,0-.57-.23H19l-3.53,0a.81.81,0,0,0-.79.8v.31a.87.87,0,0,0,.85.75h1.31l-6.51,6.49A.93.93,0,0,0,11.63,14l6.52-6.5V8.88a.82.82,0,0,0,.24.59.72.72,0,0,0,.55.22h.26A.76.76,0,0,0,20,8.94V5.17Z'/><path d='M17.36,10.53a.93.93,0,0,0-.93.92h0v6.34H5.85V7.93h7.06a.93.93,0,0,0,0-1.86H5.42A1.43,1.43,0,0,0,4,7.5V18.22a1.43,1.43,0,0,0,1.42,1.42H16.86a1.43,1.43,0,0,0,1.42-1.42V11.45h0A.92.92,0,0,0,17.36,10.53Z'/></svg>");
      }

      &:hover {
        &::after {
          content: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' fill='%23002147' viewBox='0 0 24 24'><title>icon-external</title><path d='M20,5.17a.74.74,0,0,0-.21-.57.78.78,0,0,0-.57-.23H19l-3.53,0a.81.81,0,0,0-.79.8v.31a.87.87,0,0,0,.85.75h1.31l-6.51,6.49A.93.93,0,0,0,11.63,14l6.52-6.5V8.88a.82.82,0,0,0,.24.59.72.72,0,0,0,.55.22h.26A.76.76,0,0,0,20,8.94V5.17Z'/><path d='M17.36,10.53a.93.93,0,0,0-.93.92h0v6.34H5.85V7.93h7.06a.93.93,0,0,0,0-1.86H5.42A1.43,1.43,0,0,0,4,7.5V18.22a1.43,1.43,0,0,0,1.42,1.42H16.86a1.43,1.43,0,0,0,1.42-1.42V11.45h0A.92.92,0,0,0,17.36,10.53Z'/></svg>");
        }
      }
    }
  }
}
