@import '../../styles/constants/breakpoints';
@import "../../styles/mixins/hub/hub-button-focus";

.importCTA {
  padding: 0.5rem;
  border: 1px solid #e2e2ed;
  border-radius: 4px;

  p {
    margin-bottom: 0.5rem;
  }

  button:focus {
    @include standart-focus-button();
  }
}

@media #{$smMedia} {
  .importCTA {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }
  }
}
