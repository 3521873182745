@import "../../styles/constants/breakpoints";

.cpBodyContainer {
  display: flex;
  flex: 1;
}

.cpLeft {
  flex-direction: row;
}

.cpRight {
  flex-direction: row-reverse;
}

.cpBodyContainerWrapper {
  display: flex;
  height: calc(100vh - 64px);
  flex: 1;
}

.cpBodyContainerWrapperMobile {
  display: flex;
  height: calc(100vh - 64px - 60px - 16px);
  flex: 1;

  &.progressBarNavigationhide {
    height: calc(100vh - 60px - 16px);
  }
}

.cpBodyContainerIframe {
  display: flex;
  overflow: auto;
  width: calc(100vw - 80px);
  flex: 1;
  border: 0;
  -webkit-overflow-scrolling: touch;
}

.fullHeight {
  height: 100%;
}

@media #{$xsMedia} {
  .cpBodyContainerMenu {
    display: none;
  }

  .cpBodyContainerIframe {
    height: calc(100svh - 64px - 60px - 16px);
  }
}

@media #{$smMedia} {
  .cpBodyContainerIframe {
    height: calc(100svh - 64px);
  }
}

.leftSwitchBookContainer {
  position: absolute;
  z-index: 1;
  bottom: 150px;
  left: 90px;
}

.rightSwitchBookContainer {
  position: absolute;
  z-index: 1;
  right: 90px;
  bottom: 150px;
}
