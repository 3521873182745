@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

.container {
  flex: 1;
  padding: 15px;
  background-color: $color-grey-button;
  border-radius: 5px;
  color: map-get($legacy-color-names, tooltip);
}

.title {
  display: inline-block;
  margin: 0;
  color: map-get($legacy-color-names, tooltip);
  font-size: 21px;
  font-weight: 600;
}

.subtitle {
  display: inline-block;
  margin: 0;
}

.blockSubtitle {
  display: block;
  margin: 0;
}

.inner {
  ol {
    padding-top: 15px;
    border-bottom: 1px solid map-get($legacy-color-names, background-dark);

    li {
      padding: 7px 0;
      border-top: 1px solid map-get($legacy-color-names, background-dark);
      list-style: none;

      span:first-of-type {
        font-weight: bold;
      }

      span {
        div {
          display: inline-block;
        }
      }
    }
  }

  p {
    padding-top: 15px;
  }
}

.children {
  padding: 15px;
  margin-top: 15px;
  background-color: $color-white;
  border-radius: 5px;
}

// @media #{$smMedia} {
//   .container .inner {
//     display: flex;

//     ol {
//       flex-shrink: 0;
//       margin-right: 30px;
//       width: 200px;
//     }
//   }
// }
