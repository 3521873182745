@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.textInputButton {
  height: 40px;

  // Make sure the button is given enough width
  flex-shrink: 0;
  border: solid $color-borders;
  border-width: 1px 1px 1px 0;
  background-color: $color-white;
  border-radius: 0 4px 4px 0;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover {
    border-width: 1px 1px 1px 1px;
    border-color: $color-text-input-border;
    background-color: $color-background;
  }

  &:focus {
    @include standart-focus-button();

    border-color: $color-borders-v2; // Custom non-standard color here
    outline: none;
  }

  // Text button styling
  p {
    padding: 0 15px;
    white-space: nowrap;
  }

  // Icon button styling
  svg {
    width: 38px;
    height: 100%;
    padding: 6px 9px;
    fill: $color-primary-blue;
  }

  &.isPartOfMultilineGroup {
    height: auto;
  }
}

.buttonTitle {
  margin: 0 20px;
  color: $color-primary-blue;
}
