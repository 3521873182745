@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";



.roundedCorners {
  min-width: 70px;
  border-radius: 20px !important;
}

.notificationBoxTextContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 25px 10px;
  border-radius: 4px;
  background: #4770a733;
  letter-spacing: -0.02em;
}

.textWrapper {
  padding: 0 10px;

  > * {
      display: block;
      margin: 5px 10px;
  }
}

.body {
  display: flex;
  width: 100%;
  min-height: 24px;
  align-items: center;
  margin-bottom: 8px;

  svg {
    display: inline-block;
    width: 26px;
    height: 22px;
  }

  p {
    display: inline-block;
    margin-left: 7px;
    line-height: 150%;
  }

  button {
    cursor: pointer;
  }

  @media #{$xsMedia} {
    > a,
    p {
      font-size: 13px;
    }
  }

  @media #{$phoneMedia} {
    > a,
    p {
      margin: 0 16px;
      font-size: 11px;
    }
  }
}
