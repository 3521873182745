/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import "../../styles/constants/colors";

$color-tooltip: map-get($colors, tooltip);

// TODO: Deprecate [title] selectors when components have been migrated to [data-tooltip].

[title][title],
[data-tooltip][data-tooltip] {
  position: relative;
  overflow: visible;
  outline: none;
}

[title],
[data-tooltip] {
  &::before,
  &::after {
    position: absolute;

    // Also tried negative z-index while tooltip hidden but it affects the fade in/out animation:
    z-index: 200;
    left: 50%;
    display: block;
    content: "";

    // Hidden by default:
    opacity: 0;
    pointer-events: none;
    transform: translateX(-50%);
    transition: opacity 0.2s linear;
  }

  // Tooltip beak: (arrow)
  &::before {
    border: 0 solid transparent;
  }

  &[data-placement="top"] {
    &::before {
      bottom: calc(100% + 5px);
      border-width: 5px 5px 0 5px;
      border-top-color: $color-tooltip;
    }

    &::after {
      bottom: calc(100% + 10px);
    }
  }

  &[data-placement="bottom"],
  &:not([data-placement]) {
    &::before {
      top: calc(100% + 5px);
      border-width: 0 5px 5px;
      border-bottom-color: $color-tooltip;
    }

    &::after {
      top: calc(100% + 10px);
    }
  }

  // Tooltip content: (Gets text from title attribute)
  &::after {

    // This isn't very smart but there's not much one can do unless we resort to js:
    width: auto;
    padding: 0.5rem;
    background-color: $color-tooltip;
    border-radius: 4px;
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
  }

  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    opacity: 1;
  }
}

[title] {
  &::after {
    content: attr(title);
  }
}

[data-tooltip] {
  &::after {
    content: attr(data-tooltip);
  }
}
