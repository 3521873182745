.cardContainer {
  display: grid;
  min-width: 83%;
  gap: 20px;

  .uploadButton {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }
}

.centeredButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logsContainer {
  padding-top: 20px;
  text-align: center;
}

.previewsButtonContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
