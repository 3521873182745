@import "../../styles/constants/colors";

.courseContentLayout {
  display: flex;
  height: 100vh;
  align-items: flex-start;
}

.courseContentPreviewMenu {
  overflow: hidden;
  height: 100%;
  flex-direction: row;
}

.courseContentPreviewResources {
  padding: 24px;

  :global(.grid) {
    max-width: none !important;
  }
}

.resourceSection {
  overflow: scroll;
  height: 100%;
  flex: 1;
  flex-direction: row;
}

.courseContentPreviewResources > .closeButtonContainer {
  height: auto;
}

.closeHeader {
  margin: 25px 25px 0;
  text-align: right;

  button {
    border: 1px solid $color-light-grey;

    span {
      color: map-get($primary-colors, primary);
    }

    svg {
      fill: map-get($primary-colors, primary);
    }

    &:hover {
      background-color: $color-very-light-grey;
    }
  }
}
