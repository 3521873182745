@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.linkIconWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.menuItem {
  display: flex;
  min-height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  border-radius: $border-radius-default;
  margin-bottom: $gap-1;
  background-color: map-get($base, white0);
  color: map-get($primary, primary100);
  gap: $gap-2;

  svg {
    fill: map-get($primary, primary100) !important;
  }

  > div:hover {
    svg {
      fill: map-get($primary, primary100) !important;
    }
  }

  &:hover {
    background-color: map-get($primary, primary10);
  }

  &:active {
    background-color: map-get($primary, primary100);
    color: map-get($base, white100);

    svg {
      fill: map-get($base, white100) !important;
    }
  }
}

.menuItemDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.menuItemLink {
  display: flex;
  width: 100%;
  align-items: center;
  padding: $gap-2 $gap-4;

  &:focus {
    box-shadow: $box-shadow-focus;

    border-radius: $border-radius-default;
  }

  &:hover:focus {
    box-shadow: $box-shadow-focus;

    background-color: map-get($primary, primary10);
  }

  &:active:hover {
    background-color: map-get($primary, primary90);
    color: map-get($base, white100);
  }

  &:active:focus {
    box-shadow: $box-shadow-focus;

    background-color: map-get($primary, primary100);
    color: map-get($base, white100);
  }

  &:active:hover:focus {
    box-shadow: $box-shadow-focus;

    background-color: map-get($primary, primary90);
    color: map-get($base, white100);
  }
}

.textLabel {
  flex: 1;
  align-items: center;
  margin-right: $gap-2;
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  gap: $gap-1;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-decoration: none;
  word-break: break-word;
}

.cptLabel {
  display: flex;
  align-items: center;
  padding: $gap-0 $gap-2 $gap-0 $gap-2;
  border-radius: $border-radius-large;
  background: map-get($modern, electric-blue);
  color: map-get($base, white100);
}

.subMenuContainer {
  width: 100%;
  padding-left: $gap-4;
}

.clipboardContainer {
  margin-right: $gap-2;
}

.activitySelected {
  background-color: map-get($primary, primary100);
  color: map-get($base, white100);

  svg {
    fill: map-get($base, white100) !important;
  }

  &:hover {
    background-color: map-get($primary, primary90);
    color: map-get($base, white100);
  }

  &:focus:hover {
    box-shadow: $box-shadow-focus;

    background-color: map-get($primary, primary90);
    color: map-get($base, white100);
  }
}
