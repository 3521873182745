@import "../../styles/constants/colors";

.table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
}

.translucent {
  opacity: 0.4;
}

.control {
  width: 100%;
}

.tr {
  &:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }

  &:hover {
    background-color: map-get($background-and-border-colors, selected);
  }
}

.cell {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px 7px;
  margin: -1px;
}

.th {
  composes: cell;
  height: 45px;
  border-bottom: 1px solid $color-border;
  font-size: 14px;
}

.selectDisabled {
  opacity: 0.5;
}

.header {
  composes: th;
  align-items: flex-end;
  justify-content: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 4px;
}

.heading {
  display: flex;
  padding: 7px 14px;
  height: 100%;
  border: 1px solid $color-border;
  align-items: center;
  margin-bottom: -1px;
  border-bottom-color: $color-white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.column {
  white-space: nowrap;
}

.td {
  composes: cell;
  padding-top: 12px;
  padding-bottom: 12px;
}

.entity {
  composes: td;
  justify-content: flex-start;
  color: map-get($background-and-border-colors, background-light-blue);
}

.content {
  composes: td;
  position: relative;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $color-grey-button;

  &::after {
    position: absolute;
    right: 32px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom-color: $color-grey-button;
    content: "";
  }
}

.checkbox {
  margin-right: 16px;
}

.collapsed {
  display: none;
}

.entityForCollection {
  margin: 0 16px 0 16px;
  background-color: #edf2f7ff;

  .table {
    margin-top: 4px;
  }

  .td {
    padding-left: 72px;
  }

  .tr {
    &:not(:last-child) {
      border-bottom: 1px solid #dce6efff;
    }

    &:hover {
      border-bottom: 1px solid $color-highlight;
      background-color: #d3dfeae3;
    }
  }
}

.bottomBorder {
  &:not(:last-child) {
    border-bottom: 6px solid #d9e3efff;
  }

  &:hover {
    border-bottom: 6px solid #afc3dcff;
    background-color: map-get($background-and-border-colors, selected);
  }
}

.boxShadow {
  &:not(:last-child) {
    box-shadow: 1px 8px 8px -5px $color-highlight;
  }
}
