@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";

.assignLmProductList {
  border-bottom: 1px solid $color-mid-grey-2;

  &:hover,
  &.selected {
    background-color: map-get($background-and-border-colors, selected);
  }

  label {
    align-items: center;

    > div {
      margin-left: 16px;
    }
  }

  &.disabledClass {
    opacity: 0.6;
  }

  .editBtn {
    margin-top: -15px;
    margin-left: auto;
    color: map-get($primary-colors, primary);

    &:hover {
      color: map-get($primary-colors, primary-dark);
    }
  }
}

.selectedFooter {
  padding: 1rem 0;
  border-bottom: 1px solid map-get($background-and-border-colors, border);
  margin: 0 1rem;

  span + span {
    padding-left: 8px;
    vertical-align: middle;
  }
}

.noLearningMaterials {
  padding-top: 45px;
  text-align: center;

  .svgIcon {
    width: 80px;
    height: 94px;
    margin: auto;
  }

  p {
    padding: 0 15px;
    color: map-get($legacy-color-names, tooltip);
    font-size: 2.4rem;
    font-weight: 600;
  }

  .addLearningMaterial {
    padding-top: 20px;

    button {
      color: map-get($background-and-border-colors, background-light-blue);
      text-decoration: none;

      &:hover {
        color: map-get($hubColors, blue-color-dark);
        text-decoration: underline;
      }
    }
  }
}

.learningMaterialReview {
  padding: 8px 1rem;
  border-bottom: 2px solid $color-light-border;

  .materialRepresentation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .infoIcon {
      align-self: flex-start;

      button:focus {
        @include standart-focus-button();
      }
    }
  }
}

.loading {
  text-align: center;

  .loaderSvg {
    width: 5rem;
    margin: 2rem auto;
  }
}

.loadingError {
  text-align: center;

  .svgIcon {
    width: 5rem;
    margin: 2rem auto 0;
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryLicenceView {
  margin: 0 0 3px 0;
  font-weight: bold;
}

.licenceViewDetailsWrap {
  padding-bottom: 16px;
}

.footerContainer {
  padding: 9px 0 8px;
}
