.mobileErrorContainter {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.warningIcon {
  width: 50px;
  height: 50px;
}

.warningIconContainer {
  margin-top: auto;
}

.warningText {
  margin: 10px;
  color: #8c8a8a;
  text-align: center;
}

.warningButton {
  top: 602px;
  left: 36px;
  display: flex;
  width: 303px;
  height: 46px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 100px;
  background: #4770a7;
  border-radius: 4px;
  color: white;
}

.backIcon {
  height: 15px;
  margin-right: 15px;
  fill: white;
}
