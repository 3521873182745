.productsTable {
  thead tr th,
  tbody tr td time {
    white-space: nowrap;
  }

  tbody tr td:nth-last-child(2) {
    text-align: left;
  }
}
