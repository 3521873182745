@import '../../styles/constants/colors.scss';

.confirmationModal {
  position: fixed;
  z-index: 105;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: var(--vh100, 100vh);
  padding: 0 1rem;
  background: $color-black3;
  transition: opacity 1s linear;

  .modal {
    position: relative;
    width: auto;
    max-width: 600px;
    margin: 40px auto 0;
    background: $color-white;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 15px $contrast-light-accent-50;
    box-shadow: 0 5px 15px $contrast-light-accent-50;
    text-align: center;
    transform: translateY(-40px);
    transition: transform 0.3s ease-out;

    &:not(button) > svg {
      width: 46px;
      height: 46px;
      margin-top: 20px;
      margin-bottom: -20px;
    }

    &.active {
      -webkit-transform: translate(0);
      transform: translate(0);
    }

    .title {
      padding: 5px 25px 15px;
      border-bottom: 1px solid $color-borders;
    }

    .body {
      padding: 25px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      padding: 0 25px 25px;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      svg {
        width: 10px;
        height: 10px;
        fill: $color-borders;

        &:hover {
          fill: darken($color-borders, 20%);
        }
      }
    }
  }
}

body[data-theme="hub-theme"] {
  .confirmationModal {
    min-height: 100vh;
  }
}
