@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.cpModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}

.cpModalHeader {
  color: map-get($cptColors, white);
}

@media #{$smMedia} {
  .cpModalHeader {
    position: fixed;
    z-index: 1;
    bottom: 0;
    width: 100%;
  }
}

.panelTransition {
  transition: transform 0.3s;
}

.cpModalContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

@media #{$xsMedia} {
  .cpModalFooter {
    display: grid;
  }
}

@media #{$smMedia} {
  .cpModalFooter {
    display: none;
  }
}

.cpModalFooter {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: auto;
}
