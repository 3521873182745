.icon {
  margin: 12px;
  margin-right: -20px;
  width: 1.2em;
  height: 1.2em;
}

.title,
.list {
  margin-left: 2em;
}

.title {
  font-size: 1rem;
  font-weight: normal;
}

.list {
  padding-left: 1.2em;

  li {
    display: list-item;
  }
}

.article {
  display: flex;
}
