@import '../../../styles/constants/colors';
@import '../../../styles/constants/breakpoints';

.browserCompatibilityBanner {
  padding: 1.5rem 0;
  text-align: center;

  .text {
    color: $color-white;
    margin: 0 0 1rem 0;
    flex-grow: 1;

    p:first-of-type {
      font-size: 21px;
    }

    p:last-of-type {
      margin: 0;
    }
  }

  .buttons {
    & > *:first-child {
      margin-right: 1rem;
    }

    svg {
      fill: map-get($legacy-color-names, tooltip); // TODO: This shouldn't be needed
    }
  }
}

@media #{$smMedia} {
  .browserCompatibilityBanner {
    :global(.col) {
      flex-direction: row;
      text-align: left;
      align-items: center;
    }

    .icon {
      flex-shrink: 0;
      height: 2.5rem;
      width: 2.5rem;

      margin-right: 2rem;

      fill: $color-white; // TODO this shouldn't be needed
    }

    .text {
      margin: 0 2rem 0 0;
    }

    .buttons {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }
  }
}
