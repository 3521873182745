@import "../../../styles/constants/breakpoints.scss";
@import "../../../styles/constants/colors.scss";

.TopBanner {
  .epsHubTopBanner {
    background-color: map-get($hubColors, hub_neutral_blue);
  }

  .buttonComponentWidget {
    width: 120px;
    margin-right: 15px;
    background-color: map-get($hubColors, hub_link);
    border-radius: 4px;
    color: $color-white;
  }

  .tryStyleWidget {
    margin-left: 10px;
    color: $color-white;
  }

  .linkImageTryWidget {
    margin-top: 5px;
    margin-left: 5px;
    mix-blend-mode: color-dodge;
  }

  .ctaComponentsWidget {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
  }

  .textStyleWidget {
    color: map-get($background-and-border-colors, background-dark-blue);
    font-family: "DM Serif Display", serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    white-space: break-spaces;

    @media #{$xsMedia} {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .linkStyleWidget {
    text-decoration: underline;
  }

  .linkTextStyleWidget {
    padding-right: 5px;
    text-decoration: none;
  }

  .topBannerWidget {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 15px;
    background-color: map-get($hubColors, hub_neutral_blue);
    background-image: url("/static/images/hub/homepage/Hub_homepage_v3_banner_1.png");
    background-position: 150% center;
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 4px;
  }

  .textComponentWidget {
    max-width: 92%;

    @media #{$xsMedia} {
      max-width: 100%;
    }
  }

  .textStyle {
    color: map-get($background-and-border-colors, background-dark-blue);
    font-family: "DM Serif Display", serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;

    @media #{$xsMedia} {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .mainStyle {
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-image: url("/static/images/hub/homepage/Hub_homepage_v3_banner_1.png");
    background-position: right 190px center;
    background-repeat: no-repeat;
    background-size: 420px;

    @media #{$xsMedia} {
      display: block;
      padding: 0 15px;
      margin: 0 -15px;
      background-position: right -100px center;
      background-repeat: no-repeat;
      background-size: 200px;
    }
  }

  .textComponent {
    padding: 12px 0;
  }

  .linkSTyle {
    padding: 8px 0;

    @media #{$xsMedia} {
      width: calc(100% - 153px);
      margin-top: 10px;
      float: right;
    }
  }

  .LinkimageStyle {
    padding-left: 5px;
  }

  .buttonComponent {
    width: 123px;
    height: 40px;
    background-color: map-get($hubColors, hub_link);
    border-radius: 4px;
    color: $color-white;
    cursor: pointer;

    @media #{$xsMedia} {
      margin-bottom: 15px;
    }
  }

  div.buttonComponent:hover {
    background-color: $color-astronaut-blue;
  }

  .linkImageTry {
    margin-top: 9px;
    margin-left: 5px;
    mix-blend-mode: color-dodge;
  }

  .tryStyle {
    padding-left: 10px;
    color: $color-white;
  }
}
