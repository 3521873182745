@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.correlationChart {
  position: relative;
  display: flex;
  align-items: flex-start;

  > button {
    display: none;

    @media #{$smMedia} {
      display: flex;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 1rem;
    height: 100%;
    background: -moz-linear-gradient(left, $color-transparent-black 0%, $color-transparent-white 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $color-transparent-black 0%, $color-transparent-white 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $color-transparent-black 0%, $color-transparent-white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    content: '';
    pointer-events: none;

    @media #{$smMedia} {
      display: none;
    }
  }
}

.levelTitle {
  flex: 0 0 200px;
  text-align: right;
}

.localLevelTitle,
.oxfordLevelsLevelTitle {
  padding: 10px 10px 10px 0;
}

.oxfordLevelsLevelTitle {
  border-bottom: 1px solid $color-black;
  font-weight: bold;
}

.matrixContainer {
  overflow-x: auto;
}

.levelMatrix {
  width: 100%;
  padding-right: 1rem;
  border-left: 1px solid $color-black;
  border-spacing: 0;
  table-layout: fixed;
  white-space: nowrap;

  @media #{$smMedia} {
    padding-right: 0;
    border-right: 1px solid $color-black;
  }

  @media print {
    min-width: auto !important;
  }
}

.oxfordLevelItem {
  padding: 10px 0;
  border-right: 1px solid map-get($background-and-border-colors, border);
  border-bottom: 1px solid $color-black;
  text-align: center;
}

.cellSeparator {
  border-right: 1px solid $color-black;
}

.oxfordLevelItem:last-child {
  border-right: 0;
}

.localLevelItem {
  padding: 10px 0;
  border-right: 1px solid $color-black;
  text-align: center;

  @media print {
    -webkit-print-color-adjust: exact !important;
  }
}

.localLevelItem:last-child {
  border-right: 0;
}

.arrowButton {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0 !important;
  margin: 0 5px;
  fill: $color-orb-blue;
}

.lightText {
  color: $color-white;
}
