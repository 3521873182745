@mixin help-card {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 1.5rem;
}

@mixin help-card-after {
  position: absolute;
  top: 2px;
  right: 0;
  left: 80%;
  height: 100%;
  content: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' fill='002147' viewBox='0 0 24 24'><title>icon-external</title><path d='M20,5.17a.74.74,0,0,0-.21-.57.78.78,0,0,0-.57-.23H19l-3.53,0a.81.81,0,0,0-.79.8v.31a.87.87,0,0,0,.85.75h1.31l-6.51,6.49A.93.93,0,0,0,11.63,14l6.52-6.5V8.88a.82.82,0,0,0,.24.59.72.72,0,0,0,.55.22h.26A.76.76,0,0,0,20,8.94V5.17Z'/><path d='M17.36,10.53a.93.93,0,0,0-.93.92h0v6.34H5.85V7.93h7.06a.93.93,0,0,0,0-1.86H5.42A1.43,1.43,0,0,0,4,7.5V18.22a1.43,1.43,0,0,0,1.42,1.42H16.86a1.43,1.43,0,0,0,1.42-1.42V11.45h0A.92.92,0,0,0,17.36,10.53Z'/></svg>");
}
