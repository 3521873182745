@import "../../../styles/constants/breakpoints";
@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";

.resourceHomeHeader {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 900;
}

.resourceFolderTitle {
  margin-top: 1rem;
  font-weight: bold;
}

.resourceHomeFolderContainer {
  position: relative;
  display: block;
  width: 100%;

  >div {
    position: relative;
    margin-right: 1rem;
    float: left;
  }
}

.classworkButtons {
  margin-bottom: 1rem;

  button {
    min-height: 2.5rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid map-get($background-and-border-colors, border);

    &:first-child {
      border-right: none;
      border-bottom-left-radius: 1.5625rem;
      border-top-left-radius: 1.5625rem;
    }

    &:last-child {
      border-left: none;
      border-bottom-right-radius: 1.5625rem;
      border-top-right-radius: 1.5625rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin: 0 0.75rem 0 0;
        fill: map-get($hubColors, hub_expiring_licence);
        transform: translateY(20%);
      }
    }

    &.active {
      border: 1px solid map-get($hubColors, hub_very_light_blue);
      background-color: map-get($secondary-colors, background_light_blue);
      color: $color-black;
      font-weight: bold;

      svg {
        fill: $color-white;
      }
    }

    &.inactive {
      &:hover {
        background-color: $color-button-background3;
      }
    }
  }
}
