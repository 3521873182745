@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";
@import "../../styles/mixins/hides.scss";

.wrapper {
  margin: 1em 0em 1em 5em;

  div {
    height: 16em;
    width: 18em;

    table {
      height: 15em;
      width: 18em;
      border-collapse: collapse;
      thead {
        display: inline-block;
        tr th {
          height: 29px;
          border-bottom: 1px solid map-get($background-and-border-colors, border);
          position: sticky;
          background-color: white;
          top: 0;
          text-align: left;
          &:first-child {
            width: 7em;
            border-right: 1px solid map-get($background-and-border-colors, border);

          }
          &:last-child {
            width: 10em;
            text-align: center;
          }
        }
      }
      tbody {
        height: 12em;
        width: 18em;
        display: inline-block;
        overflow-y: scroll;

        tr td {
          height: 29px;
          border-bottom: 1px solid map-get($background-and-border-colors, border);
          padding-top: 0.5em;
          &:first-child {
            text-align: left;
            border-right: 1px solid map-get($background-and-border-colors, border);
            width: 7em;
          }
          &:last-child {
            width: 10em;
            text-align: center;
          }
        }
      }
    }
  }
}

tbody::-webkit-scrollbar {
  width: 1em;
}

tbody::-webkit-scrollbar-track {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px $contrast-light-box-shadow;
}

tbody::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: darkgrey;
  outline: 1px solid map-get($background-and-border-colors, border);
}
