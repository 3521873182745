@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.checkbox {
  position: relative;
  display: flex;
  min-width: 25px; // This is needed by IE to ensure the element width does not collapse
  align-items: center;

  .svg {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    pointer-events: none; // Avoiding SVG as the event.target allows the click event to go straight to the <label> and solves IE11 bug.
  }

  // Make the native checkbox style invisible but remain interactive so
  // we don't have to fake any of it's functionality using aria or js etc:
  .input {
    position: absolute;
    width: 25px;
    height: 25px;
    margin: 0;
    cursor: pointer;
    opacity: 0;

    &:focus + .label .svg {
      border-color: $color-borders-v2;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px $contrast-light-shadow, 0 0 10px 1px $contrast-light-blue;
      outline: none;
    }
  }

  .label {
    display: flex;
    flex-basis: 100%; // To overcome flex text overflow bug in IE
    margin-top: 0.8em;
    cursor: pointer;

    &.a11-hide-label {
      margin-top: 0;
    }
  }

  .text {
    display: block;
    margin-left: 10px;
    line-height: 25px;

    &.a11-hide-text {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(1px, 1px, 1px, 1px);
    }
  }

  &.disabled .input,
  &.disabled .label {
    cursor: not-allowed;
  }

  >div {
    width: 100%;
  }
}

body[data-theme="dark-theme"] {
  .checkbox {
    &,
    input,
    label,
    svg {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin: 0;
    }

    input:focus + .label .svg {
      box-shadow: none;
    }

    label {
      padding: 0;
    }
  }
}

body[data-theme="hub-theme"] {
  .checkbox {
    svg {
      border-radius: 4px;
    }

    input:focus + .label .svg {
      box-shadow: 0 0 0 1px $color-white, 0 0 0 2px map-get($primary-colors, primary), 0 0 0 3px $color-white;
    }
  }
}
