@import './variables.scss';

//
// ORB Images
// --------------------------------------------------

h2.orb-book-cover {
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid $ces-border;
  border-radius: $ces-border-radius;
  overflow: hidden;
}

.orb-cover-container {
  .orb-book-cover {
    border: 1px solid $ces-border;
    border-radius: $ces-border-radius;
    overflow: hidden;

    &.missing-image {
      width: 100%;

      @media screen and (min-width: 700px) {
        height: 140px;
      }
    }
  }

  img, picture {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    max-width: 200px;
  }
}
