/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import '../constants/breakpoints';
@import '../constants/colors';
@import '../mixins/hides';

:global {
  // Styling for hiding elements for accessibility purposes.
  .a11y-hide {
    @include a11y-hide();
  }

  // For the "Skip to content" link. Hidden accessibly. Visible on focus:
  .a11y-hide-focusable {
    @extend .a11y-hide;

    &:focus {
      position: fixed;
      z-index: 9999;
      top: 7px;
      left: 7px;
      overflow: visible;
      width: auto;
      height: auto;
      padding: 7px 15px;
      margin: 0;
      background-color: $color-white;
      clip: auto;
      color: color(primary-dark);
    }
  }

  .xs-hide {
    display: none;
  }

  @media #{$smMedia} {
    .sm-hide {
      display: none;
    }
    // This unsets any lower breakpoint hides
    .sm-show {
      display: block;
    }

    .sm-show-flex {
      display: flex;
    }
  }

  @media #{$mdMedia} {
    .md-hide {
      display: none;
    }
    // This unsets any lower breakpoint hides
    .md-show {
      display: block;
    }

    .md-show-flex {
      display: flex;
    }
  }

  @media #{$lgMedia} {
    .lg-hide {
      display: none;
    }
    // This unsets any lower breakpoint hides
    .lg-show {
      display: block;
    }

    .lg-show-flex {
      display: flex;
    }
  }
}
