@import '../../styles/constants/colors.scss';

.horizontalScrollContainer {
  @media screen {
    position: relative;

    & > div {
      overflow-x: auto;
      white-space: nowrap;
    }

    &.hideScrollBar > div {
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
    }

    &.hideScrollBar > div::-webkit-scrollbar   {
      display: none;
    }

    &:before, &:after {
      background-repeat: repeat-x;
      content: "";
      position: absolute;
      top: 0;

      width: 1rem;
      height: 100%;
      pointer-events: none;
    }

    &:before {
      left: 0rem;
      background-image: linear-gradient(to right, $color-white 0%, transparent 100%);
    }

    &::after {
      right: 0rem;
      background-image: linear-gradient(to right, transparent 0%, $color-white 100%);
    }
  }
}

@each $colorName, $colorValue in $colors {
  :global(.color-#{$colorName}-dark-bg).horizontalScrollContainer {
    &:before {
      background-image: linear-gradient(to right, darken($colorValue, 20%), transparent);
      background-image: linear-gradient(to right, map-get($primary-dark-colors, #{$colorName}-dark), transparent);
      background-image: linear-gradient(to right, map-get($secondary-dark-colors, #{$colorName}-dark), transparent);
    }

    &::after {
      background-image: linear-gradient(to right, transparent, darken($colorValue, 20%));
      //FIXME: Bellow lines are causing postcss-loader to throw an error when building - unclear why
      // background-image: linear-gradient(to right, transparent, map-get($primary-dark-colors, #{$colorName}-dark));
      // background-image: linear-gradient(to right, transparent, map-get($secondary-dark-colors, #{$colorName}-dark));
    }
  }
}

/** DODGEY OVERRIDE!!!!!!!!! (`PRIMARY_BLUE` needs to be replaced by `PRIMARY` throughout the project) **/
:global(.color-primary-blue-dark-bg).horizontalScrollContainer {
  &:before {
    background-image: linear-gradient(to right, $color-primary-blue-dark, transparent);
  }

  &::after {
    background-image: linear-gradient(to right, transparent, $color-primary-blue-dark);
  }
}
