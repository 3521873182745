@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.cpButtonRow {
  display: flex;
  height: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $gap-3 $gap-4;
  background-color: map-get($cptColors, background);

  &.progressBarNavigationhide {
    display: none;
  }
}

.cpNextPrevButton {
  display: flex;
  width: $icon-size-large;
  height: $icon-size-large;
  max-height: $icon-size-medium;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid map-get($cptColors, light_blue2) !important;
  border-radius: 10px;
  fill: map-get($cptColors, white);

  svg {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &:hover {
    background-color: map-get($primary, primary90);
  }

  &:focus {
    box-shadow: $box-shadow-focus;
  }
}

.cpLeft {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.cpRight {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.cpDisabled {
  background-color: map-get($primary, primary40);
  cursor: not-allowed;

  &:hover {
    background-color: map-get($primary, primary40);
  }
}
