@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.OteSocialNetworkBar {
  width: 100%;
  border-top: 1px solid;

  .OteSocialNetworkBarContainer {
    display: block;
    max-width: 30rem;
    margin: 0 auto;

    .iconsContainer {
      display: flex;
      justify-content: space-around;

      .iconContainer {
        display: flex;
        min-width: 2rem;
        height: 3.2rem;
        align-items: center;
        justify-content: center;

        .logo {
          max-width: 25px;
          max-height: 25px;
        }
      }
    }
  }
}
