@import '../../styles/constants/breakpoints';
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

// Container for both the button and the dropdown:
.containerShallow {
  &.containerShallow > button {
    height: 30px;
  }
}

.container {
  position: relative;
  white-space: nowrap;

  // Extra strong selector needed
  &.container > button {
    height: 40px;
    border-radius: 20px;

    &:focus {
      @include standart-focus-button();
    }
  }
}

// Dropdown outer container:
.popupOverlay {
  &.hangRight {
    right: 0;
    left: auto;
  }

  &.hangLeft {
    right: auto;
    left: 0;
  }

  width: calc(100vw - 2.5rem);
  margin-top: 14px;
  box-shadow: 0 3px 7px 0 $contrast-light-box-shadow;

  @media #{$smMedia} {
    width: auto;
  }

  fieldset {
    margin: 0;
    box-shadow: none;
  }

  // Heading at top of dropdown:
  legend {
    padding: 15px 15px 7px 15px;
    font-weight: bold;
  }
}

// Dropdown list-items container:
.popupContainer {
  li {
    position: relative;
    min-width: 260px;
    height: 42px;
    border-top: 1px solid $color-very-light-grey;

    &:first-child {
      border: none;
    }
  }

  label {
    display: block;
    height: 42px;
    padding: 0 0.5rem 0 3rem;
    margin: 0;
    color: map-get($styleGuideColors, primary);
    line-height: 42px;

    &:focus,
    &:hover {
      background-color: map-get($styleGuideColors, selected);
    }

    // span containing label text:
    * {
      display: inline-block;
    }
  }

  // Hide the native checkmark ✔ in IE (because IE does not support appearance:none)
  ::-ms-check {
    display: none;
  }

  // This field is restyled placed in front of the label so that keyboard nav still works:
  input {

    // Make field the same size as the label so that focus looks same as Style Guide when navigating with keyboard:
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 42px;

    // For iOS 10 compatibility
    border: none;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    cursor: pointer;

    &:disabled:not(:checked) + label {
      opacity: 0.5;
    }

    &:not(:disabled):checked,
    &:not(:disabled):hover {
      + label {
        background-color: map-get($styleGuideColors, selected);
      }
    }

    // Custom checkmark ✔ to left of label text:
    + label::before {
      position: absolute;
      left: 0.8rem;
      display: inline-block;
      width: 38px;
      height: 38px;
      background: 12% 114% no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath d='M25 4a4 4 0 0 1 4-4h17a4 4 0 0 1 4 4v17a4 4 0 0 1-4 4H29a4 4 0 0 1-4-4V4zM0 29a4 4 0 0 1 4-4h17a4 4 0 0 1 4 4v17a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V29z' fill='none'/%3E%3Cpath d='M19 34.9l-7 7c-.1.2-.4.3-.9.3-.3 0-.7-.1-.9-.3l-4.4-4.4c-.5-.5-.5-1.2 0-1.8.4-.4 1.2-.4 1.8 0l3.5 3.5 6.1-6.1c.4-.5 1.2-.5 1.8 0 .6.6.6 1.4 0 1.8' /%3E%3C/svg%3E");
      background-size: 150%;
      content: "";
      fill: map-get($background-and-border-colors, background-light-blue);
      visibility: hidden;
    }

    + label.streamline::before {
      width: 30px;
      height: 30px;
      background: no-repeat url("data:image/svg+xml,%3Csvg id='UI' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3Csymbol id='icon_check' data-name='icon_check' viewBox='0 0 40 40'%3E%3Cpolyline points='10 22.46 15.03 27.49 30 12.51' style='fill:none;stroke:%23575756;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3Crect width='40' height='40' style='fill:none'/%3E%3C/symbol%3E%3C/defs%3E%3Ctitle%3Eicon-check%3C/title%3E%3Cuse width='40' height='40' xlink:href='%23icon_check'/%3E%3C/svg%3E");
      background-size: 100%;
    }
    // Only show checkmark ✔ when field is checked:
    &:checked + label::before {
      visibility: visible;
    }
  }
}

body[data-theme="dark-theme"] {
  .popupOverlay {
    margin-top: 10px;
    border-radius: 5px;
  }

  .popupContainer {
    overflow: hidden;
    border-radius: 5px;

    /* stylelint-disable no-descending-specificity */
    input {
      outline: none;

      + label {
        padding: 0 10px;
        color: var(--text-neutral);
        fill: var(--text-neutral);
        text-align: left;

        &::before {
          top: 0;
          right: 0;
          left: auto;
        }
      }

      + label.streamline {
        &::before {
          top: 5px;
          right: 10px;
          width: 30px;
          height: 30px;
        }
      }
    }
    /* stylelint-enable no-descending-specificity */
  }
}

@media #{$xsMedia} {
  .sortButton {
    order: 1;
  }
}
