@import "../../styles/constants/colors";

.labelContainer {
  position: relative;
  display: flex;
  padding: 5px 7px;
  margin-top: 1rem;
  background: $color-weak;
  border-radius: 4px;
  color: $color-white;

  &::before {
    position: absolute;
    top: -19px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $color-weak;
    content: '';
  }

  .svgContainer {
    height: 1rem;
    margin: 1px 5px 0 0;

    svg {
      height: 100%;
    }
  }

  .buttonContainer {
    margin-left: auto;

    button {
      min-height: 1rem;
      padding: 0;
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.messageContainer {
  margin-left: 0.2rem;
}

.classTestStudentView,
.previewModalLabel {
  flex-wrap: wrap;

  .buttonContainer {
    margin-left: 3px;

    &:hover {
      background-color: map-get($secondary-colors, classroom);
      border-radius: 5px;
    }
  }
}

.previewModalLabel,
.blueInfoLabel {
  margin-bottom: 0.5rem;
}

.classTestStudentView,
.blueInfoLabel {
  margin-top: 0.5rem;

  &::before {
    display: none;
  }
}

.blueInfoLabel {
  background: map-get($primary-colors, primary);
}
