@import "../../../../styles/constants/_colors.scss";

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coursePublishTableContainer tr th {
  padding: 10px;
  white-space: nowrap;
}

.coursePublishTableContainer th:first-child {
  text-align: left;
}

.coursePublishTableContainer th:last-child {
  text-align: center;
}

.coursePublishTableContainer td {
  padding: 10px;
  font-weight: normal;
  text-align: left !important;
}

.coursePublishTableContainer td:last-child {
  text-align: center !important;
}

.coursePublishTableContainer button {
  color: $color-primary-blue;
  font-weight: bold;
}

.coursePublishTableContainer table label {
  margin: 0;
}

.filters {
  width: 100%;
  margin-bottom: 20px;
}

.filters::after {
  display: table;
  clear: both;
  content: "";
}

.filter:nth-child(2) {
  float: right;
}

.no_courses {
  text-align: center;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}
