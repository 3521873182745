@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";

/* stylelint-disable */
.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  .newTag {
    position: absolute;
    right: -4px;
    display: block;
    bottom: 3px;
    width: 28px;
    background-color: map-get($hubColors, product_finder_tab_unselected_text);
    color: $color-white;
    height: 15px;
    border-radius: 5px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }

  &.size-large {
    width: 120px;
    height: 120px;
    line-height: 120px;

    svg {
      padding: 25px;
    }
  }

  &.size-very-large {
    width: 200px;
    height: 200px;
    line-height: 200px;

    svg {
      padding: 25px;
    }
  }

  &.size-section {
    width: 100px;
    height: 100px;
    line-height: 100px;

    svg {
      padding: 25px;
    }
  }

  &.size-heading {
    width: 85px;
    height: 85px;
    line-height: 85px;

    svg {
      padding: 15px;
      flex-grow: 1;
    }
  }

  &.size-hub {
    width: 70px;
    height: 60px;
    line-height: 60px;

    svg {
      padding: 15px;
    }
  }

  &.size-heading {
    width: 70px;
    height: 70px;
    line-height: 70px;

    svg {
      padding: 15px;
    }
  }

  &.size-medium,
  &.size-table {
    width: 50px;
    height: 50px;
    line-height: 50px;

    svg {
      width: 100%;
      padding: 12px;
    }
  }

  &.size-table:not(.squareImg) {
    display: block;
  }

  &.size-small {
    width: 30px;
    height: 30px;
    line-height: 30px;

    span {
      font-size: 16px;
      font-weight: bold;
    }

    svg {
      padding: 6px;
      flex-grow: 1;
    }
  }

  .smallCircleTooltip {
    display: grid;
    width: 100%;
  }

  // Grey background when disabled:
  // Also prevent standard hover style (eg ".hoverable:hover color-organization-bg" from affecting disabled Thumbnail.
  &.disabled,
  tr:hover &.disabled {
    background-color: $color-sub-text-50;
  }

  &.disabled {
    // If the element is an icon and is diabled then to fade the image out we give it 50% opacity on a white background
    &.image {
      background-color: $color-white;

      img {
        opacity: 0.5;
      }
      // Prevent grey hover style from appearing around an image when it's not exactly a square:
      tr:hover & {
        background-color: transparent;
      }
    }
  }

  &.squareImg {
    border-radius: 4px;

    &.size-large {
      width: 120px;
    }

    &.size-section {
      width: 100px;
    }

    &.size-section {
      width: 85px;
    }

    &.size-heading {
      width: 70px;
    }

    &.size-medium {
      width: 50px;
    }

    &.size-table,
    &.size-small {
      width: 30px;
    }
  }

  // Scale icons and images to the container
  svg,
  img {
    max-width: 100%;
    max-height: 100%;
  }

  // SVGIcons and text are always white in this context
  span {
    color: $color-white;
    justify-content: center;
    display: flex;
  }

  // svg will inherit this style. Setting it explicity on the svg element makes selector it too strong:
  fill: $color-white;

  // Images always have a border
  img {
    border: 1px solid $color-borders;
    border-radius: inherit;
  }
}

.smallCircle {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid white;
  margin: -5px;
  border-radius: 100%;
  font-weight: 600;

  &.size-large {
    width: 45px;
    height: 45px;
    font-size: 17px;
    line-height: 36px;

    svg {
      padding: 2px;
    }
  }

  &.size-section {
    width: 40px;
    height: 40px;
    font-size: 17px;
    line-height: 36px;

    svg {
      padding: 2px;
    }
  }

  &.size-narrow {
    width: 30px;
    height: 30px;
    font-size: 17px;
    line-height: 36px;

    svg {
      padding: 2px;
    }
  }

  &.size-heading {
    width: 28px;
    height: 28px;
    font-size: 12px;
    line-height: 24px;

    svg {
      padding: 2px;
    }
  }

  &.size-medium,
  &.size-table {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 16px;

    svg {
      padding: 2px;
    }
  }

  &.size-small {
    width: 16px;
    height: 16px;
    font-size: 8px;
    line-height: 12px;

    svg {
      padding: 2px;
    }
  }
}

body[data-theme="hub-theme"] {
  @media #{$smMedia} {
    .thumbnail {
      &.size-table:not(.squareImg),
      &.size-table {
        display: flex;

        &.squareImg {
          width: 50px;
        }
      }
    }
  }
}

@media #{$phoneMedia} {
  .thumbnail {
    &.size-table {
      &.squareImg {
        width: 60px;
        height: 100%;
      }
    }
  }
}

@media #{$smMedia} {
  .thumbnail {
    &.size-table:not(.squareImg),
    &.size-table {
      display: block;

      &.squareImg {
        width: 50px;
      }
    }
  }
}
/* stylelint-enable */
