@use "sass:map";
@import '../../../../../../styles/constants/colors';
@import '../../../../../../styles/mixins/hub/hub-button-focus';
@import "../../../../../../styles/constants/breakpoints";

.children {
  position: relative;
  height: 100%;
  min-height: 5rem;
  overflow-y: auto;
}

.footer {
  height: auto !important;
  border-top: 0.063rem solid $color-borders;
}

.addToLibraryConfirmation {
  .accessCodeWrapper {
    width: 40%;
    height: 2.5rem;
    border-width: 0.063rem;
    border-style: solid;
    border-color: map.get($background-and-border-colors, border);
    border-radius: 0.25rem;
    font-size: 1.25rem;
    line-height: 2.375rem;
  }

  .link {
    display: flex;
    fill: map.get($primary-colors, primary);
    text-decoration: none;

    &:hover {
      fill: map.get($primary-dark-colors, primary-dark);
    }

    &:focus {
      @include no-padding-button-on-focus;

      outline: none;
    }

    svg {
      width: 1.3rem;
      height: 1.3rem;
      margin-left: 0.3rem;
    }
  }

  .hubScrollContainer {
    display: block;
    overflow: auto;
    padding: 3.125rem 0 5.313rem;

    .children {
      height: auto;
    }

    .buttonCloseConfirmationHub {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      padding: 0.813rem 0.625rem;
      background: #fff;
    }

    .footer {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      background: #fff;
    }
  }
}
