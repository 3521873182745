/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import "../constants/breakpoints";
@import "../constants/grid";

// Helper for .cols-left & .cols-edge etc:
@mixin cols-align {
  flex-wrap: nowrap;

  > .col {
    max-width: 100%;
    flex-basis: auto;
    flex-shrink: 1;
  }
}

// These clases aren't localised so that their basic name cna be used.
// All grid classes should be wrapped in a :global selector
:global {
  // Initial Grid container class
  .grid {
    max-width: $gridMaxWidth;
    padding-right: $xsEdgeGutter;
    padding-left: $xsEdgeGutter;
    margin-right: auto;
    margin-left: auto;
  }

  .pos-relative {
    position: relative;
  }

  // Row class
  .row {
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    margin-right: -0.5 * $innerGutterWidth;
    margin-left: -0.5 * $innerGutterWidth;

    &.align-center {
      align-items: center;
    }

    &.cols-left,
    &.cols-right,
    &.cols-center,
    &.cols-edge {
      @include cols-align;
    }

    // This first one is not needed because it's the default:
    // &.cols-left {
    //   justify-content: flex-start;
    // }
    &.cols-right {
      justify-content: flex-end;
    }

    &.cols-center {
      justify-content: center;

      @media screen and (width <= 480px) {
        &.mView {
          display: block;
          margin-left: 1px;

          button {
            margin-bottom: 8px;
          }
        }
      }
    }

    &.cols-edge {
      justify-content: space-between;
    }

    // As above but for "sm" break point and above: (Add "md" & "lg" if needed)
    @media #{$smMedia} {
      &.sm-cols-left,
      &.sm-cols-right,
      &.sm-cols-center,
      &.sm-cols-edge {
        @include cols-align;
      }

      // This first one is not needed because it's the default:
      // &.cols-left {
      //   justify-content: flex-start;
      // }
      &.sm-cols-right {
        justify-content: flex-end;
      }

      &.sm-cols-center {
        justify-content: center;
      }

      &.sm-cols-edge {
        justify-content: space-between;
      }
    }
  }

  // Column class with default size
  .col {
    display: flex;
    max-width: 100%;
    flex: 0 0 100%;
    flex-direction: column;

    // Gutter between cols
    border: 0 solid transparent;
    border-right-width: 0.5 * $innerGutterWidth;
    border-left-width: 0.5 * $innerGutterWidth;
  }

  // Below are breakpoint specific grid edge gutter sizes and col classes
  // XS Breakpoint col classes
  @for $col from 1 through 11 {
    .col.xs#{$col} {
      max-width: 100% * calc($col / 12);
      flex-basis: 100% * calc($col / 12);
    }
  }

  // .offset
  // Rather like Bootstrap .offsetN classes, these move a column along by N columns:
  // Note we only need a handful of them, not 1 to 12.
  @for $col from 1 through 2 {
    // Use .-offsetN to shunt left:
    .col.-offset#{$col} {
      margin-left: -100% * calc($col / 12);
    }

    // Use .offsetN to shunt right:
    .col.offset#{$col} {
      margin-left: 100% * calc($col / 12);
    }
  }

  // Define .xs-offsetN overrides for XS breakpoint ONLY:
  // Use these to overide standard .offsetN on the XS breakpoint.
  // Unlike other grid styles these do not cascade to larger breakpoints.
  @media #{$xsMedia} {
    @for $col from 1 through 2 {
      // Use .-offsetN to shunt left:
      .col.-xs-offset#{$col} {
        margin-left: -100% * calc($col / 12);
      }
    }

    @for $col from 0 through 2 {
      // Use .offsetN to shunt right:
      .col.xs-offset#{$col} {
        margin-left: 100% * calc($col / 12);
      }
    }
  }

  // SM Breakpoint col classes
  @media #{$smMedia} {
    .grid {
      padding-right: $smEdgeGutter;
      padding-left: $smEdgeGutter;
    }

    @for $col from 1 through 11 {
      .col.sm#{$col} {
        max-width: 100% * calc($col / 12);
        flex-basis: 100% * calc($col / 12);
      }
    }
  }

  // MD Breakpoint col classes
  @media #{$mdMedia} {
    .grid {
      padding-right: $mdEdgeGutter;
      padding-left: $mdEdgeGutter;
    }

    @for $col from 1 through 11 {
      .col.md#{$col} {
        max-width: 100% * calc($col / 12);
        flex-basis: 100% * calc($col / 12);
      }

      // Ordering Flex Items
      .col.order-md#{$col} {
        order: $col;
      }
    }
  }

  // LG Breakpoint col classes
  @media #{$lgMedia} {
    .grid {
      padding-right: $lgEdgeGutter;
      padding-left: $lgEdgeGutter;
    }

    @for $col from 1 through 11 {
      .col.lg#{$col} {
        max-width: 100% * calc($col / 12);
        flex-basis: 100% * calc($col / 12);
      }
    }
  }

  // When you want cols to sort out their own widths without adult supervision:
  .row.fit > * {
    flex: 1 1 auto; // flex-grow flex-shrink flex-basis
  }

  .fit > .grow {
    flex: 1 0 auto;
  }

  .fit > .shrink {
    flex: 0 1 auto;
  }

  // Modifies the grid for ELT HUB AND RVS
  body[data-theme="hub-theme"],
  body[data-theme="rvs-theme"],
  body[data-theme="ocp-theme"] {
    .grid {
      max-width: 100%;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      margin: 0;
    }

    @media #{$phoneMedia} {
      .grid {
        padding-right: $xsEdgeGutter;
        padding-left: $xsEdgeGutter;
      }
    }
  }
}
