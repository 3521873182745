@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";

.tableContainer {
  min-height: 270px;

  @media print {
    max-width: 100%;
    max-height: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr:first-child {
        th {
          border-right: 1px solid $color-border;

          &:first-child {
            z-index: 100;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }

      tr:nth-child(2) {
        th {
          border-right: 1px dotted $color-border;

          &:nth-child(3),
          &:nth-child(7) {
            border-right: 1px solid $color-border;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    th {
      padding: 10px;
      font-size: 14px;
      text-align: left;
      vertical-align: top;
    }

    tbody {
      tr {
        height: 70px;

        td {
          padding: 5px 10px;
          border-right: 1px dotted $color-border;
          text-align: left;
          vertical-align: top;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(9) {
            border-right: 1px solid $color-border;
          }

          &:last-child {
            border-right: 0;
          }

          &.stickyBodyTableCell {
            background: $color-white;
          }

          .nowrap {
            white-space: nowrap;
          }
        }

        &:nth-child(odd) {
          background: $color-grey-modal-border50;

          td.stickyBodyTableCell {
            background: $smart-dropdown-option-hover;
          }
        }
      }
    }
  }
}

.stickyHeaderTableCell {
  position: sticky;
  top: 0;
  left: 0;
  min-width: 100px;
  background: white;

  @media print {
    min-width: 50px;
    max-width: 50px;
  }

  button {
    font-family: inherit;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: left;
  }

  &:first-child {
    z-index: 99;
  }

  &:not(:first-child) {
    z-index: 98;
  }
}

.stickyBodyTableCell {
  position: sticky;
  z-index: 97;
  left: 0;

  p {
    font-weight: bold;
  }
}

.stickyHeaderSecondRowTableCell {
  top: 40px;
}

.stickyBodyTableCell::before,
.stickyHeaderTableCell:first-of-type::before {
  position: absolute;
  top: 0;
  right: -1px;
  width: 1px;
  height: 100%;
  background: $color-border;
  box-shadow: 2px 2px 4px #00000050;
  content: ' ';
}

.sortableCellIcons {
  width: 20px;
  height: 20px;
}

.reactDatePickerAbsolute {
  position: absolute;
}
