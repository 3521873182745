@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.informationBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: $gap-3 $gap-5 $gap-3 $gap-3;
  border-radius: $border-radius-default;
  background-color: map-get($primary, primary10);

  .bannerMessage {
    color: map-get($primary, primary100);
    font-family: $font-family-text-font;
    font-size: $font-size-base;
    font-weight: $font-weight-600;
  }

  .icon {
    min-width: $icon-size-regular;
    height: $icon-size-regular;
    margin: 0 $gap-3;
  }
}
