@import '../../styles/constants/colors.scss';

:global {
  .orb_contact_preferences {
    position: absolute;
    z-index: 10000;
    background-color: $color-white;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-rows: auto 2fr;
    min-height: 100vh;
    min-height: var(--vh100);

    &.animated {
      transition: all 300ms;
    }

    &.opening,
    &.opened {
      top: 0;
      display: block;
    }

    &.opened .actions {
      display: block !important;
    }

    &.closing {
      top: 100%;
      display: block;
    }

    &.closed {
      display: none;
    }

    .h1,
    .h2 {
      margin: 0;
      color: $color-white;
      font-size: 21px;
    }

    .h1 {
      background-color: map-get($background-and-border-colors, background-light-blue);
      height: 80px;

      @media (min-width: 1200px) {
        height: 150px;
      }

      span {
        display: block;
        float: left;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-top: 19px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
          fill: white;
        }

        @media (min-width: 1200px) {
          width: 70px;
          height: 70px;
          border-radius: 70px;
          margin-top: 37px;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        background-color: map-get($secondary-colors, support_icon);
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
      }

      h1 {
        font-size: 25px;
        line-height: 80px;
        font-weight: 600;
        float: left;
        margin: 0 0 0 15px;

        @media (min-width: 1200px) {
          line-height: 150px;
          font-size: 35px;
        }
      }

      .close {
        position: absolute;
        top: 40px;
        right: 40px;
        cursor: pointer;

        svg {
          height: 20px;
          width: 20px;
          fill: white;
        }
      }
    }

    .h2 {
      background-color: map-get($hubColors, product_finder_tab_unselected_text);
      max-height: 150px;
      padding: 20px 0;
      font-size: 18px;
      font-weight: 400;

      @media (min-width: 1200px) {
        padding: 30px 0;
        font-size: 21px;
      }

      @media (max-width: 1199px) {
        line-height: 25px;
      }
    }

    & > .container {
      overflow-y: auto;
      position: relative;

      @media (max-width: 1199px) {
        margin-bottom: 60px;
      }

      .actions {
        display: none;

        @media (min-width: 1200px) {
          margin-bottom: 20px;
        }

        @media (max-width: 1199px) {
          position: fixed;
          bottom: 20px;
          height: 60px;
          bottom: 0;
          left: 0;
          right: 0;

          a {
            display: block;
            margin: 0;
            padding: 0;
            height: 60px;
            border-radius: 0;
            border: none;
            line-height: 60px;
          }
        }
      }
    }
    .container {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: 30px;
      margin-left: 30px;

      .header {
        padding: 40px 0 25px 0;

        h1 {
          margin: 0;
        }
      }

      .orb-teacher-contact-preferences-form-component {
        @media screen and (min-width: 1024px) {
          width: 50%;
        }

        margin-bottom: 75px;

        .form div:first-child {
          margin-top: 0;
        }
      }

      .image {
        width: 40%;
        position: absolute;
        right: 15px;
        top: 60px;
      }

      .actions {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60px;

        @media screen and (min-width: 1024px) {
          a {
            margin-right: 10px;
          }
        }
      }

      ul {
        margin-bottom: 20px;
      }

      i.info {
        line-height: 25px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}
