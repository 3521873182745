@import "../../styles/constants/colors";

.ArticleTable {
  table {
    border-collapse: collapse;
    margin-bottom: 15px;
    width: 100%;
  }
  th {
    font-size: 14px;
    padding: 12px;
  }
  td {
    padding: 12px;
    text-align: center;
  }
  th, td {
    border-bottom: 1px solid map-get($background-and-border-colors, border);
  }
}
