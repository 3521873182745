@import '../../../styles/constants/breakpoints.scss';
@import '../../../styles/constants/colors.scss';
@import "../../../styles/mixins/hub/hub-button-focus";

$tab-border-width: 2px;
$tab-height: 55px;
$header-height: 200px;
$country-dropdown-height: 56px;
$search-box-height: 80px;
$scroll-container-height: calc(100% - $header-height);

.headerProductFinderSearch,
.headerProductFinderOverview {
  position: relative;
  display: flex;
  height: $header-height;
  box-sizing: border-box;
  flex-direction: column;
  padding: 1rem;
}

.borderlessHeader {
  border-bottom-width: 0 !important;
}

.thumbnailContainer {
  display: flex;
  height: 100%;
  align-items: center;
}

.headerProductFinderSearch {
  border-bottom: 1px solid $color-borders;
}

.textRightRow {
  text-align: right;
}

.textCenterRow {
  text-align: center;
}

.iconContainer {
  width: 5rem;
  margin: 2rem auto 0;
}

.textContainer {
  margin: 1rem;
}

.scrollContainerSeriesOverviewOuter,
.scrollContainerOuter {
  position: relative;
  display: flex;
  height: calc($scroll-container-height - $country-dropdown-height - $search-box-height);
  box-sizing: border-box;
}

.changeButton {
  margin-left: 1rem;
}

.selectedTab {
  border-style: solid;
  border-color: $color-light-grey-border !important;
  border-bottom-color: $color-white !important;
  color: map-get($hubColors, product_finder_tab_selected_text) !important;
}

.unselectedTab {
  border-style: solid;
  border-color: $color-light-grey-border !important;
  background-color: $color-white !important;
  color: map-get($hubColors, product_finder_tab_unselected_text) !important;
}

.selectedTab,
.unselectedTab {
  box-sizing: border-box;
  border-width: $tab-border-width;

  @media #{$phoneMedia} {
    padding: 0 10px !important;
  }

  @media #{$xsMedia} {
    padding: 0 10px !important;
  }
}

.tabsTopContainer {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
}

.productLicencesContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.tabsContainerRow {
  height: calc($tab-height + 2 * $tab-border-width);
}

.tabsContainerTabList {
  height: 100%;
}

.tabContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.tabPanelContainer {
  position: absolute;
  z-index: 1;
  top: calc($tab-height + $tab-border-width);
  width: 100%;
  height: calc(100% - ($tab-height + 2 * $tab-border-width));
  border-width: $tab-border-width;
  border-top-style: solid;
  border-top-color: $color-light-grey-border !important;
  overflow-y: scroll;
}

.productsSelectedContainer {
  position: relative;
}

.productsUnselectLinkContainer {
  position: absolute;
  top: 0;
  right: 2rem;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.scrollContainerLicences,
.scrollContainerTabs {
  position: relative;
  display: flex;
  width: 100%;
  height: $scroll-container-height;
}

.scrollContainerLicences {
  height: 100%;
}

.scrollContainerSeriesOverviewOuter {
  width: 100%;
  height: $scroll-container-height;
}

.scrollContainerTabs,
.scrollContainerProducts {
  overflow-y: hidden;

  .scrollContainerSeriesOverviewOuter,
  .scrollContainerOuter {
    height: 100%;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  color: map-get($typography-colors, text);

  p {
    color: map-get($typography-colors, sub-text);
  }
}

.headerTextContainer {
  display: flex;
  width: 70%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
}

.panelHeading {
  max-width: calc(100% - 90px);
}

.searchFormContainer {
  margin: 1.25rem 1rem;
}

.countrySelectorContainer {
  position: relative;
  display: inline-flex;
  height: $country-dropdown-height;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem;
  border-radius: 5px;

  .countryLabel {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    color: map-get($typography-colors, text);
  }

  .countryPicker {
    position: relative;
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 900;

    >div {
      position: relative;
      width: 100%;
    }
  }
}

.titleContainer {
  display: flex;
  width: 100%;
  height: 65%;
  align-items: center;

  h2 {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media #{$phoneMedia} {
      font-size: 1.2rem;
    }

    @media #{$xsMedia} {
      font-size: 1.2rem;
    }
  }
}

.filtersContainer {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  > div,
  * > div {
    padding: 0 !important;
    margin: 0 !important;
  }

  * > button {
    padding: 0 0.25rem;
  }

  * > form {
    margin: 0 !important;
  }

  @media #{$phoneMedia} {
    flex-direction: column;
  }
}

form.rightFilterContainer {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

form.leftFilterContainer {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.subtitleProduct {
  padding: 1.5rem;
  margin: 0;
}

.alignedRight {
  align-self: flex-start;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.productContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.licencesContainer {
  margin: 1rem;
}

.multipleLicencesContainer {
  position: relative;
  display: flex;
  height: 100%;
}

.licenceLoadingContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.licenceLoadingIconContainer {
  width: 5rem;
  margin: 2rem auto;
}

.paragraph {
  margin: 1rem;
}

.navPillsContainer {
  margin-top: 1rem;
}

.navPills {
  button {
    min-height: 2.5rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid map-get($background-and-border-colors, border);

    &:first-child {
      border-right: none;
      border-bottom-left-radius: 1.5625rem;
      border-top-left-radius: 1.5625rem;
    }

    &:last-child {
      border-left: none;
      border-bottom-right-radius: 1.5625rem;
      border-top-right-radius: 1.5625rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin: 0 0.75rem 0 0;
        fill: map-get($hubColors, hub_expiring_licence);
        transform: translateY(20%);
      }
    }

    &.active {
      border: 1px solid transparent;
      background-color: map-get($primary-colors, primary);
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }

    &:focus {
      @include standart-focus-button();

      outline: none;
    }
  }
}

.emptyStateProductFinder {
  position: relative;
  width: 100%;
  padding: 0 2.5rem;
}
