@import "../../styles/constants/colors";

.classCode {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .classCodeHeader {
    display: flex;
    align-items: baseline;
    padding-right: 10px;
    margin-right: 3px;
    font-weight: bold;
  }

  .classCodeLink {
    padding-left: 10px;
    color: map-get($hubColors, hub_electric_blue);
  }
}
