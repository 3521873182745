@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

.flagContainer {
  display: inline-block;
  padding: 0 0.5rem;
  margin-top: 0.375rem;
  margin-right: 0.55rem;
  background-color: map-get($secondary-colors, hub_dashboard);
  border-radius: 1.5625rem;
  line-height: 1.2rem;

  .flagTitle {
    color: $color-white;
    font-size: 0.825rem;
  }

  svg {
    width: 0.7rem;
    height: 0.6rem;
    fill: white;
    transform: rotate(90deg);
    transition: transform 0.25s ease;
  }

  svg + .flagTitle {
    position: relative;
    padding-left: 0.35rem;
    margin-left: 0.25rem;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      border-left: 1px solid $color-text-grey;
      content: '';
    }
  }

  &.isCollapsed {
    svg {
      transform: rotate(0deg);
    }
  }
}
