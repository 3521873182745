@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/mixins/hub/hub-button-focus";

.itemContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin: 0.75rem 0;
  border-radius: 0.3125rem;
  color: $color-black;
  cursor: pointer;

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid $color-light-grey;
    border-radius: 0.3125rem;
    content: "";
  }

  .itemSubContainer {
    display: flex;
    align-items: center;
  }

  .itemDownload {
    color: map-get($primary-colors, primary);
  }

  .itemTitle {
    font-weight: 900;
  }

  .itemDocumentIcon {
    display: flex;
    overflow: visible;
    width: 1.875rem;
    height: 1.875rem;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    margin-right: 0.75rem;
    background-color: $color-grey;
    border-radius: 50%;
    fill: $color-white;
  }

  &:hover {
    text-decoration: none;

    &::before {
      border: 2px solid map-get($secondary-colors, hub_blue);
    }

    .itemDocumentIcon {
      background-color: map-get($secondary-colors, hub_blue);
    }
  }

  &:focus {
    text-decoration: none;

    @include resource-on-focus();

    .itemDocumentIcon {
      background-color: map-get($primary-colors, primary);
    }
  }
}

.itemFolderLinkContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.itemFolderContainer {
  position: relative;
  display: flex;
  overflow: visible;
  min-width: calc(25% - 1rem);
  max-width: calc(25% - 1rem);
  height: 6rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin: 0.75rem 0;
  border-radius: 0.3125rem;
  cursor: pointer;

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 6rem;
    box-sizing: border-box;
    border: 1px solid $color-light-grey;
    border-radius: 0.3125rem;
    content: "";
  }

  &:hover,
  &:focus {
    &::before {
      border: 2px solid map-get($secondary-colors, hub_blue);
    }
  }

  .itemFolderIconContainer {
    position: relative;
    display: flex;
    width: 2.875rem;
    height: 2.875rem;
    justify-content: center;
    padding: 0.375rem;
    margin-right: 0.25rem;
  }

  // Only for iphones
  @supports (-webkit-touch-callout: none) {
    .itemFolderIcon {
      width: 100%;
      height: 100%;
    }
  }

  .itemFolderTitleShort {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .itemFolderTitleLong {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
}

.itemFolderContainer.itemFolderLockedContainer {
  &::before {
    border-color: map-get($validation-colors, error);
    background-color: $color-light-grey;
  }

  &:hover,
  &:focus {
    &::before {
      border: 2px solid map-get($validation-colors, error);
    }
  }
}

@media #{$xsMedia} {
  .itemFolderContainer {
    min-width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
  }
}

@media #{$smMedia} {
  .itemFolderContainer {
    min-width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

@media #{$mdMedia} {
  .itemFolderContainer {
    min-width: calc(25% - 1rem);
    max-width: calc(25% - 1rem);
  }
}
