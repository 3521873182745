@import '../../styles/constants/variables';

.background {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: var(--vh100, 100vh);
  background-color: $u14-blue;

  @media (min-width: 680px) {
    padding: 40px 40px 30px;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  background-color: white;
  color: $dark-blue;

  @media (min-width: 680px) {
    margin: 40px 40px 0;
    max-width: 720px;
    width: 100%;
    border-radius: 25px;
  }
}

.panelNarrow {
  @media (min-width: 680px) {
    max-width: 600px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerIcon {
  display: none;
  width: 120px;
  margin: -60px 0;

  @media (min-width: 680px) {
    display: block;
  }
}

.banner {
  background-color: $u14-blue-light;
  color: $dark-blue;
  margin: 0 -40px;
  margin-bottom: 20px;
  padding: 20px 40px;
}

.bannerHeading {
  margin: 0;
  font-size: 1em;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  line-height: 1.5;
}

.heading {
  margin-top: 0;
  font-size: 2.75em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: 80px;
  background: $color-border-blue;
  color: white;

  @media (min-width: 680px) {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.footerAction {
  min-width: 250px;
  margin-top: -40px;
  padding: 20px;
  border: 4px solid white;
  border-radius: 40px;
  background-color: $green;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: $dark-green;
    text-decoration: none;
    color: currentColor;
  }

  &:disabled {
    background-color: $color-botton;
    cursor: not-allowed;
  }
}

.footerContent {
  margin-top: 30px;
  margin-bottom: 40px;
}

.brand {
  width: 120px;
}

.hidden {
  display: none;
}

.disabled {
  background-color: $color-botton;
  cursor: not-allowed;
}
