@import "../../../styles/constants/breakpoints.scss";
@import "../../../styles/constants/colors.scss";

.platformNotificationTopBanner {
  padding: 12px;
  font-weight: 600;
}

.validationMessageComponent {
  width: 100%;
}

.platformNotificationMessage {
  width: 100%;
  display: flex;
  gap: .5em;
  align-items: center;
}

.dismissButton {
  margin-left: auto;
}

.systemNotificationTopBanner {
  background-color: map-get($secondary-colors, learner);

  .textStyle {
    padding: 18px 18px 18px 21px;
    margin-right: auto;
    color: $color-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    @media #{$xsMedia} {
      padding: 25px 60px 25px 26px;
      font-size: 15px;
      line-height: 17px;
    }
  }

  .bannerBgIcon {
    display: flex;
    width: 178px;
    align-self: flex-end;

    @media #{$xsMedia} {
      position: absolute;
      right: -52px;
      bottom: 0;
      width: 117px;
    }
  }

  .mainStyle {
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: space-between;

    @media #{$xsMedia} {
      position: relative;
      display: block;
      margin: 0 -15px;
    }
  }

  .close {
    align-items: center;
    cursor: pointer;

    svg {
      width: 13px;
      height: 13px;
      fill: $color-white;

      &:hover {
        fill: darken($color-white, 20%);
      }
    }

    @media #{$xsMedia} {
      position: absolute;
      top: 5px;
      right: 14px;
    }
  }
}
