@import '../../styles/constants/colors.scss';
@import '../../styles/constants/breakpoints.scss';

.progressBar {
  width: 100%;
  text-align: center;

  .progressBarMainContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .pendingIconContainer {
    display: none;
    width: 35%;

    svg {
      fill: map-get($hubColors, gray);
    }
  }

  .progressBarWithPencil {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .progressBarContainer {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  &__container,
  &__value {
    border-radius: 6px;
  }

  &__container {
    width: 85%;
    height: 10px;
    border: 1px solid $color-borders;
    margin-top: 5px;
    margin-right: 15px;

    &.type-SECONDARY {
      width: 65%;
      height: 12px;
      border: 0;
      background: map-get($secondary-colors, no_progress_grey);
    }

    &--with-label {
      display: none;
    }
  }

  &__value {
    height: 100%;
    transition: width 0.5s ease-in-out;
  }

  .defaultColor {
    background-color: $color-primary-blue;
  }

  .shortLabel {
    display: none;
    font-size: 14px;
  }

  @media #{$phoneMedia} {
    .shortLabel {
      display: block;
    }

    .pendingIconContainer {
      display: block;
    }

    .progressBarContainer {
      flex-direction: column;
    }
  }
}

@media #{$smMedia} {
  .progressBar {
    display: flex;

    &__container {
      display: block;
    }
  }
}
