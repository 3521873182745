@import "../../styles/constants/colors";

// This is styled separately so it can be easily refactored to a generic banner component if necessary
.connectedBannerInvitation {
  min-height: 65vh;
}
.bannerInvitation {
  background-color: $color-white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;

  h4 {
    margin: 0 0 0.5rem 0;
    div {
      p {
        font-weight: 700;
        font-size: 35px;
      }
    }
    button {
      margin-left: 5px;
    }
  }

  .bannerButtons {
    flex-wrap: wrap;
  }

  .inviteHeading {
    margin-top: 2.4rem;
  }
}

.inviteSummaryThumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.inviteSummaryText {
  margin-top: 1rem;
}

.inviteSummaryText div:first-child {
  font-size: 21px;
}

.inviteSummaryText div:last-child {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 300;
}

.carouselContainer {
  display: flex;
  justify-content: center;
}
