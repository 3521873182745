@import "../../../styles/mixins/hub/hub-button-focus";
@import "../../../styles/constants/colors";

.backButton {
  a {
    :not([disabled]):hover {
      text-decoration: none !important;
    }
  }

  a:focus {
    border-radius: 3px;
    box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($primary-colors, primary), 0 0 0 4px $color-white;
  }
}

.labelTitleFolder {
  margin: 1.3rem 0.1rem;
  font-size: 21px;
  font-weight: 600;
}

.viewResourcesButton {
  margin-bottom: 1rem;

  button {
    min-height: 2.5rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid map-get($background-and-border-colors, border);

    &:first-child {
      border-right: none;
      border-bottom-left-radius: 1.5625rem;
      border-top-left-radius: 1.5625rem;
    }

    &:last-child {
      border-left: none;
      border-bottom-right-radius: 1.5625rem;
      border-top-right-radius: 1.5625rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin: 0 0.75rem 0 0;
        fill: map-get($hubColors, hub_expiring_licence);
        transform: translateY(20%);
      }
    }

    &.active {
      border: 1px solid map-get($hubColors, hub_very_light_blue);
      background-color: map-get($secondary-colors, background_light_blue);
      color: $color-black;
      font-weight: bold;

      svg {
        fill: $color-white;
      }
    }

    &.inactive {
      &:hover {
        background-color: $color-button-background3;
      }
    }
  }
}

.subFoldersSection {
  margin-top: 0.75rem;
}
