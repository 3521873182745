@import "../../styles/constants/colors";

.noticeIcon {
  width: 26px;
  height: 26px;
  padding: 5px;
}

.orgsTable {
  thead tr th span,
  tbody tr td time {
    white-space: nowrap;
  }

  thead tr th:nth-last-child(4),
  tbody tr td:nth-last-child(4) {
    text-align: center;
  }

  tbody tr td:nth-last-child(3) button {
    margin: 0 auto;
  }
}

.link {
  color: $color-primary-blue;
  white-space: nowrap;

  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  &:hover {
    text-decoration: underline !important;
  }
}
