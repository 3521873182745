@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.hubDropdownMenu {
  .dropdownButton {
    z-index: 1;
    max-width: 40px;
    max-height: 40px;
    padding: 0.625rem;
    background-color: $color-white;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 $contrast-light-accent-50;

    @media #{$phoneMedia} {
      margin-left: 0.3rem;
    }

    .svgContainer {
      display: flex;

      svg {
        height: 1.25rem;
        fill: map-get($hubColors, blue-color-primary);
      }
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: map-get($secondary-colors, hub_light_grey);

      .svgContainer svg {
        fill: map-get($legacy-color-names, tooltip);
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 2px map-get($primary-colors, primary), 0 0 0 1px $color-white;
      outline: none;
    }
  }
}

@media #{$phoneMedia} {
  .hubDropdownMenu .dropdownButton {
    box-shadow: none;
  }

  .hubDropdownMenu {
    z-index: 1;
  }
}
