
.endDateContainer,
.endDateContainer > div:first-child {
  position: relative;
  z-index: 1;
}

.startDateContainer,
.startDateContainer > div:first-child {
  position: relative;
  z-index: 2;
}
