.togglerWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  align-items: center;
  min-height: 3rem;
  div:first-child {
    display: flex;
    align-items: center;
    label {
      margin-left: 1rem;
      font-weight: 600;
    }
  }
  .togglerButton {
    height: 2rem;
    width: 3.2rem;
    padding: 3px;
    span {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
