@import "../../styles/mixins/hub/hub-button-focus";

.container {
  padding: 1rem;

  > button {
    width: 100%;

    &:not(:first-child) {
      margin-top: 0.75rem;
    }
  }

  button:focus {
    @include standart-focus-button();
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
