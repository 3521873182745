// This is styled separately so it can be easily refactored to a generic banner component if necessary

.bannerNotification {
  // The following styles only apply when the isPopop prop is specified:
  // Customise the position of the popup and it's beak:
  // Note the horizontal position typically aims to correspond with the center of a profile icon above:
  .bannerNotificationPopup {
    top: 0px;
    &:before, &:after {
      left: 24px;
    }
  }
}
