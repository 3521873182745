@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

.fallbackImage {
  display: flex;
  justify-content: center;
  background: color(primary-dark);

  svg {
    width: 70%;
    opacity: 0.5;
  }

  @media #{$phoneMedia} {
    width: 100%;
    height: 100%;
    align-items: center;
    border: 1px solid $contrast-light-accent-50;
    border-radius: 100%;

    svg {
      width: 100%;
    }
  }
}

.svgFullWidth svg {
  width: 100%;
}
