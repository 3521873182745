@import "../../styles/constants/colors";
@import './variables.scss';

//
// ORB Filter
// --------------------------------------------------
.orb-filter {
    list-style: none;
    padding: 0px;
    li {
        display: inline-block;
        margin-top: 10px;
        margin-right: 10px;
        select {
            // reset
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            margin: 0;
            // styling
            cursor: pointer;
            padding: 0.5em 3.5em 0.5em 1em;
            border: 1px solid $contrast-dark-accent-35;
            border-radius: $ces-border-radius;
            background-color: transparent;
            background-image:
                linear-gradient(45deg, transparent 50%, $contrast-dark-accent-35 50%), // left side of arrow
                linear-gradient(135deg, $contrast-dark-accent-35 50%, transparent 50%), // right side of arrow
                linear-gradient(to right, $contrast-dark-accent-35, $contrast-dark-accent-35); // divider
            background-position:
                calc(100% - 18px) calc(1em + 2px), // left side of arrow
                calc(100% - 13px) calc(1em + 2px), // right side of arrow
                calc(100% - 2.2em) 0.5em; // divider
            background-size:
                5px 5px, // left side of arrow
                5px 5px, // right side of arrow
                1px 1.5em; // divider
            background-repeat: no-repeat;
            color: $color-white;
        }
    }
    @media (max-width: $grid-float-breakpoint-max) {
        li {
            display: block;
            select, button {
                width: 100%;
            }
        }
    }
}
