@use "sass:map";

.inputGroup {
  display: flex;
  align-items: center;

  input {
    height: 40px; /* Set a consistent height */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    padding: 0 10px; /* Adjust padding as needed */
    border: 1px solid #ccc; /* Ensure border matches button */
    border-radius: 0;
  }
}

.togglePasswordVisibility {
  display: flex;
  width: 60px; /* Set a fixed width */
  height: 40px; /* Match the input height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove padding */
  border: 1px solid #ccc; /* Ensure border matches input */
  border-radius: 3px;
  margin-left: -1px; /* Ensure it touches the input field */
  background: none; /* Remove default button styling */
  cursor: pointer; /* Change cursor to pointer */
  fill: #002147;

  &:focus {
    border-color: #002147;
    box-shadow:  inset 0 0 0 1px #002147 !important;
    outline: none;
  }

  &:hover {
    border-color: #002147;
    background-color: #e5e9ed;
    mix-blend-mode: multiply;
  }
}

.passwordVisibilityStatus {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
}

input[type="password"]::-ms-reveal {
  display: none;
}
