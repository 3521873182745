@import "../../styles/constants/colors";

.ArticleVideos {
  ul {
    margin-bottom: 5px;
  }

  .videoContainer {
    border: 1px solid $color-botton;
    margin-bottom: 20px;
    padding: 15px;
  }

  video {
    cursor: pointer;
    width: 100%;
    border: 1px solid map-get($background-and-border-colors, border);
    background-color: map-get($secondary-colors, dashboard);
    margin-bottom: 10px;
  }

  h4 {
    font-weight: bold;
    margin-top: 0;
  }
}
