@import "../../../styles/constants/colors";

.container {
  display: flex;
  align-items: center;

  .infoIcon {
    display: flex;
    width: 1em;
    height: 1em;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 1.2em;
  }

  .largeTooltip {
    height: 30px;
    padding-right: 0;

    ul li {
      display: list-item;
      margin-left: 12px;
    }

    p {
      color: $color-text-grey;
      font-size: 14px;
    }

    span {
      right: auto;
      left: -13px;
      width: 268px;
      border: 2px solid skyblue;
      background-color: white;
      color: $color-text-grey;
      font-size: 14px;
      text-align: left;
      transform: none;

      &::after {
        right: auto;
        left: 22px;
        border-bottom-color: white;
        transform: none;
      }

      &::before {
        position: absolute;
        right: auto;
        bottom: 100%;
        left: 21px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-width: 0 10px 10px 10px;
        border-style: solid;
        border-color: transparent transparent map-get($teacher-resource-colors, tr-primary) transparent;
        border-top: 0;
        content: "";
        transform: none;
      }
    }
  }
}
