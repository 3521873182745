@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.popupOverlay {
  position: absolute;
  z-index: 1;
  left: 0;
  border: 1px solid map-get($background-and-border-colors, border);
  margin-top: 5px;
  background: $color-white;
  border-radius: 4px;
  transition: opacity 300ms;

  &:not(.visible) {
    opacity: 0;
    display: none;
    pointer-events: none;
  }

  &.inline {
    position: relative;
    margin-top: 0;
  }

  &::before,
  &::after {
    position: absolute;
    left: 10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    content: "";
    pointer-events: none;
  }

  &::before {
    top: -21px;
    border-bottom-color: map-get($background-and-border-colors, border);
  }

  &::after {
    top: -20px;
    border-bottom-color: $color-white;
  }
}

// Had to move this out to decrease its specificity
.hangRight {
  right: 0;
  left: auto;

  &::before,
  &::after {
    right: 10px;
    left: auto;
  }
}

body[data-theme="hub-theme"] {
  .popupOverlay {
    &::before,
    &::after {
      position: absolute;
      left: 10px;
      width: 0;
      height: 0;
      border: 12px solid transparent;
      content: "";
      pointer-events: none;
    }

    &::before {
      top: -25px;
      border-bottom-color: map-get($background-and-border-colors, border);
    }

    &::after {
      top: -23px;
      border-bottom-color: $color-white;
    }

    &:active {
      background-color: $color-white;
    }
  }

  .hangRight {
    left: auto;

    &::before,
    &::after {
      left: auto;
    }
  }
}

@media #{$xsMedia} {
  .filterButton {
    right: unset !important;
    left: 1.25rem !important;

    &::before,
    &::after {
      right: auto;
      left: 10px;
    }
  }

  .sortButton {
    right: 1.25rem !important;
    left: unset !important;
  }
}
