@use "sass:map";
@use "sass:color";
@import '../../styles/constants/colors';
@import '../../styles/constants/breakpoints';

.show {
  display: block;
}

.hide {
  display: none;
}

.confirmationModal {
  position: fixed;
  z-index: 103;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: var(--vh100, 100vh);
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background: $color-black3;
  transition: opacity 1s linear;

  .modal {
    position: relative;
    width: auto;
    min-width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 6px;
    margin: 40px auto 0;
    background: $color-white;
    box-shadow: 0 5px 15px $contrast-light-accent-50;
    text-align: center;
    transform: translateY(-40px);
    transition: transform 0.3s ease-out;

    .centerer {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;

      h3 {
        margin: 0;
        font-size: 21px;
      }
    }

    .greySubheader {
      margin-top: -4px;
      margin-bottom: 22px;
      color: map.get($hubColors, dark-gray);
      font-size: 21px;
      font-weight: 400;
      line-height: 1.4;
    }

    .bottomBorder {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-grey-modal-border;

      button:first-child {
        margin-right: 15px;
      }
    }

    .flexRighter {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;

      button:first-child {
        margin-right: 15px;
      }
    }

    &.active {
      transform: translate(0);
    }

    .title {
      padding: 5px 25px 15px;
      border-bottom: 1px solid $color-borders;
    }

    .body {
      padding: 25px;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      padding: 0 25px 25px;

      button:first-child {
        margin-left: 120px;
      }
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      svg {
        width: 10px;
        height: 10px;
        fill: $color-borders;

        &:hover {
          fill: color.adjust($color-borders, $lightness: 20%);
        }
      }
    }

    .contentWrapper {
      margin: 0 auto;
    }
  }

  @media #{$smMedia} {
    .modal {
      min-width: 600px;
    }
  }

  &.noCustomStyles {
    .modal {
      width: auto;
      min-width: auto;
      max-width: 416px;
      height: 400px;

      .button {
        text-align: right;

        span {
          color: map.get($background-and-border-colors, background-light-blue);
        }

        svg {
          fill: map.get($background-and-border-colors, background-light-blue);
        }
      }
    }
  }
}
