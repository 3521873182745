@use "sass:map";
@import '../../styles/constants/colors';
@import '../../styles/constants/breakpoints';
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.product-selection-page {
  .message {
    padding: 7px 15px;
    margin: 1em;
  }

  .productSelectionTable {
    margin-top: 20px;

    .bigText {
      font-size: 1.7em;
      text-align: center;
    }

    .mediumText {
      font-size: 1.3em;
      text-align: center;
    }

    .smallText {
      font-size: 1rem;
    }

    .textCenter {
      text-align: center;
    }

    .progressContainer {
      display: flex;
      justify-content: center;
    }

    .scoreBarContainer div:first-child {
      flex-direction: column;
      align-items: center;

      span {
        font-size: 1rem;
      }
    }

    .books {
      margin-top: 0;

      &Text {
        position: relative;
        left: 10px;
        color: map.get($typography-colors, sub-text);
        font-size: large;
      }
    }

    @media #{$phoneMedia} {
      .textCenter {
        margin: 0;
        font-size: 14px;
        text-align: center;
      }

      .mediumText,
      .scoreBarContainer div:first-child span {
        font-size: 18px;
      }
    }

    // <Table /> overrides
    thead th,
    tbody tr td {
      position: relative;
      box-sizing: content-box;
      text-align: left;
    }

    th:first-of-type,
    td:first-of-type {
      width: auto;
      padding-right: 0;
    }

    &.gradedReaders {
      th:nth-child(2),
      td:nth-child(2) {
        display: none;
      }
    }

    &.enhancedLayout {
      td {
        border-top: 0 none;
      }

      thead th {
        border-bottom: 1px solid map.get($primary, primary20);

        @media #{$phoneMedia} {
          border-bottom: none;
        }
      }

      th:first-of-type,
      td:first-of-type {
        width: 35%;
        padding-right: 5px;
      }
    }

    @media #{$smMedia} {
      th:first-of-type,
      td:first-of-type {
        width: 30%;
        padding-right: 15px;
      }

      th:nth-child(2),
      th:nth-child(3) {
        text-align: center;
      }

      th:nth-child(2),
      td:nth-child(2) {
        width: 30%;
      }

      td:nth-child(2) > div {
        margin: auto;
      }

      &.gradedReaders {
        th:nth-child(2),
        td:nth-child(2) {
          display: table-cell;
        }
      }
    }

    @media #{$phoneMedia} {
      thead th {
        border: none;
      }

      thead th span {
        padding: 0;
        border: none;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
      }

      th:nth-child(2) > div,
      th:nth-child(3) > div {
        display: none;
      }

      tr:not(:last-child) {
        display: flex;
        flex-direction: row;
      }

      tr:not(:last-child) td {
        display: block;
        border: 0;
      }

      tr:not(:last-child) td:nth-child(1) {
        width: 76px;
        padding: 12px 0 12px 16px;
        margin: 0;
      }

      tr:not(:last-child) td:nth-child(2) {
        padding: 12px 0;
        margin: 0;
      }

      tr:not(:last-child) td:nth-child(3) {
        padding: 12px 16px 12px 0;
        margin: 0;
      }

      tr:not(:last-child) td:nth-child(1) > div {
        position: relative;
        height: 120px;
      }

      tr:not(:last-child) td:nth-child(2) > div,
      tr:not(:last-child) td:nth-child(3) > div,
      tr:not(:last-child) td:nth-child(3) > p {
        position: relative;
        width: calc(50vw - 54px);
        height: 120px;
      }

      tr:not(:last-child) td:nth-child(1) > div a {
        z-index: 1;
        display: block;
        width: 100vw;
        height: 50px;
      }

      tr:not(:last-child) td:nth-child(1) > div > a > div { /* stylelint-disable-line selector-max-compound-selectors */
        position: absolute;
        top: 0;
        width: calc(100vw - 32px);
        align-items: normal;
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      tr:not(:last-child) td:nth-child(1) > div > a > div > div:nth-child(2) {
        overflow: hidden;
        height: 50px;
      }

      tr:not(:last-child) td:nth-child(1) > div > a span {
        height: 50px;
      }

      tr:not(:last-child) td:nth-child(2) > div > div,
      tr:not(:last-child) td:nth-child(3) > div > div {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px 0 0 4px;
        background: map.get($primary, primary10);
        font-size: 18px;
      }

      tr:not(:last-child) td:nth-child(3) > p > div {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        min-height: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0 4px 4px 0;
        background: map.get($primary, primary10);
        font-size: 18px;
      }
    }
  }
}

@media #{$phoneMedia} {
  body[data-theme="hub-theme"] {
    .productSelectionTable {
      /* stylelint-disable-next-line selector-max-compound-selectors */
      tr:not(:last-child) td:nth-child(1) > div > a > span > div > div:nth-child(2) {
        text-decoration: underline;
      }
    }
  }
}

.error {
  padding: 7px 15px;
  border-radius: 4px;
  margin: 1em;
  background: map.get($validation-colors, error);
  color: map.get($base, white100);
}

.gradebookProgressPieChart {
  width: 100%;

  @media #{$smMedia} {
    display: flex;

    > div {
      width: 50%;
    }

    > div:first-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  @media #{$phoneMedia} {
    span {
      font-size: 18px;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.containerTitleWithIcon {
  display: flex;
  align-items: center;

  > div:first-child {
    width: 50px;
    margin-right: 1rem;
  }

  @media #{$phoneMedia} {
    position: absolute;
    z-index: 1;

    > div:first-child {
      width: 70px;
      margin-right: 16px;
    }

    > div:nth-child(2) {
      position: relative;
      left: 5px;
      width: 100%;
    }
  }
}

.navigationChanges.product-selection-page {
  padding-right: $gap-5;
  padding-left: $gap-5;

  @media #{$phoneMedia} {
    padding: 0;
  }
}
