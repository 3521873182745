@use "sass:map";
@import "../../styles/constants/colors";

.dropdown {
  .dropdownContainer {
    position: relative;
    width: 100%;
  }

  label {
    display: inline-block;
    margin: 15px 0 5px;
    font-weight: 600;
  }

  select {
    width: 100%;
    height: 40px;
    padding: 0 44px 0 12px;
    border: 1px solid map.get($background-and-border-colors, border);
    border-radius: 4px;

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $color-white;
    box-shadow: none;
    cursor: pointer;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      border: 1px solid $color-primary-blue;
      background: $color-mid-grey-2;
      box-shadow: inset 0 1px 1px $contrast-light-shadow;
      outline: none;
    }

    &:hover {
      border: 1px solid map.get($legacy-color-names, text-input-border);
      background: $color-grey-button;
      box-shadow: inset 0 1px 1px $contrast-light-shadow;
      outline: none;
    }

    &:disabled {
      // non-standard colors
      background: $color-grey-disabled;
      color: $color-text-grey-disabled;
    }
  }

  svg {
    position: absolute;

    // This technique worked in more browsers than setting margin:
    top: 50%;
    right: 4px;
    width: 14px;
    pointer-events: none;
    transform: translateY(-50%);
  }

  // Custom inline type
  &--inline {
    display: flex;

    .dropdownContainer {
      flex-grow: 1;
    }

    label {
      align-self: center;
      margin: 0 0.5em 0 0;
      font-weight: normal;
    }
  }
}

.labelLeft {
  display: flex;

  label {
    margin-right: 7px;
  }
}
