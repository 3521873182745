@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.panelNavigationLink {
  color: map-get($styleGuideColors, primary);

  // Note the extra [class] selector to increase specificity enough to override default LinkWithIcon styles:
  &[class]:hover {
    color: map-get($styleGuideColors, primary-dark);
    text-decoration: none;
  }

  &[class]:focus {
    @include no-padding-button-on-focus();

    outline: none;
  }
}
