@import "../../styles/constants/colors";

.testItemContainer {
  margin-top: 0.75rem;

  .adaptedtest {
    display: flex;
    width: 100%;
    flex: 1;
    font-weight: 400;

    .adaptedTestArrow {
      min-width: 2rem;
      max-width: 2rem;
      min-height: 4rem;
      max-height: 4rem;
      fill: map-get($hubColors, blue-color-primary);
    }
  }
}
