@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

// Online test time
.fieldsRow {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;

  @media #{$tabletMedia} {
    width: 100%;
  }
}

.fieldsRowContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 16px 20px 16px;

  h2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 1rem;
  }

  >.fieldsRow:first-of-type {
    width: 100%;
    justify-content: flex-start;
  }

  .dateInfoRow p {
    margin-top: 5px;
  }

  .tooltipContainer {
    svg {
      margin-top: 8px;
    }

    span {
      left: -190%;
      width: 14.5rem;
      text-align: left;

      &::after {
        right: 5px;
        left: unset;
      }
    }
  }
}

.fieldsElement {
  display: flex;
  margin-right: 1rem;
}

.validationContainer {
  width: 100%;
  padding-right: 20px;

  >div {
    align-items: flex-end;
    transform: none;
  }

  svg {
    margin-top: 0;
  }
}

.infoIcon {
  display: flex;
  width: 1em;
  height: 1em;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 1.2em;
}

.durationContainer {
  display: flex;
  width: 100%;

  @media #{$phoneMedia} {
    flex-direction: column;
  }

  .durationSection {
    flex: 1;
    margin-right: 1rem;
  }
}

.infoLabelContainer {
  margin-right: 2rem;
  margin-left: 1rem;

  @media #{$phoneMedia} {
    margin-left: 2.5rem;
  }
}

.placementTestContainer {
  .fieldsRowContainer {
    padding: 0;
    margin-top: 20px;

    .fieldsRow {
      width: 100%;
    }
  }
}
