@import '../styles/mixins/hides.scss';
@import '../styles/constants/colors.scss';

.totalResults {
  margin-top: 12px;
  text-align: center;
}

.paginationButtons {
  margin: 2rem 1rem;
}

.limitToggle {
  padding: 1rem;
  border-bottom: 1px solid $color-border;
}

// Contains 2-3 elements: 1 or 2 fields and a totalResults element:
.fields {
  margin-top: 15px;

  // The label of the textbox should be hidden accessibly:
  label[for="selectionSearchTerm-input"] {
    @include a11y-hide();
  }

  // Revealer icon should be blue:
  svg[class] {
    fill: map-get($styleGuideColors, primary);
    transition: transform 0.25s;
  }
}

.hasShowMore {
  zoom: 1;
}

// The show/hide textbox is inside this element:
.revealerContent {
  overflow: hidden;
  max-height: 0;

  // The animation is subject to jank because of the scollarea stretching or squashing below it.
  // Try to coax at least this part of the animation to use GPU acceleration:
  transform: translateZ(0);
  transition: max-height 0.25s;
}

// This class is added when user clicks Show Details button: ▽
.revealerShow {
  max-height: 4rem; // Enough to reveal the Textbox
}
