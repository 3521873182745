@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/mixins/hub/hub-button-focus";

.resourceFolderContainer {
  .resourceFolder {
    position: relative;
    max-width: none;
    box-sizing: border-box;

    &::before {
      position: absolute;
      z-index: -1;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      content: "";
    }

    .folderContainer {
      display: flex;
      min-height: 48px;
      flex: 1;
      align-items: center;
      border-top: 1px dashed $color-light-grey;
      color: map-get($legacy-color-names, tooltip);
      cursor: pointer;

      &:hover {
        background-color: map-get($secondary-colors, hub_light_grey);

        .thumbnailContainer {
          fill: map-get($hubColors, blue-color-dark);
        }
      }
    }
  }

  .svgtestfolder {
    display: flex;
    width: 1.425rem;
    height: 1.425rem;
    margin-right: 10px;
    fill: map-get($legacy-color-names, weak);
  }

  .thumbnailContainer {
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    margin: 0 15px 0 10px;
    fill: map-get($hubColors, blue-color-primary);
    transition: 0.3s ease-out transform;
  }

  .collapsedThumbnail {
    transform: rotate(-180deg);
    transition-duration: 0.3s;
  }

  .lockedtest {
    font-weight: 400;
  }
}

.adaptedResourceContainer {
  margin: 10px 0 10px 40px;
}
