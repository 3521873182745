@use "sass:map";
@import '../../styles/constants/breakpoints';
@import '../../styles/constants/colors';
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.rowContainer {
  align-items: center;
  padding: 1rem 1rem 0;
  background:  map.get($primary, primary10);
}

.gridContainer {
  margin-top: 1em;
}

.workbookTitle {
  flex-grow: 1;
  margin-top: 0.3em;
}

.listTabs {
  display: flex;
  margin-bottom: 1em;

  &__list {
    display: none;
    width: 180px;
  }

  &__panel {
    display: flex;
    max-width: 100%;
    flex-direction: column-reverse;
    flex-grow: 1;
  }

  &__listItem {
    display: flex;
    max-width: 100%;
    align-items: center;
    padding: 0.7em;
    border-top: 1px solid map.get($background-and-border-colors, border);

    &--header {
      padding: 0 0.822em;
      font-size: 14px;
      font-weight: bold;
      text-align: left;

      &:first-child {
        padding: 0.7em;
      }
    }

    &--name {
      overflow: hidden;
      color: map.get($primary-colors, primary);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--current {
      font-weight: bold;
    }

    &--chevron {
      fill: map.get($primary-colors, primary);
    }
  }

  &__listIcon {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
}

.learnerProgress {
  flex-grow: 1;

  &__close {
    text-align: right;
  }

  &__export_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
      margin-left: $gap_3;
    }
  }

  @media #{$lgMediaMax} {
    &__export_button > button {
      display: none;
    }
  }
}

.centerSpacingOptions {
  text-align: right;

  li {
    margin: 0;
  }
}

@media #{$mdMedia} {
  .centerSpacingOptions {
    text-align: center;
  }
}

.hideLastHeadOnMob > thead > tr > th:last-child {
  color: transparent;
}

@media #{$smMedia} {
  .listTabs {
    &__list {
      display: inline-block;
    }

    &__panel {
      padding: 1em;
      border: 1px solid map.get($primary, primary20);
      border-bottom: 0;
    }
  }

  .hideLastHeadOnMob > thead > tr > th:last-child {
    color: map.get($typography-colors, text);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  position: absolute;
  z-index: 99999999;
  top: -10px;
  left: 50%;
  display: block;
  width: 250px;
  height: 50px;
  box-sizing: border-box;
  border: 1px solid $color-grey-progress;
  border-radius: 8px;
  margin-left: -80px;
  background-color: $color-grey-progress;
  box-shadow: 0 1px 8px $color-grey-progress;
  color: $color-white;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  transform: translate(-50%, -100%);
  white-space: normal;
  word-break: break-word;
}

.tooltip .tooltiptextMissingVSTDomain {
  position: absolute;
  z-index: 99999999;
  top: -10px;
  left: 50%;
  display: block;
  width: 219px;
  height: auto;
  box-sizing: border-box;
  border: 1px solid $color-grey-progress;
  border-radius: 8px;
  margin-left: -80px;
  background-color: $color-grey-progress;
  box-shadow: 0 1px 8px $color-grey-progress;
  color: $color-white;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  transform: translate(-50%, -100%);
  white-space: normal;
  word-break: break-word;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .tooltiptext i {
  position: absolute;
  top: 100%;
  left: 83%;
  overflow: hidden;
  width: 24px;
  height: 12px;
  margin-left: -12px;
}

.tooltip .tooltiptext i::after {
  position: absolute;
  left: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid $color-grey-progress;
  background-color: $color-grey-progress;
  box-shadow: 0 1px 8px $color-grey-progress;
  content: "";
  transform: translate(-50%, -50%) rotate(45deg);
}

.activityLabel {
  display: flex;
  width: 100%;
  justify-content: center;

  span {
    display: none;
  }

  .count {
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    margin: auto;
    background: $color-primary-blue;
    color: white;
    float: left;
    font-size: 0.75em;
    line-height: 1.5em;
    text-align: center;
  }

  @media #{$smMedia} {
    justify-content: left;

    span {
      display: block;
    }

    .count {
      display: none;
    }
  }
}

.learnerProgressBar {
  @media #{$smMedia} {
    span {
      width: 75px;
    }
  }
}

.pushRight {
  margin-left: auto;
}

.subtitleFragmentContainer {
  display: flex;
  flex-direction: column;
  padding-top: $gap-4;
  padding-bottom: $gap-4;
  gap: $gap-4;

  @media #{$smTabletMedia} {
    border-bottom: $border-width-1 solid map.get($primary, primary20);
  }

  .dataRefresherBar {
    padding-top: 0;
    padding-bottom: 0;

    @media #{$smTabletMedia} {
      max-width: unset;
      justify-content: space-between;
    }
  }
}

.gradebookControlsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media #{$smTabletMedia} {
    > div {
      width: 100%;
    }

    > div > div button {
      width: 100%;
    }
  }

  @media #{$xsMedia} {
    flex-direction: column;
    gap: $gap-3;
  }
}
