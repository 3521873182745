@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.durationContainer {
  display: flex;
  width: 100%;

  @media #{$phoneMedia} {
    flex-direction: column;
  }

  .durationSection {
    flex: 1;
    margin-right: 1rem;
  }
}

.textInputField {
  // Should fill its container
  display: block;
  width: 100%;
  height: 40px; // Need to explicitly set the height to solve an issue with date inputs
  padding: 0 12px;
  border-width: 1px;

  // Create a transparent border when not validated or focused
  border-style: solid;
  border-color: map-get($background-and-border-colors, border);

  // Shape and size
  border-radius: 4px;
  box-shadow: inset 0 1px 1px $contrast-light-shadow;
  line-height: 38px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    @include standart-focus-button();

    outline: none;
  }

  &:disabled {
    background: $color-grey-disabled;
    // non-standard colors
    color: $color-text-grey-disabled;
  }

  &.textArea {
    height: 12rem;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1rem;
    line-height: 24px;
    resize: vertical;
  }
  // Placeholder Text
  input::placeholder {
    color: $color-sub-text-50;
  }
}
