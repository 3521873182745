@import "../../../styles/constants/colors";

.linkContainer {
  display: flex;
  align-items: center;
  text-align: left;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: map-get($typography-colors, text);
  }
}
