@import '../../styles/constants/colors.scss';
@import '../../styles/constants/breakpoints.scss';

$titleHeight: 36px;
$boxHeight: 32px;

:global {
  body:not(.using-mouse) {
    .levelProgressBar {
      button:focus {
        border: 2px solid white;
        outline: 2px solid map-get($background-and-border-colors, background-light-blue);
        z-index: 1;
      }
    }
  }

  .levelProgressBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 65px; /* IE11 table layout bug */

    @media #{$smMedia} {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
}

.levelProgressTitles {
  height: $titleHeight;
}

// Unordered list `ul`
.levelList {
  height: 100%;
  display: flex;
  justify-content: flex-end;

  @media #{$smMedia} {
    justify-content: flex-start;
  }
}

.levelListItem {
  display: flex;
  align-items: center;
}

.levelBox {
  color: inherit;
  display: none;

  &:hover {
    text-decoration: none;
  }
}

// Mobile dropdow button
.levelButton {
  display: none;

  &--current {
    display: block;
  }

  > button {
    svg:first-child {
      margin-left: -0.3rem;
      height: 1.2rem;
      width: 1.2rem;

      @media #{$smMedia} {
        margin-left: -0.5rem;
        height: 2rem;
        width: 2rem;
      }
    }

    svg + span:not(:global(.a11y-hide)) {
      margin-left: 5px;

      @media #{$smMedia} {
        margin-left: 7px;
      }
    }
  }

  &.levelButton {
    > button {
      height: 40px;

      @media #{$smMedia} {
        height: 46px;
      }

      svg:last-child {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.mobileTitle {
  display: inline;
  line-height: $titleHeight;
}

@media #{$smMedia} {
  // The list item `li`
  .levelListItem, .levelListTitlesItem {
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    @media screen {
      max-width: 44px;
    }

    &--active:after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      background: $color-white;
      border: 1px solid map-get($background-and-border-colors, border);
      border-width: 1px 0 0 1px;
      position: absolute;
      left: 50%;
      bottom: -3px;
      transform: rotate(45deg) translateX(50%);
      margin-left: -13px;
    }

    &--barFocus { background-color: $color-table-background }
    &--expected {
      background-color: map-get($background-and-border-colors, border);
      &.levelListItem--expectedFocus { background-color: darken(map-get($background-and-border-colors, border), 15%) }
    }

    & + & {
      border-left: 1px solid $color-white;
    }

    &:first-of-type {
      @media screen {
        padding-left: $boxHeight / 2;
      }

      .levelBox { border-radius: 16px 0 0 16px }
    }

    &:last-of-type {
      @media screen {
        padding-right: $boxHeight / 2;
      }

      .levelBox { border-radius: 0 16px 16px 0 }
    }
  }

  .levelListTitlesItem--expected:hover {
    background-color: darken(map-get($background-and-border-colors, border), 15%);
  }

  // Coloured box
  @mixin backgroundColor($background) {
    background-color: $background;

    &:hover {
      background-color: darken($background, 10%);
    }
  }

  .levelBox {
    align-items: center;
    background-color: $color-progress-background;
    display: flex;
    justify-content: center;
    height: $boxHeight;
    width: 100%;

    @media print {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact;
    }

    &--style-progress-completed,
    &--style-progress-level-up-with-completed,
    &--style-progress-level-down-with-completed { @include backgroundColor(map-get($primary-colors, primary)) }
    &--style-progress-skipped { @include backgroundColor($color-progress-background) }
    &--status-ahead, &--style-rag-purple-bg { @include backgroundColor(map-get($class-report-colors, ahead)) }
    &--status-expected, &--style-rag-green-bg { @include backgroundColor(map-get($class-report-colors, expected)) }
    &--status-behind, &--style-rag-amber-bg { @include backgroundColor(map-get($class-report-colors, behind)) }
    &--status-very-behind, &--style-rag-red-bg { @include backgroundColor(map-get($class-report-colors, very-behind)) }
    &--style-progress-incompleted {
      background: repeating-linear-gradient(
        -45deg,
        $color-progress-background,
        $color-progress-background 3px,
        map-get($primary-colors, primary) 3px,
        map-get($primary-colors, primary) 12px
      );
    }

    &--title {
      background: none;
      height: 100%;

      &:hover {
        background: none;

        &:before, &:after {
          opacity: 0 !important;
        }
      }
    }

    &--titleFocus {
      &:hover:before, &:hover:after, &:before, &:after {
        opacity: 1 !important;
      }
    }
  }

  .levelUpIcon, .levelDownIcon, .levelUpWithCompletedIcon, .levelDownWithCompletedIcon, .currentIcon {
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .levelUpIcon, .levelDownIcon {
    fill: map-get($primary-colors, primary);
  }

  .levelUpWithCompletedIcon, .levelDownWithCompletedIcon, .currentIcon {
    fill: $color-white;
  }

  // Mobile dropdow button
  .levelButton {
    display: none;
  }

  .mobileTitle {
    display: none;
  }
}

.error {
  border-radius: 4px;
  background: map-get($validation-colors, error);
  color: $color-white;
  margin: 1em;
  padding: 7px 15px;
}
