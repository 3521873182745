@import "../../../../styles/constants/colors";

.imagePlaceholder {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: $color-botton;
  opacity: 0.2;

  svg {
    width: 75%;
  }
}
