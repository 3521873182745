@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.Container {
  max-width: 78rem;
  padding: $gap-5 $gap-6;
  margin: 0 auto;

  .Paragraph {
    margin: $gap-5 0;
  }

  .Card {
    margin-bottom: $gap-5;
  }

  .FormContainer {
    display: flex;
    flex-direction: column;
    margin-top: $gap-3;
    gap: $gap-3;
  }
}
