@use "sass:map";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.informationBoxContainer {
  display: flex;
  max-width: 577px;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  background: #4770a733;
  letter-spacing: -0.02em;

  @media #{$phoneMedia} {
    padding: 5px;
  }

  .header {
    display: flex;
    width: 100%;
    min-height: 24px;
    align-items: center;
    margin-bottom: 8px;

    svg {
      display: inline-block;
      width: 16px;
      height: 16px;
    }

    p {
      display: inline-block;
      margin-left: 7px;
      line-height: 150%;

      a {
        font-weight: 700;
      }
    }
  }

  &.titleBold .header p {
    font-weight: 700;
  }

  .body {
    width: 100%;

    @media #{$xsMedia} {
      > a,
      p {
        font-size: 13px;
      }
    }

    @media #{$phoneMedia} {
      > a,
      p {
        margin: 0 16px;
        font-size: 11px;
      }
    }

    p {
      margin: 0 23px;
      font-weight: 400;
      line-height: 150%;
    }

    p:nth-of-type(2) {
      margin-top: 8px;
    }

    a {
      border-bottom: 1px solid $contrast-light-shadow;
      color: black;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        border-bottom: 2px solid map.get($hubColors, hub_link);
        color: map.get($hubColors, hub_link);
      }
    }
  }
}
