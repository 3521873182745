@import '../../styles/constants/colors.scss';

.row {
  border-bottom: 1px solid $color-panel-background-light;
}

.inactive {
  opacity: 0.5;
}

.licenceIconWrapper {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
}

.rowInfo {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: $color-mid-grey-2;
  border-radius: 5px;

  &::before,
  &::after {
    position: absolute;
    right: 11.25%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    content: '';
    pointer-events: none;
  }

  &::before {
    top: -21px;
    border-bottom-color: $color-mid-grey-2;
  }

  &::after {
    top: -20px;
    border-bottom-color: $color-mid-grey-2;
  }
}

.rowInfoIcon {
  width: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.borderBottom {
  border-bottom: 1px solid $color-border;

  > h3 {
    margin-bottom: 0;
  }
}

.assignLicenseLable {
  display: block;
  color: $color-primary-blue;

  &:hover {
    color: $color-primary-blue-dark;
  }
}
