@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

.hubPopoutPanel,
.popoutPanel {
  &::before {
    position: fixed;
    z-index: 103;
    top: 0;
    right: 100%;
    display: block;
    width: 100vw;
    height: 100%;
    background: $color-black3;
    content: "";
    opacity: 0;

    // Default to hidden, will fade in after component mount
    transition: opacity 0.3s ease-in-out;
  }

  .panel {
    position: fixed;
    z-index: 104;
    top: 0;
    right: 0;
    width: 90vw;
    height: 100%;
    background-color: $color-white;
    overflow-y: auto;
    transform: translateX(92vw);

    // The 2vw extra here makes sure that the box shadow move off screen too
    // @ min screen size (320px), 2vw = 6.4px which is greater than the 5px wide box shadow
    // Ensure any default child elements are full height
    > :not([class]),
    > :not([class]) > form {
      height: 100%;
    }

    &.overwriteRight {
      width: 100vw;
      transform: translateX(-100vw);
    }

    &.overwriteLeft {
      position: relative;
      width: 400px;
    }

    &.cpMenuModal {
      overflow: scroll;
      width: 100vw;
      height: 100%;
      transform: translateX(-100vw);
    }

    &.cpDictionaryModal {
      position: absolute;
      top: calc(50% - 258px);
      left: calc(50% - 20vw);
      overflow: scroll;
      height: 516px;
      color: #000;
    }

    &.productDownloadModal {
      position: fixed;
      top: calc(50% - 50vh);
      left: calc(50% - 425px);
      width: 100%;
      max-width: 850px;
      height: 100%;
      max-height: 100vh;
      border-radius: 24px;

      @media screen and (width <= 850px) {
        left: 0;
        border-radius: 0;
      }

      iframe {
        width: 100%;
        height: 61vh;
        border: 0;
      }
    }
  }

  .panelTransition {
    box-shadow: 0 0 5px $color-grey-border;

    // Default to hidden, will slide in after component mount
    transition: transform 0.3s ease-in-out;

    &.overwrite {
      animation: fadeIn 0.5s;
      transition: none;
    }
  }

  .sidePanelInitialConsent {
    z-index: 999;
    overflow: visible;
  }

  &.contentMounted {
    &::before {
      right: 0;
    }
  }

  &.isOpen {
    &::before {
      opacity: 1;
    }

    .panel {
      transform: translateX(0);
    }

    .fullWidth {
      width: 100%;
    }
  }
}

.hubPopoutPanel {
  &::before {
    z-index: 104;
  }
}

@media #{$smMedia} {
  .hubPopoutPanel,
  .popoutPanel {
    .panel {
      width: 60vw;
      transform: translateX(61vw);

      // The 1vw extra here makes sure that the box shadow move off screen too
    }

    .large {
      width: 90vw;
      transform: translateX(92vw);
    }

    .fullWidth {
      width: 100vw;
      transform: translateX(100vw);
    }
  }
}

// NOTE: MD is the same width as SM (60%)

@media #{$lgMedia} {
  .hubPopoutPanel,
  .popoutPanel {
    .panel {
      width: 40vw;
      transform: translateX(41vw);

      // The 1vw extra here makes sure that the box shadow move off screen too
    }

    .large {
      width: 90vw;
      transform: translateX(92vw);
    }

    .fullWidth {
      width: 100vw;
      transform: translateX(100vw);
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
