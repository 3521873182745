@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  position: absolute;
  z-index: 99999999;
  top: -10px;
  left: 50%;
  display: block;
  width: 250px;
  height: 50px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid $color-grey-progress;
  background-color: $color-grey-progress;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 8px $color-grey-progress;
  box-shadow: 0 1px 8px $color-grey-progress;
  color: $color-white;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  white-space: normal;
  word-break: break-word;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .tooltiptext i {
  position: absolute;
  top: 100%;
  overflow: hidden;
  width: 24px;
  height: 12px;
  margin-left: -12px;
}

.tooltip .tooltiptext i::after {
  position: absolute;
  left: 50%;
  width: 12px;
  height: 12px;
  border: 1px solid $color-grey-progress;
  background-color: $color-grey-progress;
  box-shadow: 0 1px 8px $color-grey-progress;
  content: "";
  transform: translate(-50%, -50%) rotate(45deg);
}

.tooltiptextNormal {
  @extend .tooltiptext;

  i {
    left: 50%;
  }
}

.tooltiptextLastRecord {
  @extend .tooltiptext;

  margin-left: -97px;

  i {
    left: 89%;
  }
}

@media #{$phoneMedia} {
  .tooltip .tooltiptext {
    margin-left: -70px;
  }

  .tooltip .tooltiptext i {
    left: 80%;
  }
}

.roundIcon {
  display: inline-block;
}
