@import '../../styles/constants/colors.scss';

.smallNumberInput {
  display: flex;
  align-items: center;
  align-items: flex-start;

  label {
    flex-grow: 1;
    font-weight: 400;
  }

  .fieldWrapper {
    // Flex shrink 0 to force the label to wrap
    flex-shrink: 0;

    // Arrange buttons and textbox side by side:
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    // Keep some spacing between the label and the input
    margin-left: 1rem;
    margin-top: 0.8em; // Same as global label margin-top

    &.labelHidden {
      // Keep some spacing between the label and the input
      margin-left: 0;
    }

    & > div {
      width: auto;
    }
  }

  input {
    width: 48px;
    height: 36px;
    text-align: center;

    box-shadow: none;

    padding: 0;
    border: 1px solid $color-borders;
    border-width: 1px 0;
    border-radius: 0;

    // Remove native spinner buttons:
    -moz-appearance: textfield;
    appearance: none;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  button {
    width: 36px; // Same height as TextInputField
    height: 36px;
    padding: 8px;
    border: 1px solid $color-borders;

    fill: $color-primary-blue;

    // Rounded corners on far left and right:
    border-radius: 4px 0 0 4px;
    ~ button {
      border-radius: 0 4px 4px 0;
    }
  }

  // So that any focus outline is not clipped by adjacent elements:
  input, button {
    &:focus {
      z-index: 99;
    }
  }
}
