@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.mainContentContainer {
  display: flex;
  height: 100%;
  flex-direction: column;

  .panelTitle {
    padding: 1rem 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .classroomContainer {
    padding: 1rem;
    border-bottom: 1px solid $color-light-grey;

    .classroomInfo {
      display: flex;
    }

    p {
      color: map-get($typography-colors, text);
    }

    .buttonContainer {
      margin-left: auto;

      button:focus {
        @include standart-focus-button();
      }
    }
  }

  .chooseClassEmptyState {
    padding: 1rem;
  }

  nav {
    margin-top: auto;
  }
}
