@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.customContainer {
  height: 100%;
}

.assessmentContainer {
  display: flex;
  margin-bottom: 1rem;

  .assessment {
    display: flex;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0.5rem;
    border: 1px solid $color-light-grey;
    margin-bottom: 1px;
    border-radius: 5px;
    box-shadow: 0 0 0 1px $color-black-rgba;
    cursor: pointer;

    &.expired {
      border: solid 1px map-get($hubColors, hub_expired_licence);
      background-color: $color-very-light-grey;

      img {
        filter: brightness(70%);
      }
    }

    .imgContainer {
      position: relative;
      display: inherit;
      overflow: hidden;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0 0 5px 0 $contrast-light-accent-50;

      .imgOverlay {
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        background: $contrast-light-accent-50;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
      }

      .lockIconContainer {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          z-index: 3;
          height: 3rem;
          fill: $color-white;
        }
      }
    }

    .leftContainer {
      position: relative;
      display: flex;
      width: 70%;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .textContainer {
      z-index: 1;
      display: flex;
      width: calc(100% - 70px);
      box-sizing: border-box;
      align-items: center;
      padding: 0.5rem 2rem 0.5rem 0.75rem;
    }

    .emptyContainer {
      position: relative;
      display: flex;
      width: 40px;
      margin-right: 1%;
      margin-left: 9%;
    }

    .statusLicenceContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 0.75rem;

      .licenceContextContainer,
      .licenceContextContainerOpacified {
        display: flex;
        align-items: center;
      }

      .licenceContextContainerOpacified {
        svg {
          opacity: 0.5;
        }
      }

      .textContainerLicenceContext span {
        display: block;
        font-size: 1rem;
        font-weight: 500;
      }

      .textContainerLicenceContext span:first-child {
        color: map-get($typography-colors, sub-text);
        font-size: 0.85rem;
      }

      .svgContainer {
        svg {
          width: 1.6rem;
          height: 24px;
          margin-right: 0.75rem;
        }
      }

      @media #{$phoneMedia} {
        width: initial;
      }
    }

    &:hover {
      border: solid 1px map-get($hubColors, blue-color-primary);
      box-shadow: 0 0 0 1px map-get($hubColors, blue-color-primary);

      .img {
        filter: brightness(70%);
      }

      svg {
        fill: map-get($hubColors, blue-color-primary);
      }
    }

    &.expired:hover {
      border-color: map-get($hubColors, hub_expired_licence);
      box-shadow: 0 0 0 1px map-get($hubColors, hub_expired_licence);
    }

    &:focus {
      @include tile-on-focus();

      .imgContainer {
        filter: brightness(70%);
      }
    }
  }

  @media #{$phoneMedia} {
    .assessment {
      max-width: none;
      padding: 0.25rem;

      &:hover {
        border-color: $color-light-grey;
        box-shadow: none;
      }

      &:hover img {
        filter: brightness(100%);
      }

      .imgContainer {
        img {
          width: 100%;
          height: 100%;
          padding: unset;
          border-radius: 5px;
          object-fit: fill;
          object-position: 50% 0;
        }
      }

      .statusLicenceContainer {
        max-width: 1.6rem;
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
      }

      .emptyContainer {
        margin: 0;
      }
    }
  }
}
