.LevelJumpToggle {
  margin-bottom: .5rem;

  &__trigger {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    display: block;

    svg {
      width: 13px;
      margin-left: 7px;
      vertical-align: middle;
    }
  }

  &__levels {
    display: flex;
    flex-direction: column;
    margin-bottom: .8rem;
  }

  &__level {
    margin-top: .8rem;
    text-align: center;
  }
}
