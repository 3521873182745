@import '../../styles/constants/breakpoints.scss';

.glyph {
  margin-right: 10px;
  vertical-align: middle;
  width: 28px;
}

.icon {
  display: inline-block;
  margin: 7px 20px 10px 0;
}

.actionsContainer {
  display: flex ;
  justify-content: space-between;
}

@media #{$mdMedia} {

  .summary {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    text-align: right;
  }

}
