@import '../../styles/constants/colors.scss';

.center {
  text-align: center;
}

.infoIcon {
  width: 28px;
  height: 28px;
}

.emailBox {
  background: $color-mid-grey-2;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin: 10px 0;
}
