@import "../../styles/mixins/hub/hub-button-focus";
@import "../../styles/mixins/hub/help-card";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.register h2 p {
  font-size: 21px;
}

.helpCard p + a {
  font-size: 25px;
}

.helpCard a {
  @include help-card();

  padding-right: 0;
  padding-left: 0.5rem;

  &:focus {
    @include no-padding-button-on-focus();
  }
}

.helpCard {
  p {
    a::after {
      width: 20px;
      padding-left: 0.5rem;
    }
  }
}

.displayInline {
  display: flex;
  margin-top: $gap-4;

  button:nth-of-type(2) {
    margin-left: $gap-4;
  }
}

.buttonCenter {
  display: flex;
  justify-content: center;
}

.usernameHelperContainer span {
  font-weight: $font-weight-700;
}
