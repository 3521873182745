.contentWithSVGIcon {
  // Align the Badge next to the middle of the children
  display: flex;
  align-items: center;

  .children {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .icon svg {
    height: 28px;
    width: 28px;
  }
}
