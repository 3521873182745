.openedContent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.downloadableContent {
  width: 0;
  height: 0;
}
