@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.lozenge {
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  margin-right: 4px;
  border-radius: 15px;

  // Remove any whitespace
  font-size: 0;

  // Make both sit on the same line
  * {
    display: inline-block;
    vertical-align: top;
  }

  // LHS part
  span {
    padding: 0 15px;
    font-size: 1rem;
    line-height: 26px;

    &.hasButton {
      padding-right: 0;
    }
  }

  // RHS Button stylings
  button {
    width: 26px;
    height: 26px;
    padding: 8px;
    border-radius: 0 15px 15px 0;

    &:hover svg {
      fill: map-get($legacy-color-names, tooltip);
    }

    &:focus {
      @include standart-focus-button();

      outline: none;
    }
  }
}
