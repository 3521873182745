.icon {
  width: 1em;
  height: 1em;
  font-size: 26px;

  @media (max-width: 812px) and (min-width: 375px) {
    width: 1.1em;
    height: 1.1em;
    font-size: 23px;
  }
}
