@use "sass:map";
@import "../../../../../../styles/constants/colors";
@import "../../../../../../styles/constants/breakpoints";
@import "../../../../../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/box-shadow";

.enrolUserInputContainer {
  position: relative;

  svg {
    fill: $color-grey;
  }
}

.checkbox {
  width: 100%;
}

.enrolUserScrollContainerContent {
  padding: 16px 0;
}

.enrollUserFormContainer {
  padding: 10px;
  border: 1px dashed map.get($primary-colors, primary);
  border-radius: 15px;
}

.enrollUserAddClassButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  button {
    &:first-child {
      padding: 0;
    }
  }
}

.enrolUsersClassList {
  border-bottom: 1px solid $color-mid-grey-2;

  &:hover,
  &.selected {
    background-color: map.get($background-and-border-colors, selected);
  }

  label {
    align-items: center;

    > div {
      margin-left: 16px;
    }
  }

  &.disabledClass {
    opacity: 0.6;
  }

  .editBtn {
    margin-top: -15px;
    margin-left: auto;
    color: map.get($primary-colors, primary);

    &:hover {
      color: map.get($primary-colors, primary-dark);
    }
  }
}

.enrollUserCreateClass {
  padding: 0 16px 10px;

  .dashedButton {
    border: 1px dashed map.get($primary-colors, primary);
    color: map.get($primary-colors, primary);

    &:hover,
    &:focus {
      background-color: map.get($hubColors, onboarding_wizard_valid_input_background_color);
    }

    &:focus {
      @include standart-focus-button;
    }

    svg {
      width: 0.7rem;
      height: 0.7rem;
      fill: map.get($primary-colors, primary);
    }
  }
}

.selectedFooter {
  padding: 1rem 0;
  border-bottom: 1px solid map.get($background-and-border-colors, border);
  margin: 0 1rem;

  span + span {
    padding-left: 8px;
    vertical-align: middle;
  }
}

.errorEmptyLineImage {
  img {
    width: 100%;
    box-sizing: border-box;
    padding: 2rem;

    @media #{$xsMedia} {
      padding: 1rem;
    }
  }
}

.errorListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 20px;

  table, th, td {
    border: $border-width-1 solid map.get($primary, primary30);
    border-collapse: collapse;
  }

  td, th {
    padding: $gap-2;
    text-align: left;
  }

  td:nth-child(2) {
    color: map.get($validation, validation-red100);
    font-weight: $font-weight-700;
  }

  td:nth-child(1) {
    color: map.get($base, black);
  }
}

.missingHeadersTableImage {
  max-width: 100%;
  height: auto;
}

.generateUsernameSection {
  display: flex;
  flex-direction: column;
  gap: $gap-2;

  :first-child {
    flex: 1;
  }

  .usernameOrEmail {
    margin-bottom: 0.5rem;
  }
}

.usernameInfo {
  margin: 1rem 0;
}

.usernameTooltip {
  span {
    width: 90%;
  }
}

.usernameSupportLink {
  font-weight: bold;

  a {
    display: inline-block;
    color: map.get($primary, primary100);
    cursor: pointer;
    font-weight: $font-weight-700;
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.25em;

    &:hover {
      background-color: map.get($primary, primary10);
      mix-blend-mode: multiply;
      text-decoration-thickness: 2px;
    }

    &:hover:focus,
    &:focus {
      box-shadow: $box-shadow-focus;
    }

    svg {
      width: $icon-size-small;
      height: $icon-size-small;
      vertical-align: middle;
    }
  }
}

.permissions {
  padding-top: 1rem;
  border-top: 1px solid map.get($background-and-border-colors, border);
  margin-bottom: $gap-5;
}

.linkContainer {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.scrollContainerBulkConfirmation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: $gap-4;
  color: $color-sub-text;
  text-align: left;

  p {
    align-self: flex-start;
    margin: $gap-4;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin: $gap-4;
  gap: $gap-5;
}

.formContainer {
  height: 100%;
}


.largeTooltip {
  span[role="tooltip"] {
    left: 28%;
    width: 16.5rem;

    @media #{$xsMedia} {
      left: 71%;
      width: 16.5rem;
    }

    @media screen and (max-width: $screen-width-400) {
      left: 64%;
      width: 15rem;
    }

    &::after {
      left: 92%;

      @media #{$xsMedia} {
        left: 8%;
      }
    }
  }
}
