@import "../../styles/constants/colors";

.isbn {
  font-weight: normal;
}

.testCredits {
  svg {
    margin-top: auto !important;
    margin-bottom: 5px !important;
  }
}
