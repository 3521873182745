@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "../../styles/constants/colors";

.lozengeContainer {
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 5px 0;
  background-color: $color-button-background3;
  border-radius: 12px;

  .columnItem {
    padding: 10px;
  }

  .firstColumn {
    max-width: 45rem;
    word-wrap: break-word;
  }

  .secondColumn {
    min-width: 5rem;
    padding-left: 0;
  }

  .rightColumn {
    display: flex;
    margin-left: auto;
  }

  .errorIconContainer {
    width: $icon-size-regular;
    height: $icon-size-regular;
    margin-left: 10px;
  }
}

.errorContainer {
  background-color: map-get($secondary-colors, light_pink);
}
