@import "../../styles/constants/colors";

.subfolderPanelContainer {
  padding: 1rem;

  .backButton {
    button {
      :not([disabled]):hover {
        text-decoration: none !important;
      }
    }

    button:focus {
      border-radius: 3px;
      box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($primary-colors, primary), 0 0 0 4px $color-white;
    }
  }

  .panelTitle {
    padding: 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
  }
}
