@import "../../styles/constants/colors";
@import './variables.scss';

//
// ORB Typography
// --------------------------------------------------
h2 {
    &.orb-coaching-ebooks {
        span {
            color: $ces-sub-text;
            font-size: $font-size-base;
            font-weight: 200;
        }
    }
}

p {
    &.expected-attainment {
        font-weight: 200;
        font-size: $font-size-base;
        color: $ces-sub-text;
        text-align: center;
        margin: 7px 0 15px 0;
    }
    &.level-completed {
        font-weight: 200;
        font-size: $font-size-base;
        color: $color-white;
        background-color: $orb-blue;
        padding: 5px 10px;
        border-radius: $ces-border-radius;
        display: inline-block;
        position: relative;
        text-align: center;
        left: 50%;
        transform: translate(-50%);
        margin: 7px 0 15px 0;
    }
}

.orb-data {
    list-style: none;
    padding: 0px;
    margin: 0px;
    text-align: center;
    li {
        border-bottom: 1px solid $ces-border;
        padding: 15px 0;
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type {
            border-bottom-width: 0px;
            padding-bottom: 0;
        }
    }
    label, h1, h2, p, a {
        margin: 0px;
    }
    label {
        font-size: 14px;
        margin-bottom: 7px;
    }
    p {
        color: $ces-sub-text;
        margin-top: 7px;
    }
    a {
        display: block;
        margin-top: 15px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 15px;
    }
}


.section-heading {
    margin-top: 22px;
    h1, button, p {
        display: inline-block;
        margin: 0px;
        margin-right: $padding-base-horizontal;
    }
    h1, button {
        float: left;
        margin-top: -5px;
    }
    button, p, .toggle-switch {
        margin-top: 0px;
        margin-bottom: $padding-base-horizontal;
    }
    @media (max-width: $grid-float-breakpoint-max) {
        h1, button, p {
            display: block;
            text-align: center;
            margin-right: 0px;
            float: none;
        }
        button {
            margin: 0 auto;
        }
        button, p {
            margin-top: $padding-base-vertical;
            margin-bottom: $padding-base-horizontal;
        }
    }
}

.orb-book-title {
    .lead {
        margin-top: 5px;
        font-size: $font-size-base;
        margin-bottom: 0px;
    }
    button {
        float: right;
    }
    @media (max-width: $grid-float-breakpoint-max) {
        text-align: center;
        border-bottom: 1px solid $ces-border;
        margin-bottom: $padding-base-horizontal;
        padding: $padding-base-horizontal 0;
        button {
            float: none;
            margin-top: $padding-base-horizontal;
        }
    }
}
.orb-book-description {
    margin-top: $padding-base-horizontal;
    @media (max-width: $grid-float-breakpoint-max) {
        text-align: center;
    }
}
