@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";

.navigatorFormat {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cardFormat {
  height: 100%;
  width: 93vw;
  max-width: auto;
  border: solid 1px map-get($background-and-border-colors, border);
  border-radius: 5px;
  margin: 15px;
  padding: 15px;
  text-align: center;

  @media #{$smMedia} {
    width: 82vw;
    max-width: 1110px;
  }
}
.panel:nth-child(2) {
  border-left: solid 1px lightgrey;
  border-right: solid 1px lightgrey;
}
.subtitleGrey {
  color: map-get($hubColors, dark-gray);
  text-align: left;
  margin: 7px 0 15px 10px;
  line-height: 30px;
  align-self: center;
  margin: 0;
}
.progressBar {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progressBar > p {
  margin-left: 20px;

  @media #{$smMedia} {
    flex-direction: row;
  }
}
