@import "../../styles/constants/colors";

.main {
  display: flex;
  align-items: center;
  color: map-get($hubColors, dark-gray);
  font-size: 16px;
  line-height: 20px;
}

.avatar {
  position: relative;
  display: flex;
  width: 3.125em;
  height: 3.125em;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background-color: map-get($secondary-colors, learner);
  border-radius: 50%;
  color: white;
  font-size: 1em;
}

.cesAvatar {
  flex-shrink: 0;
  background-color: map-get($class-report-colors, very-behind);

  &:hover {
    background-color: map-get($heritage, ruskin_red);
  }
}

.avatarProcessing {
  background-color: map-get($legacy-color-names, background-dark) !important;
}

.avatarArchived {
  background-color: map-get($legacy-color-names, background-dark) !important;
}

.avatarDeleted {
  background-color: $color-mid-grey-2 !important;
}

.textRed {
  color: map-get($legacy-color-names, weak);
}

.staffViewAlign {
  @media screen and (max-width: 480px) {
    width: 255px;
    word-break: break-word;
  }
}

.icon {
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 25px;
  padding: 3px;
  border: 2px solid white;
  border-radius: 15px;
}

.lockedIcon {
  position: absolute;
  left: -11px;
  width: 25px;
  height: 25px;
  background-color: map-get($legacy-color-names, weak);
}

.name {
  display: flex;
  align-items: center;
  color: map-get($legacy-color-names, tooltip);
}

.nameLink {
  color: map-get($hubColors, blue-color-dark-moderate);
  text-align: left;
}

.badge {
  display: inline-block;
  padding: 3px 5px;
  border: 1px solid currentColor;
  margin-top: 2px;
  border-radius: 4px;
  font-size: 0.8rem;
  line-height: 1;
}

.lockedBadge {
  margin-top: 0;
  margin-left: 10px;
  font-size: 13px;
}

.deletedPlaceholder {
  color: map-get($hubColors, hub_people_border);
}

.identifier {
  display: flex;
  align-items: center;
  font-size: 0.8em;
}

.statusText {
  display: flex;
  align-items: center;
  padding-top: 2px;
  font-size: 0.8em;
}

.statusIndication {
  width: 17px;
  height: 15px;
  flex-shrink: 0;
  margin-right: 4px;
}

.licenceMessage {
  display: flex;
  flex-direction: row;
}

.textWarning {
  font-size: 0.8em;

  &.textNoLicence {
    color: $color-weak;
  }

  &.textHasLicence {
    color: map-get($hubColors, hub_success_text_green);
  }

  &.textLicenceCloseToExpiry {
    color: map-get($hubColors, hub_expiring_licence);
  }
}

.svgSpace {
  margin-top: 0.1rem;
}
