@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

:global(.row) {
  :global(.col) {
    @media print {
      display: block;
    }
  }
}

.itemContainer {
  border: 1px solid map-get($background-and-border-colors, border);
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;

  @media print {
    page-break-inside: avoid;
    border-color: $color-black;

    * {
      color: $color-black !important;
    }
  }

  &:before {
    margin-top: -16px;
  }

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 15px;
    left: 50%;
    transform: translate(-50%);
    background-color: map-get($background-and-border-colors, border);
    z-index: -1;
  }

  .header, .content, .footer {
    padding: 15px 0;
    margin: auto;
  }
}

.cover {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .bookCover {
    border: 1px solid map-get($background-and-border-colors, border);
    border-radius: 5px;
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-width: 150px;

    @media #{$xsMedia} {
      width: 200px;
    }
  }

  .noBorder {
    border: none !important;
  }

  .coachingBook {
    width: 40px;
    height: 40px;
    position: absolute;
    margin-left: -20px;
    bottom: 40px;
  }
}

.info {
  @media #{$xsMedia} {
    padding: 15px 0;
    text-align: center;
    border-bottom: 1px solid map-get($background-and-border-colors, border);
    margin-bottom: 15px;
  }

  &.levelChange {
    border-bottom: none;
    margin-bottom: 0 !important;
  }

  .lozenge {
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 600;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 7px 11px 0;
    border-color: $color-orb-blue;
    color: $color-orb-blue;

    @media print {
      border-color: $color-black;
      border-width: 1px;

      &, * {
        color: $color-black !important;
      }
    }
  }

  h2 {
    margin: 0;
    font-size: 1.3rem;
  }

  .lead {
    font-size: 1rem;
    color: map-get($typography-colors, sub-text);
  }
}

.widerReadingItem {
  h2, .lead {
    word-wrap: break-word;
    width: 250px;

    @media (max-width: 1000px) {
      width: 180px;
    }

    @media (max-width: #{$smBreakpoint}) {
      width: 400px;
      margin: 0 auto;
    }

    @media (max-width: 460px) {
      width: 250px;
    }
  }
}

.results {
  td {
    padding: 5px 15px;
    vertical-align: top;

    &.noWrap {
      white-space: nowrap;
    }

    &.fullWidth {
      width: 100%;
    }

    &.noPadding {
      padding: 0;
    }

    &.actionsCell {
      button {
        margin-right: 5px;
      }
    }

    .note {
      width: 350px;

      @media (max-width: #{$mdBreakpoint}) {
        width: 250px;
      }

      @media #{$xsMedia} {
        width: 400px;
      }

      @media (max-width: 600px) {
        width: 300px;
      }

      @media (max-width: 500px) {
        width: 200px;
      }

      @media (max-width: 320px) {
        width: 130px;
      }
    }
  }

  .wordBreak {
    word-wrap: break-word;
  }
}

.ratingIcon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
