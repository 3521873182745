@import '../../styles/constants/breakpoints';

.mainContent {
  padding: 1rem;

  .sectionContainer {
    font-weight: 600;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  .preferencesRaw {
    display: flex;

    span {
      width: 75%;
      margin-right: 1rem;
    }

    .toggleContainer {
      width: 25%;

      label {
        margin: 0 0 auto;
      }
    }
  }

  @media #{$phoneMedia} {
    .preferencesRaw {
      span {
        width: 60%;
      }

      .toggleContainer {
        width: 40%;
      }
    }
  }
}
