@import "../../styles/constants/colors";

.heading {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.backButton {
  min-height: max-content;
  padding: 4px 8px !important;
}

.codeDetails {
  display: block;

  summary:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.codeWrapper {
  position: relative;
  width: 100%;
}

.copyCodeButton {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 0;
  margin-right: 20px;
}

.code {
  z-index: 0;
  display: block;
  overflow: auto;
  max-height: 30vh;
  padding: 10px;
  background-color: $color-very-light-grey;
  border-radius: 4px;
}

.link {
  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  &:hover {
    text-decoration: underline !important;
  }
}

.muted {
  color: $color-text-grey;
}
