@import '../../styles/constants/colors.scss';

.ellipsis {
  color: map-get($styleGuideColors, correct);
  font-size: 1.7rem;
  margin-left: 1px;
  line-height: 0.5;
}

.intervalBar {
  width: 100%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
}

.singleBar {
  border-radius: 12.5px;
  width: 10%;
  margin: 1px;
  height: 96%;
  background-color: map-get($styleGuideColors, correct);
}

.intervalFull:first-child {
  border-top-left-radius: 12.5px;
  border-bottom-left-radius: 12.5px;
}

.intervalFull:last-child,
.intervalSegment:last-child {
  border-top-right-radius: 12.5px;
  border-bottom-right-radius: 12.5px;
}

.intervalFull {
  width: 10%;
  margin: 1px;
  height: 96%;
  background-color: map-get($styleGuideColors, correct);
}

.intervalSegment {
  width: 1%;
  margin: 1px;
  height: 96%;
  background-color: map-get($styleGuideColors, correct);
}
