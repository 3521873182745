@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors.scss";

.startTips {
  overflow: hidden;
  min-height: 100vh;
  background-color: $color-star-tips;

  .item {
    position: relative;
    display: block;
    margin-bottom: 75px;

    .page {
      padding: 0;
      margin-bottom: 20px;
      line-height: 40px;

      p {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        margin-block-end: 1em;
        margin-block-start: 1em;
        margin-inline-end: 0;
        margin-inline-start: 0;
      }

      .title {
        width: 50%;
        padding: 0;
        line-height: 30px;

        @media #{$xsMedia} {
          width: 100%;
        }
      }

      h3,
      h4 {
        font-size: 1rem;
        font-weight: 600;
      }

      .description {
        width: 50%;
        padding: 0;
        margin-top: 20px;

        @media #{$xsMedia} {
          width: 100%;
        }
      }
    }

    img {
      border: 0;
      vertical-align: middle;

      @media #{$xsMedia} {
        width: 75%;
        margin-top: 20px;
      }

      @media #{$smMedia} {
        width: 55%;
        margin-top: 20px;
      }

      @media #{$lgMedia} {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
      }
    }
  }

  .stepsAction {
    position: relative;
    height: 75px;
    line-height: 75px;

    @media #{$xsMedia},
      #{$smMedia} {
      left: auto;
      max-width: 92%;
      margin: 0 auto;
    }

    @media #{$lgMedia} {
      right: 550px;
      max-width: 88%;
      margin: 0 auto;
    }
  }
}

.termsAndConditions {
  .header {
    height: 360px;
    padding-top: 15px;
    background-color: $color-terms;
    background-position: 100% 100%;
  }

  .termsAction {
    right: 0;
    bottom: 0;
    left: 0;
    height: 75px;
    line-height: 75px;

    button {
      margin-top: 0.8em;
    }
  }
}
