@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.testFolderContainer {
  .assessmentTestFolder {
    // background-color: map-get($legacy-color-names, weak);
    position: relative;
    max-width: none;
    box-sizing: border-box;

    &::before {
      position: absolute;
      z-index: -1;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      content: "";
    }

    .folderContainer {
      display: flex;
      min-height: 64px;
      flex: 1;
      align-items: center;
      border-top: 1px dashed $color-light-grey;
      color: map-get($legacy-color-names, tooltip);
      cursor: pointer;

      &:hover {
        background-color: map-get($secondary-colors, hub_light_grey);

        .thumbnailContainer {
          fill: map-get($hubColors, blue-color-dark);
        }
      }
    }
  }

  .svgtestfolder {
    display: flex;
    width: 1.425rem;
    height: 1.425rem;
    margin-right: 10px;
    fill: map-get($legacy-color-names, weak);
  }

  .thumbnailContainer {
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    margin: 0 15px 0 10px;
    fill: map-get($hubColors, blue-color-primary);
    transition: 0.3s ease-out transform;
  }

  .collapsedThumbnail {
    transform: rotate(-180deg);
    transition-duration: 0.3s;
  }

  .testFolderTitle {
    font-weight: 600;
  }

  .lockedtest {
    font-weight: 400;
  }
}

.adaptedTest {
  display: flex;
  width: 100%;
  flex: 1;
  font-weight: 400;

  .adaptedTestArrow {
    min-width: 2rem;
    max-width: 2rem;
    min-height: 4rem;
    max-height: 4rem;
    fill: map-get($hubColors, blue-color-primary);
  }
}

.assessmentTestItem {
  border: 2px solid red;
}

.assessmentTest {
  position: relative;
  width: 100%;
  max-width: none;
  min-height: 64px;
  box-sizing: border-box;
  margin-top: 0.75rem;
  border-radius: 0.3125rem;

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid $color-light-grey;
    border-radius: 0.3125rem;
    content: "";
  }

  .svgIconContainer {
    display: flex;
    width: 1.875rem;
    height: 1.875rem;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    margin-right: 0.75rem;
    background-color: $color-grey;
    border-radius: 50%;

    svg {
      min-width: 1rem;
      max-width: 1rem;
      min-height: 1rem;
      max-height: 1rem;
      fill: $color-white;
    }
  }

  &:hover {
    &::before {
      border: 2px solid map-get($secondary-colors, hub_blue);
    }

    .svgIconContainer {
      background-color: map-get($secondary-colors, hub_blue);
    }
  }

  &.unavailableTest {
    padding: 0.06rem;
    border: 2px solid map-get($validation-colors, error);
    background-color: $color-button-background3;
    border-radius: 5px;

    &:hover {
      padding: 0;
      border: 3px solid map-get($validation-colors, error);

      .svgIconContainer {
        background-color: map-get($validation-colors, error);
      }
    }

    .svgIconContainer {
      padding: 0;
      background-color: map-get($hubColors, dark-gray);
    }
  }

  .testItemContainer {
    display: flex;
    padding: 0 1rem 0 0.5rem;
    margin-top: 0;

    &:focus {
      @include test-available-on-focus();

      .svgIconContainer {
        background-color: map-get($secondary-colors, hub_blue);
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    .buttonContainer {
      padding: 7px 0 7px 13px;

      button {
        min-height: 26px;
        padding: 2px;

        svg {
          width: 1.1rem;
          height: 1.1rem;
        }

        &:focus {
          border-radius: 3px;
          box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($secondary-colors, hub_blue), 0 0 0 4px $color-white;

          svg {
            fill: map-get($secondary-colors, hub_blue);
          }
        }

        &:hover {
          background-color: map-get($secondary-colors, hub_light_grey);

          .glyphContainer {
            background-color: map-get($secondary-colors, hub_blue);
          }
        }
      }
    }

    .selectTestButtonContainer {
      margin-left: auto;

      button:focus {
        @include standart-focus-button();
      }
    }
  }

  .testUnavailableItemContainer {
    display: flex;
    min-height: 64px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 0.9rem 0 0.4rem;

    &:focus {
      @include test-unavailable-on-focus();

      .svgIconContainer {
        background-color: map-get($validation-colors, error);
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    .buttonContainer {
      padding: 7px 0 7px 13px;

      button {
        min-height: 26px;
        padding: 2px;

        &:focus {
          border-radius: 3px;
          box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($secondary-colors, hub_blue), 0 0 0 4px $color-white;

          svg {
            fill: map-get($secondary-colors, hub_blue);
          }
        }
      }
    }
  }

  .testDetailsContainer {
    min-height: 64px;
    flex: 1;

    .testDescriptionContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .testLabelTitle {
        font-size: 16px;
      }

      .testSubLabelTitle {
        font-size: 13px;
      }
    }
  }
}

.adaptedTestContainer {
  margin: 10px 0 10px 40px;
}

.testFolderPadding {
  padding-left: 15px;
  margin-top: 0.75rem;
}

.simpleEllipsis {
  button {
    box-shadow: none !important;
  }
}

.deleteAdaptedTestButton {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  padding: 0.25rem;
  border-radius: 50%;

  svg {
    height: 100%;
  }

  span {
    display: none;
  }
}
