.textInputWithButton {
  // The inner div containing the field and button should be in a row
  & > div {
    display: flex;
    flex-direction: row;
  }

  input, div {
    // Make the input box sit straight against the button
    border-radius: 4px 0 0 4px;
  }
}
