@import "../../styles/constants/colors";

.classCodeWrapper {
  .classCode {
    display: flex;
    margin: 17px 10px 5px 1px;
    background-color: white;
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px var(--accent-color);

    .classCodeInputWrap {
      display: flex;
    }

    .orgIdSection {
      width: 60px;
      height: 40px;
      background-color: map-get($hubColors, dark-gray);
      border-radius: 5px 0 0 5px;
      color: white;
      font-weight: bolder;
      line-height: 40px;
      text-align: center;
    }

    .copySection {
      width: 51px;
      height: 40px;
      border: 1px solid map-get($cptColors, grey);
      background-color: $color-white;
      border-radius: 0 5px 5px 0;
      color: $color-black;
      line-height: 40px;
      text-align: center;

      &:hover {
        border-width: 1px 1px 1px 1px;
        border-color: map-get($legacy-color-names, text-input-border);
        background-color: map-get($legacy-color-names, default);
      }
    }

    .classCodeInfo {
      overflow: hidden;
      width: auto;
      min-width: 200px;
      max-width: 350px;
      padding-left: 10px;
      border: 1px solid map-get($cptColors, grey);
      border-radius: 5px 0 0 5px;
      color: $color-black;
      line-height: 36px;
    }

    .largeTooltip {
      height: 30px;
      padding-right: 0;

      ul li {
        display: list-item;
        margin-left: 12px;
      }

      p {
        color: $color-text-grey;
        font-size: 14px;
      }

      span {
        right: -13px;
        left: auto;
        width: 268px;
        border: 2px solid skyblue;
        background-color: white;
        color: $color-text-grey;
        font-size: 14px;
        text-align: left;
        transform: none;

        &::after {
          right: 22px;
          left: auto;
          border-bottom-color: white;
          transform: none;
        }

        &::before {
          position: absolute;
          right: 21px;
          bottom: 100%;
          left: auto;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-width: 0 10px 10px 10px;
          border-style: solid;
          border-color: transparent transparent map-get($teacher-resource-colors, tr-primary) transparent;
          border-top: 0;
          content: "";
          transform: none;
        }
      }
    }

    .iconBar {
      display: flex;
      min-width: 40px;
      justify-content: center;
      padding: 0.5em 0;
      background-color: blue;

      svg {
        color: white;
      }
    }

    .infoIcon {
      display: flex;
      width: 1em;
      height: 1em;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-right: 10px;
      margin-left: 10px;
      font-size: 1.2em;
    }

    .mainContainer {
      display: flex;
      flex: 1;
      //padding: 10px;
      align-content: center;
      margin: auto;
      color: var(--text-neutral);
      font-weight: 500;

      input {
        border-radius: 0;
      }

      .title {
        margin: 0;

        &.marginBottom {
          margin-bottom: 24px;
        }
      }
    }
  }

  .helpText {
    margin-left: 0;
    color: map-get($hubColors, dark-gray);
  }
}
