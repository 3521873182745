@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.OteHomeBottomRow {
  padding: 2rem 0;
  margin-bottom: 50px;
  color: map-get($background-and-border-colors, background-dark-blue);
  font-family: $open-sans;
}

.OteHomePageBottomRowSection {
  .row {
    .listItem {
      position: relative;

      .logo {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 6rem;
        max-height: 6rem;
        margin: auto;
        opacity: 0.8;
      }

      &:hover .logo {
        opacity: 1;
      }
    }
  }

  .redSpan {
    background-clip: content-box;
    background-color: map-get($oteColors, ote_text_background);
    color: white;
  }

  .findOutMoreButton {
    a,
    button {
      display: block;
      width: 7rem;
      height: 3rem;
      border: 1px black;
      border-style: solid;
      border-radius: 4px;
      font-size: 0.8rem;
      font-weight: 800;

      &:hover {
        background-color: black;
        color: white;
      }
    }
  }

  .imgBorder {
    outline: 15px solid black;
    outline-offset: -15px;

    &:hover {
      outline: 20px solid black;
      outline-offset: -20px;
    }
  }

  @media #{$mdMedia} {
    &:nth-child(even) {
      .row {
        flex-direction: row-reverse;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  span {
    padding: 1.5rem 0 0.625rem;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: left;

    @media #{$mdMedia} {
      font-size: 1.563rem;
      line-height: 1.25;
    }
  }

  p {
    margin: 1rem 0;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: left;
  }

  a {
    padding: 0.625rem 0;

    &:hover {
      color: map-get($primary-dark-colors, primary-dark);
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: 22rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}
