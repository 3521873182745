@import '../../styles/constants/colors.scss';
@import "../../styles/constants/breakpoints.scss";

.tabList {
  display: flex;
  flex-direction: row;
  padding-top: 5px;

  ul {
    display: flex;
    flex-direction: row;

    li {
      // Fix: CES-1961: Prevent tabs from overlapping in iOS:
      flex: 0 0 auto;
      margin-right: 8px;

      &:last-of-type {
        margin-right: 1rem;
      }
    }

    li:first-child {
      aside {
        align-items: center;
        justify-content: center;
      }
    }

    .glyphFormating {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      margin-bottom: -10px;
    }

    aside {
      height: 100%;
      padding: 0 25px;
      background: $contrast-dark-accent-02;
      border-radius: 4px 4px 0 0;
      color: $color-white;
      fill: $color-white;
      line-height: 50px;
      white-space: nowrap;

      &.truncate {
        min-width: 250px;
        max-width: 300px;

        &.selected {
          max-width: fit-content;
          text-overflow: unset;
        }
      }

      &:hover {
        background: $contrast-dark-accent-025;
      }

      &.selected {
        background-color: $color-white;
        color: map-get($legacy-color-names, tooltip);
        fill: map-get($legacy-color-names, tooltip);
      }

      &.unselected .glyphFormating {
        fill: $color-white;
      }

      &.onlyTabTitle {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media #{$phoneMedia} {
        font-size: 0.8rem;
      }
    }
  }
}

.tabPanelContainer {
  .tabPanel {
    display: none;

    &.selected {
      display: block;
    }
  }
}

.secondLine {
  margin-bottom: 0.5em;
  line-height: 2em;
}

.numberIcon,
.tabIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  vertical-align: middle;
}

.numberIcon {
  margin-left: 7px;
  background-color: map-get($hubColors, product_finder_tab_selected_text);
  border-radius: 50%;
  color: $color-white;
  line-height: 30px;
  text-align: center;
}

.tabIconSvg {
  width: inherit;
  height: inherit;
}

body[data-theme="hub-theme"] {
  .tabList {
    ul li button:focus {
      box-shadow: 0 0 0 1px $color-white, inset 0 0 0 2px map-get($primary-colors, primary), inset 0 0 0 3px $color-white;
      outline: none;
    }
  }
}
