@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/constants/variables";

.siteHeader {
  height: 60px;
  border-bottom: 1px solid #cccdc9;
  background: $color-white;

  &.headerRestyling {
    height: 75px;
    font-weight: 400;

    nav > ul {
      min-height: 75px;

      li {
        display: flex;
        height: 100%;
        margin: auto 0;

        &.oxfordLogo {
          > * {
            margin: auto;

            svg {
              width: 181px;
              height: 76px;
            }
          }

          a {
            height: 100%;
            outline: none;
          }
        }

        &.loginButton {
          padding: 23px 0;

          a {
            border-color: color(border);
            border-radius: 20px;

            > span {
              &::before {
                top: 9px;
              }
            }

            &:hover {
              border-color: color(border);
            }
          }

          button {
            border-radius: 20px;
            box-shadow: none;

            svg {
              width: 1rem;
              height: 1rem;
            }

            svg + span::before {
              background-color: map-get($hubColors, hub_link);
            }
          }
        }

        &.helpButton {
          margin-left: auto;

          a {
            width: auto;
            height: auto;
            padding: 10px;
            border: 1px solid transparent;
            margin: auto;
            border-radius: 5px;
            color: map-get($hubColors, hub_link);

            svg {
              width: 20px;
              height: 20px;
              fill: map-get($hubColors, hub_link);
            }

            &:hover {
              border-color: map-get($background-and-border-colors, border);
              color: map-get($primary-dark-colors, primary-dark);
            }

            &:focus {
              border-color: map-get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map-get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }

            &:hover,
            &:focus {
              color: map-get($hubColors, hub_link_hover);

              svg {
                fill: map-get($hubColors, hub_link_hover);
              }
            }
          }

          .helpSupportText {
            display: block;
            padding-left: 10px;
            line-height: 1.25rem;
          }
        }

        &.accountButton {
          button {
            height: 51px;
            margin: auto auto auto 1.2rem;
          }

          .accountButtonHub {
            width: 3.125rem;
            height: 3.125rem;
            padding: 0;
            border: solid 1px transparent;
            border-radius: 4px;
            outline: none;

            &:hover,
            &.open {
              border-color: color(border);

              > div {
                background-color: map-get($hubColors, hub_link_hover);
              }
            }

            &:focus,
            &.open {
              background-color: map-get($secondary-colors, hub_light_grey);
            }

            &:focus {
              border-color: map-get($primary-colors, primary);
              box-shadow: 0 0 0 1px $color-white, inset 0 0 0 1px map-get($primary-colors, primary);

              > div {
                background-color: map-get($hubColors, hub_link_hover);
              }
            }
          }
        }

        &.impersonationAccountButton {
          align-items: center;
          justify-content: center;
          border-radius: 0;
        }

        &.menuButton {
          height: auto;
          margin-left: -24px;

          button {
            margin: auto;
            outline: none;

            &:hover {
              svg {
                border-color: color(border);
                border-radius: 4px;
                fill: color(text);
              }
            }

            &:active {
              background-color: initial;
            }

            &:focus svg {
              border-color: map-get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map-get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }

            svg {
              width: 50px;
              height: 50px;
              padding: 12px;
              border: 1px solid transparent;
              fill: $color-grey;
            }
          }
        }
      }
    }

    .myAccountText {
      display: none;
      margin-left: 20px;

      @media #{$lgMedia} {
        display: flex;
      }

      > * {
        display: flex;
        align-items: center;
        padding-top: 0;
      }
    }

    @media #{$smMedia} {
      .menuButton {
        display: none;
      }

      .loginButton {
        display: flex;

        a {
          margin: 0;

          &:not(:last-child) {
            margin: 0 12px 0 0;
          }
        }
      }
    }

    @media #{$xsMedia} {
      .helpButton {
        display: flex;
        order: inherit;
      }

      .menuButton:not([hidden]) ~ .helpButton {
        display: flex;
      }
    }

    @media #{$phoneMedia} {
      .oxfordLogo {
        margin-right: 8px;
      }

      .loginButton {
        a {
          svg {
            display: none;
          }

          span {
            margin-left: 7px;

            &::before {
              display: none;
            }
          }
        }

        button {
          margin-left: 0;
        }
      }

      nav > ul {
        li {
          &.menuButton {
            margin-left: -16px;

            + .oxfordLogo {
              > * {
                padding-left: 16px;
              }
            }
          }

          &.oxfordLogo {
            svg {
              display: none;
              width: 215px;
              margin: 0 -44px;

              &.mobileLogo {
                display: inline;
              }
            }
          }

          &.helpButton {
            a {
              svg {
                width: 25px;
                height: 25px;
              }
            }

            .helpSupportText {
              display: none;
            }
          }
        }
      }
    }
  }

  &.darkBackground {
    border-bottom: 1px solid map-get($background-and-border-colors, border-grey);
    background-color: map-get($background-and-border-colors, background-dark-blue);
    font-family: $open-sans;
    font-weight: 400;

    .myAccountText {
      > * {
        color: $color-white;
      }
    }

    nav > ul {
      li {
        &.helpButton {
          a {
            color: $white-grey;

            &:focus {
              color: $white-grey;

              svg {
                fill: $white-grey;
              }
            }

            svg {
              fill: $white-grey;
            }

            &:hover {
              border-color: color(border);
              color: $color-white;

              svg {
                fill: $color-white;
              }
            }
          }
        }

        &.loginButton {
          button {
            border-color: map-get($background-and-border-colors, border-grey);
            color: $white-grey;

            svg + span::before {
              background-color: map-get($hubColors, hub_link);
            }

            &:hover {
              border-color: $color-white;
              background-color: transparent;
              color: $color-white;
            }

            &:focus {
              border-color: map-get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map-get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }
          }
        }
      }
    }
  }

  &.headerOic {
    height: auto !important;
    font-weight: 400;

    &.isOicHome {
      height: 90px;
      padding-top: 32px;
      padding-bottom: 32px;
      border-bottom: none;

      @media #{$phoneMedia} {
        height: 75px;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .loginButton {
        padding: 0;
        margin-top: 15px;

        button {
          margin-right: 15px;
          margin-left: 0;
        }

        button:last-child {
          margin-right: 0;
        }
      }

      .helpButton {
        margin-top: 8px;
        margin-right: 15px;

        @media #{$phoneMedia} {
          margin-right: 0;
        }
      }
    }

    nav > ul {
      display: flex;
      height: auto !important;
      min-height: 75px;
      flex-wrap: wrap;

      @media #{$phoneMedia} {
        flex-wrap: wrap;
      }

      li {
        &.oicLogo {
          > * {
            margin: 0;

            svg {
              width: auto;
              height: 50px;
            }
          }

          &.isOicHome svg {
            height: 90px;

            @media #{$tabletMedia} {
              height: 70px;
            }
          }

          a {
            display: flex;
            height: 100%;
            outline: none;
          }

          @media screen and (max-width: 767px) {
            .desktopLogo {
              display: none;
            }

            .mobileLogo {
              display: block;
            }
          }
        }

        @media screen and (max-width: 767px) {
          .desktopLogo {
            display: none;
          }

          .mobileLogo {
            display: block;
          }
        }

        &.loginButton {
          padding: 23px 0;

          @media #{$xsMedia} {
            display: flex;
            width: 100%;
            flex-direction: row;
            padding: 0;
            margin-top: 30px !important;
          }

          a {
            border-color: color(border);
            border-radius: 20px;

            > span {
              &::before {
                top: 9px;
              }
            }

            &:hover {
              border-color: color(border);
            }
          }

          button {
            border-radius: 20px;
            box-shadow: none;

            svg {
              width: 1rem;
              height: 1rem;
            }

            svg + span::before {
              background-color: map-get($hubColors, hub_link);
            }
          }
        }

        &.helpButton {
          margin-left: auto;

          a {
            width: auto;
            height: auto;
            padding: 10px;
            border: 1px solid transparent;
            margin: auto;
            border-radius: 5px;
            color: map-get($hubColors, hub_link);

            svg {
              overflow: initial;
              width: 20px;
              height: 20px;
              fill: map-get($hubColors, hub_link);
            }

            &:hover {
              border-color: map-get($background-and-border-colors, border);
              color: map-get($primary-dark-colors, primary-dark);
            }

            &:focus {
              border-color: map-get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map-get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }

            &:hover,
            &:focus {
              color: map-get($hubColors, hub_link_hover);

              svg {
                fill: map-get($hubColors, hub_link_hover);
              }
            }
          }

          .helpSupportText {
            display: block;
            padding-left: 10px;
            line-height: 1.25rem;
          }

          @media #{$phoneMedia} {
            .helpSupportText {
              display: none;
            }
          }
        }

        &.accountButton {
          button {
            height: 51px;
            margin: auto;
          }

          .accountButtonHub {
            width: 3.125rem;
            height: 3.125rem;
            padding: 0;
            border: solid 1px transparent;
            border-radius: 4px;
            outline: none;

            &:hover,
            &.open {
              border-color: color(border);

              > div {
                background-color: map-get($hubColors, hub_link_hover);
              }
            }

            &:focus,
            &.open {
              background-color: map-get($secondary-colors, hub_light_grey);
            }

            &:focus {
              border-color: map-get($primary-colors, primary);
              box-shadow: 0 0 0 1px $color-white, inset 0 0 0 1px map-get($primary-colors, primary);

              > div {
                background-color: map-get($hubColors, hub_link_hover);
              }
            }
          }
        }

        &.menuButton {
          height: auto;
          margin-left: -24px;

          button {
            margin: auto;
            outline: none;

            &:hover {
              svg {
                border-color: color(border);
                border-radius: 4px;
                fill: color(text);
              }
            }

            &:active {
              background-color: initial;
            }

            &:focus svg {
              border-color: map-get($primary-colors, primary);
              box-shadow:
                0 0 0 1px $color-white,
                inset 0 0 0 1px map-get($primary-colors, primary),
                inset 0 0 0 2px $color-white;
            }

            svg {
              width: 50px;
              height: 50px;
              padding: 12px;
              border: 1px solid transparent;
              fill: $color-grey;
            }
          }
        }
      }
    }

    .myAccountText {
      display: none;
      margin-left: 20px;

      @media #{$lgMedia} {
        display: flex;
      }

      > * {
        display: flex;
        align-items: center;
        padding-top: 0;
      }
    }

    @media #{$smMedia} {
      .menuButton {
        display: none;
      }

      .loginButton {
        display: flex;

        a {
          margin: 0;

          &:not(:last-child) {
            margin: 0 12px 0 0;
          }
        }
      }
    }

    @media #{$xsMedia} {
      .helpButton {
        display: flex;
        order: inherit;
      }

      .menuButton:not([hidden]) ~ .helpButton {
        display: flex;
      }
    }

    @media #{$phoneMedia} {
      .oxfordLogo {
        margin-right: 8px;
      }

      .loginButton {
        a {
          svg {
            display: none;
          }

          span {
            margin-left: 7px;

            &::before {
              display: none;
            }
          }
        }
      }

      nav > ul {
        li {
          &.menuButton {
            margin-left: -16px;

            + .oxfordLogo {
              > * {
                padding-left: 16px;
              }
            }
          }

          &.oxfordLogo {
            svg {
              display: none;
              width: 215px;
              margin: 0 -44px;

              &.mobileLogo {
                display: inline;
              }
            }
          }

          &.helpButton {
            a {
              svg {
                width: 25px;
                height: 25px;
              }
            }

            .helpSupportText {
              display: none;
            }
          }
        }
      }
    }
  }

  // The menuButton gets hidden when not userSignedIn:
  // See notes below about moving the helpButton around.
  [hidden] {
    display: none;
  }

  @media #{$xsMedia} {
    // On mobile the helpButton is moved to the far left...
    .helpButton {
      display: flex;
      order: -1;
    }
    // ...BUT only visible when the BurgerMenu is hidden when not userSignedIn:
    .menuButton:not([hidden]) ~ .helpButton {
      display: none;
    }
  }

  :global(.col) {
    display: flex;
    flex-direction: row;
  }

  nav {
    flex-grow: 1;

    > ul {
      display: flex;

      // Same height as .siteHeader:
      height: 61px;
      flex-direction: row;
      justify-content: space-between;

      > * {
        display: inline-flex;
        height: calc(100% - 8px);
        margin: auto 0;

        > a {
          display: inline-block;
        }
      }
    }
  }

  .myAccountText {
    display: none;

    @media #{$smMedia} {
      display: block;
      margin-right: auto;
      margin-left: 20px;
    }

    > * {
      padding-top: 20px;
      color: map-get($hubColors, blue-color-dark);
      font-family: "Georgia", serif;
      font-size: 18px;
    }
  }

  .menuButton,
  .searchButton,
  .helpButton,
  .accountButton {
    > a,
    > button {
      display: inline-flex;
      height: 51px;
      align-items: center;
      justify-content: center;
      margin: auto auto auto 1.2rem;
    }
  }

  .menuButton > button {
    height: auto;
    margin-left: 0;
  }

  .menuButton,
  .searchButton,
  .helpButton {
    > .open,
    > :hover {
      svg {
        fill: color(text);
      }
    }
  }

  .menuButton,
  .searchButton,
  .helpButton,
  .notificationsButton {
    svg {
      height: 30px;
      padding: 1.5px;
      fill: map-get($legacy-color-names, background-dark);
    }
  }

  .menuButton svg {
    width: 41px;
  }

  .accountButton button > div {
    // Keep the thumbnail aligned on iOS 10
    margin: 0 auto;
  }

  .oxfordLogo {
    margin-top: 15px;

    svg {
      height: 30px;
      flex-shrink: 1;
      fill: color(primary-dark);
    }
  }

  .mobileLogo {
    display: none;
  }

  .orbLogo {
    margin-top: 5px;

    svg {
      height: 50px;
      flex-shrink: 1;
      fill: color(primary-dark);
    }
  }

  .courseSelector {
    margin-top: 10px;
  }

  .menuButton {
    height: 32px;

    @media #{$smMedia} {
      display: none;
    }

    button svg {
      border: 1px solid $color-botton;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: $color-botton;
      }
    }
  }

  // Align rightmost button on RHS: (Most noticeable on XS breakpoint)
  .accountButton {
    margin-right: -12px;
  }

  .loginButton {
    padding: 15px 0;

    a {
      display: inline-flex;
    }

    button {
      margin-left: 15px;
    }

    .registerButton {
      display: none;
    }
  }

  @media #{$smMedia} {
    li {
      position: relative; // Fix the popups to the li
    }

    .loginButton {
      display: flex;

      .registerButton {
        display: block;
      }

      a {
        margin-left: 15px;
      }
    }
  }

  @media screen and (max-width: 1006px) {
    .orbLogo {
      svg {
        display: none;

        &.mobileLogo {
          display: block;
        }
      }
    }
  }
}

body[data-theme="orb-theme"] {
  .helpButton a span {
    color: map-get($primary-colors, primary);

    &:hover {
      color: map-get($primary-dark-colors, primary-dark);
    }
  }

  .accountButton button div {
    background-color: map-get($primary-dark-colors, primary-dark);
  }
}

body[data-theme="dark-theme"] {
  .siteHeader {
    border-bottom: none;

    @media #{$xsMedia} {
      .helpButton {
        order: 0;
      }
    }

    .oxfordLogo {
      margin-top: 10px;

      svg {
        width: auto;
        height: 40px;
        fill: $color-white;
      }
    }

    .searchButton {
      margin-left: auto;
    }

    .notificationsButton {
      a {
        display: flex;
        width: auto;
        align-items: center;

        &:hover {
          background-color: transparent;
          box-shadow: none;
        }

        > svg {
          min-width: 40px;
        }

        span {
          display: flex;
          min-width: 30px;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
          background: $color-very-light-grey;
          border-radius: 10px;
        }
      }
    }

    .courseHomeLink {
      color: var(--service-brand-darkest);

      svg {
        width: 40px;
        background-color: $color-white;
        border-radius: 100%;
      }
    }

    .searchButton,
    .helpButton,
    .notificationsButton {
      a,
      button {
        display: block;
        width: 40px;
        height: 40px;
        margin: 10px 0 10px 10px;
        border-radius: 100%;
        box-shadow: inset 0 0 0 1.5px transparent;
        color: $color-white;

        &:hover {
          background-color: #0000004d;
          box-shadow: inset 0 0 0 1.5px $color-white;
        }
      }

      svg {
        height: 40px;
        padding: 0;
      }
    }

    .accountButton {
      margin-left: 10px;
    }

    .searchButton,
    .helpButton,
    .accountButton {
      > .open,
      > :active {
        background-color: transparent;
      }
    }

    .accountButton button {
      display: block;
      padding: 0;

      > div {
        width: 40px;
        height: 40px;
        background-color: transparent;
        box-shadow: inset 0 0 0 1.5px $color-white;
        line-height: 40px;

        &:hover {
          background-color: $contrast-light-box-shadow;
        }
      }
    }
  }
}

body[data-theme="light-theme"] {
  .siteHeader {
    border-bottom: 1px solid map-get($background-and-border-colors, border);

    @media #{$xsMedia} {
      .helpButton {
        order: 0;
      }
    }

    .courseHomeLink svg {
      height: 40px;
      border: 1px solid map-get($background-and-border-colors, background-light-blue);
      margin-top: -5px;
      border-radius: 50%;
    }

    .searchButton {
      margin-left: auto;
    }
  }
}

body[data-theme="hub-theme"] {
  .siteHeader {
    height: 77px;

    div:first-child a:first-of-type {
      display: inline-flex;
      margin-right: 8px;
    }

    nav > ul {
      height: 78px;

      li {
        display: flex;
        margin: auto 0;

        &.oxfordLogo {
          > * {
            margin: auto;

            svg {
              max-width: 100%;
            }
          }
        }

        &.menuButton + .oxfordLogo {
          > * {
            padding-left: 24px;
          }
        }

        &.loginButton {
          a {
            border-color: color(border);
            border-radius: 20px;

            > span {
              &::before {
                top: 9px;
              }
            }

            &:hover {
              border-color: color(border);
            }
          }

          button {
            border-color: map-get($background-and-border-colors, border);
            border-radius: 20px;
          }
        }

        &.helpButton {
          margin-left: auto;

          a {
            width: 51px;
            height: 51px;
            margin: auto;
            border-radius: 4px;

            &:hover {
              border: solid 1px color(border);

              svg {
                fill: map-get($background-and-border-colors, background-light-blue);
              }
            }
          }
        }

        &.accountButton {
          button {
            height: 51px;
            margin: auto;
          }

          .accountButtonHub {
            width: 3.125rem;
            height: 3.125rem;
            padding: 0;
            border: solid 1px transparent;
            border-radius: 4px;
            outline: none;

            &:hover,
            &.open {
              border-color: color(border);

              > div {
                background-color: map-get($hubColors, hub_link_hover);
              }
            }

            &.open {
              background-color: map-get($secondary-colors, hub_light_grey);
            }
          }
        }

        &.menuButton {
          height: auto;
          margin-left: -24px;

          button {
            height: 51px;
            margin: auto;
            outline: none;

            &:hover {
              svg {
                border-color: color(border);
                border-radius: 4px;
                fill: color(text);
              }
            }

            &:active {
              background-color: initial;
            }

            svg {
              width: 50px;
              height: 50px;
              padding: 12px;
              border: 1px solid transparent;
              fill: $color-grey;
            }
          }

          .hubMenuSelected {
            svg {
              border: 1px solid color(border);
              background-color: map-get($secondary-colors, hub_light_grey);
              fill: color(text);
            }
          }
        }
      }
    }

    .menuButton {
      display: block;
    }

    @media #{$smMedia} {
      .loginButton {
        display: flex;

        a {
          margin: 0;

          &:not(:last-child) {
            margin: 0 12px 0 0;
          }
        }
      }
    }

    @media #{$xsMedia} {
      .helpButton {
        display: flex;
        order: inherit;
      }

      .menuButton:not([hidden]) ~ .helpButton {
        display: flex;
      }
    }

    @media #{$phoneMedia} {
      .oxfordLogo {
        margin-right: 8px;
      }

      .loginButton {
        a {
          svg {
            display: none;
          }

          span {
            margin-left: 7px;

            &::before {
              display: none;
            }
          }
        }
      }

      nav > ul {
        li.menuButton {
          margin-left: -16px;

          + .oxfordLogo {
            > * {
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
}
