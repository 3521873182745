.invoiceReport {
  padding: 30px;
  text-align: center;

  .error{
    position: absolute;
    right: 0;
    bottom: -15;
    left: 0;
    padding: 10px;
    background-color: #d90429;
    color: #fff;

  }

  .title {
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    margin-bottom: 10px;
  }

  .ValidationStatus{
    position: relative;

    .status{
      position: absolute;
    }
  }

  .dateFields {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    .field {
      position: relative;
      margin: 10px;
    }
  }

}
