.container {
  display: none;
  width: 0;

  &.iframeOpener {
    display: block;
    width: 100%;
  }


  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

body[data-theme="orb-theme"] {
  .container.iframeOpener {
    height: 100%;
  }
}
