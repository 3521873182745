.actions {
  display: flex;
  align-items: center;

  button {
    border: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}
