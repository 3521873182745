@use "sass:map";
@import "../../styles/constants/colors";


.backgroundColor {
  background: linear-gradient(180deg, map.get($primary-colors, primary) 0%, $contrast-light-blue 41%, $contrast-light-blue-gradient 100%);
}

.redeemCodeSuccessPage {
  min-height: 100vh;
  justify-content: center;
  padding-top: calc(2rem);
  padding-bottom: 2rem;
  margin: auto;

  .validRedeemCode {
    padding: 1px 20px 20px 30px;

    .content {
      text-align: center;

      a {
        font-size: 18px;
      }

      h1 {
        color: map.get($hubColors, hub_success_text_green);
        font-size: 36px;

        .SVGIcon {
          width: auto;
          height: 30px;
          fill: map.get($hubColors, hub_success_text_green);
        }
      }

      &.mflContent {
        h1 {
          padding: 6px;
          border: 1px solid map.get($core, border);
          font-size: 25px;
          font-weight: bold;

          .SVGIcon {
            height: 20px;
          }
        }

        &.mflResendLink{
          h1 {
            padding-top: 14px;
            border: 0;
            font-size: 26px;

            .SVGIcon {
              height: 65px;
            }
          }
        }
      }
    }
  }

  .emailContent {
    padding: 1rem 2rem;
    text-align: center;

    .email_label {
      padding-bottom: 1rem;
      font-weight: 400;
    }

    .email_info {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .successfulReedemCodeContent {
    padding: 1rem 2rem;
    text-align: center;
  }

  .linkItem {
    margin: 1rem 2rem;

    p {
      font-size: 21px;
      text-align: center;
    }

    .content {
      padding-top: 21px;
      font-size: 21px;
      text-align: center;

      a {
        height: 50px;
      }
    }
  }

  .linkItems {
    padding-top: 21px;
    padding-bottom: 21px;
    margin: 10px 5px 20px;

    .content {
      text-align: center;

      p {
        margin-bottom: 5px;
        font-size: 18px;
      }
    }
  }

  .registerLink {
    color: map.get($background-and-border-colors, background-light-blue);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: map.get($hubColors, blue-color-dark);
      text-decoration: underline;
    }
  }
}
