@import '../../styles/constants/colors.scss';

.supportPageHeader {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $color-white;

  svg {
    fill: $color-white;
  }

  .linkContainer a {
    display: inline-block;
  }

  .heading {
    display: flex;
    align-items: center;

    h1 {
      margin: 0 1rem 0 0;
    }
  }

  .subheading {
    color: $color-sub-text;
  }
}
