@import "../../styles/constants/colors";

.orbCourseSelector {

  /* from ces-navbar.scss (Unity DEV) */

  label {
    margin: 0 20px 0 0;
    font-weight: 400;
  }

  select {
    border: 1px solid map-get($cptColors, grey);
    background-color: transparent;
    border-radius: 40px;
    -webkit-appearance: none;
    height: 40px;
    line-height: 40px;
    padding: 0 40px 0 20px;
    background-image: url("/media/orb/icon-arrow-blue.svg");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 15px;
  }

  @media (max-width: 768px) {
    label {
      font-weight: normal;
      float: left;
      width: 25%;
      margin: 0;
      display: none;
    }

    select {
      width: 75%;
      float: right;
      padding: 0 16px 0 20px;
    }
  }
}
