@import '../../../../styles/constants/colors';

.coursePreviewTableContainer tr th {
  padding: 10px;
  white-space: nowrap;
}

.coursePreviewTableContainer th:first-child {
  text-align: left;
}

.coursePreviewTableContainer th:nth-child(2) {
  text-align: center;
}

.coursePreviewTableContainer th:last-child {
  text-align: right;
}

.coursePreviewTableContainer td {
  padding: 10px;
  font-weight: normal;
  text-align: center;
}

.coursePreviewTableContainer td:first-child {
  text-align: left;
}

.coursePreviewTableContainer td:last-child {
  text-align: right;
}

.coursePreviewTableContainer button {
  color: $color-primary-blue;
  font-weight: bold;
}

.coursePreviewTableContainer table label {
  margin: 0;
}

.filters {
  width: 100%;
  margin-bottom: 20px;
}

.filters::after {
  display: table;
  clear: both;
  content: "";
}

.filter:nth-child(2) {
  float: right;
}

.no_courses {
  text-align: center;
}
