.bodyText {
  text-align: left;

  li {
    display: list-item;
    margin-bottom: 1rem;
  }

  li li {
    margin-bottom: 0;
  }

  ol ol {
    margin-bottom: 0;
    list-style-type: lower-alpha;
  }

  ul > li {
    margin-bottom: 0;
    list-style-type: disc;
  }

  ul,
  ol {
    padding-left: 1rem;
  }
}

.warning {
  font-style: italic;
}
