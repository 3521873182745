@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.container {
  text-align: center;
}

.subHeaderBold {
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin-bottom: 7px;
  margin-top: 15px;
}

.largeHeaderFormat {
  margin: 0 0 7px 0;

  @media print {
    font-size: 25px;
  }
}

.expectedLabel {
  color: map-get($hubColors, dark-gray);

  @media print {
    color: $color-black;
  }
}

.midProgress {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  border: 0px solid transparent;
  border-right-width: 0.625rem;
  border-left-width: 0.625rem;
  display: flex;
  flex-direction: column;
  border-left: transparent;
  border-right: transparent;
  border-top: 1px solid map-get($background-and-border-colors, border);
  border-bottom: 1px solid map-get($background-and-border-colors, border);
  padding-bottom: 15px;

  @media #{$smMedia} {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    border-top: transparent;
    border-bottom: transparent;
    border-left: 1px solid map-get($background-and-border-colors, border);
    border-right: 1px solid map-get($background-and-border-colors, border);
  }
}

.columnDisplay {
  flex-basis: 100%;
  max-width: 100%;
}

.midColumnDisplay {
  flex-basis: 100%;
  max-width: 100%;

  @media screen {
    border-left: transparent;
    border-right: transparent;
    border-top: 1px solid map-get($background-and-border-colors, border);
    border-bottom: 1px solid map-get($background-and-border-colors, border);
  }
}
