@import "../../styles/constants/colors";

.entityPageHeader {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $color-white;

  .linkContainer {
    display: flex;
    margin-left: 90px;
  }

  .subtitle {
    margin-left: 90px;
  }

  .thumbnailContainer {
    position: absolute;
    top: 1rem;
    display: inline-table;
  }

  .headingAndButton {
    display: flex;
    align-items: center;
    margin-left: 90px;

    h1 {
      margin: 0 1rem 0 0;
    }

    button,
    a {
      margin-right: 1rem;
      color: white;
    }

    svg {
      fill: currentColor !important;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1rem;
  }
}
