@import './variables.scss';

//
// ORB Lozenges
// --------------------------------------------------
.orb-lozenge {
    border-style: solid;
    border-width: 2px;
    display: inline-block;
    font-size: $font-size-h4;
    font-weight: $headings-font-weight;
    border-radius: $ces-border-radius;
    padding: 5px 10px;
    margin-right: $padding-base-vertical;
    &.orb-lozenge-level {
        border-color: $orb-blue;
        color: $orb-blue;
    }
    &.orb-lozenge-timeline {
        border-color: $ces-border;
        color: $ces-sub-text;
        text-align: center;
        position: relative;
        margin: 0 auto;
        margin-bottom: $padding-base-horizontal;
        left: 50%;
        transform: translate(-50%);
    }
}
