.popoutPanelIconHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 0;
  text-align: center;

  &:first-child {
    margin-top: 3rem;
  }

  svg {
    width: 6rem;
    height: 6rem;
  }

  h2 {
    margin-top: 0.5rem;
  }
}

.buttonsContainerCenter {
  display: flex;
  justify-content: center;
}

.stepContainer  {
  width: 100%;
}
