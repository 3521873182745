@import '../../../styles/constants/colors.scss';
@import '../../../styles/constants/breakpoints.scss';

.videoModal {
  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .modalContainer {
    position: fixed;
    z-index: 103;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: var(--vh100, 100vh);
    align-items: center;
    justify-content: center;
    padding: 0 1rem 0 1rem;
    background: $color-black3;
    transition: opacity 1s linear;

    .modal {
      position: absolute;
      top: 20%;
      width: auto;
      height: 0;
      background-color: black;
      transform: translateY(-40px);
      transition: transform 0.3s ease-out;

      &.active {
        -webkit-transform: translate(0);
        transform: translate(0);
      }

      .iframeContainer {
        iframe {
          width: calc((40vw*16) / 9);
          height: 40vw;
        }
      }
    }

    @media #{$smMedia} {
      .modal {
        min-width: 20rem;
      }
    }
  }
}
