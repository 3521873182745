@import "../../styles/constants/colors";

.pie {
  --percentage: 0;
  --border: 5px;

  position: relative;
  width: 30px;
  height: 30px;
  margin: 5px;

  &::before {
    position: absolute;
    background: conic-gradient(map-get($secondary-colors, progress_green) calc(var(--percentage) * 1%), map-get($secondary-colors, no_progress_grey) 0);
    border-radius: 50%;
    content: "";
    inset: 0;
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--border)), $color-black calc(100% - var(--border)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--border)), $color-black calc(100% - var(--border)));
  }
}
