@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";

.buttonContainer {
  display: flex;
  min-height: 2rem;
  flex-direction: row;
  align-items: center;
  padding: $gap-2;
  border-width: $border-width-1;
  border-style: solid;
  border-color: map-get($base, white0);
  background-color: map-get($base, white0);
  border-radius: $border-radius-default;
  color: map-get($primary, primary100);
  font-family: $font-family-text-font;
  gap: $gap-2;
  line-height: $line-height-120;
  text-align: center;

  svg {
    display: block;
    width: $icon-size-regular;
    flex-shrink: 0;
    margin: auto auto;
    background-color: map-get($heritage, ruskin-red100);
    border-radius: 50%;
    fill: map-get($base, white100);
  }

  &:hover {
    background-color: map-get($primary, primary10);
  }

  &:focus {
    background-color: map-get($base, white0);

    box-shadow: $box-shadow-focus;
  }

  &:focus:hover {
    background-color: map-get($primary, primary10);
    mix-blend-mode: multiply;
  }

  &:disabled {
    border-color: map-get($base, white0);
    background-color: map-get($base, white0);
    cursor: not-allowed;

    svg {
      background-color: map-get($heritage, ruskin-red);
      fill: map-get($base, white100);
    }
  }

  &.open {
    background-color: map-get($primary, primary100);

    svg {
      background-color: map-get($base, white100);
      fill: map-get($primary, primary100);
    }

    &:focus:hover {
      background-color: map-get($primary, primary100);
    }
  }
}
