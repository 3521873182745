@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

.rocketLolly {
  display: flex;
  width: 100%;
  max-width: 100%;
}

.stripe {
  color: $color-white;
  padding: .5rem;
  text-align: center;
  white-space: nowrap;

  // This ensures the value remains visible when space is limited and the text disappears off to the left:
  direction: rtl;

  &:first-child {
    flex-basis: 30%;
    min-width: 30%;
    border-radius: 999px 0 0 999px;
  }

  &:last-child {
    border-radius: 0 999px 999px 0;
  }

  &.defaultColor {
    // 3rd stripe colour
    &:nth-child(2n + 1) {
      background-color: map-get($hubColors, product_finder_tab_unselected_text);
    }

    // 2nd stripe colour
    &:nth-child(2n + 2) {
      background-color: $color-button-background2;
    }

    // 1st stripe colour
    &:first-child {
      background-color: map-get($secondary-colors, progress_green);
    }
  }
}

// Use a little less space for the first stripe on tablet and up:
@media #{$smMedia} {
  .stripe {
    &:first-child {
      flex-basis: 20%;
      min-width: 20%;
    }
  }
}
