@import "~@oup/shared-front-end/src/styles/breakpoints";

.schoolReportDialContainer {
  width: 100%;
  padding: 20px 0;
  border: lightgrey solid 1px;
  border-radius: 8px;
}

.toggleSwitchContainer {
  margin: 20px 0;
}

.title {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.viewMoreLink {
  display: block;
  text-align: center;
}

.loadingMultiSchoolLoader {
  display: flex;
  width: 60%;
  justify-content: center;
  margin: auto;
}

.schoolReportTabsContent {
  width: 100%;
  padding: 20px;

}

.datePickerWrapper {
  display: flex;
  min-width: 150px;
  justify-content: space-around;

  > button {
    margin-right: 10px;
  }
}

.schoolReportActionButtons{
  position: relative;
  display: flex;
  margin: 15px 0;

  > * {
    margin-right: 15px;
    vertical-align: middle;
  }

}

.exportButton{
  display: flex;
  min-height: 30px;
  align-items: center;
  padding: 0 15px;
  border: 1px solid;
  border-radius: 15px;
  cursor: pointer;
  line-height: 28px;
}

.exportButton:disabled {
  cursor: initial;
  opacity: 0.5;
}

.reactDatePickerAbsolute {
  position: absolute;
  top: 100%;
  right: 50%;
  margin-top: 5px;
  transform: translateX(40%);
}

@media screen and (max-width: $screen-width-840) {
  .schoolReportActionButtons{
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > * {
    margin: 6px 0;
  }

  }

  .datePickerWrapper{
    justify-content: center;
  }

  .reactDatePickerAbsolute {
    transform: translateX(40%);
  }
}

.datePickerCalendarIcon {
  border: 1px solid;
  border-radius: 15px;
  width: 50px;
  > svg {
    margin-top: 3px;
  }
}

.itemDownload{
  margin-left: 10px;
}
