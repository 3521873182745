@import "../../styles/constants/colors";

.carousel {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid $color-light-grey;
    border-radius: 6px;
    width: 650px;
}

.carousel a:link,
.carousel a:visited {
    padding: 0.4rem 0.8rem;
    border: 1px solid $color-light-grey;
    border-radius: 5px;
    text-decoration: none;
}

.dot {
    cursor: pointer;
    height: 0.9rem;
    width: 0.9rem;
    margin: 0 2px;
    background-color: $color-mid-grey;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.dot:not(:first-child) {
    margin-left: 0.7rem;
}

.active,
.dot:hover {
    background-color: map-get($primary-colors, primary);
}

@media only screen and (max-width: 768px) {
    .carousel {
        max-width: 500px;
    }
}

@media only screen and (max-width: 600px) {
    .carousel {
        max-width: 400px;
    }
}
