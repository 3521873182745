@import '../../../styles/constants/colors.scss';

.subtextContainer {
  text-align: center;

  p {
    padding: 1rem;
  }
}

.emailContainer {
  word-break: break-word;
}
