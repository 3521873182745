@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints';
@import '../../styles/mixins/hides';

.mainContainer {
  text-align: center;
  p {
    padding-top: 1em;
  }
}

.errorContainer {
  text-align: center;
  cursor: pointer;

  .errorText{
    font-size: 2em;
    padding-top: 1em;
  }
  .errorSubText{
    font-size: 1.25em;
    color: map-get($hubColors, dark-gray);
    padding-bottom: 1.25em;
  }
  .suggestionText{
    font-size: 1em;
    padding-top: 1em;

  }
  .icon{
    height: 6.25em;
    width: 6.25em;
    margin: auto;
    padding-top: 1.25em;
  }
}
