@import "../../styles/constants/colors";

.classCodeLink {
  margin-left: 10px;
  color: map-get($background-and-border-colors, background-light-blue);
}

.classCode {
  p[data-highlightword] {
    position: relative;
  }

  p[data-highlightword]::before {
    position: absolute;
    content: attr(data-highlightword);
    font-weight: bold;
  }
}
