@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

$calendar-default-gray: $color-soft-black;
$calendar-light-gray: $smart-background-color-lozenge;
$calendar-selected-day-color: $color-blue-circle;
$white: $color-white;

.calendarTitle {
  text-align: center;
}

.calendarStoryWrapper {
  text-align: center;
}

.calendarWrapper {
  position: relative;
  display: block;
  width: 20%;
  min-width: 12em;
  height: auto;
  border: 1px solid $calendar-light-gray;
  margin: 0 auto;
  color: $calendar-default-gray;

  .calendarColStart {
    flex-grow: 0;
    text-align: left;
  }

  .calendarColCenter {
    flex-grow: 4;
    text-align: center;
  }

  .calendarColEnd {
    flex-grow: 0;
    text-align: right;
  }

  .calendarHeader {
    padding: 1.5em 0;
    font-size: 115%;
    font-weight: 700;

    @media #{$smart-mobile} {
      font-size: 100%;
    }

    .calendarActionArrow {
      height: 18px;
      flex: 0 0 10px;
    }

    .calendarIcon {
      display: inline-block;
      cursor: pointer;
      direction: ltr;
      font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: normal;
      line-height: 1;
      text-rendering: optimizeLegibility;
      text-transform: none;
      vertical-align: middle;
      white-space: nowrap;
      word-wrap: normal;

      &:first-of-type {
        margin-left: 1em;
      }

      &:last-of-type {
        margin-right: 1em;
      }
    }
  }

  .calendarRow {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5em 0;
    margin: 0;
  }

  .calendarRowMiddle {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .calendarCol {
    max-width: 100%;
  }

  .calendarWeekDays {
    padding: 0.75em 0;
    border-bottom: 1px solid $calendar-default-gray;
    font-weight: 400;
    text-transform: uppercase;
  }

  .calendarBody {
    display: grid;
    grid-auto-rows: 40px;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;

    .calendarCell {
      max-width: 40px;
      max-height: 40px;
      line-height: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    .calendarGrayText {
      color: $calendar-light-gray;
    }

    .calendarSelectedDay {
      background-color: $calendar-selected-day-color;
      color: $white;
    }
  }
}
