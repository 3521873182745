.subtitleNormal {
  text-align: left;
  margin-left: 15px;
  margin: 0;
}

.attainmentFormat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  flex-shrink: 0;
  border-radius: 50%;
  fill: white;
  height: 40px;
  padding: 5px;
  width: 40px;

  @media print {
    -webkit-print-color-adjust: exact !important;
  }
}
