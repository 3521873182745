@import '../../../../styles/constants/colors';

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.icon {
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  background-color: $color-green-subscription;
  border-radius: 35px;
}

.glyph {
  width: 35px;
  height: 35px;
  fill: $color-white;
}

.pagination {
  display: flex;
  justify-content: center;
}
