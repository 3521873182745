@import '../../styles/constants/breakpoints';
@import '../../styles/constants/colors';

.horizontalCarouselScroller {
  overflow: hidden !important;
  padding-right: 20px;

  .scrollerContainer {
    margin: auto;
    -webkit-overflow-scrolling: auto; // This seems to be working
    overflow-x: scroll;

    // There is a bug on iOS that obscures the sticky column if this inertia-based movement property is used.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
    // -webkit-overflow-scrolling: touch;

    // Try using -webkit-overflow-scrolling: auto;

    &.leftShadow {
      &::before {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 180px;
        display: block;
        width: 44px;
        height: 100%;
        background: transparent;
        background: linear-gradient(90deg, $color-black-shade 0%, $color-transparent-black 100%);
        content: '';
      }
    }

    &.rightShadow {
      &::after {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: block;
        width: 44px;
        height: 100%;
        background: transparent;
        background: linear-gradient(90deg, $color-transparent-black 0%, $color-black-shade 100%);
        content: '';
      }
    }
  }

  /* Next & previous buttons */
  .left, .right {
    position: absolute;
    z-index: 3;
    width: auto;
    padding: 12px;
    border-radius: 0 3px 3px 0;
    margin-top: -10px;
    background-color: $color-black-shade2;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: 0.6s ease;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .right {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .left:hover, .right:hover {
    background-color: $color-black-shade3;
  }
}

@media #{$ipadAirMedia} {
  .horizontalCarouselScroller{
    .left, .right {
      display: none;
    }

    .scrollerContainer {
      margin: auto;

      // There is a bug on iOS that obscures the sticky column if this inertia-based movement property is used.
      // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
      // -webkit-overflow-scrolling: touch;

      // Try using -webkit-overflow-scrolling: auto;

      -webkit-overflow-scrolling: auto; // This seems to be working
      overflow-x: scroll;

      &.leftShadow {
        &::before {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 180px;
          display: block;
          width: 44px;
          height: 100%;
          background: transparent;
          background: linear-gradient(90deg, $color-black-shade 0%, $color-transparent-black 100%);
          content: '';
        }
      }

      &.rightShadow {
        &::after {
          position: absolute;
          z-index: 2;
          top: 0;
          right: 0;
          display: block;
          width: 44px;
          height: 100%;
          background: transparent;
          background: linear-gradient(90deg, $color-transparent-black 0%, $color-black-shade 100%);
          content: '';
        }
      }
    }
  }
}
