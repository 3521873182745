@import "../../styles/mixins/hub/hub-button-focus";
@import "../../styles/mixins/hub/help-card";

.asideHeading {
  font-size: 16px;
}

.helpCard {
  @include help-card();

  padding-right: 0;
}

.changeDetailsButtonContainer {
  display: flex;
  justify-content: flex-end;
}
