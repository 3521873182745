.icon {
  stroke: inherit;
}

.emblem {
  flex-shrink: 0;
}

.SMALL {
  width: 26px;
  height: 26px;
  max-height: 26px !important;
  padding: 5px;
}

.MEDIUM {
  width: 60px;
  height: 60px;
  max-height: 60px !important;
  padding: 10px;
}

.LARGE {
  width: 100px;
  min-width: 100px !important;
  height: 100px;
  padding: 20px;
}

.LARGE_DOUBLE {
  width: 150px;
  max-width: 150px !important;
  height: 150px;
  padding: 20px;
}

.CIRCLE {
  border-radius: 50%;
}

.SQUARE {
  border-radius: 0%;
}
