
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/mixins/hub/hub-button-focus";

.header {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.initials {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin-right: 0.625rem;
  background-color: map-get($secondary-colors, register);
  border-radius: 50%;
  color: map-get($legacy-color-names, tooltip);
  font-size: 16px;
  font-weight: 400;
}

.heading {
  margin: 0;
}

.userName {
  margin-top: -5px;
  color: map-get($hubColors, dark-gray);
  font-size: 16px;
}

