@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.attainmentFormat {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.subtitleGrey {
  color: map-get($hubColors, dark-gray);
  text-align: left;
  margin: 7px 0 15px 10px;
  line-height: 30px;
  align-self: center;
  margin: 0;
}

.cardFormat {
  height: 100%;
  width: 93%;
  max-width: auto;
  border: solid 1px map-get($background-and-border-colors, border);
  border-radius: 5px;
  margin: 15px auto;
  padding: 15px;

  @media #{$smMedia} {
    width: 82%;
    max-width: 1110px;
  }
}

.progressBar {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progressBar > p {
  margin-left: 20px;

  @media #{$smMedia} {
    flex-direction: row;
  }
}

.progressHeader {
  font-size: 21px;
  line-height: 30px;
  margin: 0 0 15px 0 !important;
}

hr.separator {
  margin: 40px 0;
}

.previousAttemptsButton {
  margin-top: 40px;
  margin-bottom: 10px;
  text-decoration: underline;
  color: map-get($modern, electric_blue);
}
