@import '../../styles/constants/colors.scss';

.expandableItem {
  padding: 1em;
  border: 1px solid map-get($background-and-border-colors, border);

  @media print {
    border-color: $color-black !important;
  }

  &.expandableItem {
    border-top: none;
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  button {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.collapsed {
  @media print {
    display: none;
  }
}

.label {
  text-align: center;

  @media print {
    button {
      border: none !important;

      &:hover,
      &.active {
        background: none !important;
      }

      svg {
        display: none;
      }
    }
  }
}

.content {
  @media screen {
    padding-top: 1em;
  }
}
