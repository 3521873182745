@import "../../styles/constants/breakpoints.scss";
@import '../../styles/constants/colors.scss';

.rangeSlider {
  .slider {
    display: flex;
    width: 100%;
    flex-direction: row;

    &.disabled {
      opacity: 0.7;
    }

    .sliderLabel {
      display: inline-block;
      align-self: center;
      padding-right: 10px;
      font-weight: normal;
      line-height: 0.9;
      text-align: left;
      white-space: nowrap;
    }

    input[type="range"] {
      width: 100%;
      height: 15px;
      margin: 11px 0 0 0;
      -webkit-appearance: none;
      background: linear-gradient(90deg, $color-mid-grey-3 60%, $color-mid-grey-3 60%);
      border-radius: 12px;
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
    }

    input[type="range"]::-webkit-slider-thumb {
      width: 30px;
      height: 30px;
      border: 1px solid $color-black;
      -webkit-appearance: none;
      appearance: none;
      background: $color-light-grey;
      border-radius: 50%;
      box-shadow: 1px 1px 2px $color-black;
      cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
      width: 30px;
      height: 30px;
      border: 1px solid $color-black;
      background: $color-light-grey;
      border-radius: 50%;
      box-shadow: 1px 1px 2px $color-black;
      cursor: pointer;
    }

    @media #{$xsMedia} {
      display: flex;
      width: 100%;
      flex-direction: column;

      .sliderLabel {
        width: 100%;
      }
    }
  }
}
