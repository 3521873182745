@import '../../styles/constants/colors.scss';

$spacing: 4px;

.radioButton {
  display: flex;
  margin: 0 ($spacing * 4) ($spacing * 2) 0;
  padding: 0 ($spacing * 4) 0 ($spacing * 1.5);
  position: relative;
  width: fit-content;

  &-rounded {
    composes: radioButton;
    border: 1px solid $color-background-dark;
    border-radius: $spacing * 5;

    &:hover {
      background-color: $color-background;
    }
  }

  &-checked {
    box-shadow: inset 0 0 0 2em $contrast-dark-accent-08;
  }
}
