@import "../../../../styles/constants/colors";

.lookupContainer {
  > div {
    width: 750px;
    margin-bottom: 20px;

    > div {
      display: inline-block;
      width: 70%;
    }
  }

  .cardRowRadio {
    display: flex;
    width: 100%;
    min-height: 48px;
    margin-bottom: 10px;
    gap: 10px;

    span {
      min-width: 20%;
    }

    span + div {
      flex: 1;
    }

    label {
      display: flex;
      min-width: auto;
      flex: 1;
    }
  }

  label {
    display: inline-block;
    min-width: 30%;
  }

  button {
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  align-items: center;

  button {
    border: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.paginationContainer {
  margin-top: 40px;
}

.productGroupsResults {
  margin-top: 40px;
}
