@import "../../styles/constants/colors";

.errorContainer {
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;

  .almostInvisible {
    color: $color-table-background;
    font-size: 25px;
  }
}

.errorFooter {
  display: flex;
  padding: 1em;
  justify-content: center;
  align-items: center;
}
