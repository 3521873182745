@import "../../styles/constants/breakpoints";

.contentContainer {
  padding: 1rem;

  .heading {
    font-size: 1.1rem;
    font-weight: 600;
  }

  .sectionContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    span:first-of-type {
      font-weight: 600;
    }
  }
}

.infoLabelContainer {
  margin-right: 2rem;
  margin-left: 1rem;

  @media #{$phoneMedia} {
    margin-left: 2.5rem;
  }
}
