@import '../../styles/constants/colors.scss';

.updatePasswordContainer{
    display: flex;
    flex-direction: column;
    height: 100%;

    .header{
        h2{
            font-weight: 700;
            font-style: normal;
            font-size: 21px;
        }
    }

    .content{
        flex: 1;
    }

    .footer{
        border-top: 1px solid $color-borders;
        padding-top: 5px;
        justify-content: flex-end;
    }
}
