@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.resourceModalHeader {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
  border-bottom: 1px solid map-get($secondary-colors, hub_dashboard_selected);
  background-color: map-get($secondary-colors, hub_dashboard);

  @media #{$phoneMedia} {
    padding: 1rem 1.5rem;
  }

  .resourceTitle {
    margin-right: auto;
    color: $color-white;

    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.closeButtonContainer {
  text-align: right;

  button:focus {
    @include standart-focus-button();
  }
}
