@import "../../styles/constants/colors";

.updateUserContainer {
  padding: 1rem;
}

.updatedUser {
  margin-left: 59px;
  color: map-get($hubColors, hub_success_text_green);
  font-size: 13px;
}

.button {
  &.doneButton {
    border-bottom-color: transparent;
  }
}
