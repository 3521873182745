@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/breakpoints";

.pageContainer {
  padding: $gap-3 10rem;

  @media (max-width: $screen-width-1024) {
    padding: $gap-3 7rem;
  }

  @media (max-width: $screen-width-600) {
    padding: $gap-3 4rem;
  }

  .buttonsContainer {
    margin-top: $gap-4;
  }
}
