@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

// Container for both the button and the dropdown:
.container {
  position: relative;
  white-space: nowrap;

  // Extra strong selector needed
  &.container > button {
    height: 40px;
    border-radius: 20px;
    padding-left:5px;
  }
}

.containerShallow {
  &.containerShallow > button {
    height: 30px;
  }
}

// Dropdown outer container:
.popupOverlay {
  &.hangRight {
    left: auto;
    right: 0;
  }

  &.hangLeft {
    left: 0;
    right: auto;
  }

  margin-top: 4px;
  width: calc(100vw - 2.5rem);
  max-height: calc(100vh - 15rem);
  overflow-y: auto;

  @media #{$smMedia} {
    width: auto;
  }

  fieldset {
    margin: 0;
    box-shadow: 0px 3px 7px 0px $contrast-light-box-shadow;
  }

  // Heading at top of dropdown:
  legend {
    font-weight: bold;
    padding: 15px 15px 7px 15px;
  }
}

// Dropdown list-items container:
.popupContainer {
  li {
    height: 40px;
    min-width: 230px;
    padding: 0px 13px;
    position: relative;
    &:first-child {
      label {border: none; }
    }
    &.disabled{
      label { color: map-get($legacy-color-names, background-dark);}
    }
    &:focus,
    &:hover {
      background-color: map-get($styleGuideColors, selected);
    }
  }

  label {
    color: map-get($styleGuideColors, primary);
    display: block;
    padding: 0;
    margin: 0px;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid map-get($legacy-color-names, background-dark);

    // span containing label text:
    * {
      display: inline-block;
    }
  }

  // Hide the native checkmark ✔ in IE (because IE does not support appearance:none)
  ::-ms-check {
    display: none;
  }

  // This field is restyled placed in front of the label so that keyboard nav still works:
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    // Make field the same size as the label so that focus looks same as Style Guide when navigating with keyboard:
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 42px;
    margin: 0;
    z-index: 1;

    // For iOS 10 compatibility
    border: none;
    background: none;

    &:focus,
    &:hover {
      + label {
        background-color: map-get($styleGuideColors, selected);
      }
    }
  }
}

body[data-theme="dark-theme"] {
  .popupOverlay {
    border-radius: 5px;
    margin-top: 10px;
    &:before, &:after{
      right: 20px;
      border: 16px solid transparent;
    }
  }

  .popupContainer {
    border-radius: 5px;
    overflow: hidden;
  }

  input {
    outline: none;

    + label {
      padding: 0 10px;
      text-align: left;
      color: var(--text-neutral);
      fill: var(--text-neutral);

      &:before {
        top: 0px;
        left: auto;
        right: 0px;
      }
    }
    + label.streamline {
      &:before {
        top: 5px;
        right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
