
.readingData {
  font-size: 25px;
  font-weight: 400;
  margin-top: 0px !important;
}

.readingDataTitles {
  margin-bottom: 0 !important;
  white-space: nowrap;
}

.readingTime__flex {
  display: flex;
  align-items: flex-end !important;

  h2 {
    margin-bottom: 0px;
  }

  p {
    margin-bottom: 5px;
    padding: 0 3px 0px 3px;
  }
}
