@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.card {
  border-top: 1px solid $color-borders;
  border-right: 1px solid $color-borders;

  // Side borders
  border-bottom: 1px solid $color-borders;
  border-left: 1px solid $color-borders;
  // The Card always has a white background.
  background: $color-white;

  // Curved corners and a border line only on the top
  border-radius: 4px;

  // This is the colored container or the heading text
  .headingLabel {

    // Position relative to support the roundOff elements within it
    position: relative;

    // Inline block for auto-width
    display: inline-block;
    padding: 8px 15px;
    margin-top: -1px;
    margin-left: 15px;
    border-radius: 0 0 4px 4px;

    // The :before and :after elements overlay the roundOff's to create the curved effect
    &::before,
    &::after {
      position: absolute;
      z-index: 2;
      top: 1px;
      width: 8px;
      height: 8px;
      background: $color-white;
      border-radius: 4px;
      content: '';
    }

    &::before {
      left: -8px;
    }

    &::after {
      right: -8px;
    }
  }

  // The heading text can be any tag from h1->h4 and span, but always has this styling:
  .headingText {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
  }

  // This is for the rounded off corners in the design.
  // There are two colored element positioned either side of the tab
  .roundOff {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 5px;
    height: 5px;

    &:first-of-type {
      left: -4px;
    }

    &:last-of-type {
      right: -4px;
    }
  }

  .content {
    padding: 0 15px 15px;

    button:focus {
      @include no-padding-button-on-focus();
    }
  }
}

html[data-platform="ces"],
body[data-theme="hub-theme"] {
  .card {
    border-top: 1px solid map-get($primary, primary20);
    border-right: 1px solid map-get($primary, primary20);
    border-bottom: 1px solid map-get($primary, primary20);
    border-left: 1px solid map-get($primary, primary20);
  }
}
