@import "../../styles/constants/colors";

.licence {
  align-items: center;
  color: map-get($hubColors, dark-gray);
  display: flex;
  font-size: 14px;
  line-height: 20px;
}

.processing {
  opacity: .5;
}

.image {
  background-color: $color-green-subscription;
  height: 50px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 50px;
}

.title {
  font-size: 16px;
}

.titleIcon {
  display: inline-block;
  fill: map-get($background-and-border-colors, background-light-blue);
  margin-left: .25em;
  width: 1em;
}
