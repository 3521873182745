@import "../../styles/constants/colors";

.bulkUploadInformationBubble {
  margin: 0.8rem 0;
  position: relative;
  background: $color-light-border;
  text-align: center;
  border-radius: 4px;
  padding: 1.5rem;

  .templateDownloadBox {
    margin: auto;
    position: relative;
    border-radius: 4px;
    background: white;
    padding: 1rem;
    h3 {
      font-weight: 700;
    }
  }

  .bodyText {
    text-align: left;
    li {
      display: list-item;
      margin-bottom: 1rem;
    }
    li li {
      margin-bottom: 0;
    }
    ol  ol {
      list-style-type: lower-alpha;
      margin-bottom: 0;
    }
    ul > li {
      list-style-type: disc;
      margin-bottom: 0;
    }
    ul, ol {
      padding-left: 1rem;
    }
    h3 {
      font-weight: 700;
    }
  }
  .bubbleArrow {
    background-color: $color-light-border;
    position: absolute;
    top: -0.5rem;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    transform: rotate(45deg);
  }
  .buttonContainer {
    margin: 1rem;

    button {
      margin: auto;
    }

    div {
      margin-top: 0.5rem;
    }
  }
}
