@import "../../styles/constants/colors";
@import '../../styles/constants/variables';
@import '../../styles/constants/breakpoints';

.subheading {
  color: $soft-black;
  font-size: 20px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.button {
  margin-bottom: 20px;
}

.blueButton {
  margin-bottom: 20px;
  color: map-get($background-and-border-colors, background-light-blue);
}

.btnGroup {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media #{$mdMedia} {
    flex-direction: row;
    gap: 15px;
  }
}
