@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

.informationPopupModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: var(--vh100, 100vh);
  width: 100vw;
  z-index: 103;
  background: $color-black3;
  transition: opacity 1s linear;

  // On tiny display, such as landscape phone, we may need to scroll down to see buttons:
  overflow-y: auto;

  .modal {
    background: $color-white;
    width: auto;
    max-width: 600px;
    margin: 0 auto 0;
    position: relative;
    text-align: center;
    box-shadow: 0 5px 15px $contrast-light-accent-50;
    border-radius: 6px;
    transition: transform 0.3s ease-out;
    transform: translate(0, 0);

    .topSection {
      font-size: 0;
    }

    &.active {
      transform: translate3d(0%, 10%, 0);
    }

    .header {
      position: absolute;
      bottom: 135px;
      width: 90%;
      margin: 0 5%;
      color: $color-white;
      font-size: 35px;
      text-shadow: 0 1px 2px $color-black7;
    }

    .carouselControls {
      position: relative;
      padding: 1rem;
    }

    .image {
      display: inline-block;
      width: 100%;
      background-size: cover;
      overflow: hidden;
      min-height: 350px;
      min-width: 100%;
      background-color: gray;
      border-radius: 6px 6px 0 0;
    }

    .body {
      position: relative;
      border-bottom: 1px solid $color-sub-text;
      padding: 5px 1rem 0;
      height: 50px;


    }
    .finalPanelTitle {
      border-bottom: 1px solid $color-sub-text;
      padding: 5rem 1rem 8vh 1rem;

      @media #{$smMedia} {
        padding: 5rem 5rem;
        height: 400px;
      }
    }

    .close {
      border: 1px solid $color-sub-text;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: $color-white;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;

      svg {
        height: 10px;
        fill: $color-black;

        &:hover {
          fill: $color-sub-text;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .informationPopupModal .modal {
    margin: 1rem;
  }
}
