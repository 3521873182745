@use "sass:map";
@import '../../styles/constants/breakpoints';
@import '../../styles/constants/colors';
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

/**
tCell = td
hCell = th
*/

.tableAccordion {
  width: 100%;
  border-collapse: collapse;

  .fixedWidthEllipsis {
    overflow: hidden;
    width: 150px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__row {
    // Using box-shadow so borders don't pile up into one thick unit
    box-shadow: 0 -1px map.get($primary, primary20);
  }

  &__cell {
    height: 43px;
    padding: 0 5px;
    text-align: left;

    &:first-of-type {
      width: 25%;
    }

    @media (width >=400px) and (width <= 600px) {
      &:first-of-type {
        width: 27.5%;
      }
    }

    @media (width <= 398px) {
      &:first-of-type {
        width: 31%;
      }

      &:nth-of-type(2) {
        width: 28%;
        text-align: center;
      }

      &:nth-last-of-type(2) {
        width: 24% !important;
        text-align: center;
      }
    }

    &:nth-last-of-type(2) {
      width: 100px;
      text-align: center;
    }

    &:last-of-type {
      width: 50px;
    }
  }

  &__hCell {
    font-size: 14px;

    &:nth-of-type(2) {
      text-align: center;
    }
  }

  &__tCell--background {
    &:nth-last-of-type(2) {
      background-color: $color-highlight;
    }
  }

  &__tCell--first {
    font-weight: bold;
  }

  .popupRow {
    &__cell {
      height: auto;
      padding: 0;
    }
  }

  .revealButton {
    svg {
      width: 1em;
      fill: $color-primary-blue;
      transform: rotate(90deg);
      transition: transform 0.25s linear;
    }

    &--open svg {
      transform: rotate(270deg);
    }
  }

  // Nested table
  &Nested & {
    &__head {
      display: none;
      visibility: collapse;
    }

    &__tCell--first {
      font-size: 0.9em;
      font-weight: normal;
    }
  }

  // Third level nested table
  &Nested &Nested & {
    &__head {
      display: table-header-group;
      visibility: visible;
    }

    &__body {
      border-bottom: 2px solid map.get($primary, primary20);
    }

    &__row {
      box-shadow: none;

      &:nth-child(even) {
        background:  map.get($primary, primary10);
      }
    }

    &__cell:first-of-type {
      padding: 8px 25px 20px;
    }

    &__hCell {
      height: 15px;
      padding-top: 8px;
      line-height: 15px;
    }
  }
}

@media #{$smMedia} {
  .tableAccordion {
    &__hCell:nth-of-type(2),
    &__hCell:nth-of-type(3) {
      text-align: left;
    }

    &__cell {
      &:nth-of-type(2) {
        width: 20%;
      }

      &:nth-last-of-type(2) {
        width: unset;
      }
    }

    &Nested & {
      &__tCell--first::before {
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 5px;
        content: "\21B3";
        font-size: 1.3em;
      }
    }

    &Nested &Nested & {
      &__hCell:nth-of-type(3) {
        text-align: left;
      }

      &__cell:nth-of-type(2) {
        width: 20%;
        padding-top: 9px;
        padding-bottom: 19px;

        span {
          width: 75px;
        }
      }

      &__cell:nth-last-of-type(2) {
        width: unset;
        padding-top: 5px;
        padding-bottom: 18px;
      }
    }

    // Spacing options
    &--wideSpacing &__tCell {
      height: 47px;
      font-size: 17px;
    }

    &--narrowSpacing &__tCell {
      height: 39px;
      font-size: 15px;
    }
  }
}

@media #{$xsMedia} {
  .tableAccordion {
    &__hCell:nth-of-type(2),
    &__hCell:nth-of-type(3) {
      text-align: center;
    }

    &Nested & {
      &__tCell--first {
        font-size: 0.8em;
      }

      &__tCell--first::before {
        position: relative;
        top: 2px;
        display: inline-block;
        margin-right: 5px;
        content: "\21B3";
        font-size: 1em;
      }
    }

    &Nested &Nested & {
      &__cell:first-of-type {
        padding-left: 15px;
      }
    }
  }
}
