@import "../../styles/constants/colors";

.levelChange {
  border-color: map-get($secondary-colors, support_icon) !important;
  background-color: $color-table-background;
  text-align: center;

  @media print {
    border-color: $color-black !important;

    * {
      color: $color-black !important;
    }
  }
}
