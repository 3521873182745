@import '../../../styles/constants/colors';

.statusContainer {
  margin: 0 !important;
  padding: 0 !important;
}

.keySection {
  align-items: center;
  display: flex;
  margin: 5px 0;
}

.icon {
  border-radius: 50%;
  fill: $color-white;
  height: 30px;
  margin-right: 0.5em;
  min-width: 30px;
  padding: 4px;
  width: 30px;

  @media print {
    -webkit-print-color-adjust: exact !important;
  }
}
