@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.resourceModalFooter {
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-top: 1px solid map-get($secondary-colors, hub_dashboard_selected);
  background-color: map-get($secondary-colors, hub_dashboard);

  button:focus {
    @include standart-focus-button();
  }

  @media #{$phoneMedia} {
    flex-direction: column;
    padding: 1rem 1.5rem;
  }
}

.resourceModalFooterInfo {
  color: $color-white;

  p {
    font-size: 1.3125rem;
    font-weight: 600;
  }

  span {
    font-size: 1rem;
  }

  @media #{$phoneMedia} {
    margin: 0 0 0.75rem 0;
    text-align: center;
  }
}

.resourceModalDownloadRow {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
}
