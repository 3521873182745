@import '../../styles/constants/breakpoints';
@import '../../styles/constants/grid';


.orbRegionLayout {
  background-color: var(--ui-background);

  &.white {
    background-color: white;
  }

  & > div {
    overflow-x: hidden;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: var(--vh100, 100vh);
  }

  :global {
    .grid {
      max-width: none;

      &.grid_max_width {
        max-width: $NGSgridMaxWidth;
      }
    }
  }
}

.pageContainer {
  main {
    @media screen {
      min-height: calc(100vh - 136px); /* Fallback for browsers that do not support Custom Properties */
      min-height: calc(var(--vh100, 100vh) - 136px);
    }
  }

  &.pageContainerWithoutNavbar {
    main {
      @media screen {
        min-height: calc(100vh); /* Fallback for browsers that do not support Custom Properties */
        min-height: calc(var(--vh100, 100vh) - var(--fixed-header-height, 0));
      }
    }
  }
}

.pageContainer {
  margin-bottom: 76px; // additional space for NGS subheader fixed at the bottom of the page

  @media only screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
}
