@import "../../styles/constants/breakpoints";

.progressContainer {
  display: flex;
  align-items: center;

  .progressText {
    margin-left: 3px;
    font-size: 1rem;
  }

  .shortLabel {
    display: none;
    font-size: 14px;
  }

  @media #{$phoneMedia} {
    .shortLabel {
      display: block;
    }
  }

  @media #{$xsMedia} {
    .pieChartContainer {
      display: none;
    }
  }
}
