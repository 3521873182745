@import '../../styles/constants/breakpoints';
@import '../../styles/constants/colors';


.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 15px;
  border-radius: 10px;
  background: $color-background-sign;
  box-shadow: 3px 3px $contrast-dark;

  @media (min-width: 480px) {
    max-width: 230px;
  }
}

.row {
  position: relative;
  max-width: 190px;
  width: 100%;
  margin: 4px 0;
  font-size: 16px;
  font-weight: 600;
  color: $color-soft-black;

  &:after {
    position: absolute;
    right: -4px;
    bottom: 6px;
    width: 80px;
    height: 2px;
    background: currentColor;
    content: '';
  }
}
