.SEBSignedOutContainer {
  display: flex;
  justify-content: center;
  margin-top: 237px;
}

.container {
  display: flex;
  max-width: 35rem;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;

  button {
    width: 117px;
    margin-top: 35px;
    margin-bottom: 16px;
  }

  >p {
    font-size: 27px;
    font-weight: 700;
    line-height: 120%;
  }

  .no_account_text,
  .register_text {
    float: left;
  }

  .register_text {
    margin-left: 1px;
    color: black;
  }
}
