@import '../../styles/constants/colors.scss';

.progressContainer {
  display: flex;
  width: 100%;
  height: 12px;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .step {
    display: flex;
    height: 6px;
    flex-grow: 1;
    background-color: map-get($background-and-border-colors, border-light-grey);
    border-radius: 3px;
  }

  .previousStep {
    height: 12px;
    background-color: map-get($hubColors, blue-color-primary);
    border-radius: 6px;
  }

  .animation {
    width: 0;
    height: 12px;
    align-self: center;
    border-radius: 6px;
  }

  .grow {
    width: 100%;
    background-color: map-get($hubColors, blue-color-primary);
    transition: width 0.3s linear;
  }
}
