@import '../../styles/constants/colors.scss';

.container {
  padding: 20px;
  background: $color-mid-grey-2;
  border-radius: 8px;
  position: relative;
}

.containerPointer:after {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid $color-mid-grey-2;
  position: absolute;
  content: "";
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.center {
  text-align: center;
}

.iconContainer {
  height: 40px;
  width: 40px;
  display: inline-block;
}

.ctaContainer {
  padding-left: 12px;
}

hr {
  color: map-get($typography-colors, text);
}
