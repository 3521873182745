@import "../../styles/constants/colors";

.lookupContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;

  button {
    min-width: 3em;
    padding-left: 0 !important;
    background: map-get($background-and-border-colors, background-light-blue) !important;
    color: $color-white;
    text-align: center;
  }
}

.lookupTable {
  width: 100%;
  border-collapse: collapse;

  tr {
    width: 100%;
    cursor: pointer;
  }

  tr:hover {
    background-color: map-get($styleGuideColors, selected);
  }

  td {
    padding: 5px;
    border-bottom: 1px solid map-get($typography-colors, sub-text-50);
  }

  .prettyText {
    text-transform: capitalize;
  }

  .innerTable {
    display: table;
  }

  .innerRow {
    display: table-row;
    width: 100%;

    div {
      display: table-cell;
      padding-right: 10px;
    }
  }

  .productName {
    font-weight: bold;
  }
}
