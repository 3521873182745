@import "../../styles/constants/breakpoints.scss";

.app {
  > div {
    min-height: calc(100vh - 67px); /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh100, 100vh) - 67px);
    overflow-x: hidden;
  }
}

.appFullHeight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div,
  .pageContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.pageContainer main {
  &.noMinHeight {
    min-height: auto;
  }

  &.whiteBackground {
    background-color: white;
  }

  &.transparentBackground {
    background: transparent;
  }

  &.fullFluidHeight {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  @media screen {
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: var(--vh100, 100vh);
  }
}
