@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";
@import "../../styles/mixins/hides.scss";

/* stylelint-disable */
.button {
  //trunacte name css to truncate name for long name in dropdown
  .truncName {
    display: inline-block;
    overflow: hidden;
    max-width: 130px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // Use flexbox to align content vertically:
  display: inline-flex;
  min-width: 40px;
  max-width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  padding: 9px 15px;
  border: 1px solid transparent;
  border-width: 1px 1px 3px 1px;
  border-radius: 4px;
  text-decoration: none;

  // For svg icons:
  fill: $color-white;
  line-height: 1; //36px;

  // Soften the hover transition a smidge:
  transition: background-color 0.1s;

  // Fix: CES-1778: Ensure click events won't get soaked up by whatever is inside the button:
  * {
    pointer-events: none;
  }

  small {
    display: block;
    font-size: "0.8em";
  }
  // Remove text decoration when Button is actually a styled Link
  &:hover {
    text-decoration: none;
    mix-blend-mode: normal;
  }

  &:focus {
    border-color: $color-borders-v2; // Custom non-standard color here
    box-shadow: inset 0 1px 1px $contrast-light-shadow, 0 0 10px 1px $contrast-light-blue;
    outline: none;
    mix-blend-mode: normal;
  }

  // Padding when the button only contains an SVG
  &.iconOnly {
    svg + span {
      margin-left: 0;
    }
  }

  &.fullWidth {
    display: block;
    width: 100%;
    text-align: center;
  }

  // Button Type Styles
  .buttonIconText {
    width: 30px;
    height: 30px;
    margin-right: 7px;
    background: map-get($secondary-colors, teacher);
    border-radius: 50%;
    color: $color-white;
    font-size: 12px;
    line-height: 30px;
  }

  &.hasGlyphContainer {
    .glyphContainer {
      display: flex;
      width: 1.875rem;
      height: 1.875rem;
      align-items: center;
      justify-content: center;
      padding: 0.375rem;
      margin-right: 0.75rem;
      background-color: $color-grey;
      border-radius: 50%;

      svg {
        fill: white;
      }
    }


    &:hover {
      .glyphContainer {
        background-color: map-get($primary-colors, primary);
        svg {
          fill: white;
        }
      }
    }
  }

  &.type-COPY_CLIPBOARD {
    &:focus {
      border-color: white;
      box-shadow: none;
      outline: none;
    }

    padding: 0;

    svg {
      width: 1em;
      height: 1em;
      fill: map-get($background-and-border-colors, background-light-blue);
    }
  }

  &.type-PENCIL_REVIEW {
    &:focus {
      border-color: white;
      box-shadow: none;
      outline: none;
    }

    padding: 0;
    &:hover {
      background-color: $color-button-background;
    }

    svg {
      width: 1.3em;
      height: 1.3em;
      fill:  map-get($rvsColors, rvs_drop_zone);

      &:hover {
        fill: map-get($hubColors, dark-gray);
      }
    }
  }

  &.type-PENCIL_REVIEW_ACTIVE {
    &:focus {
      border-color: white;
      box-shadow: none;
      outline: none;
    }

    padding: 0;
    &:hover {
      background-color: $color-button-background;
    }

    svg {
      width: 1.3em;
      height: 1.3em;
      fill: map-get($hubColors, blue-color-dark-moderate);

      &:hover {
        fill: map-get($hubColors, blue-color-dark);
      }
    }
  }

  // primary button styles
  &.type-PRIMARY,
  &.type-PRIMARY_REVERSED {
    padding: 9px 15px;
    border-bottom-color: $color-astronaut-blue;
    background: map-get($background-and-border-colors, background-light-blue);
    color: $color-white;
    mix-blend-mode: normal;

    &:hover {
      background: $color-astronaut-blue;
    }
  }

  &.type-PRIMARY_REVERSED {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    & span {
      margin-right: 0.4375rem;
    }
  }

  &.type-THERE_BE_DRAGONS {
    padding: 9px 15px;
    border-bottom-color: color(error-dark);
    background: color(error);
    color: $color-white;

    &:hover {
      background: color(error-dark);
    }
  }

  &.type-ACTION,
  &.type-DOWNLOAD,
  &.type-ACTION_NO_ICON,
  &.type-ACTION_BLOCK,
  &.type-ACTION_BLOCK_NO_ICON {
    height: 40px;
    padding: 0 15px;
    border: none;
    background-color: $color-very-light-grey;
    color: map-get($background-and-border-colors, background-light-blue);
    line-height: 40px;
    text-align: center;

    svg {
      width: 10px;
      height: 10px;
      margin-left: 6px;
      fill: map-get($background-and-border-colors, background-light-blue);
    }

    &:hover {
      background: $color-grey-basket;
      color: map-get($hubColors, blue-color-dark);

      svg {
        fill: map-get($hubColors, blue-color-dark);
      }
    }
  }

  &.type-ACTION_BLOCK_NO_ICON {
    border: none;
    box-shadow: none;
    color: map-get($background-and-border-colors, background-light-blue);
    outline: none;

    svg {
      display: none;
    }
  }

  &.type-DOWNLOAD {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.type-ACTION_BLOCK,
  &.type-ACTION_BLOCK_NO_ICON {
    width: 100%;
    background: none;

    &:hover {
      background: none;
    }
  }

  &.type-CANCEL {
    padding: 0 50px;
    border: none;

    &:hover {
      color: color(error-dark);
    }
  }

  // secondary button styles
  &.type-SECONDARY {
    border-color: map-get($hubColors, dark-gray);
    background: $color-white;

    &:hover {
      background: $color-mercury;
    }

    svg {
      fill: map-get($background-and-border-colors, background-light-blue);
    }
  }

  &.type-SELECTED {
    border-bottom-color: $color-strong;
    background: map-get($legacy-color-names, good);
    color: $color-white;

    &:hover {
      background: $color-strong;
    }
  }

  &.type-NO_BORDER,
  .type-RED {
    border: 0;
    background-color: transparent;
    color: $color-sub-text;

    svg {
      width: 26px;
      height: 26px;
      fill: $color-sub-text;
    }

    &:hover {
      color: $color-text;

      svg {
        fill: $color-text;
      }
    }
  }

  &.type-DOWNLOAD_RESOURCE {
    padding-right: 0;
    border: none;
    background-color: transparent;

    &:hover {
      color: map-get($primary-colors, primary);

      & svg {
        fill: map-get($primary-colors, primary);
      }
    }

    &:focus {
      box-shadow: none;
      color: map-get($primary-colors, primary);
      outline: none;
    }

    &:disabled {
      opacity: 0.4;
    }

    & svg {
      width: 12px;
      height: 12px;
      fill: map-get($primary-colors, primary);
    }
  }

  &.type-RED {
    color: $color-red;

    &:hover,
    &:focus {
      background-color: $color-very-light-grey;
    }
  }

  &.type-FULLWIDTH {
    width: 100%;
    height: 70px;
    flex-shrink: 0;
    border: 0;
    background-color: var(--accent-color);
    border-radius: 0;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: var(--accent-color-darker);
    }
  }

  &.type-INFO_NO_BORDER {
    border: 0;
    background-color: transparent;
    color: $color-sub-text;

    svg {
      width: 26px;
      height: 26px;
      fill: map-get($legacy-color-names, background-dark);
    }

    &:hover {
      color: $color-primary-blue;

      svg {
        fill: $color-primary-blue;
      }
    }
  }

  &.type-DROPDOWN_NO_BORDER {
    border: 0;
    background-color: transparent;
    color: $color-sub-text;

    svg {
      width: 20px;
      height: 20px;
      fill: $color-primary-blue;
    }

    &:hover {
      color: $color-primary-blue-dark;

      svg {
        fill: $color-primary-blue-dark;
      }
    }
  }

  &.type-GROUP {

    // This seems to work better than inline-flex in Safari: (On both Desktop & iOS)
    display: inline-block;
    padding: 0 8px;
    border: 1px solid $color-borders;
    background-color: $color-white;
    color: $color-primary-blue;
    line-height: 36px;

    svg {
      fill: $color-primary-blue;
    }

    // Be aware: The "of-type" selectors only match elements with same tag, not class...
    // This css ensures that adjacent GROUP buttons assume a style for first/middle/last button.
    // Also, contraversially, this css allows for when GROUP buttons are wrapped in <li> elements.

    // Buttons in middle or on far left:
    &:not(:last-of-type),
    li:not(:last-of-type) > & {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    // Buttons in middle or on far right:
    &:not(:first-of-type),
    li:not(:first-of-type) > & {
      // Avoid double borders between each button:
      border-left-width: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    // Selected buttons:
    &.active,
    &:active {
      background: $color-primary-blue;
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }

    &:hover {
      background: $color-mercury;
      color: $color-primary-blue;

      svg {
        fill: $color-primary-blue;
      }
    }

    &.active:hover {
      background: $color-astronaut-blue;
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }

  &.type-LAUNCH {
    border: none;
    background-color: var(--accent-color);
    border-radius: 5px;
    border-top-left-radius: 20px;
    color: $color-white;
    font-size: 16px;
    text-transform: uppercase;

    &:focus {
      box-shadow: none;
    }

    &:focus,
    &:hover {
      background: var(--accent-color-darker);
    }
  }

  &.type-DROPDOWN_SHORT,
  &.type-ROUNDED,
  &.type-ROUNDED_SMALL,
  &.type-GHOST,
  &.type-GHOST_INVERTED,
  &.type-GHOST_NEUTRAL,
  &.type-GHOST_ACCENT,
  &.type-BREADCRUMB,
  &.type-DROPDOWN,
  &.type-DROPDOWN_SHORT,
  &.type-DROPDOWN_JUMBO,
  &.type-DRILLDOWN,
  &.type-CLOSE,
  &.type-CLOSE_BOLD,
  &.type-CLOSE_BOLD_HOVER,
  &.type-BLUE,
  &.type-WHITE,
  &.type-ORB_LEARNING_OBJECT {
    min-height: 30px;
    padding: 0 15px;
    border: 1px solid;
    border-radius: 15px;
    line-height: 28px;

    svg {
      width: 1rem;
      height: 1rem;
    }

    // This provides nice rounded focus outline in Chrome but it's not practical to solve in all browsers.
    // Best to leave native focus style alone.
    // &:focus {
    //   outline: none;
    //   box-shadow: 0 0 3px 2px #3b99fcff;
    // }
  }

  &.type-ROUNDED_SMALL {
    width: 20px;
    min-width: auto;
    height: 20px !important;
    min-height: auto;
    padding: 0;
    border: 1px solid;
    border-color: map-get($background-and-border-colors, background-light-blue) !important;
    margin: 0 0 0 5px;
    border-radius: 50% !important;
    line-height: 15px;

    svg {
      width: 0.7rem;
      height: 0.7rem;
    }
  }

  &.type-BACK,
  &.type-CLOSE_NO_BORDER {
    padding: 0;
    border: 0;
    color: $color-black3;

    &:focus,
    &:hover {
      color: $color-black;
    }
  }

  &.type-BACK {
    svg {
      width: 40px;
      height: 40px;
    }
  }

  &.type-CLOSE_NO_BORDER {
    svg {
      width: 1em;
      height: 1em;
    }
  }

  &.type-GHOST {
    border-color: $contrast-dark-accent-35;
    background-color: transparent;
    color: $color-white;

    &:hover {
      border-color: transparent;
      background-color: $color-white;
      color: map-get($background-and-border-colors, background-light-blue);
      // Foreground color should be controlled externally

      svg {
        fill: map-get($background-and-border-colors, background-light-blue);
      }
    }
  }

  &.type-ACCENT,
  &.type-GHOST_NEUTRAL,
  &.type-GHOST_ACCENT {
    height: 40px;
    border-radius: 20px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &.type-ACCENT {
    background-color: var(--accent-color);
    color: $color-white;

    &:focus,
    &:hover {
      background-color: var(--accent-color-darker);
    }
  }

  &.type-GHOST_NEUTRAL {
    border-color: var(--text-neutral);
    color: var(--text-neutral);

    &:focus,
    &:hover {
      background-color: var(--text-neutral);
      color: $color-white;
    }
  }

  &.type-GHOST_ACCENT {
    border-color: var(--accent-color);
    color: var(--accent-color);

    &:focus,
    &:hover {
      background-color: var(--accent-color);
      color: $color-white;
    }
  }

  &.type-GHOST_INVERTED,
  &.type-BREADCRUMB,
  &.type-ORB_LEARNING_OBJECT {
    position: relative;

    // To place icon on far left aligned with curve of button:
    padding-left: 5px;
    border-color: $color-primary-blue;
    color: $color-primary-blue;

    .leftSvg {
      position: relative;

      // To keep it in front of the round background:
      z-index: 1;
      top: 0;
      left: 4px;
      width: 12px;
      height: 12px;
      fill: $color-white;

      + span {
        vertical-align: top;

        // Move text right further than usual:
        &:not(.a11y-hide) {
          margin-left: 15px;
        }

        // Dark circle behind icon:
        // This convoluted solution seemed to work better than other techniques.
        // Adding background and border-radius to the SVG itself adversly affected its rendering.
        &::before {
          position: absolute;
          top: 4px;
          left: 5px;
          width: 20px;
          height: 20px;
          background-color: $color-primary-blue;
          border-radius: 50%;
          content: "";
        }
      }
    }

    &:hover {
      border-color: map-get($legacy-color-names, text-input-border);
      background-color: $color-grey-button;
      color: $color-button-background2;

      svg {
        + span {
          &::before {
            background-color: $color-button-background2;
          }
        }
      }
    }
  }

  &.type-ORB_LEARNING_OBJECT {
    background-color: $color-primary-blue;
    color: $color-white;

    svg.leftSvg {
      width: 24px;
      height: 24px;

      + span::before {
        display: none;
      }
    }

    span {
      margin-left: 10px;
    }

    &:hover {
      background-color: map-get($hubColors, blue-color-dark);
      color: $color-white;
    }
  }

  &.type-BREADCRUMB {
    max-width: 220px;
    margin: 0.5em 0.5em 0 0;

    .leftSvg {
      fill: $color-primary-blue;

      + span {
        &:not(.a11y-hide) {
          overflow: hidden;
          width: calc(100% - 20px);
          margin-left: 10px;
          text-overflow: ellipsis;
        }

        &::before {
          display: none;
        }
      }
    }

    &:hover {
      .leftSvg {
        fill: $color-primary-blue-dark;
      }
    }
  }

  &.type-ROUNDED,
  &.type-ROUNDED_SMALL,
  &.type-DROPDOWN,
  &.type-DROPDOWN_SHORT,
  &.type-DROPDOWN_JUMBO,
  &.type-DRILLDOWN {
    height: 40px;
    border-color: map-get($legacy-color-names, text-input-border);
    background-color: $color-white;
    border-radius: 20px;
    color: map-get($legacy-color-names, tooltip);

    .dropdownArrow,
    .drilldownArrow {
      fill: $color-primary-blue;
    }

    &:hover {
      background-color: $color-grey-button;
    }

    .dropdownArrow,
    .drilldownArrow {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      transition: transform 0.3s;
    }

    &.active {
      .dropdownArrow {
        transform: rotate(270deg);
      }
    }
  }

  &.type-DROPDOWN_JUMBO {
    position: relative;
    height: 46px;
    border-radius: 23px;
    color: $color-primary-blue;

    .glyphBG {
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      width: 2.1rem;
      height: 2.1rem;
      border-radius: 50%;

      & + svg {
        z-index: 1;
        width: 2.1rem;
        height: 2.1rem;
        padding: 5px;
        margin-left: -0.6rem;
        fill: $color-white;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-left: -0.5rem;
    }

    .dropdownArrow {
      width: 1rem;
      height: 1rem;
    }
  }

  &.type-DROPDOWN_SHORT {
    height: 30px;
  }

  .dropdownArrow,
  .drilldownArrow {
    width: 12px;
    height: 12px;
    margin-left: 5px;
  }

  &.type-DRILLDOWN {
    min-width: 35px;
    height: 35px;

    &.iconOnly {
      padding: 0 8px;

      svg {
        width: 1rem;
        // Slightly bigger arrow for this one
        height: 1rem;
      }
    }

    &.type-ROUNDED {
      min-width: 48px;
    }
  }

  &.type-CLOSE,
  &.type-CLOSE_BOLD {
    min-width: 35px;
    height: 35px;
    border-radius: 18px;

    svg {
      width: 11px;
      height: 11px;
      margin-left: 6px;
      fill: $color-sub-text;
    }
  }

  &.type-CLOSE_WHITE,
  &.type-CLOSE_BORDER_GRAY {
    border: none;
    background-color: $color-white;
    border-radius: 25px;
    color: $color-primary-blue;
    transition: 0.2s filter ease-in-out;

    &:hover {
      filter: brightness(80%);
    }

    & svg {
      width: 12px;
      height: 12px;
      margin-left: 5px;
      fill: $color-primary-blue;
    }
  }

  &.type-CLOSE_BORDER_GRAY {
    padding: 0 0.5rem;
    border: 1px solid map-get($secondary-colors, hub_light_grey);
  }

  &.type-CLOSE_BOLD_HOVER {
    border-color: $color-borders;
    box-shadow: none;
    color: $color-primary-blue;

    svg {
      width: 0.8rem;
      height: 0.8rem;
      margin-left: 6px !important;
      fill: $color-primary-blue;
    }

    &:hover {
      background-color: $color-borders;
      color: $color-primary-blue-dark;

      svg {
        fill: $color-primary-blue-dark;
      }
    }
  }

  &.type-PRIMARY_THIN {
    min-height: auto;
    padding: 5px 10px;
    background-color: $color-orb-blue;
    border-radius: 5px;
    color: $color-white;
    cursor: default;
    text-align: center;
  }

  &.type-BLUE {
    min-width: 35px;
    height: 35px;
    background: $color-primary-blue;
    border-radius: 18px;
    color: white;
  }

  &.type-WHITE {
    min-width: 35px;
    height: 35px;
    border-color: map-get($legacy-color-names, background-dark);
    background: white;
    border-radius: 18px;
    color: $color-primary-blue;
  }

  &.type-OIC_GREEN {
    background:  map-get($oicColors, oisa_green);
    color: $color-black;

    &:hover {
      background: darken(map-get($oicColors, oisa_green), 5%);
    }
  }

  &.type-OIC_BLUE {
    background:  map-get($oicColors, oisa_blue);
    color: $color-black;

    &:hover {
      background: darken(map-get($oicColors, oisa_blue), 5%);
    }
  }

  &.type-OIC_GREEN, &.type-OIC_BLUE, &.type-OIC_GREEN:focus, &.type-OIC_BLUE:focus {
    border: none;
    min-height: auto;
    height: auto;
    padding: 4px 10px 4px 5px;

    svg {
      width: 20px !important;
      height: 20px !important;
      color: $color-white;
    }
  }

  &.type-OIC_GREEN:focus, &.type-OIC_BLUE:focus {
    outline: 2px solid map-get($oicColors, oxford_blue);
  }


  // Icon styling
  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  // If there is an icon and text
  svg + span:not(:global(.a11y-hide)) {
    margin-left: 7px;
  }

  svg,
  span {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
  }

  // all button states that are disabled
  // Note that .disabled class can be used to apply disabled
  // style without causing focus to jump away from the button.
  .disabled,
  &[disabled] {
    // cursor: not-allowed; // Has no effect with pointer-events.
    opacity: 0.5;
    pointer-events: none;
  }

  // External links have a special icon on the right:
  &.external {
    span:first-child {
      text-indent: 0.25rem;
    }

    svg:last-of-type {
      fill: $color-primary-blue;
    }

    &.type-PRIMARY,
    &.type-PRIMARY_REVERSED {
      svg:last-of-type {
        margin-left: 0.5rem;
        fill: $color-white;
      }
    }
  }

  // Converts any button into a Floating Action Button on XS breakpoint:
  &.floater {
    @media #{$xsMedia} {
      position: fixed;
      z-index: 99;
      top: calc(100% - 85px);
      left: calc(100% - 85px);

      // Using calc from top/left proved more reliable when content causes to overflow viewport:
      width: 70px;
      height: 70px;
      justify-content: center;
      border: none;
      border-radius: 100%;
      box-shadow: 0 0 10px 3px $contrast-light-blue2;
      line-height: 70px;
      text-align: center;

      svg {
        width: 26px;
        height: 26px;
      }

      span {
        @include a11y-hide();
      }
    }
  }

  .truncName {
    @media #{$xsMedia} {
      width: 92px;
    }
  }
}

// height and width overwritten by action-TYPES > svg
.loading {
  width: 18px !important;
  height: 18px !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

// Invert foreground color on hover for ghost buttons
// Typically in Page Header banners.

////
////Hover Colours
///
:global(.classroom-dark) .type-GHOST:hover {
  color: color(classroom-dark);

  svg {
    fill: color(classroom-dark);
  }
}

:global(.color-primary-dark) .type-GHOST:hover {
  color: color(primary-dark);

  svg {
    fill: color(primary-dark);
  }
}

:global(.color-profile-dark) .type-GHOST:hover {
  color: color(profile-dark);

  svg {
    fill: color(profile-dark);
  }
}

:global(.color-text) .type-GHOST:hover {
  color: color(text);

  svg {
    fill: color(text);
  }
}

body[data-theme="dark-theme"] {
  .button {
    &:focus {
      box-shadow: none;
    }

    &.type-GHOST {
      height: 40px;
      padding-right: 20px;
      padding-left: 20px;
      border: 0;
      background: transparent;
      border-radius: 50px;
      box-shadow: inset 0 0 0 1.5px $color-white;
      color: $color-white;
      cursor: pointer;
      font-size: 16px;
      outline: none;
      transition: background-color 300ms, box-shadow 300ms, color 300ms;

      &:focus,
      &:hover {
        background-color: $color-white;
        color: var(--text-neutral);
      }
    }
  }
}

body[data-theme="hub-theme"] {
  .button {
    &.type-GHOST_INVERTED {
      padding: 0 16px 0 8px;

      .leftSvg {
        + span {
          &::before {
            top: 9px;
            left: 8px;
          }
        }
      }
    }
  }
}
