@import '../../../../styles/constants/breakpoints';
@import '../../../../styles/constants/colors';

.homePageSectionOne {
  background-color: map-get($background-and-border-colors, background-light-blue);
  background-image: linear-gradient(to bottom, map-get($primary-colors, primary) 0%, map-get($hubColors, blue-color-dark) 100%);

  .bannerText {
    color: $color-white;
    text-align: center;
    padding: 0 29px;

    h1, p {
      max-width: 100%;
    }

    // This is the button
    a {
      height: 50px;
      font-size: 20px;
      padding: 10px 16px;
      margin-bottom: 2rem;

      background-clip: padding-box;
      border-color: transparent;
      border-bottom-color: $contrast-dark-accent-03;

      @media #{$mdMedia} {
        margin-bottom: 0;
      }

      &:hover {
        color: map-get($background-and-border-colors, background-light-blue);
        border-color: map-get($hubColors, dark-gray);
        }
    }

    @media #{$smMedia} {
      padding: 0 6rem;
    }

    @media #{$mdMedia} {
      text-align: left;
      padding: 0 30px;

      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
  }


  h1 {
    margin: 4.5rem 0 2rem;
    font-size: 2em;

    @media #{$smMedia} {
      margin-top: 6.25rem;
      font-size: 3em;
    }

    @media #{$mdMedia} {
      margin-top: 1.5rem;
    }
  }

  p {
    margin: 0 0 1.5rem;
    font-size: 21px;
    line-height: 1.4;
    opacity: 0.8;
  }
}

.imageContainer {
  position: relative;
  text-align: center;
  height: 350px;
  font-size: 0;

  img:nth-of-type(1) {
    z-index: 2;
    position: relative;
    height: 100%;
  }

  img:nth-of-type(2) {
    opacity: .5;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    max-width: 100%;
  }
}

@media #{$mdMedia} {
  .homePageSectionOne .imageContainer {
    height: auto;

    img:nth-of-type(1) {
      width: 100%;
      height: auto;
    }

    img:nth-of-type(2) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: auto;
    }
  }
}
