@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

/* stylelint-disable */
.resource {
  position: relative;
  max-width: none;
  box-sizing: border-box;
  border-radius: 0.3125rem;
  cursor: pointer;

  &::before {
    position: absolute;
    z-index: -1;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    border: 1px solid $color-light-grey;
    border-radius: 0.3125rem;
    content: "";
  }

  .svgIconContainerLocked,
  .svgIconContainer {
    display: flex;
    width: 1.875rem;
    height: 1.875rem;
    align-items: center;
    justify-content: center;
    padding: 0.375rem;
    margin-right: 0.75rem;
    background-color: $color-grey;
    border-radius: 50%;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: $color-white;
    }
  }

  .svgIconContainerLocked {
    svg {
      width: 1rem;
      height: 1rem;
      fill: $color-white;
    }
  }

  .svgIconExternalLink {
    width: 1.5rem;
    height: 1.5rem;
    fill: map-get($hubColors, blue-color-primary);
  }

  &:hover {
    &::before {
      border: 2px solid map-get($hubColors, blue-color-primary);
    }

    .svgIconContainer {
      background-color: map-get($hubColors, blue-color-primary);
    }
  }

  &.expiredLicence {
    padding: 0.06rem;
    border: 2px solid map-get($validation-colors, error);
    background-color: $color-button-background3;
    border-radius: 5px;

    &:hover {
      padding: 0;
      border: 3px solid map-get($validation-colors, error);

      .svgIconContainerLocked,
      .svgIconContainer {
        background-color: map-get($validation-colors, error);
      }
    }

    &:focus {
      @include resource-on-focus();

      .svgIconContainerLocked,
      .svgIconContainer {
        background-color: map-get($validation-colors, error);
      }
    }

    .svgIconContainerLocked,
    .svgIconContainer {
      padding: 0;
      background-color: map-get($hubColors, dark-gray);
    }
  }

  &.notStartedLicence {
    &:hover {
      &::before {
        border: 2px solid map-get($hubColors, blue-color-primary);
      }

      content: "";

      .svgIconContainer {
        background-color: map-get($hubColors, blue-color-primary);
      }
    }

    .svgIconContainer {
      padding: 0;
      background-color: map-get($hubColors, dark-gray);
    }
  }

  &:not(:first-child) {
    margin-top: 0.75rem;
  }

  .resourceItemContainer {
    display: flex;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    word-break: break-all;

    &:focus {
      @include resource-on-focus();

      .svgIconContainer {
        background-color: map-get($hubColors, blue-color-primary);
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    .buttonContainer {
      padding: 7px 0 7px 13px;

      button {
        min-height: 26px;
        padding: 2px;

        &:focus {
          border-radius: 3px;
          box-shadow: 0 0 0 1px $color-white, 0 0 0 3px map-get($hubColors, blue-color-primary), 0 0 0 4px $color-white;

          svg {
            fill: map-get($hubColors, blue-color-primary);
          }
        }
      }
    }

    .isDownloadingButton button:focus svg {
      fill: map-get($primary-colors, primary);
    }
  }

  .resourceDetailsContainer {
    min-height: 48px;
    flex: 1;
  }

  .isDownloadingButton button {
    span {
      margin-left: 0;
    }

    svg {
      width: 1.875rem;
      height: 1.875rem;
      margin-right: 0.75rem;

      path {
        fill: map-get($primary-colors, primary);
      }
    }
  }
}
