@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
/* stylelint-disable css-modules/no-global-scoped-selector */

[data-list="product"] {
  overflow: visible;
  width: 100%;
  margin-bottom: 0.5rem;

  .product {
    display: flex;
    width: 100%;
    min-width: 13.75rem;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 0.75rem;
    border-radius: 0;
    box-shadow: 0 0 0 0.063rem $color-black-rgba;
    cursor: pointer;
    @media (max-width: $screen-width-840) {
      gap: 1rem;
    }

    .dropDownMenu {
      button {
        box-shadow: none;
      }
    }

    .leftContainer,
    .leftContainerWide {
      position: relative;
      display: flex;
      width: 50%;
      height: 100%;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: $screen-width-600) {
        width: 100%;
      }
      .leftContainerContent {
        display: flex;
        align-items: center;
      }
    }

    .leftContainerWide {
      width: 100%;
    }

    .statusUsedLicencesContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5%;
      span {
        font-weight: bold;
      }
    }

    .textContainer {
      display: flex;
      width: calc(100% - 4.375rem);
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 2rem 0 0.75rem;

      .innerTextContainer {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        padding-top: 0.375rem;
        font-size: 1rem;
      }

      .activeLinkText {
        color: map-get($hubColors, hub_link);

        svg {
          fill: map-get($hubColors, blue-color-primary);
        }
      }

      svg {
        width: 0.7rem;
        height: 0.7rem;
        box-sizing: content-box;
        margin-bottom: -0.1rem;
        fill: $color-grey;
      }

      .pendingStateInfo {
        font-weight: $font-weight-600;
        font-size: $font-size-small;

        svg {
          width: $icon-size-small;
          height: $icon-size-small;
        }

        span {
          margin-left: 0.313rem;
        }
      }

      .addResourcesFlag {
        width: fit-content;
        padding: 0 0.5rem;
        margin-top: 0.5rem;
        background-color: map-get($secondary-colors, hub_dashboard);
        border-radius: 1.125rem;
        color: $color-white;
        font-size: 0.8125rem;
        font-weight: 400;
      }

      .loading {
        width: 0.9125rem;
        height: 1.3125rem;
        margin-left: 0.3rem;
        fill: map-get($hubColors, blue-color-primary);
      }
    }

    &:hover,
    &:focus {
      background-color: map-get($secondary-colors, hub_light_grey);
      border-radius: 0.313rem;
    }

    &:focus {
      @include tile-on-focus();

      svg {
        fill: map-get($hubColors, blue-color-primary);
      }

      img {
        filter: brightness(70%);
      }
    }

    .statusLicenceContainer {
      display: flex;
      min-width: 25%;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: $screen-width-600) {
        min-width: 30%;
      }
      @media (max-width: $screen-width-840) {
        min-width: 35%;
      }
      .manageLicenceStatusItem,
      .licenceContextContainer,
      .licenceContextContainerOpacified,
      .removedLicenceContainer {
        display: flex;
        align-items: center;
      }

      .licenceContextContainerOpacified {
        svg {
          opacity: 0.5;
        }
      }

      .textContainerLicenceContext span {
        display: block;
        font-size: 1rem;
        font-weight: 500;
      }

      .textContainerLicenceContext span:first-child {
        color: map-get($typography-colors, sub-text);
        font-size: 0.85rem;
      }

      .svgContainer {
        svg {
          width: 1.6rem;
          margin-right: 0.75rem;
        }
      }

      @media (max-width: $screen-width-480) {
        width: initial;
      }
    }

    .manageStatusTextContainer {
      width: 12rem;
      padding: 0 2rem 0 0.3rem;

      @media (max-width: $screen-width-600) {
        width: 4rem;
        padding-right: 0.2rem;
      }
    }

    .textContainerRemovedLicence {
      display: flex;
      flex-direction: column;

      span:first-child {
        color: map-get($typography-colors, sub-text);
        font-size: 0.85rem;
      }

      span:nth-child(2) {
        color: map-get($hubColors, blue-color-dark);
      }
    }

    &.noLicence:hover,
    &.expired:hover {
      box-shadow: 0 0 0 0.063rem map-get($hubColors, hub_expired_licence);
    }

    &.onboardingWizardLicence:hover {
      box-shadow: 0 0 0 0.063rem map-get($hubColors, hub_light_grey);
    }

    .imgContainer {
      position: relative;
      display: flex;
      overflow: hidden;
      width: 3.75rem;
      align-items: center;

      img {
        position: relative;
        min-width: 3.75rem;
        max-width: 3.75rem;
      }
      &.hasLinkedProducts {
        &.imgRemovedLicence {
          &::before {
            opacity: 0.4;
          }

          &::after {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: $color-white;
            border-radius: 0.375rem;
            content: '';
          }

          img {
            z-index: 1;
            opacity: 0.4;
          }
        }
      }
      &.nonOptProductCover {
        img {
          border-width: 0.063rem;
          border-style: solid;
          border-color: map-get($hubColors, gray);
          border-radius: 0.313rem;
        }
      }

      .lockedImage {
        filter: brightness(70%);
      }

      .lockIconContainer {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          height: 2.5rem;
          fill: $color-white;
        }
      }
    }

    .dropdownContainer {
      position: relative;
      display: flex;
      width: 5%;
      align-items: center;
      margin-right: 1%;
      margin-left: 4%;
    }
  }

  .labelsContainer {
    position: relative;
    display: flex;
    height: 40%;

    .labelPill {
      overflow: hidden;
      height: 1.25rem;
      padding: 0 0.5rem;
      margin: 0 0.313rem 0 0;
      border-radius: 0.625rem;
      font-size: 0.8125rem;
      line-height: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .newContentlabelPill{
      overflow: hidden;
      height: 1.25rem;
      padding: 0 0.5rem;
      margin: 0 0.313rem 0 0;
      border-radius: 0.625rem;
      font-size: 0.8125rem;
      line-height: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: map-get($hubColors, hub_summer_yellow);
      span{
        color: map-get($primary-colors, primary);
      }
    }

    .linkedProductsIcon {
      display: inline-block;
      color: map-get($secondary-colors, hub_dashboard);
      font-size: 0.8125rem;
      font-weight: 600;
      line-height: 1.2rem;

      svg {
        height: 0.875rem;
        margin-right: 0.225rem;
        margin-bottom: -0.15rem;
        fill: map-get($secondary-colors, hub_dashboard);
      }
    }

    .labelPillDown {
      overflow: hidden;
      height: 1.25rem;
      padding: 0 0.5rem;
      margin: 0 0.313rem 0 0;
      border-radius: 0.625rem;
      font-size: 0.8125rem;
      line-height: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #333333;

      svg {
        transform: rotate(90deg);
        fill: $color-white;
      }
    }
  }

  .labelsCreditsContainer {
    position: relative;
    display: flex;
    height: 30%;

    .labelPill {
      overflow: hidden;
      height: 1.25rem;
      padding: 0 0.5rem;
      margin: 0 0.313rem 0 0;
      border-radius: 0.625rem;
      font-size: 0.8125rem;
      line-height: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .newContentlabelPill{
      overflow: hidden;
      height: 1.25rem;
      padding: 0 0.5rem;
      margin: 0 0.313rem 0 0;
      border-radius: 0.625rem;
      font-size: 0.8125rem;
      line-height: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: map-get($hubColors, hub_summer_yellow);
      span{
        color: map-get($primary-colors, primary);
      }
    }

    .linkedProductsIcon {
      display: inline-block;
      color: map-get($secondary-colors, hub_dashboard);
      font-size: 0.8125rem;
      font-weight: 600;
      line-height: 1.2rem;

      svg {
        height: 0.875rem;
        margin-right: 0.225rem;
        margin-bottom: -0.15rem;
        fill: map-get($secondary-colors, hub_dashboard);
      }
    }

    .labelPillDown {
      overflow: hidden;
      height: 1.25rem;
      padding: 0 0.5rem;
      margin: 0 0.313rem 0 0;
      border-radius: 0.625rem;
      font-size: 0.8125rem;
      line-height: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #333333;

      svg {
        transform: rotate(90deg);
        fill: $color-white;
      }
    }

    @media (max-width: $screen-width-600) {
      display: none;
    }
  }

  .comingSoonLabel {
    height: 1.25rem;
    padding: 0 0.438rem;
    margin: 0 0.313rem 0 0;
    background-color: map-get($primary-colors, primary);
    border-radius: 0.625rem;
    font-size: 0.8125rem;
    line-height: 1.2rem;
  }

  .topLevel {
    background-color: map-get($secondary-colors, hub_light_grey);
    border-radius: 0;
  }

  &.collapsed {
    .topLevel:not(:hover) {
      background-color: white;
    }

    .linkedProductsContainer {
      border: 0 none;

      &::after {
        height: 0;
        max-height: 3.125rem;
        transition: height 0.35s linear;
      }

      .innerLinkedContainer {
        max-height: 0;
        margin-bottom: -125rem;
        transform: translateY(-100%);
        transition:
          margin-bottom 0.25s cubic-bezier(1, 0, 1, 1),
          visibility 0s 0.25s,
          max-height 0s 0.25s,
          transform 0.25s ease;
        visibility: hidden;
      }
    }

    .creditsBackground {
      border-radius: 0.313rem;
      background-color: map-get($core, hover) !important;
    }
  }

  @media (max-width: $screen-width-480) {
    &:nth-child(n+3) {
      padding-top: 0.5rem;
      border-top-width: 0.063rem;
      border-top-style: solid;
      border-top-color: map-get($hubColors, gray);
    }

    .product {
      display: flex;
      max-width: none;
      align-items: center;
      padding: 0.75rem 1rem;

      &:hover {
        border-color: $color-light-grey;
        box-shadow: none;

        svg {
          fill: $color-light-grey;
        }

        img {
          filter: brightness(100%);
        }
      }

      .svgContainer {
        width: 1.563rem;
      }

      .imgContainer {
        width: 3.75rem;
        padding: 0;

        img {
          position: unset;
          width: 100%;
          filter: drop-shadow(0 0 0.188rem $color-black2);
          object-fit: contain;
          object-position: 50% 0;
          transform: rotate(0);
        }

        div {
          border-radius: 0.313rem;
          filter: drop-shadow(0 0 0.188rem $color-black2);
        }
      }

      .textContainer {
        display: flex;
        height: unset;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 0 0 0.5rem;
      }

      .licenceInfoContainerLink {
        div { color: map-get($hubColors, hub_link); }
      }

      .innerTextContainer {
        display: flex;
      }

      .dropdownContainer {
        margin: 0 1.25rem 0 0;
      }

      .statusLicenceContainer {
        min-width: unset;
      }

      .statusLicenceContainer,
      .statusUsedLicencesContainer {
        display: none;
      }
    }
  }
}

.assessmentContainer {
  display: grid;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-border-blue2;
  }

  .assessment {
    display: grid;
    overflow: hidden;
    width: 100%;
    min-width: 13.75rem;
    max-width: 18.75rem;
    box-sizing: border-box;
    border: 0.063rem solid $color-light-grey;
    margin-bottom: 0.063rem;
    border-radius: 0.313rem;
    box-shadow: 0 0 0 0.063rem $color-black-rgba;
    cursor: pointer;
    grid-template-rows: auto 1fr;

    &.expired {
      border: solid 0.063rem map-get($hubColors, hub_expired_licence);
      background-color: $color-very-light-grey;

      img {
        filter: brightness(70%);
      }
    }

    .imgContainer {
      position: relative;
      display: inherit;
      overflow: hidden;
      height: 7.5rem;
      box-shadow: 0 0 0.313rem 0 $contrast-light-accent-50;

      .imgOverlay {
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 7.5rem;
        background: $contrast-light-accent-50;
      }

      img {
        position: absolute;
        top: 50%;
        width: 100%;
        height: auto;
        padding: 0 1.875rem;
        margin: auto;
        transform: translateY(-50%);
      }

      .lockIconContainer {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          z-index: 3;
          height: 4rem;
          fill: $color-white;
        }
      }
    }

    .textContainer {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.75rem;

      span {
        position: relative;
        font-weight: 600;
      }

      div {
        justify-content: space-between;
      }
    }

    .statusLicenceContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 0.75rem;

      .manageLicenceStatusItem,
      .licenceContextContainer,
      .licenceContextContainerOpacified {
        display: flex;
        align-items: center;
      }

      .licenceContextContainerOpacified {
        svg {
          opacity: 0.5;
        }
      }

      .textContainerLicenceContext span {
        display: block;
        font-size: 1rem;
        font-weight: 500;
      }

      .textContainerLicenceContext span:first-child {
        color: map-get($typography-colors, sub-text);
        font-size: 0.85rem;
      }

      .svgContainer {
        svg {
          width: 1.6rem;
          margin-right: 0.75rem;
        }
      }

      @media (max-width: $screen-width-480) {
        width: initial;
      }
    }

    &:hover {
      border: solid 0.063rem map-get($hubColors, blue-color-primary);
      box-shadow: 0 0 0 0.063rem map-get($hubColors, blue-color-primary);

      .img {
        filter: brightness(70%);
      }

      svg {
        fill: map-get($hubColors, blue-color-primary);
      }
    }

    &.expired:hover {
      box-shadow: 0 0 0 0.063rem map-get($hubColors, hub_expired_licence);
    }

    &:focus {
      @include tile-on-focus();

      .imgContainer {
        filter: brightness(70%);
      }
    }
  }

  @media (max-width: $screen-width-480) {
    .assessment {
      max-width: none;
      grid-gap: 0.5rem;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;

      &:hover {
        border-color: $color-light-grey;
        box-shadow: none;
      }

      &:hover img {
        filter: brightness(100%);
      }

      .imgContainer {
        width: 6.25rem;
        height: 6.25rem;
        border: 0.063rem solid $contrast-light-accent-50;
        margin: 0.5rem;
        border-radius: 100%;

        img {
          width: 100%;
          height: 100%;
          padding: unset;
          border-radius: 0.313rem;
          object-fit: fill;
          object-position: 50% 0;
        }
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.75rem 1rem 0.75rem 0;

      }
    }
  }
}

// Linked products
.linkedProductsContainer {
  display: block;
  overflow: hidden;
  width: 100%;

  &::after {
    height: 3.125rem;
    max-height: 0;
    content: '';
    transition: height 0.35s linear, max-height 0s 0.35s linear;
  }

  .innerLinkedContainer {
    position: relative;
    overflow: auto;
    max-height: 26.9rem; /* height of 5 products */
    padding: 0.1rem 0.3rem;
    border: 0.125rem solid map-get($secondary-colors, hub_light_grey);
    border-top-color: map-get($cptColors, grey);
    margin: -0.02rem 0.75rem 0;
    box-shadow: 0 0.125rem 0.25rem $color-black5 inset;
    transform: translateY(0%);
    transition: margin-bottom 0.35s cubic-bezier(0, 0, 0, 1), transform 0.4s ease;
  }

  .licenceInfoContainer {
    div {
      padding-left: 1rem;
      color: map-get($typography-colors, sub-text);
      font-size: 1rem;
    }

    .licenceTitle {
      svg {
        width: 1.175rem;
        height: 1.175rem;
        box-sizing: content-box;
        border: none;
        margin-bottom: -0.3rem;
        margin-left: 0.1rem;
        fill: map-get($hubColors, blue-color-primary);
      }
    }
  }

  .licenceInfoContainerLink {
    div { color: map-get($hubColors, hub_link); }
  }

  .linkedProductItem {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    margin: 0.1875rem 0 0 0;
    border-radius: 0.3125rem;

    &.active {
      background-color: map-get($secondary-colors, hub_light_grey);
    }

    &:hover {
      background-color: map-get($secondary-colors, hub_light_grey);
    }

    .leftContainer {
      width: 100%;
    }
  }
}

.imgRemovedLicence img {
  opacity: 0.4;
}

.creditsPill {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  color: $color-white;

  .used {
    width: 5rem;
    height: 2.7rem;
    background-color: #31A965;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .available {
    width: 5rem;
    height: 2.7rem;
    background-color: #4575B0;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.packContainer {
  border-bottom-right-radius: 0.313rem;
  border-bottom-left-radius: 0.313rem;
  border-left: 0.0625rem solid map-get($core, hover);
  border-right: 0.0625rem solid map-get($core, hover);
  border-bottom: 0.0625rem solid map-get($core, hover);
  margin-left: 1.3rem;
  margin-right: 1.3rem;
  box-shadow: 0 0.7rem 0.625rem -0.5rem $color-black5 inset;
}

.packChild {
  display: flex;
  align-items: center;
  padding: 1.3rem;

  img {
    position: relative;
    min-width: 3.75rem;
    max-width: 3.75rem;
  }

  .labelsCreditsContainer {
    display: flex;
    flex-direction: column;
    margin-left: 0.8rem;
    gap: 0.5rem;

    div {
      max-width: 7.5rem;
      background-color: #333333;
    }
  }
}

.creditsTitle {
  color: #333333;
  font-weight: 400;
}

.availableCount {
  display: inline-block;

  @media (max-width: $screen-width-480) {
    display: none;
  }
}
