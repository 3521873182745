@import "../../styles/constants/breakpoints.scss";

.imageDialsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: center;
  margin-bottom: 30px;

  @media #{$smMedia} {
    flex-direction: row;
    justify-content: space-around;
  }
}

.imageDialsContainerTextMode {
  display: none;

  @media #{$smMedia} {
    display: flex;
  }

  @media print {
    page-break-inside: avoid;
  }
}

.textDialContainer {
  display: block;

  @media #{$smMedia} {
    display: none;
  }
}

.dialCard {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  @media #{$smMedia} {
    width: 31%;
    margin-right: 5px;
    margin-left: 5px;
  }

  @media print {
    canvas {
      width: 220px !important;
      height: auto !important;
    }
  }
}

.dialSeat {
  width: 73%;
  max-width: 300px;
  margin-bottom: 30px;
}

.textCenter {
  max-width: 100%; /* IE11 Flexbox bug */
  text-align: center;
}

.loadingContainer {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.svgFormat {
  width: 70px;
  height: 70px;
}

.textDialRow {
  display: flex;
  margin: 15px 0;
}

.textDialRowName {
  width: 200px;
}

.textDialRowValue {
  font-weight: bold;
}
