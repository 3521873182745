@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

.container {
  position: relative;
  overflow: hidden;
  height: 7.5rem;

  .imgContainer {
    height: 100%;

    svg {
      height: 100%;
    }

    @media #{$phoneMedia} {
      width: 100%;
      height: 100%;
      align-items: center;
      border: 1px solid $contrast-light-accent-50;
      border-radius: 100%;
    }
  }
}
