@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.studentAnswerView {
  .backBtn {
    span {
      width: auto;
    }
  }

  .backBtnContainer {
    justify-content: center;
  }

  .stdListContainer {
    display: flex;
    align-items: center;
  }

  .className {
    display: flex;
    padding-right: 15px;

    h3 {
      display: inline-block;
      overflow: hidden;
      max-width: 200px;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .stdDropDown {
    display: flex;

    >div {
      padding-right: 15px;
    }
  }

  .tooglefilter {
    display: flex;

    label {
      margin: 0;
    }
  }

  .backBtnContainer .className {
    display: none;
  }

  @media #{$xsMedia} {
    display: flex;
    width: 100%;
    flex-direction: column;

    .backBtn span,
    .className {
      display: none;
    }

    .stdListContainer {
      justify-content: space-between;
    }

    .backBtnContainer .className {
      display: inline-block;

      h3 {
        max-width: 120px;
        padding-top: 5px;
        font-size: 16px;
      }
    }

    .backBtnContainer {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.studentAnswerViewLTI {
  .ltiIframe {
    position: fixed;
    top: 115px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-white;
  }
}

body[data-theme="hub-theme"] {
  .studentAnswerViewLTI {
    .ltiIframe {
      top: 135px;

      iframe {
        height: calc(100vh - 10rem);
      }

      @media only screen and (min-width: #{$smMdBreakpoint}) {
        left: 4.275rem;

        iframe {
          width: calc(100vw - 4.275rem);
        }
      }

      @media only screen and (min-width: #{$lgBreakpoint}) {
        left: 17.9rem;

        iframe {
          width: calc(100vw - 17.9rem);
        }
      }

      @media only screen and (max-width: #{$smMdBreakpoint}) {
        left: 0;

        iframe {
          width: 100vw;
        }
      }
    }
  }
}
