.contentWithButton {
  // Align the Button next to the middle of the children
  display: flex;
  min-height: 5rem;
  align-items: center;

  .children {
    flex-grow: 1;
    margin-right: 0.5rem;
  }
}
