@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

.headerPopup {
  &:active {
    background-color: white;
  }
}

div.accountWidget {
  margin-top: 0;
  right: 1.25rem;
  white-space: nowrap;
  line-height: 1rem;
  min-width: 200px;
  background-color: white;

  @media #{$smMedia} {
    right: 0;
  }

  // Center the popup arrow on the button
  &:before,
  &:after {
    right: 4px;
  }

  .myDetails,
  .myOrganizations {
    border-top: 1px solid $color-borders;

    h3 {
      flex-grow: 1;
      display: flex;
      padding: 10px 15px;
      font-size: 0.875rem;
      margin: 0;
    }
  }

  .myDetails > div {
    padding: 10px 15px;
  }

  .userDetailsThumbnail {
    > div {
      background-color: map-get($styleGuideColors, primary-dark);
    }

    + div {
      overflow-x: hidden;
    }
  }

  .myAccount {
    h2 {
      color: $color-text;
      font-weight: 600;
      font-size: 1.25em;
      margin: 0;
      padding: 10px 15px;
      padding-bottom: 5px;
    }

    p {
      margin: 0;
      padding: 10px 15px;
    }
  }

  .myOrgLink,
  .itemLink {
    display: flex;
    height: 58px;
    padding: 10px 15px;
    align-items: center;
    text-decoration: none;
    color: $color-primary-blue;
  }

  .myOrgLink {
    outline-offset: -5px;
  }

  .itemLink {
    border-top: 1px solid $color-borders;
    height: 40px;
    background-color: white;

    &:first-of-type {
      border-top-color: transparent;
    }
  }

  .myOrgLink:hover,
  .itemLink:hover {
    background-color: map-get($styleGuideColors, selected);
  }

  .smallSubText {
    font-size: 13px;
    color: $color-sub-text;
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown {
    background-color: $color-very-light-grey;
    margin-bottom: 1rem;
    padding: 1rem;
    margin: 5px 15px 10px 15px;

    label {
      font-size: 0.875rem;
      margin: 0;
      margin-bottom: .5rem;
    }
  }
}

@media #{$smMedia} {
  div.accountWidget {
    width: auto;

    &:before,
    &:after {
      right: 24px;
    }
  }
}
