.content {
  padding: 16px;
  text-align: center;
}

.aligntext {
  padding-left: 20% !important;

  li {
    display: list-item !important;
    text-align: left;
  }
}
