@import "../../styles/mixins/hub/hub-button-focus";

.PopoutNavFooter {
  display: flex;
  align-items: center;
  padding: 1rem;

  // When button is alone or next to, say, a Back link, then position it on the right:
  // Note: In Flexbox, margin auto has an effect equivalent to the poorly supported justify-self.
  button {
    &:focus {
      @include no-padding-button-on-focus();

      outline: none;
    }

    &:only-child {
      margin-left: auto;
    }

    &:last-child {
      &:not(:only-child) {
        margin-left: auto;
      }
    }

    &:only-child.backButton {
      margin-left: 0;
    }
  }

  a:focus {
    @include no-padding-button-on-focus();

    outline: none;
  }
}
