@import '../../styles/constants/colors.scss';
@import '../../styles/constants/breakpoints.scss';

$blockWidth: 15px;
$blockGap: 3px;

.resultsBar {
  display: flex;
  align-items: center;
}

.label {
  width: 75px;
  margin-right: 10px;
}

.list {
  display: none;
  height: $blockWidth;
  margin-top: 2px;
  text-align: left;
}

.block {
  display: inline-block;
  max-width: $blockWidth;
  height: 100%;
  margin-right: $blockGap;

  &Half {
    height: 100%;
    background: linear-gradient(90deg, map-get($validation-colors, correct) 50%, map-get($secondary-colors, no_progress_grey) 50%);
  }

  &Correct {
    background-color: map-get($validation-colors, correct);
  }

  &Incorrect {
    background-color: map-get($secondary-colors, no_progress_grey);
  }

  &:first-child {
    border-radius: 50% 0% 0% 50%;
  }

  &:last-child {
    border-radius: 0% 50% 50% 0%;
  }
}

@media #{$smMedia} {
  .list {
    display: block;
    width: 65%;
    padding-right: 15px;
  }
}
