@import '../../styles/constants/colors.scss';
@import "../../styles/constants/breakpoints.scss";

.carouselControls {
  display: flex;
  align-items: center;

  // Arrow buttons size
  svg {
    width: 12px;
    height: 12px;
  }

  // The center element should grow to push the buttons to the side
  > div {
    flex-grow: 1;
    text-align: center;
  }

  > button {
    padding: 9px 13px;
  }

  // List of buttons in a row
  ol {
    display: flex;
    justify-content: center;
  }

  li {
    width: 15px;
    height: 15px;
    margin: 0 8px;

    // Background color must be set on this element so that the child can overlay it
    background-color: $color-sub-text-50;

    // Default state is a 16px circle
    border-radius: 50%;

    // Animate the size change
    transition: transform 0.2s ease;
  }

  // Dot buttons
  li button {
    display: block; // CES-1547
    width: 100%;
    height: 100%;
    // Fill parent size
    border-radius: 50%;

    // Fade background color in
    transition: all 0.2s ease;
  }

  // Styles for the text variant, show when there are more than 6 items
  p {
    color: $color-sub-text-50;
    font-weight: 600;
  }

  em {
    color: $color-sub-text;
  }
}

body[data-theme="hub-theme"] {
  .carouselControls li {
    button {
      outline: 1px solid map-get($typography-colors, sub-text);
    }

    .selected button {
      outline: 1px solid map-get($primary-colors, primary);
    }
  }

  .carouselControls button:focus {
    box-shadow: 0 0 0 1px $color-white, inset 0 0 0 2px map-get($primary-colors, primary), inset 0 0 0 3px $color-white;
  }
}
