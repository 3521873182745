@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "../../styles/constants/breakpoints";

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.warningModal {
    max-width: 34rem;
  }

  &.myProgressEmptyState {
    padding-right: 2rem;
    padding-left: 2rem;

    h1 {
      font-size: 2rem;
    }

    p {
      color: map-get($typography-colors, sub-text);
    }

    svg {
      fill: map-get($hubColors, hub_very_light_blue);
    }

    @media #{$xsMedia} {
      padding-right: 1rem;
      padding-left: 1rem;

      h1 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  &.displayMobileButtons {
    @media #{$smMedia} {
      button {
        display: none;
      }
    }
  }

  &.resourceModalEmptyState {
    color: $color-white;
  }

  a:focus {
    @include standart-focus-button();
  }

  h1 {
    margin: 1rem;
    line-height: 1.2;
  }

  &.userTilesEmptyState,
  &.invitedUserTileEmptyState {
    color: map-get($typography-colors, sub-text);

    h1 {
      color: map-get($typography-colors, text);
    }
  }

  > div:first-child {
    margin: 1.5rem 0;

    svg {
      padding: 1rem;
    }
  }

  p {
    display: inline;
    font-size: 1.3rem;
    font-weight: 400;
  }

  .links {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-right: 1.5rem;
    padding-left: 20px;
    color: map-get($background-and-border-colors, background-light-blue);
    font-size: 16px;
    text-decoration: underline;
  }

  .linksGlyph {
    width: 1.3rem;
    height: 1.3rem;
    fill: map-get($background-and-border-colors, background-light-blue);
  }

  .links,
  .linksGlyph {
    &:hover {
      color: map-get($hubColors, blue-color-dark);
      text-decoration: underline;
    }
  }

  .redeemContainer {
    display: flex;
    align-items: center;

    .buttonContainer {
      position: relative;

      &:nth-of-type(2) {
        margin-left: 2px;
      }

      &:hover svg {
        fill: map-get($primary-dark-colors, primary-dark);
      }
    }

    .iconContainer {
      position: absolute;
      top: 45%;
      right: -0.425rem;
      width: 1.2rem;
      height: 1.2rem;
      box-sizing: content-box;
      margin-left: 0.375rem;

      svg {
        fill: map-get($primary-colors, primary);
      }
    }

    a:focus {
      border: none;
      box-shadow: none;
      outline: none;
      transform: none;
    }

    button,
    a:link,
    a:visited {
      margin-top: 1rem;
      box-shadow: none;
      color: map-get($primary-colors, primary);

      &:hover,
      &:focus {
        color: map-get($primary-dark-colors, primary-dark);
      }

      &:focus {
        @include standart-focus-button();
      }

      &:active {
        border: none;
        box-shadow: none;
        outline: none;
        transform: none;
      }
    }

    .primaryButtonContainer {
      button,
      a {
        color: $color-white;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $color-white;
        }
      }
    }
  }

  &.myDashboardEmptyState {
    .redeemContainer {
      flex-direction: column;
    }
  }

  &.invitedUserTileEmptyState {
    > div:first-child svg {
      padding: 0;
      fill: map-get($hubColors, blue-color-primary);
    }
  }

  &.classPageFilteredEmptyState {
    color: map-get($typography-colors, sub-text);

    h1 {
      color: map-get($typography-colors, text);
    }

    > div:first-child {
      svg {
        padding: 0;
        fill: map-get($hubColors, blue-color-primary);
      }
    }
  }

  &.toolsPageFilteredEmptyState {
    color: map-get($typography-colors, sub-text);

    h1 {
      color: map-get($typography-colors, text);
    }

    > div:first-child {
      svg {
        padding: 0;
        fill: white;
      }

      padding: 10px;
      fill: map-get($hubColors, blue-color-primary);
    }
  }

  &.userSelectionEmptyState {
    padding-right: 2rem;
    padding-left: 2rem;

    h1 {
      font-size: 2rem;
    }

    p {
      color: map-get($typography-colors, sub-text);
    }

    svg {
      fill: map-get($hubColors, hub_very_light_blue);
    }

    > div:first-child {
      margin: 3rem 0 0 0;

      svg {
        padding: 1rem;
      }
    }

    @media #{$xsMedia} {
      padding-right: 1rem;
      padding-left: 1rem;

      h1 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  &.setTestEmptyState {
    padding: 1rem;
  }
}
