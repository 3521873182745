@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.title {
  font-size: 25px;
  text-align: center;
  margin-right: 0px;

  @media #{$smMedia} {
    margin-right: 15px;
    font-size: 35px;
  }

  @media print {
    font-size: 25px;
  }
}

.titleRow {
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;

  @media #{$smMedia} {
    flex-direction: row;
  }
}

.statusIcon {
  width: 30px;
  height: 30px;
}

.blockContainer {
  border: 1px solid map-get($background-and-border-colors, border);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;

  @media #{$smMedia} {
    flex-direction: row;
  }

  @media print {
    border-color: $color-black;
  }
}

.leftColumn {
  margin-bottom: 15px;

  @media #{$smMedia} {
    margin-bottom: 0px;
  }
}
