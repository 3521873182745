@import "../../styles/constants/colors";

.loadingSpinnerContainer {
  display: flex;
  height: var(--vh100, 100vh);
  min-height: var(--vh100, 100vh);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
