/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

:global {
  .flex {
    flex: 1;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-around {
    justify-content: space-around;
  }

  .justify-content-start {
    justify-content: flex-start
  }

  .align-items-center {
    align-items: center;
  }

  .flex-1\/3 {
    flex: 0.333;
  }

  .flex-1\/4 {
    flex: 0.25;
  }

  .flex-1\/2,
  .flex-2\/4 {
    flex: 0.5;
  }

  .flex-3\/4 {
    flex: 0.75;
  }
}
