@import '../../../../styles/constants/variables';

.subheading {
  color: $soft-black;
  font-size: 20px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.button {
  margin-bottom: 20px;
}

.blueButton {
  margin-bottom: 20px;
  color: #4776ae;
}

.uploadInputCardContainer {
  width: 100%;
  margin-bottom: 15px;
}
