@import '../../styles/constants/colors.scss';

.loadingIndicator {
  div {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $color-primary-blue;
    margin: 0 2px;
    display: inline-block;
    animation: loadingIndicatorDot 1.4s infinite ease-in-out both;

    // Prior to animation have all scaled 0
    transform: scale(0);

    &:nth-of-type(2) {
      animation-delay: 0.16s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.32s;
    }
  }
}

@keyframes loadingIndicatorDot {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
