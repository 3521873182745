.classCodeInput {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;

  .customiseToggle {
    margin-top: 0.5em;
    margin-right: 1rem;
    button {
      height: 2rem;
      width: 3.2rem;
      padding: 3px;
      span {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
