@import "../../styles/constants/colors";

.content {
  padding: 16px;
  text-align: center;
}

.email {
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background-color: map-get($legacy-color-names, default);
  border-radius: 4px;
}
