@import '../../styles/constants/colors.scss';

.tabs {
  & > ul {
    display: block;

    @media print {
      display: none;
    }

    border-bottom: 1px solid map-get($background-and-border-colors, border);

    &::after {
      content: "";
      clear: both;
      display: table;
    }

    & > li {
      float: left;
      position: relative;
      display: block;

      &.active {
        a {
          font-weight: 600;
          color: map-get($primary-dark-colors, primary-dark);
          background-color: transparent;
          border-bottom-color: map-get($primary-dark-colors, primary-dark);
        }
      }

      a {
        padding: 14px 20px 10px 20px;
        border-radius: 0px;
        border-bottom: 5px solid transparent;
        position: relative;
        display: block;

        &:hover, &:focus {
          border-bottom-color: map-get($background-and-border-colors, background-light-blue);
          color: map-get($primary-dark-colors, primary-dark);
          text-decoration: none;
        }
      }
    }
  }

  .panel {
    margin-top: 10px;
  }
}

