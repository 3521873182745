@import "../../styles/constants/breakpoints";

.solidColorBackgroundContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.solidColorBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.normalImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

@media #{$smTabletMedia} {
  .solidColorBackgroundContainer {
    position: absolute;
    height: calc(100vh - 60px);
  }
}

@media #{$xsMedia} {
  .solidColorBackgroundContainer {
    height: calc(100svh - 60px);
  }
}
