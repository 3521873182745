@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/breakpoints";

.middleSection {
  padding: 25px 0;
}

.carouselImage {
  display: flex;
  flex-direction: column;

  max-width: 550px;
  min-height: 1px;
  align-self: center;
  padding: 25px 0 0;

  @media #{$mdMedia} {
    padding: 50px 4rem 50px 0;
    max-width: unset;
  }
}

.carouselMonitor {
  border: 1rem solid $color-black;
  border-radius: 15px 15px 0 0;
  position: relative;
  overflow: hidden;

  @media #{$smMedia} {
    border-width: 1.5rem;
  }

  img {
    width: 100%;
  }
}

.screenBorder {
  width: 100%;
  min-height: 1px;
}

.carouselCol {
  justify-content: center;
}

.carouselText {
  text-align: center;
  height: 250px;
  overflow: hidden;

  @media #{$smMedia} {
    height: 180px;
  }
  @media #{$mdMedia} {
    text-align: left;
    height: 250px;
  }

  h2 {
    margin: 0 0 12px;
    font-size: 35px;
  }

  p {
    font-size: 21px;
    color: map-get($hubColors, dark-gray);
    line-height: 1.4;
  }
}

.carousel {
  padding: 22px 0;

  @media #{$smMedia} {
    padding: 22px 6rem;
  }

  @media #{$mdMedia} {
    padding: 0;
  }
}

.middleSection .carousel li {
  transform: scale(1);
}

.imageSlider {
  display: flex;
}

.transitioning {
  transition: margin-left 0.6s ease-in-out;
}

body[data-theme="hub-theme"] {
  .middleSection {
    .hubGrid {
      margin-left: auto;
      margin-right: auto;
      max-width: 78rem;
      padding: 0 2rem;

      @media #{$xsMedia} {
        padding: 0 1rem;
      }
    }
  }
}
