.confirmationWindow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.confirmationContainer {
  display: flex;
  width: 300px;
  flex-direction: column;
  background-color: #fff;
}

.confirmationButtonsContainer {
  display: flex;
  width: 300px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
