@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";

.outerModal {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in background-color;
  visibility: hidden;

  &.open {
    z-index: 5000;
    background-color: $contrast-light-hover;
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
    visibility: initial;
  }

  .innerModal {
    padding: 1rem 0;
    background-color: $color-white;
    border-radius: 0.3125rem;
    text-align: center;

    .modalButtonHeader {
      padding: 0 1.5rem 0 0;
      text-align: right;

      button:focus {
        @include standart-focus-button();
      }
    }

    .modalContent {
      padding: 0 1.5rem 1.5rem 1.5rem;

      svg {
        height: 100%;
        box-sizing: content-box;
        padding: 0;
      }
    }

    .modalButtonBottom {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 1.5rem 0 0;
      border-top: 1px solid map-get($secondary-colors, hub_light_grey);

      .modalLink {
        display: flex;
        min-width: 40px;
        max-width: 100%;
        min-height: 40px;
        align-items: center;
        padding: 0.5625rem 0.9375rem;
        border: 1px solid transparent;
        border-width: 1px 1px 3px 1px;
        background-color: map-get($primary-colors, primary);
        border-radius: 0.25rem;
        color: $color-white;

        &:focus {
          @include no-padding-button-on-focus();

          border-color: map-get($primary-colors, primary);
          outline: none;
        }

        &:hover {
          border-bottom-color: $color-astronaut-blue;
          background-color: $color-astronaut-blue;
          text-decoration: none;
        }

        svg {
          width: 1rem;
          height: 1rem;
          margin-left: 0.3rem;
          fill: $color-white;
        }
      }
    }
  }
}
