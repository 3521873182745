@import "../../styles/constants/colors";

.linkWithIcon {
  color: map-get($background-and-border-colors, background-light-blue);
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  &[disabled] {
    pointer-events: none;
  }
}

.text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  small {
    display: block;
  }
}



.icon {
  height: 10px;
  width: 10px;

  &Left {
    margin-right: 6px;
  }

  &Right {
    margin-left: 6px;
  }
}

a.linkWithIcon,
button.linkWithIcon {
  :not([disabled]):hover {
    text-decoration: underline;
  }
}

.linkWithIcon:hover {
  color: inherit;
}

/* IE browser styles goes here*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon {
    width: 15px;
  }
}
