@import '../../styles/constants/breakpoints.scss';

.contentWithThumbnail {
  // Align the thumbnail next to the text
  display: flex;
  align-items: center;

  .children {
    flex-grow: 1;
    // Force wrap on very long words such as a url:
    word-break: break-word;
  }

  // This makes just enough height for the heading or heading+button:
  // The thumbnail is 70px high and sits vertically aligned in the middle, BUT
  // if the heading text wraps then the thumbnail will remain where it is.
  &.size-heading {
    min-height: 74px;

    .thumbnail {
      align-self: flex-start;
      margin-top: 2px;
    }
  }

  // Change the margin based on thumbnail size:
  &.size-section .thumbnail,
  &.size-heading .thumbnail {
    margin-right: 1rem;
  }

  &.size-table .thumbnail,
  &.size-medium .thumbnail,
  &.size-small .thumbnail {
    margin-right: 0.625rem;
  }

  @media #{$phoneMedia} {
    &.size-table .thumbnail,
    &.size-medium .thumbnail,
    &.size-small .thumbnail {
      margin-right: 16px;
    }
  }
}
