@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

h1 {
  font-size: 2rem;

  @media #{$xsMedia} {
    text-align: center;
    font-size: 1.7rem;
  }

  &.title {
    @media print {
      display: none;
    }
  }
}

.timelineHeaderWrapper {
  text-align: center;
}

.timelineHeader {
  border-style: solid;
  border-width: 2px;
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
  border-color: map-get($background-and-border-colors, border);
  color: map-get($typography-colors, sub-text);
  text-align: center;
  position: relative;
  margin: -1px auto 15px auto;

  @media print {
    page-break-inside: avoid;
    margin: 15px auto;
    border-color: $color-black;
    border-width: 1px;

    &, * {
      color: $color-black !important;
    }
  }
}

.lastGroup {
  & > :last {
    &:after {
      width: 0;
      height: 0;
    }
  }
}
