@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";

body.hiddenClass {
  overflow: hidden;
}

.orbNotification {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: calc(100vh - 60px);
  margin-top: 0;
  background-color: $color-star-tips;

  .content {
    padding-top: 40px;
  }

  .actions {
    display: block;
    margin-top: 20px;
  }
}

@media #{$smMedia} {
  .orbNotification {
    margin-top: -51px;

    .actions {
      position: relative;
      top: 20px;
    }
  }
}
