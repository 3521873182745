/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

// Overrides of default browser stylings
@import '../constants/colors';

// Force all elements to be use the same box-sizing
* {
  box-sizing: border-box;
}

// Apply margins based on context
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
dl,
dd,
button,
select {
  margin: 0;
}

// We more commonly use lists for layout then text, so it makes sense to disable styles by default.
// To re-enable the text style of lists, see `typography.scss`
ul,
ol {
  padding-left: 0;
}

li {
  display: block;
}

// Override button stylings in one place
button {
  padding: 0;
  border: none;
  background: transparent; // Note: background:inherit seems to be ignored in IE11
  cursor: pointer;
}

// Common font override for form elements
button,
select,
input,
textarea {
  color: $color-text;
  font-family: inherit;
  font-size: 1rem;
}

// Set our Hr variant
hr {
  border: none;
  border-bottom: 1px solid $color-borders;
  margin: 0;
}

// We prefer our em's bold
em {
  font-style: normal;
  font-weight: 600;
}

fieldset {
  padding: 0;
  border: none;
}
