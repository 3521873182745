@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints";

.imageContainer {
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: $color-grey;
  border-radius: 100%;

  svg {
    width: 50%;
    fill: $color-white;
  }

  @media #{$phoneMedia} {
    min-width: 2rem;
    height: 2.5rem;
  }

  &.active {
    background-color: map-get($hubColors, blue-color-primary);

    svg {
      fill: $color-white;
    }
  }

  @media #{$phoneMedia} {
    svg {
      height: 2rem;
    }
  }
}
