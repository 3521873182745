@import "../../styles/constants/colors.scss";
@import '../../styles/constants/breakpoints';

.header {
  display: flex;
  flex-direction: column;

  @media #{$smMedia} {
    flex-direction: row;
    align-items: center;
  }
}

.actions {
  gap: 0.9375rem;

  legend {
    width: 100%;
  }
}

.statusFilters {
  align-items: flex-start;
  margin-left: auto;
  font-size: small;
  font-weight: normal;
  gap: 10px;
}

.refreshButton {
  display: flex;
  flex-direction: column;

  svg {
    fill: map-get($primary, primary100);
  }
}
