@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";

.midContainer {
  padding: 1rem;
}

.tryAgainButton svg {
  display: none;
}

.infoLabelContainer {
  margin-right: 2rem;
  margin-left: 1rem;

  @media #{$phoneMedia} {
    margin-left: 2.5rem;
  }
}
