@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

.searchContainer,
.filtersContainer {
  display: flex;
  width: auto;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
}

.searchContainer {
  padding: 0;
}

.searchContainer > div {
  margin-bottom: 1rem;
}

.searchContainer label {
  margin: 0;
}

.filterBarControls {
  padding: 0 1rem;
  margin-bottom: 1rem;
  background-color: #33ccff26;
  border-radius: 5px;

  @media #{$phoneMedia} {
    padding: 0 0.5rem;
  }

  .filterBarLabel {
    padding: 0 10px;
    margin: 0;
    color: map-get($typography-colors, sub-text);
    font-size: 0.875rem;
  }

  .controls {
    display: flex;
    width: 100%;
    min-height: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    >label:first-child {
      padding-left: 0;
      margin-left: 0;

      @media #{$phoneMedia} {
        display: none;
      }
    }

    >label:last-of-type {
      border-left: 1px solid map-get($background-and-border-colors, border);
      margin-left: 8px;
    }
  }

  /* stylelint-disable no-descending-specificity */
  button.filterBarBtn {
    height: 30px;
    padding: 0 10px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    color: map-get($typography-colors, text);
    font-size: 0.875rem;
    font-weight: 600;

    span {
      margin-right: 3px;
    }

    > svg:first-of-type {
      width: 10px;
      height: 10px;
      fill: map-get($hubColors, blue-color-primary);
    }

    &:hover,
    &:focus,
    &[aria-expanded='true'] {
      background-color: $color-button-focus;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px map-get($primary-colors, primary);
    }

    &[aria-expanded='true'] {
      > svg {
        transform:
          rotate(
            -90deg
          );
      }
    }
  }

  .reverseSortBtn {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 0.875rem;

    svg {
      fill: map-get($typography-colors, text);
    }

    &:hover,
    &:focus {
      background-color: $color-button-focus;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px map-get($primary-colors, primary);
    }

    &.reversed {
      svg {
        transform:
          rotate(
            -180deg
          );
      }
    }
  }

  div[class="grid"] {
    padding: 0 0.625rem;
  }
}

.sortOptionsContainer {
  display: flex;
  align-items: center;
}

.filterBarSkeletonControls {
  display: flex;
  width: 100%;
  min-height: 40px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 5px;

  >:first-child {
    margin-left: 0.55rem;
  }

  > * {
    width: auto;
    margin-right: 0.5rem;
  }

  .skeletonButton {
    width: 155px;
    height: 30px;
    border-radius: 5px;

    @media #{$phoneMedia} {
      width: 120px;
    }
  }

  .skeletonRoundButton {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
}

body[data-theme="hub-theme"] {
  .filterDropdownOverlay {
    z-index: 16;

    &::after,
    &::before {
      content: none;
    }
  }
}
