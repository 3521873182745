@import "../../styles/constants/colors";

.ArticleParagraph {
  margin-bottom: 25px;
}

.note {
  padding: 15px;
  border: 1px solid map-get($background-and-border-colors, border);
  margin-bottom: 25px;
  border-radius: 5px;

  h3 {
    margin-top: 0;
  }
}
