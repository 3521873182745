@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.copyButtonContainer {
  width: 2.1rem;
  height: 2.1rem;
  margin-left: 5px;

  .buttonContainer {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: 50%;

    svg {
      width: 1.1rem;
      height: 1.1rem;
      fill: map-get($hubColors, blue-color-primary);
    }

    &.selected,
    &:hover,
    &:focus {
      background-color: map-get($legacy-color-names, background);

      .svgContainer svg {
        fill: map-get($primary-dark-colors, primary-dark);
      }
    }

    &:focus {
      @include standart-focus-button();

      outline: none;
    }
  }
}
