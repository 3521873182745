@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

.skeletonLoader {
  .headerSection {
    background-color: $color-panel-background-light;
  }

  .row {
    padding-top: 1rem;
  }

  .loader {
    background-color: map-get($background-and-border-colors, selected);
    border-radius: 5px;
  }

  .subSection {
    padding-bottom: 1rem;

    .loader {
      background-color: map-get($background-and-border-colors, selected);
      border-radius: 5px;
    }
  }

  .mainSkeletonBackground {
    background-color: $color-panel-background;
    color: $color-panel-background;
  }

  .titleRowHead {
    width: 17%;
    border-radius: 5px;
  }

  .orbDialsContainer {
    margin-top: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media #{$xsMedia} {
      display: none;
    }
  }

  .orbSubSection {
    margin-top: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .orbDialsFlexContainer {
    display: none;

    @media #{$xsMedia} {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-top: 16px;
    }
  }

  .gbProdSkeleton {
    width: 100%;
    margin-top: 25px;
  }

  .gbTableSkeleton {
    width: 100%;
  }

  .gbTableStudentDetailsSkeleton {
    width: 320px;
    height: 240px;
  }

  .large {
    @media #{$ipadAirMedia} {
      display: none;
    }

    @media #{$phoneMedia} {
      display: none;
    }
  }

  .medium {
    @media #{$lgMedia} {
      display: none;
    }

    @media #{$phoneMedia} {
      display: none;
    }
  }

  .small {
    @media #{$ipadAirMedia} {
      display: none;
    }

    @media #{$lgMedia} {
      display: none;
    }
  }
}
