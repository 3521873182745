@import '../constants/spacing';

/// Generate predefined css classes for `.gin-top`, `.gin-bot`, `.pad-top`, `.pad-bot`, and left/right/all variants.
/// @name verticalSpacing
/// @param {number} $n     - Required. The size of the vertical space in multiples of `$vgridSize` (typically `0.5rem`).
/// @param {string} $prefix - *Depricated. Use `-IF-` conditional classnames instead eg: `.pad-top-IF-sm`.* Optional. Prefix for the generated css classes, eg: `$prefix:"sm"` → `.sm-pad-top` etc.
/// @group Grid
/// @see $vgridSize
/// @author George Adamson
@mixin verticalSpacing ($n, $prefix: null) {
  $space: $vgridSize * $n;

  @if $prefix != null {
    $prefix: "#{$prefix}-";
  }

  // Dubious hack:
  // Note the DOUBLE syntax to make selector strong enough to win in most components.

  // Padding classes
  .#{$prefix}pad-top#{$n}.#{$prefix}pad-top#{$n} {
    padding-top: $space;
  }

  .#{$prefix}pad-bot#{$n}.#{$prefix}pad-bot#{$n} {
    padding-bottom: $space;
  }

  .#{$prefix}pad-left#{$n}.#{$prefix}pad-left#{$n} {
    padding-left: $space;
  }

  .#{$prefix}pad-right#{$n}.#{$prefix}pad-right#{$n} {
    padding-right: $space;
  }

  // Apply to all sides
  .#{$prefix}pad#{$n}.#{$prefix}pad#{$n} {
    padding: $space;
  }

  // Margin classes
  .#{$prefix}gin-top#{$n}.#{$prefix}gin-top#{$n} {
    margin-top: $space;
  }

  .#{$prefix}gin-bot#{$n}.#{$prefix}gin-bot#{$n} {
    margin-bottom: $space;
  }

  .#{$prefix}gin-left#{$n}:not(.col) {
    margin-left: $space;
  }

  .#{$prefix}gin-right#{$n}:not(.col) {
    margin-right: $space;
  }

  // Apply to all sides (but don't break .col elements)
  .#{$prefix}gin#{$n}.#{$prefix}gin#{$n} {
    margin-top: $space;
    margin-bottom: $space;
  }
  .#{$prefix}gin#{$n}:not(.col) {
    margin-left: $space;
    margin-right: $space;
  }
}
