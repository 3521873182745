@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors.scss";
@import "~@oup/shared-front-end/src/styles/themes/base/colors.scss";
@import "../../styles/mixins/hub/hub-button-focus";

.listPageControls {
  padding-top: 0.9375rem;     // 15px
  padding-bottom: 0.9375rem;  // 15px
  margin-bottom: 0.375;       // 6px to produce 22px gap when added to the 16px gin-top2 of the component below
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 5px 1px;

  .controls {
    display: flex;
    min-height: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    // Search input
    > *:first-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    // Search input
    > *:not(:first-child) {
      width: auto;
      padding: 0 1rem;
      margin: 0 -1rem;

      button {
        width: auto;
        justify-content: center;
      }
    }

    .addButtonContainer {
      margin-left: auto;
    }
  }
}

.loadingTitle {
  margin: 0;
  font-size: 20px;
}

.loadingSubtitle {
  color: map-get($typography-colors, sub-text-50);
}

@media #{$smMedia} {
  .listPageControls {
    .controls {
      flex-wrap: nowrap;

      > * {
        margin-right: 2rem;
      }

      // Search input
      > *:nth-child(1) {
        flex-grow: 1;
        margin-right: 0.9375rem; // 15px
        margin-bottom: 0;
      }

      // Search input
      > *:not(:first-child) {
        width: auto;
        padding: 0;
        margin: 0 0.9375rem 0 0; // 15px

        button {
          width: auto;
          justify-content: center;
        }
      }

      // Search input
      > *:last-child {
        margin-right: 0;
      }
    }
  }
}

.refreshButton {
  display: flex;
  flex-direction: column;
  &:focus {
    @include standart-focus-button();
  }
  svg {
    fill: map-get($primary, primary100);
  }
}

.skeletonButton {
  width: 130px;
  border-radius: 20px;
}

.skeletonRoundButton {
  width: 55px;
  border-radius: 35px;
}
