@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.SlideupPanel {
  .panel {
    position: fixed;
    z-index: 101; // This needs to be on front of everything except the PopoutPanel

    bottom: 0;
    left: 0;
    right: 0;

    background-color: $color-white;
    box-shadow: 0 0 0 $color-grey-border;
    padding: 1em 0;

    // Default to hidden, will slide in after component mount
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
  }

  &.isOpen {
    .panel {
      box-shadow: 0px 0px 15px $color-grey-border;
      transform: translateY(0);
    }
  }
}

// Demo page
.left, .right {
  flex-basis: 50%;
  max-width: 50%;
}

.right {
  text-align: right;
}

.horizontalContainer {
  display: none;
}

.expandableList {
  display: block;
  margin: 0 -1em -1em;

  @media screen {
    padding-top: 1em;
  }
}

@media #{$smMedia} {
  .horizontalContainer {
    display: block;
  }

  .left {
    flex-basis: 20%;
    max-width: 20%;
  }

  .right {
    flex-basis: 80%;
    max-width: 80%;
  }

  .expandableList {
    display: none;
  }
}
