.logo {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    width: auto;
  }
}

.mobile_logo {
  display: none;

  @media screen and (max-width: 1023px) {
    display: block;
  }
}
