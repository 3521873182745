.popoutPanelIconHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 0;
  text-align: center;

  &:first-child {
    margin-top: 3rem;
  }

  h2 {
    margin-top: 0.5rem;
  }
}
