.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;

  .coverImage {
    height: 50px;
    width: 60px;
    img {
      width: 100%;
    }
  }

  .title {
    font-size: 30px;
  }
  .listItems {
    text-align: left;
    width: 350px;
    font-size: 16px;
  }

  .images {
    padding-top: 20px;
    img {
      height: 33px;
      width: 100px;
    }
  }
}
