// Online test edit settings
@import "../../styles/constants/colors";

.editClassButton {
  width: 60%;
  height: 40px;
  padding: 0 12px;
  border-width: 1px;
  border-style: solid;
  border-color: map-get($background-and-border-colors, border);
  margin: 0 1rem;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px $color-black8;
  line-height: 38px;
  text-align: left;
}

.editClassText {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  color: map-get($primary-colors, primary);

  svg {
    height: 25%;
    margin-left: 0.25rem;
    fill: map-get($primary-colors, primary);
  }
}

.editClassText:hover {
  text-decoration: underline;
}

.boldTextContainer {
  display: flex;
  width: 100%;
  padding: 0.3rem 1rem;
  font-weight: 900;
}

.containerWithPadding {
  display: flex;
  padding: 0.3rem 1rem;
}

.studentNumberLabel {
  margin-left: 0.5rem;
  color: map-get($secondary-colors, classroom);
  font-weight: 400;
}

.lozengeContainer div {
  display: flex;

  &:hover {
    border-color: map-get($hubColors, hub_green_hover);

    button {
      min-width: 26px;

      svg {
        fill: map-get($hubColors, hub_green_hover);
      }
    }
  }
}
