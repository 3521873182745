@import "../../../styles/constants/breakpoints";
$minColWidth: 220px;
$resourceMinWidth: 140px;
$maxColWidth: 1fr;

.courseMaterial {
  display: grid;
  width: 100%;
  min-height: 0;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax($minColWidth, $maxColWidth));

  @media #{$phoneMedia} {
    grid-gap: 0.5rem;
    grid-template-columns: none;
  }
}

.courseMaterialList {
  display: flex;
  width: 100%;
  padding-bottom: 8rem;
  flex-direction: column;
}
