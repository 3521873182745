@import '../../styles/constants/colors.scss';

$small: 16px;
$medium: 22px;
$large: 28px;

.radioInput {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 6px 0;
  min-width: $medium; // This is needed by IE to ensure the element width does not collapse
  position: relative;
  width: fit-content;

  &-small {
    composes: radioInput;
    line-height: $small;
  }

  &-medium {
    composes: radioInput;
    line-height: $medium;
  }

  &-large {
    composes: radioInput;
    line-height: $large;
  }
}

.input {
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:focus + .icon {
    box-shadow: 0 0 10px 1px $color-primary-blue;
  }
}

.icon {
  background-color: $color-white;
  stroke: $color-white;
  border: 1px solid $color-background-dark;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: none; // Avoiding SVG as the event.target allows the click event to go straight to the <label> to fix an IE11 bug.

  &-small {
    composes: icon;
    height: $small;
    padding: 2px;
    width: $small;
  }

  &-medium {
    composes: icon;
    height: $medium;
    padding: 3px 4px;
    width: $medium;
  }

  &-large {
    composes: icon;
    height: $large;
    padding: 5px;
    width: $large;
  }

  &-checked {
    border: none;
  }
}

.text {
  margin-left: 10px;
}

// States
.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.indented {
  margin-left: 30px;
}
