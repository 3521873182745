$imageSize: 30px;
.user {
    align-items: center;
    display: flex;
    flex-direction: flex-row;
    justify-content: space-between;

    .status{
        height: $imageSize;
        width: $imageSize;
        margin-right: 1rem;
    }
}
