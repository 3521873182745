@import '../../../styles/constants/breakpoints';
@import '../../../styles/constants/colors';

.offlineAppLink {
  @media #{$smMedia} {
    /* that random little offline app divider line */
    &::before {
      position: relative;
      left: -10px;
      height: 50%;
      padding-left: 3px;
      border-left: 2px solid map-get($background-and-border-colors, background-light-blue);
      content: "";
    }

    &.selected {
      padding-left: 25px;
    }
  }
}

.quickNavLinks {
  // Hidden in dropdown on xs breakpoint:
  position: fixed;

  // This keeps the little ":after" arrows in front of the page content
  z-index: 100;

  // Fully visible on on SM breakpoint and higher:
  @media #{$smMedia} {
    position: static;
    border-bottom: 1px solid $color-borders;
  }

  ul {
    display: flex;
    flex-direction: row;
  }

  li {
    white-space: nowrap;

    > a {
      display: inline-block;
      padding: 0 16px;
      color: $color-text;
      line-height: 50px;

      &:hover {
        background: $color-borders;
        text-decoration: none;

        &.selected::after {
          border-top-color: $color-borders;
        }
      }

      &.selected {
        position: relative;
        color: $color-primary-blue;
        font-weight: bold;
        outline: none;

        @media #{$smMedia} {
          &::before,
          &::after {
            position: absolute;
            z-index: 99;
            bottom: -10px;
            left: 50%;
            width: 0;
            height: 0;
            border-top: 10px solid $color-white;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            content: "";
            transform: translateX(-50%);
          }

          &::before {
            bottom: -11px;
            border-top-color: $color-borders;
          }
        }
      }
    }
  }

  // Restyle SubHeader as a dropdown menu on XS breakpoint:
  @media #{$xsMedia} {
    width: 100%;
    background-color: $color-white;
    // Menu collapsed: (Init off canvas)
    transform: translateY(-100%);
    // To animate up:
    transition: transform 0.2s ease-in;

    // Slide down when opened/closed:
    // This selector is a bit yucky: It relies on .quickNavLinks being in a div that's adjacent to
    // .siteHeader.subNavMenu-open:
    &.open {
      // Add box shodow to separate menu from content behind it:
      box-shadow: 0 -1px 6px 0 $color-grey-shadow;
      // Menu expanded:
      transform: translateY(0);
      // To animate down:
      transition: transform 0.2s ease-out;
    }

    // Override standard grid classNames:
    > :global(.grid) {
      padding: 0;

      > :global(.row) {
        margin: 0;

        > :global(.col) {
          border-width: 0;
        }
      }
    }

    ul,
    li {
      display: block;
      width: 100%;
    }

    // This selector applies to all menu links except the li > div > "Help & Support" button:
    li {
      > a {
        display: block;
        border-bottom: 1px solid $color-very-light-grey;
        color: color(primary);
        line-height: 40px;

        &:hover {
          background-color: $color-very-light-grey;
        }

        // Hide the little beak arrow:
        &::after {
          display: none;
        }
      }
    }
  }
}

body[data-theme="orb-theme"] {
  .quickNavLinks {
    li {
      > a {
        border-top: 5px solid transparent;
        box-shadow: none;
        color: map-get($primary-colors, primary);
        line-height: 45px;

        &:hover {
          border-top-color: map-get($primary-colors, primary);
          background-color: white;
          color: map-get($primary-dark-colors, primary-dark);
          text-decoration: none;

          &.selected::after {
            border-top-color: $color-borders;
          }
        }

        &.selected {
          position: relative;
          border-top-color: map-get($primary-dark-colors, primary-dark);
          color: map-get($primary-dark-colors, primary-dark);
          font-weight: bold;
          outline: none;

          @media #{$smMedia} {
            &::before,
            &::after {
              position: absolute;
              z-index: 99;
              bottom: 0;
              left: 0;
              width: 0;
              height: 0;
              border: 0;
              content: "";
              transform: none;
            }

            &::before {
              bottom: 0;
              border-top-color: $color-borders;
            }
          }
        }
      }
    }

    // Restyle SubHeader as a dropdown menu on XS breakpoint:
    @media #{$xsMedia} {
      li {
        > a {
          border-top: 0;
          border-left: 5px solid transparent;
          color: map-get($primary-colors, primary);
          line-height: 50px;

          &:hover {
            border-top: 0;
            border-left-color: map-get($primary-colors, primary);

            &.selected::after {
              border-left-color: $color-borders;
            }
          }

          &.selected {
            border-top: 0;
            border-left-color: map-get($primary-dark-colors, primary-dark);
          }
        }
      }
    }
  }
}
