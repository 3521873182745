.button {
  position: relative;
  display: block;
  cursor: default;

  &:hover,
  &:focus {
    outline: none;

    .caption {
      visibility: visible;
    }
  }
}

.caption {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: black;
  font-size: 0.9rem;
  color: white;
  transform: translateX(-50%);
  visibility: hidden;
  z-index: 2;

  &::after {
    position: absolute;
    left: 50%;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 0;
    border-bottom-color: #000;
    transform: translateX(-50%);
    content: '';
  }
}

.tooltipTitle {
  font-weight: normal;
}
