@import '../styles/constants/colors';
@import '../styles/constants/variables';

.form {
  width: 100%;
}

.formMain {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  line-height: 1.5;
  padding: 40px;
  padding-bottom: 60px;
}

.hide {
  display: none;
}

.heading {
  margin-top: 0;
}

.notification {
  background-color: $u14-blue-light;
  color: $dark-blue;
  margin: 0 -40px;
  padding: 20px 40px;
}

.errorHeading {
  margin: 0 0 10px;
}

.field {
  align-items: center;
  display: flex;
  flex-direction: row;
  max-width: 560px;
  margin-top: 20px;

  @media only screen and (max-width: 680px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.label {
  color: $dark-blue;
  font-size: large;
  margin: 0 20px 0 0;
  min-width: 130px;
}

.orgUniqueId {
  display: flex;
  color: $color-grey-2;

  @media only screen and (max-width: 680px) {
    padding-top: 10px;
  }
}

.change {
  color: $dark-blue;
  margin-left: 10px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.input {
  align-self: center;
  border-color: $dark-blue !important;
  border-radius: 10px !important;
  border-width: 2px !important;
  flex: 1;

  @media only screen and (max-width: 680px) {
    align-self: baseline;
    width: 100%;
  }
}

.error {
  border-color: red !important;
}

.info {
  display: flex;
  margin-top: 30px;
}

.infoIcon {
  font-size: 1.2em;
  height: 1em;
  margin-right: 10px;
  width: 1em;
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &,
  &:hover,
  &:focus {
    color: inherit;
  }
}

.authorizeLink {
  align-self: center;
  margin-top: 0;
  color: $color-white;
}

.formFooter {
  display: flex;
  flex-direction: column;
  min-height: 80px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: $color-border-blue;
}

.cta {
  display: block;
  align-self: center;
  min-width: 250px;
  margin-top: -40px;
  padding: 20px;
  border: 4px solid white;
  border-radius: 40px;
  background-color: $green;
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: $dark-green;
  }
}
