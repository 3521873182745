// All the colors for refresh phase should be added here
// Colours grouped as per the Style Guide for easier maintenance.
// Names of colours have been defined by OUP designers.
// Note the :export below that enables JS to import this colour data as json.

// Helper to convert scss data, such as a map, to a json string:
@import "../mixins/json/index";
@import "../mixins/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

$primary-colors: (
  primary:#002147,
);
$primary-dark-colors: (
  primary-dark: #002147,
);

$primary-light-colors: (
  primary-light: #ccd3da,
);

$color-white: #fff;
$color-black: #000;

// Core colours
$core: (
  border: #ccd3da,
  hover: #e5e9ed,
  icons: #99a6b5,
  background: #e5e9ed,
);

$modern: (
  electric_blue: #0043e0,
  madrid_orange: #ebebeb,
  big_apple_green: #c8dc00,
  kipper_yellow: #ffe12d,
  isadora_pink: #f3a7b8,
);

$neutral: (
  neutral_blue: #cad7d8
);

$validation: (
  light_green: lighten(#008000, 20%), // #33CC33
  dark_green: #008000,
  green:  lighten(#008000, 50%), // #66FF66
  light_orange:  lighten(#ff9914, 20%),   // #FFCC33
  dark_orange: #ff9914,
  orange: lighten(#ff9914, 50%),  // #FFCC66
  light_red: lighten(#d90429, 20%), // #FF3333
  dark_red: #d90429,
  red: lighten(#d90429, 50%), // #FF6666
);

$heritage: (
  ruskin_red: #9b1827,
  mallard_green: #005145,
  walton_stone: #82745e,
  scholar_purple: #5f4261
);

// This is old file of _colors.scss with the new colors added to it.

// These appear in our "colors" style demo page under the heading "Secondary colours":
$secondary-colors: (
  classroom_background:#503,
  classroom: #a10062,
  learner: #fc795f,
  organization: #ffce53,
  profile: #639b2b,
  register: #9fd368,
  course: #47cf88,
  dashboard: #1899c7,
  support_icon:#4fc1e9,
  parent: #5d9ceb,
  group: #ac92eb,
  tbc: #eb86c0,
  archived: #b3b3b3,
  teacher: #a10062,
  teacher_admin: #6cc,
  org_admin: #ffce53,
  activity: #22b1e3,
  hub_dashboard: #3d3b54,
  hub_dashboard_hover: #26243d,
  hub_dashboard_selected: #54526b,
  hub_border: #3cf,
  hub_blue: #3cf,
  background_light_blue: #deebff,
  hub_light_grey: #e5e9ed,
  hub_support_link: #4470ba,
  progress_green: #38a867,
  no_progress_grey: #c1d4ea,
  class_average: #f8f1a0,
  student_average: #c1d4ea,
  light_pink: #c9246733,
  light_brown: #b2aaaa
);

// These appear in our "colors" style demo page under the heading "Secondary hover colours":
$secondary-dark-colors: (
  classroom-dark: darken(map-get($secondary-colors, classroom), 15%),
  learner-dark: darken(map-get($secondary-colors, learner), 20%),
  organization-dark: darken(map-get($secondary-colors, organization), 20%),
  profile-dark: darken(map-get($secondary-colors, profile), 20%),
  support-icon: darken(map-get($secondary-colors, support_icon), 20%),
  course-dark: darken(map-get($secondary-colors, course), 20%),
  dashboard-dark: darken(map-get($secondary-colors, dashboard), 20%),
  parent-dark: darken(map-get($secondary-colors, parent), 20%),
  group-dark: darken(map-get($secondary-colors, group), 20%),
  tbc-dark: darken(map-get($secondary-colors, tbc), 20%),
  archived-dark: #333,
  teacher-dark: darken(map-get($secondary-colors, teacher), 20%),
  teacher_admin-dark: darken(map-get($secondary-colors, teacher_admin), 20%),
  org_admin-dark: darken(map-get($secondary-colors, org_admin), 20%),
  activity-dark: darken(map-get($secondary-colors, activity), 20%),
);

// These appear in our "colors" style demo page under the heading "Typography colours":
$typography-colors: (
  text: #333,
  sub-text: #666,
  sub-text-50: lighten(#333, 50%),
);

// Greys and whites:
$background-and-border-colors: (
  background: #fff,
  border: #ccd3da,
  icon: #b3b3b3,
  selected: #f5f5f5,
  background-dark-blue: #011e41,
  background-light-blue: #002147,
  background-dark-grey: #c9d7d8,
  border-grey: #ffffff4d,
  border-light-grey: #ccc
);

// These appear in our "colors" style demo page under the heading "Validation colours":
$validation-colors: (
  correct: #38a867,
  pending: #ff870f,
  error: #d90429,
);
$validation-dark-colors: (
  correct-dark: darken(map-get($validation-colors, correct), 10%),
  pending-dark: darken(map-get($validation-colors, pending), 10%),
  error-dark: darken(map-get($validation-colors, error), 10%),
);

$class-report-colors: (
  ahead: #551a8b,
  expected: map-get($validation-colors, correct),
  behind: map-get($validation-colors, pending),
  very-behind: #e50000,
);

$teacher-resource-colors: (
  tr-primary: #00a2ff,
  tr-secondary: #27b800,
  tr-tertiary: map-get($secondary-colors, organization),
  tr-header: lighten(#248c56, 18%) // The header darkens whatever you give it,
);

// TODO: These can be retired as and when they're removed from code:
$legacy-color-names: (
  primary-blue: map-get($primary-colors, primary),
  primary-blue-hover: map-get($primary-dark-colors, primary-dark),
  primary-hover: map-get($primary-dark-colors, primary-dark),
  black-color: $color-black,
  classes-teachers: #ec5464,
  classes-teachers-dark: darken(#ec5464, 20%),
  learners: map-get($secondary-colors, learner),
  courses: map-get($secondary-colors, course),
  support: map-get($secondary-colors, dashboard),
  support-icon: map-get($secondary-colors, support_icon),
  parents: map-get($secondary-colors, parent),
  groups: map-get($secondary-colors, group),
  background: #ebebea,
  background-dark: #b3b3b3,
  borders: map-get($background-and-border-colors, border),
  default: #ebebea,
  weak: #d90429,
  warning: #ff8700,
  OK: #ff870f,
  good: #38a867,
  strong: #107635,
  text-input-border: #949494,
  borders-v2: #002147,
  tooltip: #333,
  dark-blue: #002048,
  light-blue: #4670a7,
);

// Combine into one big map:
$styleGuideColors: map-merge($primary-colors, $primary-dark-colors);
$styleGuideColors: map-merge($styleGuideColors, $secondary-colors);
$styleGuideColors: map-merge($styleGuideColors, $secondary-dark-colors);
$styleGuideColors: map-merge($styleGuideColors, $typography-colors);
$styleGuideColors: map-merge($styleGuideColors, $background-and-border-colors);
$styleGuideColors: map-merge($styleGuideColors, $validation-colors);
$styleGuideColors: map-merge($styleGuideColors, $validation-dark-colors);
$styleGuideColors: map-merge($styleGuideColors, $class-report-colors);
$styleGuideColors: map-merge($styleGuideColors, $teacher-resource-colors);

// Make the colours available to all stylesheets:
// TODO: Deprecate legacy-color-names from this map.
$colors: map-merge($legacy-color-names, $styleGuideColors);

$legacyColors: map-merge($styleGuideColors, $legacy-color-names);

// Prepare categorised map of colours as per Style Guide:
$colorsByCategory: (
  primary: map-merge($primary-colors, $primary-dark-colors),
  secondary: $secondary-colors,
  secondary-dark: $secondary-dark-colors,
  typography: $typography-colors,
  background-and-border: $background-and-border-colors,
  validation: $validation-colors,
  validation-dark: $validation-dark-colors,
);

//Colors that belong to the HUB app
$hubColors: (
  blue-color-primary: #3cf,
  blue-color-secondary: #4486c6,
  blue-color-tertiary: #002147,
  blue-color-dark-moderate: #002147,
  blue-color-dark: #002147,
  gray: #aaa,
  dark-gray: #666,
  hub_link: #002147,
  hub_link_hover: #002147,
  hub_sidenav_level: #ecffff,
  hub_non_preview_icon: #66cafa,
  hub_active_licence: #008000,
  hub_expiring_licence: #b85c00,
  hub_expired_licence: #d90429,
  hub_people_border:#999,
  hub_banner_success:#2a8250,
  hub_electric_blue: #0043e0,
  hub_summer_yellow: #ffe12d,
  hub_very_light_blue: #34ccff,
  hub_dark_blue: #011d42,
  hub_neutral_blue: #cad7d8,
  hub_madrid_orange: #fd5821,
  hub_madrid_orange_dark: #e26249,
  hub_green_hover: #248c56,
  onboarding_wizard_valid_input_background_color: #eff2f3,
  onboarding_wizard_valid_input_border_color: #629e79,
  product_finder_tab_unselected_text: #4670a7,
  product_finder_tab_selected_text: #012147,
  hub_success_text_green: #2b8250
);

$oteColors: (
  white: #fff,
  ote_text_background: #fd495c,
  ote_text_background_2: #3bbfad,
  yellow: #f2cd00
);

$rvsColors: (
  rvs_summer_yellow: #ffe12d,
  rvs_drop_zone: #a9a9a8
);

$cptColors: (
  white: #fff,
  background: map-get($primary, primary100),
  yellow: map-get($modern, kipper_yellow),
  grey: #ccc,
  light_black: #333,
  light_grey: #ccd3da,
  dark_white: #e5e9ed,
  light_blue: #3cf,
  light_blue2: #425b77,
  dark_blue: #002147,
  greybutton: #777687,
  bluebutton: #3a3aca,
  greyborder: #ffffff4d,
  secondaryred: #c92467
);

$oicColors: (
  oxford_blue: #011e41,
  oisa_green: #99bd3c,
  oisa_blue: #48bed8,
  light_grey: #f2f4f6
);

// Export all colours to share names and codes with JS:
@each $colorName, $colorValue in $colors {
  :export {
    #{$colorName}: $colorValue;
  }
}

// Export our colours to JS as a JSON string:
:export {
  /* stylelint-disable property-case */
  colors: json-encode($colors); // All colours. Both legacy and Style Guide.
  colorsByCategory: json-encode($colorsByCategory);
  styleGuideColors: json-encode($styleGuideColors);
  /* stylelint-enable */
}

// TODO: Deprecate these in favour of global color(name) function:
//       Those in comments have already been deprecated.
$color-white: #fff;
$color-black: #000;
$color-black-shade: #0000002e;
$color-black-shade2: #00000030;
$color-black-shade3: #000c;
$color-light-grey: #ccd3da;
$color-grey: #ccc;
$color-grey-2: #444;
$color-grey-3: #1e1e1e;
$color-mid-grey: #c9c9c9;
$color-mid-grey-2: #e2e2e2;
$color-mid-grey-3: #c0c0c0;
$color-very-light-grey: #ededed;
$color-grey-shadow: #222;
$color-grey-button: #e6e5e5;
$color-grey-disabled: #dcdcdc;
$color-grey-disabled2: #dcdcdcff;
$color-grey-modal-border: #e5e5e5;
$color-grey-modal-border50: #e5e5e050;
$color-button-background: #e9e9e9;
$color-button-background2: #002048;
$color-button-background3: #ebebeb;
$color-grey-border: #888;
$color-grey-calendar: #484848;
$color-light-grey-border:#d9d9d9;
$color-blue-circle: #10739e;
$color-modals-background: #497c85;
$color-green-border: #00a699;
$color-day-piker: #dbdbdb;
$color-border-calendar: #c4c4c4;
$color-hover-calendar: #008489;
$color-light-grey-calendar: #cacccd;
$color-grey-calendar-hover: #cfe3ec;
$color-grey-border-2: #e8e8ed;
$color-grey-calendar-fill: #82888a;
$color-grey-progress: #33353a;
$color-text-grey: #66667d;
$color-text-grey-disabled: #808080;
$color-red: #d90429;
$color-background-sign: #fafae5;
$color-soft-black: #58595b;
$color-black2 : #000000bf;
$color-black3: #000000b3;
$color-black4: #00000073;
$color-black5: #0000002b;
$color-black6: #00000026;
$color-black7: #0009;
$color-black8: #00000012;
$color-black9: #0000000d;
$contrast-light-box-shadow: #0000004d;
$color-transparent-black: rgba(255, 255, 255, 0);
$color-transparent-white: rgba(255, 255, 255, 1);
$color-black-rgba: rgba(0, 0, 0, 0);

$color-black8: #d9d9d9;// culoare in lucru

$color-primary-blue: color(primary);
$color-primary-blue-dark: color(primary-dark);
$color-border-blue: #116993;
$color-border-blue2:#11699380;
$color-icon: #f5f6f7;
$color-orb-blue: #3973b8;
$color-table-background: #f1fafc;
$color-progress-background: #caebf5;
$color-orb-bright-blue: #1b9af2;

$color-classes-teachers: map-get($legacyColors, classes-teachers);
$color-classes-teachers-dark: map-get($legacyColors, classes-teachers-dark);

$color-organization: map-get($legacyColors, organization);
$color-organization-dark: darken($color-organization, 20%);

$color-profile: map-get($legacyColors, profile);
$color-profile-dark: darken($color-profile, 20%);

$color-register: map-get($legacyColors, register);

$color-support: map-get($legacyColors, support);
$color-support-dark: darken($color-support, 20%);

$color-support-icon: map-get($legacyColors, support_icon);
$color-support-icon-dark: darken($color-support-icon, 20%);

// These colors don't need dark and light variants
$color-text: map-get($legacyColors, text);
$color-sub-text: map-get($legacyColors, sub-text);
$color-sub-text-50: map-get($legacyColors, sub-text-50);
$color-background: map-get($legacyColors, background);
$color-background-dark: map-get($legacyColors, background-dark);
$color-borders: map-get($legacyColors, borders);
$color-correct: map-get($legacyColors, correct);
$color-error: map-get($legacyColors, error);
$color-warning: map-get($legacyColors, warning);
$color-weak: map-get($legacyColors, weak);
$color-good: map-get($legacyColors, good);
$color-strong: map-get($legacyColors, strong);
$color-text-input-border: map-get($legacyColors, text-input-border);
$color-borders-v2: map-get($legacyColors, borders-v2);
$color-requested: map-get($legacyColors, learner);

$color-green-subscription: #3ec875;
$color-border: #e5e5e0;
$color-border-light: #eee;
$color-botton: #ddd;
$color-border-header: #cccdc9;
$color-astronaut-blue: #2a4574;
$color-mercury: #e6e6e6;
$color-light-border:#e4e4e4;
$color-highlight:  #aabbde;
$color-star-tips : #d2effd;
$color-terms : #addcee;// background panel color
$color-panel-background: #e5e9ed;
$color-panel-background-light: #d7d7d7;
$color-grey-basket: #d5d5d5;
$color-player-shadow: #d2cfcf40;
$color-button-focus: #33ccff26;
// NGS Theme colors
$contrast-dark-hover: #fff9;
$contrast-dark-accent-75: #ffffffbf;
$contrast-dark-accent-35: #ffffff59;
$contrast-dark-accent-08: #fffc;
$contrast-dark-accent-03: #ffffff4d;
$contrast-dark-accent-02: #fff3;
$contrast-dark-accent-015: #ffffff26;
$contrast-dark-accent-025: #ffffff40;
$contrast-dark: #cdcdcd;

$contrast-light: #0000001a;
$contrast-light-accent: #0003;
$contrast-light-box-shadow: #0000004d;
$contrast-light-hover: #0006;
$contrast-dark-hover2: #00000040;
$contrast-light-shadow: #00000013;
$contrast-light-accent-50: #00000080;

$contrast-light-blue: #4776aefc;
$contrast-light-blue2: #4776ae66;
$contrast-light-blue3: #4776ae99;
$contrast-light-blue4: #4776ae17;
$contrast-light-blue-gradient: #00d4ff;

// Smart
$smart-background-color-lozenge: #eceef2;
$smart-link-color-normal: #10749e;
$smart-link-color-darker: #005b85;
$smart-text-neutral: #575757;
$smart-accent-color: #10749e;
$smart-accent-color-lighter: rgba($smart-accent-color, 0.5);
$smart-pink-dot: #e5135e;
$smart-border-dropdown: #ccd3da;
$smart-dropdown-option-hover: #f5f5f5;

// Smart DSE Band
$smart-dse-developing: #fe0;
$smart-dse-secure: #b0e501;
$smart-dse-extending: #c55cfc;

// SmartCard
$smart-card-border-color: #ebebed;
$color-calendar : #f4f5f5;
