@import '../../../../styles/constants/variables';
@import '../../../../styles/constants/colors';

.subheading {
  color: $soft-black;
  font-size: 20px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.button {
  margin-bottom: 20px;
}

.blueButton {
  margin-bottom: 20px;
  color: map-get($cptColors, dark_blue);
}
