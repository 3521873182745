@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.pageContainer {
  .searchContainer {
    max-width: 40rem;
  }

  .resultsContent {
    padding: 1rem 0;
  }

  .boldText {
    font-weight: 700;
  }

  .loaderSvg {
    width: 100%;
    padding: 1rem 0;
    color: map-get($primary, primary0);
    text-align: center;

    svg {
      width: 5rem;
    }
  }

  .userDataContainer {
    .headingContainer {
      display: flex;
      box-shadow: 0 1px map-get($primary, primary20);

      .headingItem {
        padding: 10px;
        font-weight: $font-weight-700;
      }
    }

    .rowContainer {
      display: flex;

      .rowItem {
        padding: 10px;
        word-wrap: break-word;
      }
    }

    .firstName,
    .lastName {
      width: 12.5rem;
    }

    .userEmail {
      width: 18.75rem;
    }
  }

  .launcherContainer {
    align-items: center;

    .launchDropdown {
      margin-right: 0.5rem;
    }

    .launchButton {
      button {
        min-height: 2rem;
        padding: 0.5rem 1rem;

        span {
          color: map-get($primary, primary100);
          font-weight: $font-weight-700;
        }
      }
    }
  }
}
