.description {
  color: #333;
}

.createdAt {
  min-width: 75px;
  text-align: center;
}

.creator {
  min-width: 200px;
  text-align: center;
}
