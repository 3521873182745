@import '../../styles/constants/colors';

.pageContainer main {
  @media screen {
    min-height: 60vh;
  }
}

.sebSignedInContainer {
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    border-color: $color-light-grey;
    margin-top: 40px;
    margin-right: 10%;
    margin-left: 10%;

    span:nth-child(1),
    span:nth-child(3) {
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: $color-light-grey;
    }

    span:nth-child(1) {
      width: 15px;
    }

    span:nth-child(3) {
      flex-grow: 1;
    }

    .tab {
      padding: 8px 15px;
      border: 1px;
      border-style: solid;
      border-color: $color-light-grey;
      border-bottom: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      font-weight: 700;
      line-height: 150%;
    }
  }

  .element_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .element {
      display: flex;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 12px;
      border: 1px solid;
      border-color: $color-light-grey;
      margin-top: 16px;
      margin-right: 10%;
      margin-left: 10%;
      border-radius: 6px;

      .element_title {
        display: flex;
        align-items: center;

        .zip_container {
          display: flex;

          .zip {
            height: 50px;
            margin-right: 15px;
            margin-left: 15px;
          }
        }

        .text {
          min-width: 450px;

          p:nth-child(1) {
            font-weight: 400;
            line-height: 150%;
          }

          p:nth-child(2) {
            margin-top: 0;
            color: map-get($typography-colors, sub-text);
            font-size: 13px;
            line-height: 150%;
          }
        }
      }

      .download_container {
        display: flex;
        width: 100%;
        justify-content: end;
        margin-right: 15px;

        .download_link_container {
          display: flex;

          .download {
            height: 30px;
          }
        }
      }
    }
  }
}
