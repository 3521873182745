@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.cookieBanner {
  padding: 1.5rem 0;
  background: map-get($legacy-color-names, tooltip);
  text-align: center;

  .text {
    margin: 0 0 1rem 0;
    color: $color-white;
  }

  .buttons > *:first-child {
    margin-right: 1rem;
  }
}

@media #{$smMedia} {
  .cookieBanner {
    :global(.col) {
      flex-direction: row;
      text-align: left;
    }

    .text {
      margin: 0 2rem 0 0;
    }

    .buttons {
      display: flex;
      flex-shrink: 0;
      align-items: center;
    }
  }
}
