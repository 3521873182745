@import "../../../styles/constants/colors";
@import "../../../styles/constants/breakpoints.scss";
@import "../../../styles/mixins/hub/hub-button-focus";

.navBarContainer {
  min-width: 13.75rem;
  max-width: 18.75rem;
  height: 100%;
  min-height: calc(100vh - 11.8525rem); //right border expansion
  padding: 1.5rem;
  border-right: 1px solid $color-light-grey;

  li a {
    line-height: 1.5rem;
  }

  .navBarItem {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 3.125rem;
    align-items: center;
    border-left: 5px solid $color-black-rgba;
    color: map-get($hubColors, hub_link);
    font-weight: 400;
    text-align: unset;

    .buttonName {
      position: relative;
      width: 100%;
      padding: 0.8125rem 1rem 0.8125rem 0.6875rem;
      cursor: pointer;
      text-transform: capitalize;
    }

    &:focus:not(.navBarItemActive) {
      background-color: map-get($secondary-colors, hub_light_grey);
      border-radius: 3px;
      box-shadow: 0 0 0 1px map-get($primary-colors, primary), 0 0 0 2px $color-white;
      outline: none;
    }

    &:hover:not(.navBarItemActive) {
      border-left: 5px solid map-get($secondary-colors, hub_light_grey);
      background-color: map-get($secondary-colors, hub_light_grey);
      color: map-get($hubColors, blue-color-tertiary);

      a &:focus {
        @include submenu-button-on-focus();

        color: map-get($hubColors, blue-color-tertiary);
      }
    }

    &.navBarItemActive {
      border-left: 5px solid map-get($secondary-colors, hub_blue);
      color: map-get($secondary-dark-colors, archived-dark);
      font-weight: 600;

      &:focus {
        @include no-padding-button-on-focus();

        color: map-get($hubColors, blue-color-tertiary);
      }
    }
  }

  .dropdownButtonContainer {
    position: relative;

    .thumbnailContainer {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);

      &:focus {
        @include tile-on-focus();

        border-radius: 50%;
        outline: none;

        div {
          background-color: map-get($secondary-colors, hub_light_grey);
        }
      }

      div {
        background-color: $color-white;

        &:hover {
          background-color: map-get($secondary-colors, hub_light_grey);
        }
      }
    }

    .thumbnailContainer svg {
      width: 15px;
      height: 15px;
      box-sizing: content-box;
      fill: map-get($hubColors, blue-color-primary);
      transition: 0.1s ease-out transform;
    }

    .thumbnailActive svg {
      transform: rotate(270deg);
    }
  }

  .dropdownItemsContainer {
    overflow: hidden;
    max-height: 0;
    margin-left: 1rem;
    visibility: hidden;

    .additionalResources {
      display: block;
      padding: 1rem 1rem 0.3125rem 1rem;
      font-size: 14px;
      font-weight: 600;
    }

    .dropdownItemChild {
      display: block;
      max-height: 0;
      padding: 0.5rem 1rem 0.5rem 1rem;
      color: map-get($hubColors, hub_link);
      cursor: pointer;
      font-weight: 400;

      .border {
        position: relative;
        top: -0.5rem;
        width: 100%;
        border-top: 1px dashed map-get($background-and-border-colors, border);
        margin-right: 0.9375rem;
      }

      .linkIcon {
        position: relative;
        top: 0;
        width: 1.3rem;
        height: 1.3rem;
        fill: map-get($hubColors, hub_link);
        transform: translateY(20%);
      }

      &:hover:not(.dropdownItemChildActive) {
        background-color: map-get($secondary-colors, hub_light_grey);
        color: map-get($hubColors, blue-color-tertiary);

        .linkIcon {
          fill: map-get($hubColors, blue-color-tertiary);
        }
      }

      &:focus:not(.dropdownItemChildActive) {
        background-color: map-get($secondary-colors, hub_light_grey);
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px map-get($primary-colors, primary), inset 0 0 0 2px $color-white;
        outline: none;
      }

      &.dropdownItemChildActive {
        box-shadow: inset 5px 0 0 0 map-get($hubColors, blue-color-primary);
        color: map-get($secondary-dark-colors, archived-dark);
        font-weight: 600;

        &:focus {
          border-radius: 3px;
          box-shadow: inset 0 0 0 1px map-get($primary-colors, primary), inset 0 0 0 2px $color-white, inset 7px 0 0 0 map-get($hubColors, blue-color-primary);
        }
      }

      .linkContainer {
        display: flex;
        max-height: unset;
        flex-direction: column;
        justify-content: center;

        .link::first-letter {
          text-transform: capitalize;
        }
      }

      .svgContainer {
        display: inline-block;
        height: 100%;
      }

      .subtext {
        color: map-get($typography-colors, sub-text);
        font-size: 14px;
      }
    }

    /* stylelint-disable no-descending-specificity */
    &.childrenActive {
      max-height: 300px;
      background:/* Shadow covers */
        linear-gradient(white 30%, $color-transparent-black),
        linear-gradient($color-transparent-black, white 70%) 0 100%,
        /* Shadows */
        radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), $color-black-rgba),
        radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), $color-black-rgba) 0 100%;
      background:
        /* Shadow covers */
        linear-gradient(white 30%, $color-transparent-black),
        linear-gradient($color-transparent-black, white 70%) 0 100%,
        /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), $color-black-rgba),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), $color-black-rgba) 0 100%;
      /* Opera doesn't support this in the shorthand */
      background-attachment: local, local, scroll, scroll;
      background-color: white;
      background-repeat: no-repeat;
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      overflow-y: auto;
      visibility: initial;

      .dropdownItemChild {
        max-height: unset;

        .linkContainer {
          max-height: unset;

          a:focus {
            @include submenu-button-on-focus();

            color: map-get($hubColors, blue-color-tertiary);
          }
        }
      }
    }
  }
}

@media #{$phoneMedia} {
  .navBarContainer {
    width: 100%;
    min-width: unset;
    max-width: unset;
    padding: unset;
    border: none;
  }
}
