@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";

.whiteBG {
  .spacingOptions__listItem {
    border: 1px solid map-get($background-and-border-colors, border);
    background: map-get($background-and-border-colors, 'background');
    &:hover {
      background: map-get($primary-colors, primary) !important;
    }
  }

  label:hover .spacingOptions__icon {
    fill: map-get($background-and-border-colors, 'background') !important;
  }

  .spacingOptions__icon {
    fill: map-get($background-and-border-colors, icon) !important;
  }

  input:checked + label {
    background: map-get($primary-colors, primary) !important;

    & .spacingOptions__icon {
      fill: map-get($background-and-border-colors, 'background') !important;
    }
  }
}

.spacingOptions {
  display: none;
  text-align: right;
  list-style: none;
  margin: 0;
  clear:both;

  &:hover &__icon {
    opacity: 0.5;
  }

  &__listItem {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    z-index: 1;
  }

  &__listLabelItem {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    z-index: 1;
  }

  &__input {
    display: none;
  }

  &__input:checked + &__label &__icon {
    opacity: 1;
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 5px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__label:hover &__icon {
    fill: map-get($primary-dark-colors, primary-dark);
    opacity: 1;
  }

  &__icon {
    fill: map-get($primary-colors, primary);
    opacity: 0.35;
    transition: 0.2s;
    width: 1.75em;
    display: inline-block;
    vertical-align: middle
  }
}

@media #{$smMedia} {
  .spacingOptions {
    display: inline-block;
  }
}

/* IE browser styles goes here*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .spacingOptions {
    &__icon {
      margin-bottom: -50px;
      margin-top: -60px;
    }
  }
}
