/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

/* make header parent sticky */
html[data-platform="oic"][data-page="/"] {
  div > div > div > div:first-of-type {
    position: sticky !important;
    z-index: 1;
    top: 0;
  }

  /* send content to back */
  div > div > div > div ~ div {
    z-index: 0;
  }

  /* unset header as absolute */
  div div div div header {
    position: relative !important;
  }

  /* hide undeeded padder div */
  div > div > div > div > header ~ div {
    display: none;
  }

  footer {
    border-top: none !important;
    background-color: #f2f4f6 !important;
  }

  footer div {
    border-top: none !important;
  }
}
