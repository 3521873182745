@import '../../styles/constants/colors.scss';

.cardWithThumbnail {
  .thumbnail {
    // Center the thumbnail
    display: flex;
    justify-content: center;

    // Make it appear above the white box
    position: relative;
  }

  .contentCard {
    box-shadow: 0 0 10px 3px #03030326;
    // Give it that card look
    background: $color-white;
    border-radius: 4px;

    // Put the top of the box half way behind the thumbnail with 10px between it and the content
    &.size-section {
      margin-top: -50px;
      padding: 60px 15px 15px;
    }
    &.size-heading {
      margin-top: -30px;
      padding: 40px 15px 15px;
    }
  }
}
