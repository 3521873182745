@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.cpHeader {
  background-color: map-get($cptColors, background);
  color: map-get($cptColors, white);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-align: $text-align-left;
  text-decoration: none;

  .cpDesktopView {
    position: relative;
    display: flex;
    width: 100%;
    height: 64px;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }

  .cpTitle {
    h1 {
      overflow: hidden;
      flex: none;
      flex-grow: 0;
      align-items: center;
      order: 0;
      margin: $gap-4 0 0 0;
      color: map-get($base, white100);
      font-size: $font-size-base;
      font-style: normal;
      font-weight: $font-weight-400;
      letter-spacing: $letter-spacing-0;
      line-height: $line-height-120;
      text-align: $text-align-left;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .cpSubtitle {
    overflow: hidden;
    flex: none;
    flex-grow: 0;
    align-items: center;
    order: 1;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    color: map-get($cptColors, white);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.017em;
    line-height: 150%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cpNavigationDiv {
    width: 60%;
    height: 38px;
    flex-grow: 1;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .cpNavProgressTitle {
    display: flex;
    align-self: center;
    justify-content: center;
    color: map-get($modern, kipper-yellow);
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-400;
    line-height: 150%;

    p {
      overflow: hidden;
      font-size: 16px;
      letter-spacing: $letter-spacing-0;
      line-height: $line-height-120;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .cpTitleDiv {
    width: 25rem;
    height: 100%;
    margin-right: $gap-2;
  }

  .cpCloseDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .cpClose {
    width: $icon-size-large;
    height: $icon-size-medium;
    padding: 0;
    border: 1px solid map-get($cptColors, light_blue2);
    margin: 0;
    background-color: map-get($cptColors, background);
    border-radius: 10px;
    color: map-get($cptColors, white);
    line-height: 2.75rem;
    text-align: center;

    svg {
      width: $icon-size-small;
      height: 1.25rem;
      margin-left: 0;
      fill: white;
    }
  }

  .cpHeaderRow {
    display: flex;
    min-width: 0;
    height: 100%;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 $gap-4;
  }

  p {
    font-size: $font-size-base;
    line-height: $line-height-120;
  }

  .cpMenuButton {
    width: $icon-size-large;
    height: $icon-size-medium;
    padding: 0;
    border: 1px solid map-get($cptColors, light_blue2);
    border-radius: 10px;
    fill: map-get($cptColors, white);
    line-height: 2.75rem;
    text-align: center;

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}

@media #{$xsMedia} {
  .cpHeader {
    .cpHeaderRow {
      padding: $gap-3 $gap-4;
    }

    .cpNavProgressTitle {
      min-width: 30%;
      height: 2.25rem;
      padding: 0 $gap-2;
      color: map-get($modern, kipper-yellow);
      font-family: $font-family-text-font;
      font-size: $font-size-base;
      font-weight: $font-weight-400;
      letter-spacing: $letter-spacing-0;
      line-height: 2.25rem;
      text-align: $text-align-right;
      text-decoration: none;
      text-overflow: ellipsis;

      p {
        line-height: 2.25rem;
      }
    }

    .cpNavRowBullet {
      justify-content: space-between;
      padding: $gap-1 $gap-4;
      gap: $gap-1;
    }

    .cpTitleDiv {
      width: auto;
      margin: 0;
    }
  }

  .cpDesktopView {
    display: none !important;
  }

  .cpMobileView {
    display: block;
  }

  .cpNavRowBarActive {
    width: 90%;
    height: $gap-2;
    align-self: center;
    background-color: map-get($modern, kipper-yellow);
    border-radius: 5px;
  }

  .cpNavRowBarInactive {
    width: 90%;
    height: $gap-1;
    align-self: center;
    background-color: map-get($primary, primary20);
    border-radius: 5px;
  }

  .cpHeaderRowMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
  }
}

@media #{$smMedia} {
  .cpDesktopView {
    display: block;
  }

  .cpMobileView {
    display: none;
  }
}
