@import "../../styles/constants/colors";

.header {
  align-items: center;
  display: flex;
  margin-top: 20px;
}

.initials {
  align-items: center;
  background-color: map-get($secondary-colors, register);
  border-radius: 50%;
  color: $color-white;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
  justify-content: center;
  margin-right: .625rem;
  width: 50px;
}

.heading {
  margin: 0;
}

.userName {
  color: map-get($hubColors, dark-gray);
  font-size: 0.875rem;
  margin-top: 2px;
}
