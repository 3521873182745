.searchForm {
  display: flex;
  gap: 20px;
}

.searchContainer {
  margin-top: 20px;
  margin-left: 20px;
}

.searchCriteriaContainer {
  flex-grow: 10;
}

.cardRow {
  display: flex;
  width: 100%;
  min-height: 48px;
  margin-bottom: 10px;
  gap: 10px;

  span {
    width: 20%;
    margin-top: 10px;
  }

  div {
    flex-grow: 1;
    margin-left: 0;
  }
}

.searchButtonsContainer {
  flex-grow: 2;

  div {
    display: flex;
    gap: 10px;
  }

  button {
    height: 48px;
  }
}

.cardRowRadio {
  display: flex;
  width: 100%;
  min-height: 48px;
  margin-bottom: 10px;
  gap: 10px;

  span {
    min-width: 20%;
  }

  span + div {
    flex: 1;
  }

  label {
    flex: 1;
  }
}

.resultsArea {
  margin-top: 10px;
}

.warningIcon {
  display: block;
  width: 50px;
  height: 50px;
  margin: 20px auto;
}

.errorMsg {
  text-align: center;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
