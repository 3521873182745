@use "sass:map";
@import "../../styles/constants/colors";

.validation {
  .jCenter {
    justify-content: center;
  }

  .topRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .childrenContainer {
      flex-grow: 1;
    }

    .iconSpace {
      width: 30px;
      height: 54px;
      flex-shrink: 0;
      padding: 21px 0 8px 10px;

      svg {
        width: 100%;
        height: 1.25rem;
        margin: 0 2rem 0 0;
      }
    }
  }

  .vstRoleMessage,
  .errorMessage,
  .warningMessage,
  .noticeMessage {
    position: relative;
    padding: 7px 15px;
    border-radius: 4px;
    margin: 5px 30px 0 0;
    background: $color-error;
    color: $color-white;

    &.noIcon {
      margin-right: 0;
    }

    &.rightHandInput::before {
      right: 41px;
    }

    &::before {
      position: absolute;
      top: -19px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: map.get($legacy-color-names, weak);
      content: "";
    }
  }

  .vstRoleMessage {
    background: $color-strong;

    &::before {
      position: absolute;
      top: -19px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: $color-strong;
      content: "";
    }
  }

  // height and width svg
  .loading {
    height: 30px !important;
    fill: $color-white;
  }

  .warningMessage {
    background: $color-warning;

    &::before {
      border-bottom-color: $color-warning;
    }
  }

  .noticeMessage {
    background: $color-primary-blue;

    &::before {
      border-bottom-color: $color-primary-blue;
    }
  }

  &.noInputErrorTopSpacing {
    .iconSpace {
      height: 30px;
      padding-top: 10px;
    }
  }

  // When the validation icon needs to be higher than usual, eg for a radio or ToggleButton:
  &.noLabelAboveField {
    .vstRoleMessage,
    .errorMessage,
    .warningMessage,
    .noticeMessage {
      margin: 5px 32px 0;
      text-align: center;

      &.rightHandInput::before {
        right: calc(100% - 53%);
      }
    }

    .onboardingWizardWarning {
      margin: 5px 32px 0 0;
    }

    .childrenContainer {
      align-items: center;
      padding-top: 6px;
      padding-left: 32px;
    }

    .iconSpace {
      padding-top: 16px;
      padding-left: 10px;

      svg {
        width: auto;
        height: 20px;
      }
    }
  }

  &.showValidationIconInsideInput {
    .topRow {
      position: relative;
    }

    input {
      padding-right: 2rem;
    }

    .iconSpace {
      position: absolute;
      top: 2.6rem;
      right: 0.8rem;
    }

    .iconSpaceRight15 {
      position: absolute;
      top: 0.15rem;
      right: 15.55rem;
    }

    .iconSpaceRight4 {
      position: absolute;
      top: 0.15rem;
      right: 4rem;
    }

    &.isValid {
      input {
        border-color: $color-good;
      }
    }
  }
}
