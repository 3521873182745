@import '../../styles/mixins/hides.scss';
@import '../../styles/constants/colors.scss';

.selectedFooter {
  padding: 1rem 0;
  border-bottom: 1px solid map-get($background-and-border-colors, border);
  margin: 0 1rem;

  span + span {
    padding-left: 8px;
    vertical-align: middle;
  }
}
