/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

:global {
  .only-print {
    display: none;
  }

  .print-link {
    position: absolute;
    top: 10px;
    right: 0;

    @media print {
      display: none;
    }
  }

  @media print {
    #fixedHeader,
    #subNavMenu {
      display: none;
    }

    .only-print {
      display: block;

      .glyphFormating {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        margin-bottom: -10px;
      }
    }

    .avoid-print-break {
      break-inside: avoid-page;
    }

    [title],
    [data-tooltip] {
      &::before,
      &::after {
        display: none;
      }
    }

    .orb-class-report {
      .grid {
        padding-right: 0;
        padding-left: 0;
      }

      .pad-top3,
      .scroll-container {
        display: none;
      }

      .gin-top8 {
        margin-top: 20px !important;
      }

      #searchResults-table {
        font-size: 14px;

        tr,
        thead {
          page-break-inside: avoid;
        }

        #row-toolbar {
          display: none;
        }
      }
    }
  }
}
