@import "../../styles/constants/breakpoints";
@import '~@oup/shared-front-end/src/styles/themes/base/sizes';

.ClassProgress {
  &__header {
    display: flex;
  }

  &__title {
    flex-grow: 1;
    margin-top: 0.3em;
  }

  &__export_button {
    display: none;
  }

  @media #{$lgMedia} {
    &__export_button {
      display: inline-block;
    }
  }
}

.progressSettingsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: $gap-4 0;
}

body[data-theme="hub-theme"] {
  .ClassProgress {
    &__header {
      width: 100%;
      justify-content: space-between;
    }
  }
}
