.libTab {
  // Fix: CES-1561: Don't use table-layou:fixed here because it causes overflow to the right :(
  table-layout: auto;

  td, th {
    &:first-of-type, &:nth-child(2) {
      width: 45%;
    }
    &:nth-child(3), &:nth-child(4) {
      width: 5%;
    }
  }
}
