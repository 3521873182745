.oicOrsTable {
  thead tr th:nth-last-child(2),
  tbody tr td:nth-last-child(2),
  thead tr th:last-child {
    text-align: center;
  }

  thead tr th span {
    white-space: nowrap;
  }

  .glyphClassName {
    width: 16px !important;
  }

  .tooltip {
    > span {
      width: 100%;
    }
  }

  .feedback {
    display: inline-flex;
    cursor: pointer;

    svg {
      display: inline-block;
      width: 16px;
      margin-left: 4px;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .linkButton {
    span {
      margin-right: 4px;
    }

    box-shadow: none;
  }
}
