@import './variables.scss';

//
// ORB Cards
// --------------------------------------------------

.orb-card-container {
  border: 1px solid $ces-border;
  border-radius: $ces-border-radius;
  margin-bottom: $padding-base-horizontal;

  .orb-card-header,
  .orb-card-content,
  .orb-card-footer {
    padding: $padding-base-horizontal 0;
    margin: auto;

    &.orb-card-horizontal {
      div {
        border-style: solid;
        border-color: $ces-border;
        border-width: 0px;
        border-right-width: 1px;

        &:last-of-type {
          border-width: 0px;
        }
      }
    }
  }

  .orb-card-header,
  .orb-card-footer {
    text-align: center;
    border-top: 1px solid $ces-border;

    &:first-of-type {
      border-width: 0px;
    }
  }

  .orb-card-header {
    @media (min-width: $grid-float-breakpoint-max) {
      text-align: left;
    }

    ~ .orb-card-content {
      padding-top: 0px;
    }

    .btn-primary {
      white-space: normal;
    }
  }

  .orb-card-content {
    margin-left: 0px;
    margin-right: 0px;

    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      justify-content: center;
    }

    .table-information {
      margin: 0px;
    }
  }

  h1,
  h2 {
    margin: 0px;
  }

  &.orb-card-timeline {
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: $padding-base-horizontal;
      left: 50%;
      transform: translate(-50%);
      background-color: $ces-border;
      z-index: 0;
    }

    &:before {
      margin-top: -16px;
    }

    &:after {
      margin-top: 1px;
    }
  }

  &.orb-card-resources {
    border-color: $orb-aqua;

    .orb-card-footer {
      border-color: $orb-aqua;
      background-color: $orb-aqua-lighter;
      border-radius: 0 0 $ces-border-radius $ces-border-radius;

      h2 {
        ~ button, ~ a {
          margin-top: 10px;
        }
      }

      button, a {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .orb-card-header,
    .orb-card-content,
    .orb-card-footer {
      &.orb-card-horizontal {
        div {
          border-width: 0px;

          .orb-data {
            padding: $padding-base-horizontal 0;
            border-bottom: 1px solid $ces-border;
          }

          &:first-of-type {
            .orb-data {
              padding-top: 0px;
            }
          }

          &:last-of-type {
            .orb-data {
              border-bottom-width: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }

      .pull-left,
      .pull-right {
        float: none !important;
      }
    }
  }
}
