@import '../../../styles/constants/colors';

.searchResultsCategory {
  padding-top: 1rem;
}

.searchResultsCategoryList {
  padding: 0 1rem;
}

.searchResultsItem {
  border-bottom: 1px solid $color-borders;
  padding: 1rem 0;

  &:last-child {
    border: none;
  }
}
