@import '../../styles/constants/breakpoints';
@import "../../styles/constants/colors";

.registerChoiceBody {
  display: flex;
  height: 450px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border-radius: 20px;

  .pad-top2 {
    width: 50%;
  }
}

.registerChoiceMg {
  margin-left: 5px;
}

.registerChoiceItems {
  display: flex;
  justify-content: center;
}

.registerOxfordIdRow {
  text-align: center;
}

.registerChoiceRow {
  justify-content: flex-end;
}

.registerChoiceCol {
  min-height: 100vh;
  justify-content: center;
  padding-top: calc(61px + 2rem);
  padding-bottom: 2rem;

  svg {
    width: 100%;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border: 1px solid $color-sub-text;
  background: $color-white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    height: 10px;
    fill: $color-black;

    &:hover {
      fill: $color-sub-text;
    }
  }
}

@mixin register_button {
  width: 180px;
  height: 40px;
  border: 1px solid $color-astronaut-blue;
  border-radius: 4px;
  font-size: 16px;
}

.registerButtonEmail {
  @include register_button;
}

.registerButtonGoogle,
.registerButtonApple,
.registerButtonMicrosoft {
  @include register_button;

  position: relative;
  display: flex;
  width: 265px;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  padding-left: 7px;
  border-width: 2px;
  border-style: solid;
  border-color: $contrast-dark;
  margin: 5px 0;
  border-radius: 20px;

  img {
    height: 100%;
    padding: 6px;
    margin: 0 6px;
    margin-left: 0;
  }
}

.registerButtonApple:focus,
.registerButtonApple:hover,
.registerButtonGoogle:focus,
.registerButtonGoogle:hover,
.registerButtonMicrosoft:focus,
.registerButtonMicrosoft:hover {
  box-shadow: 0 0 5px 5px $contrast-light-box-shadow;
}

.linkText {
  display: inline-block;
  padding: 0 10px;
  margin: 0;
  text-align: center;
}

.registerSubtext {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  hr {
    display: flex;
    width: 45%;
    height: 2px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.registerChoiceBg {
  background-image: url(/media/idp/self-managed-user/bg.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
