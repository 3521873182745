@import '../../styles/constants/colors.scss';

.rowContainerFormat tr td > div {
  padding-bottom: 7px;
  padding-top: 7px;
}

.rowContentFormat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: map-get($typography-colors, text);
}

.tableSpacing {
  margin-bottom: 30px;
}

.tableTitle {
  font-size: 18px;
}
