@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints.scss";
@import "../../styles/mixins/hub/hub-button-focus";

.resourceOuterModal {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s opacity ease-in-out;

  &.open {
    z-index: 9999999;
    background-color: map-get($secondary-colors, hub_dashboard);
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
  }

  .resourceModalContent {
    .resourceModalPreviewable {
      background-color: map-get($secondary-colors, hub_dashboard);

      .resourceModalIframe {
        position: absolute;
        z-index: -1;
        top: 89px;
        left: 0;
        width: 100%;
        height: calc(100% - 84px);
        border: none;
        box-shadow: none;
        outline: none;
      }
    }

    .resourceModalNonPreviewable {
      h1 {
        font-size: 34px;

        @media #{$phoneMedia} {
          font-size: 21px;
        }
      }

      .buttonContainer {
        text-align: center;

        button {
          border: none;
          margin-top: 1.5rem;
          color: map-get($primary-colors, primary);

          &:focus {
            @include standart-focus-button();
          }
        }
      }
    }
  }

  iframe {
    background-color: transparent;
  }
}
