@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.OteHomeTopRow {
  font-family: $open-sans;

  .heroContainer {
    position: relative;
    overflow: hidden;
    height: 40rem;
    max-height: 70rem;
    border: 15px map-get($oteColors, yellow) solid;

    .heroImage {
      position: absolute;
      width: 100%;
      height: 50rem;
      object-fit: cover;
    }

    @media #{$xsMedia} {
      height: 34rem;
    }

    .heroContent {
      position: absolute;
      z-index: 1;
      top: 20%;
      left: 10%;
    }

    .triangleImage {
      position: absolute;
      z-index: 0;
      left: 0;
      width: 110%;
      height: 105%;
      margin-top: -20px;
      object-fit: cover;
      object-position: 35%;
      opacity: 0.4;
    }
  }

  .halfSunContainer {
    display: flex;
    justify-content: center;
  }

  p {
    margin: 1rem 0;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1;
    text-align: left;
  }

  .blueBackground {
    background-clip: content-box;
    background-color: map-get($oteColors, ote_text_background_2);
  }
}
