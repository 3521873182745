@import "../../../../styles/constants/colors.scss";

.loader {
  display: grid;
  width: 100%;
  height: 260px;
  align-content: center;
  text-align: center;

  .stepsContainer {
    display: flex;
    height: 28px;
    flex-direction: row;
    align-items: center;
    gap: 1px;
    place-content: center;

    .step {
      display: flex;
      max-width: 10%;
      height: 28px;
      flex-grow: 1;
      background-color: map-get($background-and-border-colors, border-light-grey);
    }

    .step:first-child {
      overflow: hidden;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    .step:last-child {
      overflow: hidden;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .previousStep {
      height: 28px;
      background-color: map-get($background-and-border-colors, background-light-blue);
    }

    .animation {
      width: 0;
      height: 28px;
      align-self: center;
    }

    .grow {
      width: 100%;
      background-color: map-get($background-and-border-colors, background-light-blue);
      transition: width 0.3s linear;
    }
  }

  .outOfContainer {
    margin-top: 5px;

    p span:first-of-type {
      animation: fade-in-animation 0.3s ease-in forwards;
      color: map-get($background-and-border-colors, background-light-blue);
      font-weight: bolder;
      opacity: 0;
    }

    @keyframes fade-in-animation {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }

      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    p span:last-of-type {
      font-weight: bolder;
    }
  }
}
