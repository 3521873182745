@import "../../styles/constants/colors";

:global {
  @import "../../styles/orb/orb-filter";
}

.search {
  position: relative;

  .autocompleteSuggestions {
    background-color: $color-white;
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    z-index: 2;
    border: 1px solid map-get($background-and-border-colors, border);
    padding-top: 5px;
    border-radius: 4px;

    .item {
      color: black;
      padding: 5px 15px;
      border-bottom: 1px solid $color-very-light-grey;
      content: "";
      clear: both;
      display: table;
      width: 100%;

      &:hover {
        background-color: map-get($background-and-border-colors, selected);
        cursor: pointer;
      }

      span {
        &.match {
          float: left;
          width: 85%;
        }

        &.field {
          float: right;
          text-align: right;
          width: 15%;
          font-style: italic;
          color: map-get($hubColors, dark-gray);
        }
      }
    }
  }
}

.filters {
  select option {
    color: black;
  }
}
