.calendarContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;

  .calendarWrapper {
    display: flex;
    align-items: center;
  }
}

.popUp {
  width: 500px;
}
