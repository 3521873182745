.user {
  align-items: center;
  display: flex;
  flex-direction: flex-row;
  justify-content: between;
}

.processing {
  opacity: .5;
}

.cursorNotAllowed {
  cursor: not-allowed;
}
