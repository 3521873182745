@import "../../../styles/constants/colors";

.dateField {
  display: inline-block;

  > div {
    width: 100%;
  }

  input {
    padding: 0 40px;
  }

  svg {
    position: absolute;
    top: 50%;
    fill: $color-mid-grey;
    transform: translateY(-50%);

    &:nth-of-type(1) {
      left: 12px;
      width: 24px;
      height: 24px;
      /*
      these properties will be removed
      once we will have the right svg icon for the calendar.
      */
      mix-blend-mode: difference;
      opacity: 0.4;
    }

    &:nth-of-type(2) {
      right: 12px;
      width: 16px;
      height: 16px;
      color: #686767;
      fill: #686767;
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
