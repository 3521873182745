@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.entityWrapper {
  .link {
    display: block;
    background-color: transparent;
    mix-blend-mode: normal;

    &:hover {
      text-decoration: none;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .clipboardButton {
    padding: 0;

    &:focus {
      border-color: white;
      box-shadow: none;
      outline: none;
    }

    svg {
      width: 1em;
      height: 1em;
    }
  }

  .infoIcon {
    display: flex;
    width: 1em;
    height: 1em;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 1.2em;

    button:focus {
      @include no-padding-button-on-focus() ;
    }
  }

  .largeTooltip {
    display: initial;
    height: 30px;
    padding-right: 13px;

    ul li {
      display: list-item;
      margin-left: 12px;
    }

    p {
      color: $color-text-grey;
      font-size: 14px;
    }

    span {
      right: auto;
      left: 0;
      width: 268px;
      border: 2px solid skyblue;
      background-color: white;
      color: $color-text-grey;
      font-size: 14px;
      text-align: left;
      transform: none;

      &::after {
        right: auto;
        left: 11px;
        border-bottom-color: white;
        transform: none;
      }

      &::before {
        position: absolute;
        right: 50%;
        bottom: 100%;
        left: 18px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-width: 0 10px 10px;
        border-style: solid;
        border-color: transparent transparent skyblue;
        border-top: 0;
        content: "";
        transform: translateX(-50%);
      }
    }
  }

  .text {
    > h2 {
      max-width: 350px;
      margin: 0;
      color: $color-text;
    }

    > p {
      font-size: 1rem;
    }

    &.deletedStyle > p {
      color: $color-sub-text;
    }

    > p:not(:first-child) {
      margin-top: 2px;
      font-size: 0.875rem;

      &:not([class*="-fg"]) {
        color: $color-sub-text;
      }

      &.line3color {
        color: map-get($validation, validation-green100);
        font-weight: bold;
      }
    }

    &.ellipsisText > h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.leftAlignment {
      span {
        text-align: left;
      }
    }

    &.centerAlignment {
      span {
        text-align: center;
      }
    }

    &.rightAlignment {
      span {
        text-align: right;
      }
    }

    a[class]:hover {
      color: map-get($styleGuideColors, primary);
      text-decoration: none;
    }
  }

  .line1Text {
    display: inline-block;
  }

  .line2Color {
    display: flex;
    align-items: baseline;
    color: $color-sub-text;
  }

  .line3color {
    color: map-get($validation, validation-green100);
  }
  .linealignment {
    display: flex;
  }

  .badge {
    display: inline-block;
    padding: 3px 5px;
    border: 1px solid;
    border-radius: 4px;
    margin-top: 2px;
    font-size: 0.8rem;
  }

  .statusIndication {
    width: 17px;
    height: 15px;
    flex-shrink: 0;
    margin-right: 4px;
    vertical-align: middle;
  }

  .badgeWithMargin {
    margin-left: 10px;
  }

  .line4Text {
    padding: 0 0.375rem;
    border-radius: 1rem;
    background-color: map-get($secondary-colors, hub_dashboard);
    color: $color-white;
    font-size: 0.8125rem;
  }

  .helpAndSupportLink {
    display: inline-block;
    margin-left: 0.5rem;
    color: map-get($secondary-colors, hub_dashboard);
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      color: map-get($secondary-colors, hub_dashboard);
      filter: brightness(50%);
    }
  }

  .linkGlyph {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
}
