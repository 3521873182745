.tabCaption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button > svg {
    margin-left: 0 !important;
  }
}

.tabCaptionContent {
  display: inline-block;
  overflow: hidden;
  padding-right: 0.5em;
  font-size: 1.3em;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
