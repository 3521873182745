@import "../../styles/constants/colors";

.backButton {
  height: 40px;

  // Make sure the button is given enough width
  flex-shrink: 0;
  border: 1px solid $color-borders;
  background-color: $color-white;
  border-radius: 4px;
  padding: 3px 10px;
  color: $color-primary-blue-dark;
}
