@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "../../styles/constants/variables";

.OicHomePageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
