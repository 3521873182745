// Standard colours and animations for SVG icons

@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Some browsers need to be prevented from setting focus on SVG when it's clicked:
.svgIcon[tabindex="-1"] {
  pointer-events: none;
}

// Animate:
.icon-ICON_LOADING {
  animation: spin 1s linear infinite;
  fill: $color-primary-blue;
}

// Animate:
.icon-PROCESSING {
  animation: spin 8s linear infinite;
}

.icon-ICON_SETTINGS {
  fill: $color-primary-blue;
}

// Rotate just a smidge:
.icon-ICON_HELP {
  transform: rotate(-7deg);
}

// Rotate and shrink "x" to make "+":
.icon-ICON_PLUS {
  transform: scale(0.75);
}

// Rotate: If any more icons need rotations then we could DRY these into a loop:
.icon-CHEVRON_UP,
.icon-CHEVRON_UP_THICK,
.icon-ARROW_UP {
  transform: rotate(270deg);
}

.icon-CHEVRON_DOWN,
.icon-CHEVRON_DOWN_THICK,
.icon-ARROW_DOWN {
  transform: rotate(90deg);
}

.icon-ICON_ARROW_LEFT {
  transform: scale(-1, 1);
}

// Colour:
.icon-ICON_CHECK_CIRCLE {
  fill: $color-good;
}

.icon-ICON_TICK {
  stroke: map-get($typography-colors, text);
}

.icon-ICON_ERROR_CIRCLE {
  fill: $color-error;
}

.icon-ICON_WARNING_CIRCLE {
  fill: $color-warning;
}

.icon-INFOINFO,
.icon-ICON_CHECKBOX_CHECKED,
.icon-ICON_RADIO_CHECKED,
.icon-VALIDATION_WARNING_BLUE {
  fill: $color-primary-blue;
}

.icon-ICON_CHECKBOX_UNCHECKED,
.icon-ICON_RADIO_UNCHECKED {
  fill: $color-background;
}

.icon-ICON_MENU,
.icon-ICON_PENDING,
.icon-ICON_INVITED,
.icon-ICON_ARCHIVED {
  fill: $color-background-dark;
}

.icon-REQUESTED {
  fill: $color-requested;
}

.rounded {
  border-radius: 50%;

  > * {
    transform: scale(0.8);
    transform-origin: center;
  }
}

.streamline {
  width: 40px;
  height: 40px;
}

.demo svg {
  width: 100px;
}

.icon-ICON_RESTORE {
  fill: $color-background-dark;
  transform: rotate(180deg);
}

.icon-ICON_HELP_CIRCLE {
  color: map-get($modern, madrid-orange);
  fill: map-get($modern, madrid-orange);
}

.icon-ICON_REMOVED_CIRCLE,
.icon-ICON_REFRESH_CIRCLE,
.icon-ICON_INFORMATION_CIRCLE {
  color: map-get($primary, primary100);
  fill: map-get($primary, primary100);
}

.icon-ICON_USER_CIRCLE {
  color: map-get($base, white100);
  fill: map-get($base, white100);
}
