.GradebookCard {
  margin-bottom: 1rem;

  &__title {
    margin-top: 5px;
  }

  &__table {
    width: 100%;

    th, td {
      text-align: left;
      padding-top: 5px;
    }
  }
  &__total {
    font-size: 1.5em;
  }

  &__totalLabel {
    font-size: .9rem;
    font-weight: bold;
  }
};
