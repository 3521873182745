.searchContainer {
  margin: 20px;
}

.fieldsList {
  list-style-type: disc;

  li {
    display: list-item;
    margin-left: 20px;
  }
}

.searchForm {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.searchCriteriaContainer {
  flex-grow: 10;
}

.searchButtonsContainer {
  flex-grow: 2;

  div {
    display: flex;
    gap: 10px;
  }

  button {
    height: 48px;
  }
}

.cardRow {
  display: flex;
  width: 100%;
  min-height: 48px;
  margin-bottom: 10px;
  gap: 10px;

  span {
    width: 20%;
    margin-top: 10px;
  }

  select {
    width: 58px;
    padding-right: 10px;
    padding-left: 10px;
  }

  div {
    flex-grow: 1;
    margin-left: 0;
  }

  &:first-of-type {
    margin-top: 10px;
  }
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resultsContainer {
  margin-top: 20px;
}
