@import "../../styles/constants/colors";

.editUserFailure {
  .title {
    font-size: 1rem;
    font-weight: bold;
  }

  .editUserFailureText {
    margin-top: 30px;
    color: $color-grey-border;
    font-size: 18px;
    text-align: center;
  }

  .editUserFailureTextBold {
    font-weight: bold;
  }
}
