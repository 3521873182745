@import "../../styles/constants/colors";

.parentDiv {
    display: inline-block;
}
.parentDiv > a {
    word-break: break-word;
}
.childDiv {
    margin-top: 2px;
    display: flex;
    justify-content: flex-start;
}
.yearFormat {
    display: inline-block;
    min-height: 20px;
    border-radius: 5px;
    padding: 0px 5px;
    font-size: 13px;
    border: solid 1px map-get($background-and-border-colors, border);
}
.notesFormat {
    background-color: map-get($secondary-colors, class_average);
    height: 20px;
    border: 1px solid map-get($modern, big_apple_green);
    line-height: 0.3;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 22px;
    color: map-get($modern, big_apple_green);
    margin: 0 0 0 5px !important;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
}
.iconFormat {
    height: 20px;
    vertical-align: bottom;
    display: inline-block;
}
