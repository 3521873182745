@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';

// Used by both the help & user popups

// This div part of the CSS selector is required to increase its specificity
.headerPopup {
  // Mobile width and position
  width: calc(100vw - 2.5rem);

  // full width links with hover backgrounds
  .fullWidthLink,
  .fullWidthLinkShort {
    display: flex;
    height: 58px;
    padding: 9px 15px;
  }

  // Note this only applies to <a> elements:
  // Because there are places where we borrow .fullWidthLink style on a div.
  a.fullWidthLink:hover,
  a.fullWidthLinkShort:hover {
    background: map-get($styleGuideColors, selected);
    color: $color-primary-blue;
    text-decoration: none;
  }

  .fullWidthLinkShort {
    height: 40px;
  }

  .miniEntity {
    li + li, div + div, p:last-of-type {
      font-size: 13px;
      color: map-get($hubColors, dark-gray);
      margin-top: 0;
    }
  }

  // sections
  .section {
    padding: 10px 15px;

    // First section is headings
    &:first-of-type {
      p, h2 {
        color: $color-sub-text;

        // Title styling
        &:first-of-type {
          color: $color-text;
          font-weight: 600;
          font-size: 1.25em;
          padding-bottom: 5px;
        }

        // sub Title styling
        &:last-of-type {
          margin: 0;
        }
      }
    }

    // Last section is a centered link
    &:last-of-type {
      text-align: center;
    }
  }
}

@media #{$smMedia} {
  // This div part of the CSS selector is required to increase its specificity
  div.headerPopup {
    white-space: nowrap; // This needs confirming, it may cause issue with large text from the CMS
    width: auto;
  }
}
