@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.folderContainer {
  display: flex;
  min-height: 64px;
  flex: 1;
  align-items: center;
  border-bottom: 1px solid $color-light-grey;
  color: map-get($legacy-color-names, tooltip);
  cursor: pointer;

  &:hover {
    background-color: map-get($secondary-colors, hub_light_grey);

    .thumbnailContainer {
      fill: map-get($hubColors, blue-color-dark);
    }
  }

  &:focus {
    @include standart-focus-button();

    outline: none;
  }

  .expandButtonContainer {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .fallbackSvgContainer {
    display: flex;
    overflow: hidden;
    width: 100%;
    min-width: 2.425rem;
    max-width: 2.425rem;
    height: 3.125rem;
    align-items: center;
    justify-content: center;
    background-color: color(primary-dark);
    border-radius: 5px;

    svg {
      z-index: 100;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      fill: $color-icon;
    }
  }

  .thumbnailContainer {
    width: 15px;
    height: 15px;
    box-sizing: content-box;
    margin: 0 15px 0 10px;
    fill: map-get($hubColors, blue-color-primary);
    transition: 0.3s ease-out transform;
  }

  .collapsedThumbnail {
    transform: rotate(-180deg);
    transition-duration: 0.3s;
  }

  .folderTitle {
    margin-left: 15px;
    font-weight: 600;
  }
}
