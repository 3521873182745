@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";

.sticky {
  thead {
    display: block;
    background-color: white;
    z-index: 2; /* To avoid issues with some tbody elements with z-index: 1 (like .levelProgressBar button) */

    th:first-child div span:nth-child(2) {
      display: none;

      @media #{$lgMedia} {
        display: inline;
      }
    }
  }

  @media print {
    page-break-inside: avoid;
  }

  @media #{$smMedia} {
    tr {
      display: flex;
    }

    thead {
      tr > th {
        display: block !important;
      }
    }

    tbody {
      display: block;

      tr > td {
        display: block !important;
      }

      tr > *:last-child {
        width: 80%;
      }

      .fullWidth,
      tr:first-child td {
        width: 100% !important;
      }
    }
  }
}
