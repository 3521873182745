@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors';

.linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.linkGlyph {
  width: 1.3rem;
  height: 1.3rem;
  fill: map-get($hubColors, hub_link);
  vertical-align: bottom;

  .link:hover {
    color: map-get($hubColors, hub_link_hover);

    .linkGlyph {
      fill: map-get($hubColors, hub_link_hover);
    }
  }
}

.chartContainer {
  padding: 20px;
}

.tableContainer {
  overflow: auto;
}

.table {
  position: relative;

  @media #{$xsMedia} {
    margin-top: 50px;
  }

  thead {
    th {
      display: table-cell !important;
      width: 40%;
      text-align: left;

      &:first-child {
        width: 20%;
      }
    }
  }

  tbody {
    tr td {
      display: table-cell !important;
      text-align: left;

      @media #{$smMedia} {
        &:first-child {
          width: 20% !important;
        }

        &:last-child {
          width: 80% !important;
        }
      }

      &:not(:first-child) {
        padding: 15px 20px;
        white-space: nowrap;
      }
    }
  }

  .sortControl {
    position: absolute;
    top: -15px;
    right: 0;
    flex: 1 1 auto;
    padding-right: 15px;
    margin: 0 !important;
    font-size: 1rem;
    font-weight: normal;
    text-align: right;

    @media #{$xsMedia} {
      top: -60px;
      padding-right: 0;
    }
  }
}
