@import "../../styles/constants/colors";

:global {
  .teacher-app-download {
    background-color: map-get($hubColors, product_finder_tab_unselected_text);
    height: 100vh;
    color: white;

    &.with-error {
      background: white;
    }

    .error-message {
      border-radius: 4px;
      background: map-get($legacy-color-names, weak);
      color: $color-white;
      margin: 1em;
      padding: 7px 15px;
    }

    &.android {
      .first-section {
        .download-button {
          padding: 20px;

          span {
            position: relative;
            top: 15px;
            line-height: 30px;
          }
        }
      }
    }

    a {
      color: white;
      font-weight: 600;
      text-decoration: underline;
    }

    .first-section {
      background-color: map-get($background-and-border-colors, background-light-blue);
      padding: 40px;

      h1 {
        display: inline;
      }
      h3 {
        margin-bottom: 40px;
      }
      .platform {
        font-size: 35px;
        font-weight: 400;
      }
      .download-button {

        box-sizing: border-box;
        margin: 0;
        font: inherit;
        overflow: visible;
        text-transform: none;
        font-family: inherit;
        line-height: 1.42857;
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        border-bottom: 3px solid transparent;
        font-size: 16px;
        border-radius: 4px;
        user-select: none;
        background-color: $color-white;
        padding: 40px;
        width: 50%;
        max-width: 500px;
        white-space: normal;
        color: map-get($background-and-border-colors, background-light-blue);
        border-color: map-get($background-and-border-colors, border);

        &:hover {
          background-color: $color-star-tips;
        }

        .icon {
          width: 40px;
          height: 40px;
          float: left;
          background: url("/static/images/orb/icon-download.svg");
          background-position: 80px -40px;
        }

        .icon-android {
          width: 60px;
          height: 60px;
          float: left;
          background: url("/static/images/orb/app-icon.svg");
        }
      }
    }

    .second-section {
      background-color: map-get($hubColors, product_finder_tab_unselected_text);
      padding: 40px 20px;

      .instructions {
        width: 50%;
        float: left;

        .info {
          background: url("/static/images/orb/sprite_icons.svg");
          background-position: 40px 40px;
          width: 40px;
          height: 40px;
          margin-bottom: 20px;
          margin-left: 20px;
        }

        ul {
          padding-left: 20px;

          li {
            margin-bottom: 40px;
          }
        }
      }
      .flying-books {
        background-image: url('/static/images/orb/content-image_flying-books.svg');
        width: 50%;
        background-repeat: no-repeat;
        float: right;
        height: 200px;
        background-position: center;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .teacher-app-download {
      .first-section {
        .platform {
          font-size: 20px !important;
          display: block;
        }
        .download-button {
          width: 100%;
          padding: 20px;

          .icon, .icon-android {
            float: none;
            margin: auto;
          }
        }
      }
      .second-section {
        .instructions {
          width: 100%;
        }
        .flying-books {
          width: 100%;
        }
      }
    }
  }
}
