@import "../../styles/constants/colors";

.header {
  margin-top: 20px;
}


.heading {
  margin: 0;
}

.userName {
  color: map-get($hubColors, dark-gray);
  font-size: 0.875rem;
  margin-top: 2px;
}
