.continueButtonContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.resetPasswordConfirmationRow {
  width: 100%;
}

.errorButtonContainer {
  display: flex;
  justify-content: center;
}
