@import '../../styles/constants/colors.scss';

.roundedBorder {
  border: 1px solid map-get($background-and-border-colors, border);
  border-radius: 5px;
  padding: 10px;
}

.resultsLimiter {
  margin-top: 10px;
  max-height: 181px;
  overflow: auto;
  width: 100%;
}

.resultLine {
  border-bottom: 1px solid map-get($background-and-border-colors, border);;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  line-height: 30px;
  white-space: nowrap;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}
