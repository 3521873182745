@import '../../styles/constants/colors.scss';

.copy {
  color: $color-sub-text;
}

.answer {
  composes: copy;
  font-size: 21px;
  line-height: 28px;
}

.list {
  border: 1px solid $color-botton;
  border-radius: 6px;
  margin: 20px 0;
}

.item {
  font-size: 16px;
  padding: 15px;
  border-top: 1px solid $color-botton;
}

.borderless {
  border-top: none;
}

.item a {
  display: block;
  font-size: 13px;
}
