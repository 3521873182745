.textInputWithButtonAndDropdown {
  // The inner div containing the field and button should be in a row
  & > div {
    display: flex;
    flex-direction: row;
  }

  .dropdown {
    flex-shrink: 0;
    max-width: 50%;

    select {
      width: 100%;
      border-radius: 4px 0 0 4px;
    }
  }

  input {
    // Make the input box sit straight against the button
    border-radius: 0;
    flex-grow: 1;
  }
}
