@import "../../styles/constants/colors";

.editUserFailure {
  .title {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
  }

  .list {
    padding-left: 1.2em;

    li {
      display: list-item;
    }
  }
}

.button {
  &.backButton {
    width: auto;
    min-width: 75px;
    border-bottom-color: transparent;
    background-color: transparent;
    float: left;

    &:hover {
      background: transparent;
      color: map-get($hubColors, blue-color-dark);

      svg {
        fill: map-get($hubColors, blue-color-dark);
      }
    }
  }

  &.doneButton {
    position: relative;
    top: -0.75rem;
    width: auto;
    min-width: 75px;
    max-width: 75px;
    border-bottom-color: transparent;
    float: right;
  }
}
