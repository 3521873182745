@import "../../styles/constants/colors";

.table {
  margin-top: 1rem;
  border-bottom: 1px solid map-get($background-and-border-colors, border);
}
.actionsContainer {
  display: flex ;
  justify-content: space-between;
}
