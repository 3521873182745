@import '../../styles/constants/breakpoints.scss';

.progressGauge {
  position: relative;
  display: flex;
  justify-content: center;
}

.display {
  font-weight: bold;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  z-index: -1;
  font-size: inherit;

  &.small {
    font-size: 11px;
  }
}

.errorDisplay {
  font-weight: bold;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 80%;
  left: 10%;
  z-index: -1;
  font-size: 15px;

  &.small {
    SVGIcon {
      max-width: 3px;
    }
    font-size: 8px;
  }
}

.label {
  line-height: 2.5;
}

.errorLabel {
  line-height: 1;
}

.percentage {
  line-height: 0.8;
  font-size: 3em;
}

.warningSvg {
  min-width: 35px;
  max-width: 35px;
}
