@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

$transitiontime: 0.4s;

// controlShape
.controlShape {
  position: relative;
  display: flex;
  display: inline-block;
  width: 60px;
  height: 34px;
  padding: 10px 7px;
  margin-right: 1em;
  background-color: map-get($legacy-color-names, background-dark);
  border-radius: 34px;
  transition: $transitiontime;
}

.selected {
  background: $color-correct;

  ::before {
    transform: translate(26px, 0);
    transition: $transitiontime;
  }
}

// structural container for sub-elements: interaction area for clicks.
.toggleSwitch {
  display: flex;
  width: 100%;
  flex-direction: row;

  &,
  input {
    cursor: pointer;
  }

  &:focus,
  &:hover {
    .controlShape {
      filter: brightness(1.15);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  // Label before text display as block
  .blockLabelBefore {
    display: block;
    align-self: center;
    line-height: 1.4;
    text-align: left;
  }
  // text prompt
  .labelText,
  .labelBeforeText {
    display: inline;
    align-self: center;
    line-height: 1.4;
    text-align: left;
  }

  .labelText {
    flex: 1;
    font-weight: 600;
  }

  // the ball inside the toggle
  .toggleSlider {
    &::before {
      position: absolute;
      bottom: 4px;
      left: 4px;
      display: inline-block;
      width: 26px;
      height: 26px;
      background-color: white;
      border-radius: 50%;
      content: "";
      transition: $transitiontime;
    }
  }

  // String toggle type styles
  &.type-STRING_TOGGLE {
    display: block;
    min-height: 40px;
    margin: 0; // This has to be specific unfortunately

    .onOffString {
      align-self: center;
      line-height: 1.4;

      &--active {
        font-weight: bold;
      }
    }

    .controlShape {
      margin: 0.2em 0.5em -0.7em;
      background: map-get($primary-colors, primary);
    }
  }

  svg {
    width: 15px;
    height: 15px;
    margin-left: .2em;
    display: block;
    fill: $color-white;
  }

  &.type-THEMED_TOGGLE {
    margin: 3px 0;

    svg {
      display: none;
    }

    .labelBeforeText {
      margin-right: 10px;
      text-decoration: underline;
    }

    .controlShape {
      background: var(--service-brand);

      &.selected {
        background: white;

        .toggleSlider {
          &::before {
            background: var(--service-brand);
          }
        }
      }
    }
  }

  // html elements
  input {

    // By styling the hidden checkbox to mimic shape of ToggleSwitch,
    // the highlight added by some Assisitive Tech will align with visual element:
    position: absolute;
    z-index: 99;
    width: 60px;
    height: 34px;
    margin: 0;
    opacity: 0;

    &:focus ~ .controlShape {
      @include standart-focus-button();

      border-color: $color-borders-v2; // Custom non-standard color here
      outline: none;
    }
  }
}
