.navigatorCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.leftArrow {
  margin-right: 30px;
}
.rightArrow {
  margin-left: 30px;
}
.noCursor {
  cursor: auto;
}
.clickCursor {
  cursor: pointer;
}
.pointerSvg {
  fill: lightblue;
  height: 12px;
  width: 12px;
}
.hidden {
  visibility: hidden;
  height: 12px;
  width: 66px;
  margin-bottom: 15px;
}
.progressHeader {
  font-size: 21px;
  line-height: 30px;
  margin: 0;
}
.previousAttemptHeader {
  text-align: left;
  width: 100%;
}
.previousAttemptHeaderText {
  font-weight: 300;
  font-size: 27px;
}
