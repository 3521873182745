@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.selectionList {

  // Drop 0.5rem of the right and bottom margin as its provided by the content
  padding: 1rem 0.5rem 0.5rem 1rem;
  border: 1px solid $color-borders;
  border-radius: 4px;

  .link {
    margin: 0 0.5rem 0.5rem 0;

    // Link color
    color: $color-primary-blue;

    svg {
      fill: $color-primary-blue;
    }

    // Link hover color
    &:hover {
      color: $color-primary-blue-dark;

      svg {
        fill: $color-primary-blue-dark;
      }
    }

    &:focus {
      @include no-padding-button-on-focus();
    }
  }

  // Put margin after and below every Lozenge
  .childrenContainer {
    li {
      display: inline-block;
    }
    // Font size 0 to remove whitespace between inline-block elements
    font-size: 0;

    // Spacing after and below each Lozenge
    > * {
      margin: 0 0.5rem 0.5rem 0;
    }
  }
}
