@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.scrollContainer {
  position: relative;

  // Diplay as a flex column
  display: flex;
  width: 100%;

  // Should fill container
  height: 100%;
  flex-direction: column;

  // Header and footer should always be given the height they need
  .header,
  .footer {
    flex-shrink: 0;
  }

  // Children should fill remaining height and scroll any overflow
  .children {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  // Header and footer always have a border
  .header {
    height: auto !important;
    padding: 1rem;
    border-bottom: 1px solid $color-borders;
  }

  .enhancedHeader {
    height: auto !important;
    padding: 1rem;

    h2 {
      font-size: 2rem;
    }
  }

  .hubHeaderConfirmation {
    border: unset;
  }

  .footer {
    height: auto !important;
    border-top: 1px solid $color-borders;
  }

  &::before {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.modalFooter {
    // Overlay in front ot header & children:
    &::before {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: $contrast-light-accent-50;
      content: '';
      opacity: 1;
    }

    // Footer shows in front of overlay:
    .footer {
      position: relative;
      background-color: $color-white;
    }
  }

  a:focus {
    @include no-padding-button-on-focus;
  }
}

.hubScrollContainer {
  overflow: auto;
  padding: 50px 0 85px;

  .button-close-confirmation-hub {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 13px 10px;
    background: #fff;
  }

  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto !important;
    border-top: 1px solid $color-borders;
    background: #fff;
  }
}
