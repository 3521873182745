@import '../../styles/constants/colors.scss';

.loadingSpinner {
  text-align: center;
  padding: 1rem;
}

.icon {
  width: 5rem;
  margin: 0 auto 1rem;
}

.small {
  composes: icon;
  width: 3rem;
}

.medium {
  composes: icon;
}

.large {
  composes: icon;
  width: 7rem;
}
