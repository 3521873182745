@import '../../styles/constants/colors.scss';

.emailBox {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 36px;
  border-radius: 5px;
  background-color: $color-borders;
}

.contentContainer {
  min-height: 350px
}

.address {
  font-style: normal;
}
