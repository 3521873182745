@import "../../styles/constants/breakpoints.scss";
@import "../../styles/constants/colors";

.borders {
  @media #{$mdMedia},
  #{ipadAirMedia} {
    border-top: solid 1px map-get($legacy-color-names, background-dark);
    border-right: solid 1px map-get($legacy-color-names, background-dark);
    min-height: 60vh;
  }
}

.dropdownResponsive {
  display: contents;

  @media #{$smMedia} {
    display: none !important;
  }
}

.hider__responsive {
  display: none;

  @media #{$smMedia} {
    display: flex !important;
    flex-direction: column;
    width: 240px;
  }
}

.linkFormatter {
  color: grey;
  cursor: pointer;
}

.flexTitle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.setTableWidth {
  width: 100%;
  overflow: hidden;
}

.iconLinkFormat {
  position: absolute;
  right: 5px;
  top: 0px;
}

.hide {
  display: none;
  @media #{$mdMedia},
  #{ipadAirMedia} {
    display: flex;
  }
}

.show {
  display: flex;
  justify-content: space-around;
  width: 90vw;

  @media #{$mdMedia},
  #{ipadAirMedia} {
    display: none;
  }
}
.showInline {
  display: inline;

  @media #{$mdMedia},
  #{ipadAirMedia} {
    display: none;
  }
}
.readingData {
  font-size: 25px;
  font-weight: 400;
  margin-top: 0px !important;
}

.linkFormat {
  color: map-get($background-and-border-colors, background-light-blue);
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
}

.readingTime__flex {
  display: flex;
  align-items: flex-end !important;
}

.h2Format {
  margin-bottom: 0px;
}

.paraFormat {
  margin-bottom: 5px;
  padding: 0 3px 0px 3px;
}

.divFlex {
  display: flex;
}

.flexMargin {
  flex-grow: 1;
  margin-top: 0.3em;
  text-align: left;
}

.blackText {
  color: black;
}

.subTitleFormat {
  font-weight: 300;
  margin-top: 3px;
}

.alignText {
  margin: 0 3px 10px 3px;
}

.marginTopper {
  margin-top: 10px;
}

.cursorBold {
  cursor: pointer;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.pointerNoMargin {
  cursor: pointer;
  margin: 0;
}

.flexRowThumb {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.subtitleText {
  font-size: 0.9rem;
}

.emphasisedText {
  font-size: 1.5rem !important;
}

.thumbNailFormat {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid map-get($background-and-border-colors, border);
}

.barSizer {
  width: 37vw;
}

.bottomBorder {
  border-bottom: solid 1px map-get($legacy-color-names, background-dark) solid;
  padding: 12px;
}

.backgroundCenter {
  background: map-get($validation-colors, pending);
  border-radius: 50%;
  color: white;
  height: 3.5em;
  width: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 1em;
}

.addScroll {
  overflow-x: auto;
}

.rowWidth {
  margin-top: 2rem;
  width: 100%;
}

.students__list {
  .responsiveBorder {
    border-right: solid 1px map-get($legacy-color-names, background-dark);
    width: 200px;
  }
}

.boxFormat {
  border-bottom: solid map-get($legacy-color-names, background-dark) 1px;
  padding: 12px;
}

.centerBoxFormat {
  text-align: center;
}

.largerWidth {
  margin-top: 2rem;
  width: 110%;
}

/* IE browser styles goes here*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .addScroll {
    overflow: hidden;
  }
}
