@import '../../styles/constants/colors.scss';

.wrapper {
  background-color: #FFF;
  border: 1px solid map-get($typography-colors, sub-text);
  overflow: hidden;
  padding: 30px 0;
  position: relative;
  text-align: center;
}

.ribbon {
  background-color: map-get($typography-colors, sub-text);
  border-width: 0;
  height: 35px;
  left: -107px;
  position: absolute;
  top: 21px;
  transform: rotate(315deg);
  width: 300px;
}

.sample {
  border-width: 0;
  color: $color-white;
  font-size: 1.1rem;
  font-weight: 700;
  left: 2px;
  position: absolute;
  top: 8px;
  width: 296px;
  word-wrap: break-word;
}

.detail {
  padding-bottom: 8px;
}

.label {
  font-size: 1.2rem;
}

.value {
  font-weight: bold;
}
