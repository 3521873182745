/* These styles are intentionally global */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import '../mixins/colors';
@import '../constants/colors';

// Generate color classnames based on the color variables specified in the constants folder
// TODO: OPTIMISATION: Only generate css classes for $styleGuideColors instead of all $colors, but
// first you'd need to replace all legacy color references, eg: colors.LEARNERS --> colors.LEARNER.
:global {
  @each $colorName, $colorValue in $colors {
    $darkerColorValue: darken($colorValue, 20%);

    // Foregound: (Text or SVG)
    .color-#{$colorName}-fg {
      color: $colorValue;
      fill: $colorValue;
    }

    // Background:
    .color-#{$colorName}-bg {
      background-color: $colorValue;

      // The .hoverable class is used to darken things like Thumbnails when mouseover parent <tr>:
      :global(.hoverable):hover & {
        background-color: $darkerColorValue;
      }
    }
    .color-#{$colorName}-dark-bg {
      background-color: $darkerColorValue;
    }
    .color-#{$colorName}-dark-bg-hover:hover {
      background-color: $darkerColorValue;
    }

    // Border:
    .color-#{$colorName}-bd {
      border-color: $colorValue;
    }
    .color-#{$colorName}-dark-bd {
      border-color: $darkerColorValue;
    }
  }

  .color-primary-blue-dark-bg {
    background-color: $color-primary-blue-dark;
  }

  .color-classroom-bg {
    color: $color-white;
  }
}
