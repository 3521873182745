@import "../../styles/mixins/hub/hub-button-focus";

.paginationButtons {
  display: flex;
  flex-direction: row;

  &.center {
    align-items: center;
    justify-content: center;
  }

  li {
    display: flex;
    max-width: 64px;
    flex-grow: 1;
  }

  li button {
    flex-grow: 1;

    &:focus {
      @include standart-focus-button();
    }
  }
}
