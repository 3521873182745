@import '../../styles/constants/colors.scss';

$imageSize: 30px;

.passwordInputV2 {
  width: 100%;

  .inputContainer{
    display : flex;
    // Shape and size
    border-radius: 4px;
    padding: 0 5px 0 12px;
    line-height: 38px;
    height: 40px; // Need to explicitly set the height to solve an issue with date inputs

    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    // Create a transparent border when not validated or focused
    border-style: solid;
    border-width: 1px;
    border-color: map-get($background-and-border-colors, border);

    box-shadow: inset 0 1px 1px $contrast-light-shadow;

    &:focus-within {
      border-color: $color-borders-v2; // Custom non-standard color here
      box-shadow: inset 0 1px 1px $contrast-light-shadow,
        0 0 8px $contrast-light-blue3;
    }

    .svgContainer {
      height: $imageSize;
      width: $imageSize;
      align-self: center;
      margin-left: 12px;
      align-items: flex-end;
      justify-content: flex-end;

      &:hover {
        cursor : pointer;
      }
    }

    .inputField {
      // Should fill its container
      flex: 1;
      width: 100%;

      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      border-style: none;
      border-color: Transparent;
      overflow: auto;

      &:disabled {
        // non-standard colors
        color: $color-text-grey-disabled;
        background: $color-grey-disabled;
      }

      &.textArea {
        line-height: 24px;
        font-size: 1rem;
        resize: vertical;
        height: 12rem;
        padding-top: 8px;
        padding-bottom: 8px;
        outline: none;
      }
      // Placeholder Text
      &::placeholder {
        color: $color-sub-text-50;
      }
    }
  }

}
