@mixin a11y-hide {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  z-index: -999;
  overflow: hidden;
}

// Used for overriding the styles above:
// Only use this for dev styles such as markup warnings.
@mixin a11y-unhide {
  // For older browsers:
  clip: inherit;
  height: inherit;
  width: inherit;
  position: inherit;
  z-index: inherit;
  overflow: inherit;
  // For newer browsers:
  clip: revert;
  height: revert;
  width: revert;
  position: revert;
  z-index: revert;
  overflow: revert;
}
