@import "../../styles/constants/colors";

.dropdown {
    select {
      &:focus {
        border: 1px solid map-get($background-and-border-colors, border);
        background: $color-white;
        box-shadow: inset 0 1px 1px $contrast-light-shadow;
        outline: none;
      }
      &:hover {
        border: 1px solid map-get($legacy-color-names, text-input-border);
        background: $color-grey-button;
        box-shadow: inset 0 1px 1px $contrast-light-shadow;
        outline: none;
      }
    }
  }

  .buttonWithSubText {
    line-height: 18px !important;
  }
