@import '../../styles/constants/colors.scss';

.boxFormat {
  width: 100%;
  min-height: 120px;
  border: solid 1px map-get($secondary-colors, support_icon);
  background-color: $color-table-background;
  text-align: center;
  padding-bottom: 1.2em;
  padding-top: 1.2em;
  border-radius: 6px;

  .buttonsFormat {
    margin-bottom: 10px;
  }

  Button {
    margin: 0 5px;
  }

  &.show {
    display: contents;
  }

  &.hide {
    display: none;
  }
}

.smallerTitle {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subText {
  margin-bottom: 1em;
  color: map-get($hubColors, dark-gray);
}
