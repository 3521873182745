@import "../../styles/constants/colors";

.popoutPanelListHeading {
  border-bottom: 1px solid $color-border;

  h3 {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px 9px;
    margin: 0 0 -1px 1rem;
    border: 1px solid $color-border;
    border-bottom-color: $color-white;
    border-radius: 4px 4px 0 0;
  }
}
