@use "sass:map";
@import "../../styles/constants/colors";

.title_container {
  flex: auto;

  .title {
    color: $color-primary-blue;
    font-size: 16px;

    button {
      color: map.get($background-and-border-colors, background-light-blue);
    }
  }

  .titleSub {
    color: map.get($primary, primary50);
    font-size: 13px;
  }
}

.containerGuestDownload > div > div{
  padding-top: 20px;
}

.download_container {
  display: flex;
  width: 100%;
  justify-content: center;

  .download_link_container {
    display: flex;

    .download {
      width: 30px;
      height: 30px;
    }
  }
}
