@import "../../../styles/constants/colors";
@import "../../../styles/mixins/hub/hub-button-focus";
@import "../../../styles/constants/breakpoints";

.outerModal {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in background-color;
  visibility: hidden;

  &.open {
    z-index: 5000;
    background-color: $contrast-light-hover;
    opacity: 1;
    pointer-events: unset;
    transition: 0.2s opacity ease-in-out;
    visibility: initial;
  }

  .innerModal {
    max-width: 700px;
    padding: 1rem 0;
    background-color: $color-white;
    border-radius: 0.3125rem;
    text-align: center;

    .modalButtonHeader {
      padding: 0 1.5rem 0 0;
      text-align: right;

      button:focus {
        @include standart-focus-button();
      }
    }

    .modalContent {
      padding: 0 1.5rem 1.5rem 1.5rem;

      > div > div {
        margin-top: 0;
        margin-bottom: 0;
      }

      p {
        @media #{$phoneMedia} {
          font-size: 1rem;
        }
      }

      h1 {
        margin-top: 0;

        @media #{$phoneMedia} {
          font-size: 1.3rem;
        }
      }

      svg {
        width: 100%;
        box-sizing: content-box;
        padding: 0;
      }

      .externalLink {
        display: block;
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 1.1em;
        font-weight: bold;
        text-decoration: underline;

        @media #{$phoneMedia} {
          font-size: 0.8rem;
        }

        svg {
          position: relative;
          top: 3px;
          width: 18px;
          margin-left: 3px;
        }
      }
    }

    .modalButtonBottom {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem 0 0;
      border-top: 1px solid map-get($secondary-colors, hub_light_grey);

      button:first-child {
        padding: 0 10px;
        margin: 0 15px;
      }
    }
  }
}
