@import '../../styles/constants/colors.scss';

.table {
  border-collapse: collapse;
  min-width: 1000px;
  width: 100%;
}

.th {
  border-bottom: 1px solid $color-border;
  font-size: 14px;
  line-height: 20px;
  padding: 7px 10px 7px;
}

.tr:hover {
  background-color: $smart-dropdown-option-hover;
}

.td {
  border-bottom: 1px solid $color-botton;
  border-right: 1px solid $color-botton;
  padding: 4px 6px;
  text-align: center;
}

.edit {
  width: 140px;
}

.status {
  width: 65px;
  padding-left: 0;
  padding-right: 0;
}

.top {
  vertical-align: top;
}

.updating {
  width: auto;
}

.label {
  margin-bottom: 10px;
}

.icon {
  height: 28px;
  width: 28px;
}

.cta {
  margin: 0 5px 5px 5px;
  display: inline-block;
}

.required {
  color: map-get($validation-colors, error);
}

.warningRow {
  padding: 7px 15px;
  background-color: map-get($background-and-border-colors, border);
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.warningTd {
  padding: 0;
}

.warningIcon {
  width: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
