@import "../../styles/constants/colors";
@import '../../styles/constants/breakpoints.scss';

.wrapper {
  overflow-x: auto;
  margin: 0 -1rem;

  @media #{$smMedia} {
    margin: 0;
  }

  :global(.color-white-dark-bg) {
    @media screen {
      &::before {
        background: -moz-linear-gradient(left, $color-transparent-white 0%, $color-transparent-black 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $color-transparent-white 0%, $color-transparent-black 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $color-transparent-white 0%, $color-transparent-black 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }

      &::after {
        background: -moz-linear-gradient(left, $color-transparent-black 0%, $color-transparent-white 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $color-transparent-black 0%, $color-transparent-white 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $color-transparent-black 0%, $color-transparent-white 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }

      > div {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}

.table {
  padding: 7px 15px;

  @media screen {
    background-color: $color-grey-button;
    border-radius: 5px;
    color: map-get($legacy-color-names, tooltip);
    white-space: normal;
  }

  @media print {
    padding: 0;
  }

  .title {
    display: inline-block;
    margin: 0;
    font-weight: 600;
    color: map-get($legacy-color-names, tooltip);
    font-size: 21px;

    @media print {
      font-size: 14px;
    }
  }

  .columnHeader {
    padding: 5px 15px !important;
    font-size: 14px;
    white-space: nowrap;

    @media print {
      white-space: normal;
    }

    &:first-of-type {
      text-align: left;
      padding-left: 0px !important;
    }
  }

  .entry {
    padding: 5px 15px !important;
    text-align: center;

    &:first-of-type {
      padding-left: 0px !important;
      width: 100%;
      text-align: left;
    }

    &:last-of-type {
      font-weight: 600;
    }
  }
}
