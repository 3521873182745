@import '../styles/constants/breakpoints';
@import '../styles/constants/variables';
@import '../styles/constants/colors';

.heading {
  margin-top: 0;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: $dark-blue;
  margin-bottom: 15px;
  line-height: 1.3;

  > p:not(:last-child) {
    margin-bottom: 1em;
  }

  @media (min-width: 680px) {
    padding-right: 280px;
  }
}

.cta {
  background-color: $green;
  border-radius: 40px;
  border: 4px solid $color-white;
  color: $color-white;
  cursor: pointer;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 15px auto -80px;
  min-width: 250px;
  padding: 20px;

  &:hover {
    background-color: $dark-green;
  }
}

.sampleSignInCard {
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;

  @media (min-width: 680px) {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    margin-left: 20px;
    transform: rotate(-10deg);
    transform-origin: center;
  }
}
