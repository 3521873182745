@import "../../../../styles/constants/breakpoints";
@import "../../../../styles/constants/colors";
@import "../../../../styles/constants/variables";

.OicHomeTopRow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: auto;
  font-family: $open-sans;
  overflow-x: hidden;

  .title {
    font-size: clamp(1rem, 2vw, 1.75rem);
  }

  .description {
    font-size: clamp(1rem, 2vw, 1.25rem);
  }

  .cta {
    padding: 0 1rem;
    font-size: clamp(1rem, 2vw, 1.25rem);
  }

  li {
    display: list-item;
    margin-left: 15px;
  }

  .landingImage {
    overflow: hidden;

    &.missing-image {
      width: 100%;

      @media screen and (min-width: $smBreakpoint) {
        height: 140px;
      }
    }
  }

  .kv {
    height: auto;
    margin-top: auto;
    margin-left: auto;
  }

  img, picture {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
