@import '../../styles/constants/colors.scss';

.sortHeader {
  display: inline-flex; // To align vertically
  align-items: center;
  fill: map-get($primary-colors, primary); // For SVG
  margin: 0;
  white-space: nowrap;

  &--sorted,
  &:hover {
    cursor: pointer;
    color: map-get($primary-colors, primary);
  }

  &__input {
    display: none;
  }

  &__icon {
    fill: map-get($typography-colors, text);
    height: 18px;
    margin-left: 5px;
    opacity: 0.3;
    vertical-align: middle;
    width: 18px;
  }

  &:hover &__icon {
    opacity: 1;
    fill: map-get($primary-colors, primary);
  }

  &--sorted &__icon {
    fill: map-get($primary-colors, primary);
    margin: 0 3px 0 7px;
    opacity: 1;
    width: 13px;
  }

  .disabled, &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
