@import "../../styles/mixins/hub/hub-button-focus";

.popoutActionFooter {
  padding: 1rem;

  h4 {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  > button {
    width: 100%;
  }

  .stackedButtons {
    display: flex;
    flex-direction: column;

    button:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .wizardButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggleContainer {
    margin-bottom: 1rem;

    label {
      margin: 0;
    }
  }
}

body[data-theme="hub-theme"] {
  .popoutActionFooter {
    button:focus {
      @include no-padding-button-on-focus();
    }
  }
}
// display: flex;
// align-items: center;
// padding: 1rem;

// button:last-child {
//   margin-left: auto;
// }
