@import '../../styles/constants/breakpoints.scss';
@import '../../styles/constants/colors.scss';
@import "../../styles/mixins/hub/hub-button-focus";

.product,
.series {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-bottom: 1px solid $color-mid-grey-2;

  &:hover,
  &.selected {
    background-color: map-get($background-and-border-colors, selected);
  }

  label {
    align-items: center;

    > div {
      margin-left: 16px;
    }
  }

  &.disabledClass {
    opacity: 0.6;
  }

  .editBtn {
    margin-top: -15px;
    margin-left: auto;
    color: map-get($primary-colors, primary);

    &:hover {
      color: map-get($primary-colors, primary-dark);
    }
  }
}

.product {
  display: flex;
}

.levelGroupContainer,
.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
}

.levelGroupContainer {
  padding-left: 1rem;
}

.contentWithButtonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.levelGroupHeader {
  display: flex;
  width: 100%;
  padding: 1.5rem;
  margin: 0;
  font-size: 1.2rem;
}

.textContainer {
  margin: 1rem;
}

.userAssignments {
  .borderTop {
    border-top: 1px solid $color-panel-background-light;
  }

  &.assignMultipleLm {
    margin: 1rem;

    .borderTop {
      border-top: 0;
    }
  }
}

.licencePeriodContainer {
  display: block;
  margin-top: 2px;
}

.licenceStatusContainer {
  display: block;
  color: map-get($typography-colors, sub-text);
}

.licencesInUseContainer {
  color: $color-sub-text;
  font-size: 1.3rem;
  font-weight: 400;
}

.licencesInUseLabel {
  max-width: 300px;
  margin: 0.5rem auto 0;
}

.informationContainer {
  margin-top: 1rem;
}

.licenceListContainer {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.locenceListHeader {
  margin-top: 1rem;
}

.licencesSubtitle {
  position: relative;
  top: -6px;
  font-size: 0.8rem;
}

.informationSpacedContainer {
  margin-top: 2rem;
}

.productLicence {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $color-mid-grey-2;

  .dropDownMenu {
    margin-left: 1rem;

    button {
      box-shadow: none;
    }
  }
}

.productLicenceTextContainer {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  padding-left: 0.75rem;

  .productLicenceLabel {
    padding: 0 0.375rem;
    background-color: map-get($secondary-colors, hub_dashboard);
    border-radius: 1rem;
    color: #fff;
    font-size: 0.8125rem;
  }

  .productLicenceTitle {
    color: map-get($primary-colors, primary);
  }

  .productLicenceStatus {
    position: relative;
    width: 100%;
    margin-top: 0.25rem;
  }
}

.link {
  display: inline-block;
  margin-left: 0.5rem;
  color: map-get($secondary-colors, hub_dashboard);
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    color: map-get($secondary-colors, hub_dashboard);
    filter: brightness(50%);
  }
}

.containerLicenceNotUsed,
.containerLicenceUsed {
  font-size: 0.9rem;
  font-weight: 900;
}

.containerLicenceUsed {
  color: map-get($hubColors, hub_success_text_green);
}

.containerLicenceNotUsed {
  color: map-get($secondary-colors, hub_dashboard);
}

.linkGlyph,
.infoIcon,
.checkIcon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
}

.checkIcon {
  fill: map-get($hubColors, hub_success_text_green);
}

.displayFlexBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  // ghost question button styles
  .questionIcon {
    width: 26px;
    height: 26px;
    border: 0;
    margin: auto;
    background-color: transparent;
    fill: $color-primary-blue;

    &:hover {
      fill: $color-primary-blue-dark;
    }
  }
}

.largeTooltip {
  height: 30px;
  padding-right: 13px;
  margin-top: 0.8em;

  span {
    z-index: 100;
    right: 0;
    left: auto;
    width: 246px;
    text-align: left;
    transform: none;

    &::after {
      right: 17px;
      left: auto;
      transform: none;
    }
  }
}

.assignmentSummary {
  &.assignmentSummaryMargin {
    margin-left: 3.75rem;
  }

  .displayFlexBoxMulti {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-top: 1rem;

    .orgLmAvailableStyle {
      color: map-get($legacy-color-names, tooltip);
      font-weight: 600;
      line-height: 1.4;
    }
  }

  .licencesContainer {
    padding: 1rem;
  }

  .availableSvgContainer {
    width: 30px;
    height: 30px;
    margin: 0 auto 0.5rem;
  }

  .unavailableContainer {
    text-align: center;
  }

  .unavailableSvgContainer {
    width: 40px;
    height: 40px;
    margin: auto;
  }

  .paragraph {
    color: $color-text;
    font-size: 1.3rem;
    font-weight: 600;
  }

  .informationInnerContainer {
    padding: 1rem;
    background-color: $color-white;
    border-radius: 5px;
    text-align: center;
  }

  .linkContainer {
    padding: 12px 0;
    text-align: center;
  }

  .licenseTypeView {
    margin-top: 1rem;
    background: $color-panel-background;
    border-radius: 8px;

    .jumbotron {
      padding: 16px;
      margin-bottom: 10px;
      background-color: $color-panel-background-light;
      border-radius: 5px;
      text-align: center;

      .mixedLicenceInfo {
        width: 30px;
        height: 30px;
        margin: auto;
        margin-bottom: 6px;
      }
    }

    .licenseViewTable {
      width: 100%;
      border-collapse: collapse;
      text-align: left;

      th,
      td {
        padding: 16px 0;
        border-bottom: 1px solid $color-panel-background-light;

        &:not(:first-child) {
          padding: 0 5px;
          text-align: center;
        }
      }

      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    hr {
      border-color: $color-panel-background-light;
    }
  }

  .reviewSummary {
    box-sizing: border-box;
    padding: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: $color-light-border;
    background-color: $color-black;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    h4 {
      margin: 0 0 5px 0;
      color: map-get($legacy-color-names, tooltip);
      font-weight: 600;
      line-height: 1.4;
    }

    .infoDisplay {
      display: flex;
      margin-top: 4px;
      font-size: 0.875rem;
    }

    .infoIcon {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      border: 0;
      margin-right: 4px;
      background-color: transparent;
      fill: $color-primary-blue;

      &:focus {
        @include no-padding-button-on-focus() ;
      }
    }

    .infoAvailable {
      margin-top: 4px;
      font-size: 0.875rem;
    }

    .infoAvailableIcon {
      width: 17px;
      height: 15px;
      flex-shrink: 0;
      margin-right: 4px;
    }

    .learMoreLink {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding-right: 1.5rem;
      padding-left: 20px;
      color: map-get($background-and-border-colors, background-light-blue);
      font-size: 0.875rem;

      &:hover {
        color: map-get($hubColors, blue-color-dark);
        text-decoration: underline;
      }

      &:focus {
        @include no-padding-button-on-focus();
      }
    }

    a.learMoreLink::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 100%;
      content: url("data:image/svg+xml;utf8,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' fill='%234776ae' viewBox='0 0 24 24'><title>icon-external</title><path d='M20,5.17a.74.74,0,0,0-.21-.57.78.78,0,0,0-.57-.23H19l-3.53,0a.81.81,0,0,0-.79.8v.31a.87.87,0,0,0,.85.75h1.31l-6.51,6.49A.93.93,0,0,0,11.63,14l6.52-6.5V8.88a.82.82,0,0,0,.24.59.72.72,0,0,0,.55.22h.26A.76.76,0,0,0,20,8.94V5.17Z'/><path d='M17.36,10.53a.93.93,0,0,0-.93.92h0v6.34H5.85V7.93h7.06a.93.93,0,0,0,0-1.86H5.42A1.43,1.43,0,0,0,4,7.5V18.22a1.43,1.43,0,0,0,1.42,1.42H16.86a1.43,1.43,0,0,0,1.42-1.42V11.45h0A.92.92,0,0,0,17.36,10.53Z'/></svg>");
    }
  }

  .licenceDetails {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    border-width: 1px;
    border-style: solid;
    border-color: $color-light-border;
    margin-top: -1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &.spaceBetween {
      justify-content: space-between;
    }

    .removeIcon {
      color: color(error);
      font-size: 16px;

      &:focus {
        @include standart-focus-button();
      }
    }

    .linkButtonStyle {
      color: map-get($background-and-border-colors, background-light-blue);

      &:hover {
        color: map-get($hubColors, blue-color-dark);
        text-decoration: underline;
      }

      &:focus {
        @include standart-focus-button();
      }
    }
  }
}
