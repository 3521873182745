@import "../../styles/constants/colors";
@import "../../styles/constants/breakpoints";
@import "../../styles/mixins/hub/hub-button-focus";

.assessmentFolderContainer {
  .courseAssessmentFolder {
    position: relative;
    max-width: none;
    box-sizing: border-box;

    &::before {
      position: absolute;
      z-index: -1;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      content: "";
    }
  }
}

.folderItemsContainer {
  padding: 0.5rem 1rem 0.5rem 2.3rem;

  .subfoldersContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .assessmentSubFolderWidth {
      min-width: 48%;
      max-width: 48%;
    }
  }
}
