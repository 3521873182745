@import "../../styles/constants/colors";

.imagePlaceholder {
  width: 130px;
  height: 180px;
  background-color: $color-botton;
  opacity: 0.2;
  border: 1px solid map-get($hubColors, hub_people_border);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 75%;
  }

  @media screen and (max-width: 700px) {
    width: 180px;
    height: 200px;
  }
}
