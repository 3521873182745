@use "sass:map";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";

$left-cell-width: 180px;

.gradebookTable {
  &__hideSm {
    display: none;
  }

  &--stickyColumn {
    position: relative;
    padding-left: $left-cell-width;
  }

  &--fixColumn {
    position: sticky;
    z-index: 2;
    right: 0;
  }

  > div {
    -webkit-overflow-scrolling: auto; // This seems to be working
    overflow-x: scroll;

    // There is a bug on iOS that obscures the sticky column if this inertia-based movement property is used.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
    // -webkit-overflow-scrolling: touch;

    // Try using -webkit-overflow-scrolling: auto;
  }

  &__Table {
    width: 100%;
    border-spacing: 0;
  }

  &__Row {
    border: none;
  }

  &__Row,
  &__Header,
  &__Cell {
    background: $color-white;

    &--shaded {
      background: map.get($primary, primary10);
    }

    &--highlight {
      background: map.get($modern, kipper-yellow);
    }

    &--shaded-highlight {
      background: map.get($modern, kipper-yellow);
    }

    > button {
      display: inline-flex;
    }
  }

  &__Header,
  &__Cell {
    min-width: 100px;
    padding: 0.7rem;
    border-top: 1px solid map.get($primary, primary20);
    border-right: 1px solid map.get($primary, primary20);
    text-align: center;
    white-space: nowrap;

    &__Header {
      font-size: 14px;
    }

    &:last-child {
      border-right: none;
    }

    &--stickyColumn {
      position: absolute;
      left: 0;
      width: $left-cell-width;
    }

    &--fixColumn {
      position: sticky;
      z-index: 2;
      right: 0;
    }

    &--textAlign-left {
      justify-content: left;
      text-align: left;
    }

    &--textAlign-right {
      justify-content: right;
      text-align: right;
    }

    &--filter-highlight {
      background-color: $color-highlight;
    }
  }

  &__Header[headers] {
    font-size: initial;
    font-weight: normal;
  }

  &__Head &__Header,
  &__Foot &__Header {
    a {
      font-size: 1rem;
    }
  }

  &__Body &__Row {
    margin-bottom: 1rem;
  }

  &__Body &__Row &__Header[headers] {
    margin-bottom: -1rem;
    font-size: 1rem;
    font-weight: normal;
    text-align: left;
  }

  /*
    Note: aligning to the SpacingOptions component, the default/normal
    spacing option is "medium", with "narrow" and "wide" as alternatives.
  */

  &--spacing-wide &__Body &__Header,
  &--spacing-wide &__Body &__Cell {
    padding: 0.8rem;
    font-size: 17px;
  }

  &--spacing-narrow &__Body &__Header,
  &--spacing-narrow &__Body &__Row &__Header,
  &--spacing-narrow &__Body &__Cell,
  &--spacing-narrow &__Body &__Row &__Cell {
    padding: 0.5rem;
    font-size: 15px;
  }

  .locked {
    background-color: map.get($neutral, neutral-grey);
  }
}

body[data-theme="hub-theme"] {
  .scrollContainer {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  }


  .gradebookTable {
    &--stickyColumn {
      width: 100%;
    }

    &--fixColumn {
      position: sticky;
      z-index: 2;
      right: 0;
    }
  }
}

@media #{$smMedia} {
  .gradebookTable {
    &__hideSm {
      display: block;
    }
  }
}

/* IE browser styles goes here */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .gradebookTable {
    > div {
      overflow-y: hidden;
    }
  }
}
