@import '../../styles/constants/colors.scss';

:global {
  @import '../../styles/orb/orb-typography.scss';
  @import '../../styles/orb/orb-images.scss';
  @import '../../styles/orb/orb-lozenges.scss';
  @import '../../styles/orb/orb-cards.scss';
}

.searchArea {
  background-color: map-get($primary-dark-colors, primary-dark);
  padding: 20px 0;
}

.correlationChart {
  margin-bottom: 15px;
}

.resourcesArea {
  padding-top: 15px;
}

.numberOfResources {
  margin-bottom: 15px;
}

.loadMore {
  margin: 35px 0 50px 0;
  text-align: center;

  button {
    width: 100%;
    max-width: 400px;
  }
}

.expiredWarning {
  font-size: 2.4rem;
  color: red;
  margin-bottom: 20px;
}

.openedContent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.downloadableContent {
  width: 0;
  height: 0;
}
