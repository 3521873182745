@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/breakpoints";
@import "~@oup/shared-front-end/src/styles/box-shadow";

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $gap-5;
  color: map-get($primary, primary100);
  font-family: $font-family-text-font;
  letter-spacing: $letter-spacing-0;
  text-align: center;

  img {
    width: $illustration-size-regular;
    height: $illustration-size-regular;
  }

  h1 {
    margin: $gap-5 0 0;
    font-size: $font-size-xlarge;
    font-weight: $font-weight-700;
    line-height: $line-height-120;

    @media screen and (max-width: $screen-width-840) {
      margin: $gap-4 0 0;
      font-size: $font-size-large;
    }
  }

  .bodyText {
    margin-top: $gap-4;
    font-size: $font-size-medium;
    font-weight: $font-weight-400;
    line-height: $line-height-default-150;

    @media screen and (max-width: $screen-width-840) {
      margin-top: $gap-3;
      font-size: $font-size-base;
    }
  }

  .additionalText {
    margin-top: $gap-5;
    font-size: $font-size-base;
    font-weight: $font-weight-400;
    line-height: $line-height-default-150;

    @media screen and (max-width: $screen-width-840) {
      margin-top: $gap-4;
    }
  }

  .buttonsContainer {
    display: flex;
    margin-top: $gap-4;

    button:nth-of-type(2) {
      margin-left: $gap-4;
    }

    button:nth-of-type(3) {
      margin-left: $gap-4;
    }

    svg {
      width: $icon-size-regular;
      height: $icon-size-regular;
      vertical-align: middle;

      &.svgFillWhite {
        path {
          fill: map-get($base, white100);
        }
      }
    }
  }

  a {
    display: inline-block;
    color: map-get($primary, primary100);
    cursor: pointer;
    font-weight: $font-weight-400;
    gap: $gap-1;
    letter-spacing: $letter-spacing-0;
    text-align: left;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.25em;

    &:hover {
      background-color: map-get($primary, primary10);
      mix-blend-mode: multiply;
      text-decoration-thickness: 2px;
    }

    &:hover:focus,
    &:focus {
      box-shadow: $box-shadow-focus;
    }

    svg {
      width: $icon-size-small;
      height: $icon-size-small;
      vertical-align: middle;
    }
  }

  .bottomLinkContainer {
    margin-top: $gap-5;
    a {
      font-weight: $font-weight-700;
    }
  }
}
