@use "sass:map";
@import "../../styles/constants/colors";
@import "../../styles/mixins/hub/hub-button-focus";

.textInputFieldWrapper {
  /* Should fill its container */
  display: block;
  width: 100%;
  word-break: break-all;
}

.textInputField {
  /* Should fill its container */
  display: block;
  overflow: auto;
  width: 100%;
  height: 40px; /* Need to explicitly set the height to solve an issue with date inputs */
  padding: 0 12px;
  border-width: 1px;

  /* Create a transparent border when not validated or focused */
  border-style: solid;
  border-color: map.get($background-and-border-colors, border);

  /* Shape and size */
  border-radius: 4px;
  box-shadow: inset 0 1px 1px $contrast-light-shadow;
  line-height: 38px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    @include standart-focus-button;

    outline: none;
  }

  &:disabled {
    background: $color-grey-disabled;
    /* non-standard colors */
    color: $color-text-grey-disabled;
  }

  &.readonly-multiline {
    height: auto;
    word-break: break-word;
  }

  &.textArea {
    height: 12rem;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1rem;
    line-height: 24px;
    resize: vertical;
  }
  /* Placeholder Text */
  input::placeholder {
    color: $color-sub-text-50;
  }
}

.infoText {
  display: inline-block;
  margin-bottom: 10px;
  color: $color-text-grey-disabled;
}

.calendarClassName {
  button span::before {
    border-color: #95989d; /* darker than default */
  }

  button:hover span::before {
    border-color: #787b7f; /* darker than default */
  }

  div[role="button"]:hover {
    background: map.get($background-and-border-colors, icon);
  }
}

body[data-theme="hub-theme"] {
  .datepickerPopper {
    z-index: 10;
  }
}

.validationIcon {
  min-width: 30px;
  max-width: 30px;
  margin-left: 10px;
}
