@import '../../styles/constants/colors.scss';

.ArticleThumbnailLinkList {
  ul {
    border: 1px solid map-get($styleGuideColors, border);
    border-radius: 5px;
    margin-bottom: 25px;
  }

  // Lines between links
  li + li {
    border-top: 1px solid map-get($styleGuideColors, border);
  }

  a {
    color: map-get($styleGuideColors, primary);
    display: flex;
    padding: 10px 15px;

    &:hover {
      color: $color-astronaut-blue;
      background-color: map-get($styleGuideColors, selected);
      text-decoration: none;
    }
  }

  .linkText {
    color: $color-black;
    font-weight: bold;
  }

  .linkHref {
    font-size: 13px;
  }

  .thumbnail {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: inline-block;
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }
}
