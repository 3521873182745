@import '../../../styles/constants/breakpoints.scss';
@import '../../../styles/constants/colors.scss';

.classReportTable {
  // These are overrides for the `Table` compoenent
  margin-bottom: 90px;

  @media print {
    page-break-inside: unset;
  }

  @media #{$smMedia} {
    table-layout: fixed; /* IE11 bugfix */
  }

  thead tr th,
  tbody tr td:not(:first-of-type) {
    position: relative;
    padding: 0;
  }

  tbody tr:first-child td {
    padding: 0;
  }

  //override Table.scss hardcoded width 100% for for first th
  thead tr th:first-child {
    @media #{$smMedia} {
      width: 23%;
    }
  }

  thead tr th:not(:first-child),
  tbody tr td:not(:first-of-type) {
    @media #{$smMedia} {
      width: 77%;
    }
  }

  //override Table.scss hardcoded width 100% for for first td
  tbody tr td:first-child {
    @media #{$smMedia} {
      width: 23%;
    }
  }

  tr > * {
    display: table-cell;
  }

  //override Table.scss label
  thead th:first-child span:nth-child(2) {
    display: none;

    @media #{$smMedia} {
      display: inline;
    }
  }
}

.toolbarRow {
  flex-direction: row-reverse;
  padding: 10px 0;
}

.nameColumn {
  align-items: center;
}

.nameColumn > div:first-child {
  @media print {
    display: none;
  }
}

.selectAllColumn {
  padding-left: 15px;
}

.selectAllColumn > div {
  top: 7px;
  display: inline-block;
  margin-right: 1rem;
}

.optionsColumn {
  flex: 1 1 auto;
  padding-right: 15px;
  margin: 0 !important;
  text-align: right;

  @media #{$xsMedia} {
    padding-right: 0;
  }
}

.dial {
  width: 300px;
  margin: 2em auto 0;
}

.icons {
  display: inline-block;
  width: 30px;
  padding: 5px;
  margin: 0 5px -8px 0;
  border-radius: 50%;
  fill: white;
}

.errorMessage {
  display: inline-block;
  margin-left: 7px;
  vertical-align: bottom;
}

.classReportTableRefreshBar {
  .labelChange {
    margin-right: 0.5rem;
    font-weight: 900;
  }

  .labelRow {
    padding: 1.2rem;
    padding-bottom: 0.4rem;
  }

  .refreshBar {
    display: flex;
    max-width: 35rem;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1.2rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media #{$mdMedia} {
  .dial {
    width: 250px;
  }
}

@media #{$smMedia} {
  .table {
    tr > * {
      display: table-cell;
    }
  }
}
