.panelSearchControl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0.375;

  & > *:first-child {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}

.searchOptions {
  padding-left: 5px;
}
