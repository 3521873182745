@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/box-shadow";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.cpCloseButton {
  width: 40px;
  height: 40px;
  border: 1px solid map-get($cptColors, light_blue2);
  background-color: map-get($cptColors, background);
  border-radius: 10px;
  color: map-get($cptColors, white);

  svg {
    width: 17px;
    height: 31px;
    margin-left: 0;
    fill: map-get($cptColors, white);
  }
}

.cpCloseButtonDictionary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}

.dictionaryHeader {
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: map-get($cptColors, background);
}

.dictionaryHeader .title {
  color: map-get($cptColors, white);
  font-size: 30px;
}

.dictionaryContainer {
  padding: 20px;
}

.dictionaryForm {
  display: flex;
  flex: 5;
  flex-direction: row;
  gap: 8px;
}

.searchBar {
  width: 90%;
  height: 36px;
  border: 1px solid var(--multiplied-colours-primary-primary-10, #e5e9ed);
  border-radius: 8px;
}

.searchButton {
  height: 36px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px solid var(--core-white-0, rgba(255, 255, 255, 0));
  background: var(--core-white-0, rgba(255, 255, 255, 0));
  border-radius: 8px;
  color: map-get($primary, primary100);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  text-align: center;
}

.searchButton:hover {
  background: var(--multiplied-colours-primary-primary-10, #e5e9ed);
  background-blend-mode: multiply;
}

.searchButton:active {
  background: map-get($primary, primary100);
  color: map-get($cptColors, white);
}

.searchButton:focus {
  box-shadow: $box-shadow-focus;

  transition: background-color 300ms, box-shadow 300ms, color 300ms;
}

.dictionaryContent {
  padding: 20px 0;
}

.dictionaryEntryContent {
  margin: $gap-2;
}

.dictionaryTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $gap-1;
}

.cpMenuIcon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cpMenuIcon svg {
  width: $icon-size-medium;
  fill: map-get($cptColors, white);
}
